import { default as api_45legacyf0HOCg9cfmMeta } from "/codebuild/output/src1146024831/src/horizon/pages/api-legacy.vue?macro=true";
import { default as awardsgYpgrB1iMsMeta } from "/codebuild/output/src1146024831/src/horizon/pages/awards.vue?macro=true";
import { default as programsbOFtF4zjhAMeta } from "/codebuild/output/src1146024831/src/horizon/pages/benefits/programs.vue?macro=true";
import { default as _91slug_93OWLViBc7wbMeta } from "/codebuild/output/src1146024831/src/horizon/pages/blog/[slug].vue?macro=true";
import { default as _91slug_930qQ3vT1GLiMeta } from "/codebuild/output/src1146024831/src/horizon/pages/blog/category/[slug].vue?macro=true";
import { default as indexp7Yp666FpqMeta } from "/codebuild/output/src1146024831/src/horizon/pages/blog/index.vue?macro=true";
import { default as call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta } from "/codebuild/output/src1146024831/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue?macro=true";
import { default as _91slug_93RKHtK3QrPqMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93NDgQFFNdUAMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/[slug].vue?macro=true";
import { default as aboutjY6Z2G7gycMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/about.vue?macro=true";
import { default as indexwnND0bYsg2Meta } from "/codebuild/output/src1146024831/src/horizon/pages/company/careers/benefits/index.vue?macro=true";
import { default as engineering_45and_45productWntSzPx5SJMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/engineering-and-product.vue?macro=true";
import { default as indexN9CB7NJQLlMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/index.vue?macro=true";
import { default as salesExVt2duptrMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/sales.vue?macro=true";
import { default as emerging_45talentOWCcnqv7HdMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/careers/emerging-talent.vue?macro=true";
import { default as indexUJ1zDydu3ZMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/careers/index.vue?macro=true";
import { default as index3lF6yZUvdDMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/careers/locations/index.vue?macro=true";
import { default as _91slug_93gU5FYqWjlOMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/[slug].vue?macro=true";
import { default as indexD6ZjgmTzvGMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/index.vue?macro=true";
import { default as contactNO77Lz8LqrMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/contact.vue?macro=true";
import { default as diversitynWDc7wSJnEMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/diversity.vue?macro=true";
import { default as indexevbMVPIdLnMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/index.vue?macro=true";
import { default as efficiencyz31xTybCWAMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/mission/efficiency.vue?macro=true";
import { default as safetyeTXya5SD4hMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/mission/safety.vue?macro=true";
import { default as sustainabilityyoZaXlHNmfMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/mission/sustainability.vue?macro=true";
import { default as in_45the_45newsUeNORlJnA7Meta } from "/codebuild/output/src1146024831/src/horizon/pages/company/news/in-the-news.vue?macro=true";
import { default as index7ZQbQDdUidMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/news/index.vue?macro=true";
import { default as press_45releasesrL9NBAKyBwMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/news/press-releases.vue?macro=true";
import { default as privacyNHPnoV7iZgMeta } from "/codebuild/output/src1146024831/src/horizon/pages/company/privacy.vue?macro=true";
import { default as cost_45assessment_45tool99CLK0um8UMeta } from "/codebuild/output/src1146024831/src/horizon/pages/cost-assessment-tool.vue?macro=true";
import { default as _91slug_93JzIgXGX7DBMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/[slug].vue?macro=true";
import { default as _91slug_93HuXsaRkmAuMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/category/[slug].vue?macro=true";
import { default as indexKxA0biOONhMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/index.vue?macro=true";
import { default as _91slug_93FUKHLlF8jZMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/tips/[slug].vue?macro=true";
import { default as _91slug_93PQXsEvFUtMMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/tips/category/[slug].vue?macro=true";
import { default as indexSV3z7gnmjPMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/tips/index.vue?macro=true";
import { default as _91_46_46_46slug_93rVIkrXYccyMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue?macro=true";
import { default as above_45beyondVLjcKeMPk3Meta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/above-beyond.vue?macro=true";
import { default as excellence_45performanceeXEcxlN0RVMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue?macro=true";
import { default as excellence_45service5v3K8Hi7VWMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-service.vue?macro=true";
import { default as indexdi38pAHVv0Meta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/index.vue?macro=true";
import { default as safest_45fleet4iusJ5rBEdMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue?macro=true";
import { default as driver_45performanceAd8EdgNEedMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/driver-performance.vue?macro=true";
import { default as excellence_45performancec2GZOTkCKFMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue?macro=true";
import { default as fleet_45innovator7om76Jp5vRMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue?macro=true";
import { default as indexwuVwGfrfukMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/index.vue?macro=true";
import { default as public_45fleetnkdJRZFFxHMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/public-fleet.vue?macro=true";
import { default as safest_45fleetOe8iqgiBUoMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue?macro=true";
import { default as indexKFUUwVqHpcMeta } from "/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/index.vue?macro=true";
import { default as _91slug_936KcMo1jQ2HMeta } from "/codebuild/output/src1146024831/src/horizon/pages/events/[slug].vue?macro=true";
import { default as index7OItxuEU6iMeta } from "/codebuild/output/src1146024831/src/horizon/pages/events/index.vue?macro=true";
import { default as fleet_45app4dAaq0CiDVMeta } from "/codebuild/output/src1146024831/src/horizon/pages/fleet-app.vue?macro=true";
import { default as _91slug_93b8oxRSMA45Meta } from "/codebuild/output/src1146024831/src/horizon/pages/fleet/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93aAQ8b0OJbjMeta } from "/codebuild/output/src1146024831/src/horizon/pages/fleet/[slug].vue?macro=true";
import { default as _91slug_931QfdNBHRMqMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/[slug].vue?macro=true";
import { default as attract_45retain_45talentp0XFeV6FiQMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue?macro=true";
import { default as increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue?macro=true";
import { default as indexf075u1mE3xMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/index.vue?macro=true";
import { default as protect_45optimize_45assetspAFNpePIS3Meta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue?macro=true";
import { default as best_45dash_45camsejvmk0iCPHMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue?macro=true";
import { default as indexHQFmG19AtDMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/index.vue?macro=true";
import { default as safety_45programArLHyjidYoMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-program.vue?macro=true";
import { default as safety_45roiBNE4ZNt9joMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-roi.vue?macro=true";
import { default as indexXbQAoLRc4sMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/index.vue?macro=true";
import { default as build_45for_45the_45futurebqEf9upeFjMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue?macro=true";
import { default as empower_45your_45teams1vulEWPybtMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue?macro=true";
import { default as indexfvuRr4wttDMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/index.vue?macro=true";
import { default as take_45action_45with_45dataB5o6ggP2WAMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue?macro=true";
import { default as _91slug_93EW0AVVOXopMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue?macro=true";
import { default as indexTqvdHjOZQyMeta } from "/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue?macro=true";
import { default as indexwH1a3v3OQkMeta } from "/codebuild/output/src1146024831/src/horizon/pages/index.vue?macro=true";
import { default as _91slug_93o9EB4ptuqnMeta } from "/codebuild/output/src1146024831/src/horizon/pages/industrial/[slug].vue?macro=true";
import { default as _91slug_93t9kBIowUUtMeta } from "/codebuild/output/src1146024831/src/horizon/pages/industries/[slug].vue?macro=true";
import { default as indexXnA36DpsnjMeta } from "/codebuild/output/src1146024831/src/horizon/pages/industries/index.vue?macro=true";
import { default as citiespKSah627rWMeta } from "/codebuild/output/src1146024831/src/horizon/pages/insights/cities.vue?macro=true";
import { default as index8Jh7n9HAosMeta } from "/codebuild/output/src1146024831/src/horizon/pages/integrations/index.vue?macro=true";
import { default as index0FnYcgUsyCMeta } from "/codebuild/output/src1146024831/src/horizon/pages/invite/index.vue?macro=true";
import { default as learn_45moreyoet67FiBUMeta } from "/codebuild/output/src1146024831/src/horizon/pages/learn-more.vue?macro=true";
import { default as candidate_45privacy_45noticeUVShCPQ2PyMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/candidate-privacy-notice.vue?macro=true";
import { default as cookie_45consentjxXnF8xg22Meta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-consent.vue?macro=true";
import { default as cookie_45policymB7ioutq42Meta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-policy.vue?macro=true";
import { default as data_45protection_45addendumBzeFTvtt8OMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/data-protection-addendum.vue?macro=true";
import { default as express_45order_45termsZBhmvffk8wMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/express-order-terms.vue?macro=true";
import { default as hardware_45warrantyiNQzXCJD9zMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/hardware-warranty.vue?macro=true";
import { default as hosted_45software_45slawGLX6bb1cSMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/hosted-software-sla.vue?macro=true";
import { default as partner_45code_45of_45conductnAjYmZ9zFVMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/partner-code-of-conduct.vue?macro=true";
import { default as platform_45terms_45of_45servicex49KjrKiEXMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/platform-terms-of-service.vue?macro=true";
import { default as privacyWA1KeDmi5rMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/privacy.vue?macro=true";
import { default as public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue?macro=true";
import { default as requestingcustomerdataUi8BU8YMgLMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/requestingcustomerdata.vue?macro=true";
import { default as securityQR1MEnlhCIMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/security.vue?macro=true";
import { default as transparencyreporthuvpAvDR0FMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/transparencyreport.vue?macro=true";
import { default as website_45terms_45of_45servicenxppPwVMIYMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/website-terms-of-service.vue?macro=true";
import { default as your_45privacy_45choicestbLr2jvrTyMeta } from "/codebuild/output/src1146024831/src/horizon/pages/legal/your-privacy-choices.vue?macro=true";
import { default as pricingG6h1fx7u0ZMeta } from "/codebuild/output/src1146024831/src/horizon/pages/page/pricing.vue?macro=true";
import { default as _91slug_93DWLyFsxVkIMeta } from "/codebuild/output/src1146024831/src/horizon/pages/pages/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93p59oG4YytIMeta } from "/codebuild/output/src1146024831/src/horizon/pages/pages/[slug].vue?macro=true";
import { default as asset_45trackingb5XqkqMAUqMeta } from "/codebuild/output/src1146024831/src/horizon/pages/pages/asset-tracking.vue?macro=true";
import { default as best_45dash_45camsO0FzeRaZQOMeta } from "/codebuild/output/src1146024831/src/horizon/pages/pages/best-dash-cams.vue?macro=true";
import { default as constructionXTK8xlPrSXMeta } from "/codebuild/output/src1146024831/src/horizon/pages/pages/construction.vue?macro=true";
import { default as fleet_45managementmLHQSBR3RaMeta } from "/codebuild/output/src1146024831/src/horizon/pages/pages/fleet-management.vue?macro=true";
import { default as nationwide1NSSYMoYKpMeta } from "/codebuild/output/src1146024831/src/horizon/pages/pages/nationwide.vue?macro=true";
import { default as safety_45coaching1DLjoi67QWMeta } from "/codebuild/output/src1146024831/src/horizon/pages/pages/safety-coaching.vue?macro=true";
import { default as pricingJmzX0NdIiMMeta } from "/codebuild/output/src1146024831/src/horizon/pages/pricing.vue?macro=true";
import { default as _91slug_93yKFVoDkTQqMeta } from "/codebuild/output/src1146024831/src/horizon/pages/products/[prefix]/[slug].vue?macro=true";
import { default as _91slug_93eBZ0yF3w8RMeta } from "/codebuild/output/src1146024831/src/horizon/pages/products/[slug].vue?macro=true";
import { default as asset_45trackingZSw2p8sjgrMeta } from "/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue?macro=true";
import { default as equipment_45trackingKvHS985TiIMeta } from "/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue?macro=true";
import { default as reefer_45monitoringupIKotA9G9Meta } from "/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue?macro=true";
import { default as smart_45trailersRF1z8SK0sTMeta } from "/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue?macro=true";
import { default as trailer_45tracking5yog54i72WMeta } from "/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue?macro=true";
import { default as indexuQKDX2Xae9Meta } from "/codebuild/output/src1146024831/src/horizon/pages/products/index.vue?macro=true";
import { default as _91slug_93kmLfz6QxHZMeta } from "/codebuild/output/src1146024831/src/horizon/pages/products/models/[slug].vue?macro=true";
import { default as indexmRYLkwozHuMeta } from "/codebuild/output/src1146024831/src/horizon/pages/products/models/index.vue?macro=true";
import { default as gps_45fleet_45trackingfgRuofwOE5Meta } from "/codebuild/output/src1146024831/src/horizon/pages/products/telematics/gps-fleet-tracking.vue?macro=true";
import { default as indexAYKIxQMafCMeta } from "/codebuild/output/src1146024831/src/horizon/pages/products/telematics/index.vue?macro=true";
import { default as referralsCz6JFEcY5WMeta } from "/codebuild/output/src1146024831/src/horizon/pages/referrals.vue?macro=true";
import { default as _91slug_93s5XoXltRBBMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/[slug].vue?macro=true";
import { default as brand_45assetspS10vHQYfLMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/brand-assets.vue?macro=true";
import { default as _91slug_930ne7ZB6G3nMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/content/[slug].vue?macro=true";
import { default as email_45preferences_45centerN3Pzald1hoMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/content/email-preferences-center.vue?macro=true";
import { default as one_45click_45unsubscribeI9x0uE3mLsMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/content/one-click-unsubscribe.vue?macro=true";
import { default as _91slug_9396AXK4GmJVMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/experts/[slug].vue?macro=true";
import { default as _91slug_93l5SYcbNHvNMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/experts/category/[slug].vue?macro=true";
import { default as index4Otwyzf88BMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/experts/index.vue?macro=true";
import { default as index0scpmezXseMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/index.vue?macro=true";
import { default as _91slug_939MnvXT7plBMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/[slug].vue?macro=true";
import { default as _91slug_93mGdAb4vNkjMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/category/[slug].vue?macro=true";
import { default as index8JYl3T8JUKMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/index.vue?macro=true";
import { default as build_45with_45samsaraaDfCI4QL6cMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/build-with-samsara.vue?macro=true";
import { default as evolve_45insurance_45programs143iOuyndOMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue?macro=true";
import { default as index9yOZRw47FcMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/index.vue?macro=true";
import { default as provide_45servicesha5MjDQpD4Meta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/provide-services.vue?macro=true";
import { default as sell_45samsara4PfcS2YPyGMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/sell-samsara.vue?macro=true";
import { default as tourNbtqZmSqgfMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/tour.vue?macro=true";
import { default as videoszMpIapMiYiMeta } from "/codebuild/output/src1146024831/src/horizon/pages/resources/videos.vue?macro=true";
import { default as indexaWrgMgYvmBMeta } from "/codebuild/output/src1146024831/src/horizon/pages/roi-calculator/index.vue?macro=true";
import { default as samsara_45venturespEEkMTCYM5Meta } from "/codebuild/output/src1146024831/src/horizon/pages/samsara-ventures.vue?macro=true";
import { default as security_45summitXiWbsYAbEWMeta } from "/codebuild/output/src1146024831/src/horizon/pages/security-summit.vue?macro=true";
import { default as _91slug_933Whhx14nJAMeta } from "/codebuild/output/src1146024831/src/horizon/pages/solutions/[slug].vue?macro=true";
import { default as indexPiynYGTCDVMeta } from "/codebuild/output/src1146024831/src/horizon/pages/solutions/index.vue?macro=true";
import { default as securityOreqRDijW6Meta } from "/codebuild/output/src1146024831/src/horizon/pages/solutions/security.vue?macro=true";
import { default as spacehzw0Vn71UCMeta } from "/codebuild/output/src1146024831/src/horizon/pages/space.vue?macro=true";
import { default as spark1Wko5nXLaUMeta } from "/codebuild/output/src1146024831/src/horizon/pages/spark.vue?macro=true";
import { default as _91slug_93VeS4OweZF1Meta } from "/codebuild/output/src1146024831/src/horizon/pages/styles/[slug].vue?macro=true";
import { default as _91slug_93SfQGVby4qeMeta } from "/codebuild/output/src1146024831/src/horizon/pages/support/[prefix]/[slug].vue?macro=true";
import { default as _91slug_933DgPUltuFEMeta } from "/codebuild/output/src1146024831/src/horizon/pages/support/[slug].vue?macro=true";
import { default as apps_45privacy_45policyLTMoK14AUdMeta } from "/codebuild/output/src1146024831/src/horizon/pages/support/apps-privacy-policy.vue?macro=true";
import { default as customer_45referrals_45faqGAZfV2aSUaMeta } from "/codebuild/output/src1146024831/src/horizon/pages/support/customer-referrals-faq.vue?macro=true";
import { default as driver_45training_45spanishcUvcFhFD7mMeta } from "/codebuild/output/src1146024831/src/horizon/pages/support/driver-training-spanish.vue?macro=true";
import { default as driver_45trainingpWLuawigwBMeta } from "/codebuild/output/src1146024831/src/horizon/pages/support/driver-training.vue?macro=true";
import { default as indexrT9mqcUqGOMeta } from "/codebuild/output/src1146024831/src/horizon/pages/support/index.vue?macro=true";
import { default as industrialUf11sOnH61Meta } from "/codebuild/output/src1146024831/src/horizon/pages/support/industrial.vue?macro=true";
import { default as modern_45slavery_45statement2dPOiYbyC4Meta } from "/codebuild/output/src1146024831/src/horizon/pages/support/modern-slavery-statement.vue?macro=true";
import { default as apps_45privacy_45policyrokOnuwc5nMeta } from "/codebuild/output/src1146024831/src/horizon/pages/support/privacy/apps-privacy-policy.vue?macro=true";
import { default as indexm3pUALtPjVMeta } from "/codebuild/output/src1146024831/src/horizon/pages/support/privacy/index.vue?macro=true";
import { default as special_45featuresMVrQL1YyP1Meta } from "/codebuild/output/src1146024831/src/horizon/pages/support/privacy/special-features.vue?macro=true";
import { default as subprocessor_45listjLVPZnshzKMeta } from "/codebuild/output/src1146024831/src/horizon/pages/support/privacy/subprocessor-list.vue?macro=true";
import { default as support_45kb_45articlesJrBNXKsRk0Meta } from "/codebuild/output/src1146024831/src/horizon/pages/support/support-kb-articles.vue?macro=true";
import { default as thank_45you_45pageupGhtubXEKMeta } from "/codebuild/output/src1146024831/src/horizon/pages/thank-you-page.vue?macro=true";
import { default as thankyou_45recruitingHpnR5GDq7YMeta } from "/codebuild/output/src1146024831/src/horizon/pages/thankyou-recruiting.vue?macro=true";
import { default as thankyou_45referralMTclriTzMGMeta } from "/codebuild/output/src1146024831/src/horizon/pages/thankyou-referral.vue?macro=true";
import { default as thankyouM1tKYuy0WSMeta } from "/codebuild/output/src1146024831/src/horizon/pages/thankyou.vue?macro=true";
import { default as eliteZyXPc9QlFFMeta } from "/codebuild/output/src1146024831/src/horizon/pages/tiers/elite.vue?macro=true";
import { default as plusQfbwSkeAVZMeta } from "/codebuild/output/src1146024831/src/horizon/pages/tiers/plus.vue?macro=true";
import { default as premierfqbp6koKr4Meta } from "/codebuild/output/src1146024831/src/horizon/pages/tiers/premier.vue?macro=true";
import { default as starterAAT2AD9ifKMeta } from "/codebuild/output/src1146024831/src/horizon/pages/tiers/starter.vue?macro=true";
import { default as processd2GoM6jeXcMeta } from "/codebuild/output/src1146024831/src/horizon/pages/trial/process.vue?macro=true";
import { default as supportGYqEHNb5hNMeta } from "/codebuild/output/src1146024831/src/horizon/pages/warranty/support.vue?macro=true";
import { default as _91slug_93MjvyIMUlenMeta } from "/codebuild/output/src1146024831/src/horizon/pages/webinars/[slug].vue?macro=true";
import { default as indexfR7k4n860eMeta } from "/codebuild/output/src1146024831/src/horizon/pages/webinars/index.vue?macro=true";
import { default as _91slug_93OfcTuPvAQLMeta } from "/codebuild/output/src1146024831/src/horizon/pages/webinars/thank-you/[slug].vue?macro=true";
export default [
  {
    name: api_45legacyf0HOCg9cfmMeta?.name ?? "api-legacy___en-US",
    path: api_45legacyf0HOCg9cfmMeta?.path ?? "/api-legacy",
    meta: api_45legacyf0HOCg9cfmMeta || {},
    alias: api_45legacyf0HOCg9cfmMeta?.alias || [],
    redirect: api_45legacyf0HOCg9cfmMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyf0HOCg9cfmMeta?.name ?? "api-legacy___ca",
    path: api_45legacyf0HOCg9cfmMeta?.path ?? "/ca/api-legacy",
    meta: api_45legacyf0HOCg9cfmMeta || {},
    alias: api_45legacyf0HOCg9cfmMeta?.alias || [],
    redirect: api_45legacyf0HOCg9cfmMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyf0HOCg9cfmMeta?.name ?? "api-legacy___nl",
    path: api_45legacyf0HOCg9cfmMeta?.path ?? "/nl/api-legacy",
    meta: api_45legacyf0HOCg9cfmMeta || {},
    alias: api_45legacyf0HOCg9cfmMeta?.alias || [],
    redirect: api_45legacyf0HOCg9cfmMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyf0HOCg9cfmMeta?.name ?? "api-legacy___uk",
    path: api_45legacyf0HOCg9cfmMeta?.path ?? "/uk/api-legacy",
    meta: api_45legacyf0HOCg9cfmMeta || {},
    alias: api_45legacyf0HOCg9cfmMeta?.alias || [],
    redirect: api_45legacyf0HOCg9cfmMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyf0HOCg9cfmMeta?.name ?? "api-legacy___de",
    path: api_45legacyf0HOCg9cfmMeta?.path ?? "/de/api-legacy",
    meta: api_45legacyf0HOCg9cfmMeta || {},
    alias: api_45legacyf0HOCg9cfmMeta?.alias || [],
    redirect: api_45legacyf0HOCg9cfmMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyf0HOCg9cfmMeta?.name ?? "api-legacy___mx",
    path: api_45legacyf0HOCg9cfmMeta?.path ?? "/mx/api-legacy",
    meta: api_45legacyf0HOCg9cfmMeta || {},
    alias: api_45legacyf0HOCg9cfmMeta?.alias || [],
    redirect: api_45legacyf0HOCg9cfmMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyf0HOCg9cfmMeta?.name ?? "api-legacy___fr",
    path: api_45legacyf0HOCg9cfmMeta?.path ?? "/fr/api-legacy",
    meta: api_45legacyf0HOCg9cfmMeta || {},
    alias: api_45legacyf0HOCg9cfmMeta?.alias || [],
    redirect: api_45legacyf0HOCg9cfmMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: api_45legacyf0HOCg9cfmMeta?.name ?? "api-legacy___fr-ca",
    path: api_45legacyf0HOCg9cfmMeta?.path ?? "/fr-ca/api-legacy",
    meta: api_45legacyf0HOCg9cfmMeta || {},
    alias: api_45legacyf0HOCg9cfmMeta?.alias || [],
    redirect: api_45legacyf0HOCg9cfmMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/api-legacy.vue").then(m => m.default || m)
  },
  {
    name: awardsgYpgrB1iMsMeta?.name ?? "awards___en-US",
    path: awardsgYpgrB1iMsMeta?.path ?? "/awards",
    meta: awardsgYpgrB1iMsMeta || {},
    alias: awardsgYpgrB1iMsMeta?.alias || [],
    redirect: awardsgYpgrB1iMsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsgYpgrB1iMsMeta?.name ?? "awards___ca",
    path: awardsgYpgrB1iMsMeta?.path ?? "/ca/awards",
    meta: awardsgYpgrB1iMsMeta || {},
    alias: awardsgYpgrB1iMsMeta?.alias || [],
    redirect: awardsgYpgrB1iMsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsgYpgrB1iMsMeta?.name ?? "awards___nl",
    path: awardsgYpgrB1iMsMeta?.path ?? "/nl/awards",
    meta: awardsgYpgrB1iMsMeta || {},
    alias: awardsgYpgrB1iMsMeta?.alias || [],
    redirect: awardsgYpgrB1iMsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsgYpgrB1iMsMeta?.name ?? "awards___uk",
    path: awardsgYpgrB1iMsMeta?.path ?? "/uk/awards",
    meta: awardsgYpgrB1iMsMeta || {},
    alias: awardsgYpgrB1iMsMeta?.alias || [],
    redirect: awardsgYpgrB1iMsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsgYpgrB1iMsMeta?.name ?? "awards___de",
    path: awardsgYpgrB1iMsMeta?.path ?? "/de/awards",
    meta: awardsgYpgrB1iMsMeta || {},
    alias: awardsgYpgrB1iMsMeta?.alias || [],
    redirect: awardsgYpgrB1iMsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsgYpgrB1iMsMeta?.name ?? "awards___mx",
    path: awardsgYpgrB1iMsMeta?.path ?? "/mx/awards",
    meta: awardsgYpgrB1iMsMeta || {},
    alias: awardsgYpgrB1iMsMeta?.alias || [],
    redirect: awardsgYpgrB1iMsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsgYpgrB1iMsMeta?.name ?? "awards___fr",
    path: awardsgYpgrB1iMsMeta?.path ?? "/fr/awards",
    meta: awardsgYpgrB1iMsMeta || {},
    alias: awardsgYpgrB1iMsMeta?.alias || [],
    redirect: awardsgYpgrB1iMsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsgYpgrB1iMsMeta?.name ?? "awards___fr-ca",
    path: awardsgYpgrB1iMsMeta?.path ?? "/fr-ca/awards",
    meta: awardsgYpgrB1iMsMeta || {},
    alias: awardsgYpgrB1iMsMeta?.alias || [],
    redirect: awardsgYpgrB1iMsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/awards.vue").then(m => m.default || m)
  },
  {
    name: programsbOFtF4zjhAMeta?.name ?? "benefits-programs___en-US",
    path: programsbOFtF4zjhAMeta?.path ?? "/benefits/programs",
    meta: programsbOFtF4zjhAMeta || {},
    alias: programsbOFtF4zjhAMeta?.alias || [],
    redirect: programsbOFtF4zjhAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbOFtF4zjhAMeta?.name ?? "benefits-programs___ca",
    path: programsbOFtF4zjhAMeta?.path ?? "/ca/benefits/programs",
    meta: programsbOFtF4zjhAMeta || {},
    alias: programsbOFtF4zjhAMeta?.alias || [],
    redirect: programsbOFtF4zjhAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbOFtF4zjhAMeta?.name ?? "benefits-programs___nl",
    path: programsbOFtF4zjhAMeta?.path ?? "/nl/benefits/programs",
    meta: programsbOFtF4zjhAMeta || {},
    alias: programsbOFtF4zjhAMeta?.alias || [],
    redirect: programsbOFtF4zjhAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbOFtF4zjhAMeta?.name ?? "benefits-programs___uk",
    path: programsbOFtF4zjhAMeta?.path ?? "/uk/benefits/programs",
    meta: programsbOFtF4zjhAMeta || {},
    alias: programsbOFtF4zjhAMeta?.alias || [],
    redirect: programsbOFtF4zjhAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbOFtF4zjhAMeta?.name ?? "benefits-programs___de",
    path: programsbOFtF4zjhAMeta?.path ?? "/de/benefits/programs",
    meta: programsbOFtF4zjhAMeta || {},
    alias: programsbOFtF4zjhAMeta?.alias || [],
    redirect: programsbOFtF4zjhAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbOFtF4zjhAMeta?.name ?? "benefits-programs___mx",
    path: programsbOFtF4zjhAMeta?.path ?? "/mx/benefits/programs",
    meta: programsbOFtF4zjhAMeta || {},
    alias: programsbOFtF4zjhAMeta?.alias || [],
    redirect: programsbOFtF4zjhAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbOFtF4zjhAMeta?.name ?? "benefits-programs___fr",
    path: programsbOFtF4zjhAMeta?.path ?? "/fr/benefits/programs",
    meta: programsbOFtF4zjhAMeta || {},
    alias: programsbOFtF4zjhAMeta?.alias || [],
    redirect: programsbOFtF4zjhAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: programsbOFtF4zjhAMeta?.name ?? "benefits-programs___fr-ca",
    path: programsbOFtF4zjhAMeta?.path ?? "/fr-ca/benefits/programs",
    meta: programsbOFtF4zjhAMeta || {},
    alias: programsbOFtF4zjhAMeta?.alias || [],
    redirect: programsbOFtF4zjhAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/benefits/programs.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OWLViBc7wbMeta?.name ?? "blog-slug___en-US",
    path: _91slug_93OWLViBc7wbMeta?.path ?? "/blog/:slug()",
    meta: _91slug_93OWLViBc7wbMeta || {},
    alias: _91slug_93OWLViBc7wbMeta?.alias || [],
    redirect: _91slug_93OWLViBc7wbMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OWLViBc7wbMeta?.name ?? "blog-slug___ca",
    path: _91slug_93OWLViBc7wbMeta?.path ?? "/ca/blog/:slug()",
    meta: _91slug_93OWLViBc7wbMeta || {},
    alias: _91slug_93OWLViBc7wbMeta?.alias || [],
    redirect: _91slug_93OWLViBc7wbMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OWLViBc7wbMeta?.name ?? "blog-slug___mx",
    path: _91slug_93OWLViBc7wbMeta?.path ?? "/mx/blog/:slug()",
    meta: _91slug_93OWLViBc7wbMeta || {},
    alias: _91slug_93OWLViBc7wbMeta?.alias || [],
    redirect: _91slug_93OWLViBc7wbMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OWLViBc7wbMeta?.name ?? "blog-slug___uk",
    path: _91slug_93OWLViBc7wbMeta?.path ?? "/uk/blog/:slug()",
    meta: _91slug_93OWLViBc7wbMeta || {},
    alias: _91slug_93OWLViBc7wbMeta?.alias || [],
    redirect: _91slug_93OWLViBc7wbMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OWLViBc7wbMeta?.name ?? "blog-slug___fr",
    path: _91slug_93OWLViBc7wbMeta?.path ?? "/fr/blog/:slug()",
    meta: _91slug_93OWLViBc7wbMeta || {},
    alias: _91slug_93OWLViBc7wbMeta?.alias || [],
    redirect: _91slug_93OWLViBc7wbMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OWLViBc7wbMeta?.name ?? "blog-slug___de",
    path: _91slug_93OWLViBc7wbMeta?.path ?? "/de/blog/:slug()",
    meta: _91slug_93OWLViBc7wbMeta || {},
    alias: _91slug_93OWLViBc7wbMeta?.alias || [],
    redirect: _91slug_93OWLViBc7wbMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930qQ3vT1GLiMeta?.name ?? "blog-category-slug___en-US",
    path: _91slug_930qQ3vT1GLiMeta?.path ?? "/blog/category/:slug()",
    meta: _91slug_930qQ3vT1GLiMeta || {},
    alias: _91slug_930qQ3vT1GLiMeta?.alias || [],
    redirect: _91slug_930qQ3vT1GLiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930qQ3vT1GLiMeta?.name ?? "blog-category-slug___ca",
    path: _91slug_930qQ3vT1GLiMeta?.path ?? "/ca/blog/category/:slug()",
    meta: _91slug_930qQ3vT1GLiMeta || {},
    alias: _91slug_930qQ3vT1GLiMeta?.alias || [],
    redirect: _91slug_930qQ3vT1GLiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930qQ3vT1GLiMeta?.name ?? "blog-category-slug___mx",
    path: _91slug_930qQ3vT1GLiMeta?.path ?? "/mx/blog/category/:slug()",
    meta: _91slug_930qQ3vT1GLiMeta || {},
    alias: _91slug_930qQ3vT1GLiMeta?.alias || [],
    redirect: _91slug_930qQ3vT1GLiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930qQ3vT1GLiMeta?.name ?? "blog-category-slug___uk",
    path: _91slug_930qQ3vT1GLiMeta?.path ?? "/uk/blog/category/:slug()",
    meta: _91slug_930qQ3vT1GLiMeta || {},
    alias: _91slug_930qQ3vT1GLiMeta?.alias || [],
    redirect: _91slug_930qQ3vT1GLiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930qQ3vT1GLiMeta?.name ?? "blog-category-slug___fr",
    path: _91slug_930qQ3vT1GLiMeta?.path ?? "/fr/blog/category/:slug()",
    meta: _91slug_930qQ3vT1GLiMeta || {},
    alias: _91slug_930qQ3vT1GLiMeta?.alias || [],
    redirect: _91slug_930qQ3vT1GLiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930qQ3vT1GLiMeta?.name ?? "blog-category-slug___de",
    path: _91slug_930qQ3vT1GLiMeta?.path ?? "/de/blog/category/:slug()",
    meta: _91slug_930qQ3vT1GLiMeta || {},
    alias: _91slug_930qQ3vT1GLiMeta?.alias || [],
    redirect: _91slug_930qQ3vT1GLiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexp7Yp666FpqMeta?.name ?? "blog___en-US",
    path: indexp7Yp666FpqMeta?.path ?? "/blog",
    meta: indexp7Yp666FpqMeta || {},
    alias: indexp7Yp666FpqMeta?.alias || [],
    redirect: indexp7Yp666FpqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexp7Yp666FpqMeta?.name ?? "blog___ca",
    path: indexp7Yp666FpqMeta?.path ?? "/ca/blog",
    meta: indexp7Yp666FpqMeta || {},
    alias: indexp7Yp666FpqMeta?.alias || [],
    redirect: indexp7Yp666FpqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexp7Yp666FpqMeta?.name ?? "blog___mx",
    path: indexp7Yp666FpqMeta?.path ?? "/mx/blog",
    meta: indexp7Yp666FpqMeta || {},
    alias: indexp7Yp666FpqMeta?.alias || [],
    redirect: indexp7Yp666FpqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexp7Yp666FpqMeta?.name ?? "blog___uk",
    path: indexp7Yp666FpqMeta?.path ?? "/uk/blog",
    meta: indexp7Yp666FpqMeta || {},
    alias: indexp7Yp666FpqMeta?.alias || [],
    redirect: indexp7Yp666FpqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexp7Yp666FpqMeta?.name ?? "blog___fr",
    path: indexp7Yp666FpqMeta?.path ?? "/fr/blog",
    meta: indexp7Yp666FpqMeta || {},
    alias: indexp7Yp666FpqMeta?.alias || [],
    redirect: indexp7Yp666FpqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexp7Yp666FpqMeta?.name ?? "blog___de",
    path: indexp7Yp666FpqMeta?.path ?? "/de/blog",
    meta: indexp7Yp666FpqMeta || {},
    alias: indexp7Yp666FpqMeta?.alias || [],
    redirect: indexp7Yp666FpqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.name ?? "call-for-speakers-at-samsara-beyond___en-US",
    path: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.path ?? "/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.name ?? "call-for-speakers-at-samsara-beyond___ca",
    path: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.path ?? "/ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.name ?? "call-for-speakers-at-samsara-beyond___nl",
    path: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.path ?? "/nl/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.name ?? "call-for-speakers-at-samsara-beyond___uk",
    path: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.path ?? "/uk/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.name ?? "call-for-speakers-at-samsara-beyond___de",
    path: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.path ?? "/de/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.name ?? "call-for-speakers-at-samsara-beyond___mx",
    path: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.path ?? "/mx/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr",
    path: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.path ?? "/fr/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.name ?? "call-for-speakers-at-samsara-beyond___fr-ca",
    path: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.path ?? "/fr-ca/call-for-speakers-at-samsara-beyond",
    meta: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta || {},
    alias: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.alias || [],
    redirect: call_45for_45speakers_45at_45samsara_45beyondupRIxogVgWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/call-for-speakers-at-samsara-beyond.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RKHtK3QrPqMeta?.name ?? "company-prefix-slug___en-US",
    path: _91slug_93RKHtK3QrPqMeta?.path ?? "/company/:prefix()/:slug()",
    meta: _91slug_93RKHtK3QrPqMeta || {},
    alias: _91slug_93RKHtK3QrPqMeta?.alias || [],
    redirect: _91slug_93RKHtK3QrPqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RKHtK3QrPqMeta?.name ?? "company-prefix-slug___ca",
    path: _91slug_93RKHtK3QrPqMeta?.path ?? "/ca/company/:prefix()/:slug()",
    meta: _91slug_93RKHtK3QrPqMeta || {},
    alias: _91slug_93RKHtK3QrPqMeta?.alias || [],
    redirect: _91slug_93RKHtK3QrPqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RKHtK3QrPqMeta?.name ?? "company-prefix-slug___nl",
    path: _91slug_93RKHtK3QrPqMeta?.path ?? "/nl/company/:prefix()/:slug()",
    meta: _91slug_93RKHtK3QrPqMeta || {},
    alias: _91slug_93RKHtK3QrPqMeta?.alias || [],
    redirect: _91slug_93RKHtK3QrPqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RKHtK3QrPqMeta?.name ?? "company-prefix-slug___uk",
    path: _91slug_93RKHtK3QrPqMeta?.path ?? "/uk/company/:prefix()/:slug()",
    meta: _91slug_93RKHtK3QrPqMeta || {},
    alias: _91slug_93RKHtK3QrPqMeta?.alias || [],
    redirect: _91slug_93RKHtK3QrPqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RKHtK3QrPqMeta?.name ?? "company-prefix-slug___de",
    path: _91slug_93RKHtK3QrPqMeta?.path ?? "/de/company/:prefix()/:slug()",
    meta: _91slug_93RKHtK3QrPqMeta || {},
    alias: _91slug_93RKHtK3QrPqMeta?.alias || [],
    redirect: _91slug_93RKHtK3QrPqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RKHtK3QrPqMeta?.name ?? "company-prefix-slug___mx",
    path: _91slug_93RKHtK3QrPqMeta?.path ?? "/mx/company/:prefix()/:slug()",
    meta: _91slug_93RKHtK3QrPqMeta || {},
    alias: _91slug_93RKHtK3QrPqMeta?.alias || [],
    redirect: _91slug_93RKHtK3QrPqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RKHtK3QrPqMeta?.name ?? "company-prefix-slug___fr",
    path: _91slug_93RKHtK3QrPqMeta?.path ?? "/fr/company/:prefix()/:slug()",
    meta: _91slug_93RKHtK3QrPqMeta || {},
    alias: _91slug_93RKHtK3QrPqMeta?.alias || [],
    redirect: _91slug_93RKHtK3QrPqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93RKHtK3QrPqMeta?.name ?? "company-prefix-slug___fr-ca",
    path: _91slug_93RKHtK3QrPqMeta?.path ?? "/fr-ca/company/:prefix()/:slug()",
    meta: _91slug_93RKHtK3QrPqMeta || {},
    alias: _91slug_93RKHtK3QrPqMeta?.alias || [],
    redirect: _91slug_93RKHtK3QrPqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NDgQFFNdUAMeta?.name ?? "company-slug___en-US",
    path: _91slug_93NDgQFFNdUAMeta?.path ?? "/company/:slug()",
    meta: _91slug_93NDgQFFNdUAMeta || {},
    alias: _91slug_93NDgQFFNdUAMeta?.alias || [],
    redirect: _91slug_93NDgQFFNdUAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NDgQFFNdUAMeta?.name ?? "company-slug___ca",
    path: _91slug_93NDgQFFNdUAMeta?.path ?? "/ca/company/:slug()",
    meta: _91slug_93NDgQFFNdUAMeta || {},
    alias: _91slug_93NDgQFFNdUAMeta?.alias || [],
    redirect: _91slug_93NDgQFFNdUAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NDgQFFNdUAMeta?.name ?? "company-slug___nl",
    path: _91slug_93NDgQFFNdUAMeta?.path ?? "/nl/company/:slug()",
    meta: _91slug_93NDgQFFNdUAMeta || {},
    alias: _91slug_93NDgQFFNdUAMeta?.alias || [],
    redirect: _91slug_93NDgQFFNdUAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NDgQFFNdUAMeta?.name ?? "company-slug___uk",
    path: _91slug_93NDgQFFNdUAMeta?.path ?? "/uk/company/:slug()",
    meta: _91slug_93NDgQFFNdUAMeta || {},
    alias: _91slug_93NDgQFFNdUAMeta?.alias || [],
    redirect: _91slug_93NDgQFFNdUAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NDgQFFNdUAMeta?.name ?? "company-slug___de",
    path: _91slug_93NDgQFFNdUAMeta?.path ?? "/de/company/:slug()",
    meta: _91slug_93NDgQFFNdUAMeta || {},
    alias: _91slug_93NDgQFFNdUAMeta?.alias || [],
    redirect: _91slug_93NDgQFFNdUAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NDgQFFNdUAMeta?.name ?? "company-slug___mx",
    path: _91slug_93NDgQFFNdUAMeta?.path ?? "/mx/company/:slug()",
    meta: _91slug_93NDgQFFNdUAMeta || {},
    alias: _91slug_93NDgQFFNdUAMeta?.alias || [],
    redirect: _91slug_93NDgQFFNdUAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NDgQFFNdUAMeta?.name ?? "company-slug___fr",
    path: _91slug_93NDgQFFNdUAMeta?.path ?? "/fr/company/:slug()",
    meta: _91slug_93NDgQFFNdUAMeta || {},
    alias: _91slug_93NDgQFFNdUAMeta?.alias || [],
    redirect: _91slug_93NDgQFFNdUAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93NDgQFFNdUAMeta?.name ?? "company-slug___fr-ca",
    path: _91slug_93NDgQFFNdUAMeta?.path ?? "/fr-ca/company/:slug()",
    meta: _91slug_93NDgQFFNdUAMeta || {},
    alias: _91slug_93NDgQFFNdUAMeta?.alias || [],
    redirect: _91slug_93NDgQFFNdUAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/[slug].vue").then(m => m.default || m)
  },
  {
    name: aboutjY6Z2G7gycMeta?.name ?? "company-about___en-US",
    path: aboutjY6Z2G7gycMeta?.path ?? "/company/about",
    meta: aboutjY6Z2G7gycMeta || {},
    alias: aboutjY6Z2G7gycMeta?.alias || [],
    redirect: aboutjY6Z2G7gycMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutjY6Z2G7gycMeta?.name ?? "company-about___ca",
    path: aboutjY6Z2G7gycMeta?.path ?? "/ca/company/about",
    meta: aboutjY6Z2G7gycMeta || {},
    alias: aboutjY6Z2G7gycMeta?.alias || [],
    redirect: aboutjY6Z2G7gycMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutjY6Z2G7gycMeta?.name ?? "company-about___nl",
    path: aboutjY6Z2G7gycMeta?.path ?? "/nl/company/about",
    meta: aboutjY6Z2G7gycMeta || {},
    alias: aboutjY6Z2G7gycMeta?.alias || [],
    redirect: aboutjY6Z2G7gycMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutjY6Z2G7gycMeta?.name ?? "company-about___uk",
    path: aboutjY6Z2G7gycMeta?.path ?? "/uk/company/about",
    meta: aboutjY6Z2G7gycMeta || {},
    alias: aboutjY6Z2G7gycMeta?.alias || [],
    redirect: aboutjY6Z2G7gycMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutjY6Z2G7gycMeta?.name ?? "company-about___de",
    path: aboutjY6Z2G7gycMeta?.path ?? "/de/company/about",
    meta: aboutjY6Z2G7gycMeta || {},
    alias: aboutjY6Z2G7gycMeta?.alias || [],
    redirect: aboutjY6Z2G7gycMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutjY6Z2G7gycMeta?.name ?? "company-about___mx",
    path: aboutjY6Z2G7gycMeta?.path ?? "/mx/company/about",
    meta: aboutjY6Z2G7gycMeta || {},
    alias: aboutjY6Z2G7gycMeta?.alias || [],
    redirect: aboutjY6Z2G7gycMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutjY6Z2G7gycMeta?.name ?? "company-about___fr",
    path: aboutjY6Z2G7gycMeta?.path ?? "/fr/company/about",
    meta: aboutjY6Z2G7gycMeta || {},
    alias: aboutjY6Z2G7gycMeta?.alias || [],
    redirect: aboutjY6Z2G7gycMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: aboutjY6Z2G7gycMeta?.name ?? "company-about___fr-ca",
    path: aboutjY6Z2G7gycMeta?.path ?? "/fr-ca/company/about",
    meta: aboutjY6Z2G7gycMeta || {},
    alias: aboutjY6Z2G7gycMeta?.alias || [],
    redirect: aboutjY6Z2G7gycMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/about.vue").then(m => m.default || m)
  },
  {
    name: indexwnND0bYsg2Meta?.name ?? "company-careers-benefits___en-US",
    path: indexwnND0bYsg2Meta?.path ?? "/company/careers/benefits",
    meta: indexwnND0bYsg2Meta || {},
    alias: indexwnND0bYsg2Meta?.alias || [],
    redirect: indexwnND0bYsg2Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnND0bYsg2Meta?.name ?? "company-careers-benefits___ca",
    path: indexwnND0bYsg2Meta?.path ?? "/ca/company/careers/benefits",
    meta: indexwnND0bYsg2Meta || {},
    alias: indexwnND0bYsg2Meta?.alias || [],
    redirect: indexwnND0bYsg2Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnND0bYsg2Meta?.name ?? "company-careers-benefits___nl",
    path: indexwnND0bYsg2Meta?.path ?? "/nl/company/careers/benefits",
    meta: indexwnND0bYsg2Meta || {},
    alias: indexwnND0bYsg2Meta?.alias || [],
    redirect: indexwnND0bYsg2Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnND0bYsg2Meta?.name ?? "company-careers-benefits___uk",
    path: indexwnND0bYsg2Meta?.path ?? "/uk/company/careers/benefits",
    meta: indexwnND0bYsg2Meta || {},
    alias: indexwnND0bYsg2Meta?.alias || [],
    redirect: indexwnND0bYsg2Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnND0bYsg2Meta?.name ?? "company-careers-benefits___de",
    path: indexwnND0bYsg2Meta?.path ?? "/de/company/careers/benefits",
    meta: indexwnND0bYsg2Meta || {},
    alias: indexwnND0bYsg2Meta?.alias || [],
    redirect: indexwnND0bYsg2Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnND0bYsg2Meta?.name ?? "company-careers-benefits___mx",
    path: indexwnND0bYsg2Meta?.path ?? "/mx/company/careers/benefits",
    meta: indexwnND0bYsg2Meta || {},
    alias: indexwnND0bYsg2Meta?.alias || [],
    redirect: indexwnND0bYsg2Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnND0bYsg2Meta?.name ?? "company-careers-benefits___fr",
    path: indexwnND0bYsg2Meta?.path ?? "/fr/company/careers/benefits",
    meta: indexwnND0bYsg2Meta || {},
    alias: indexwnND0bYsg2Meta?.alias || [],
    redirect: indexwnND0bYsg2Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: indexwnND0bYsg2Meta?.name ?? "company-careers-benefits___fr-ca",
    path: indexwnND0bYsg2Meta?.path ?? "/fr-ca/company/careers/benefits",
    meta: indexwnND0bYsg2Meta || {},
    alias: indexwnND0bYsg2Meta?.alias || [],
    redirect: indexwnND0bYsg2Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/benefits/index.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productWntSzPx5SJMeta?.name ?? "company-careers-departments-engineering-and-product___en-US",
    path: engineering_45and_45productWntSzPx5SJMeta?.path ?? "/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productWntSzPx5SJMeta || {},
    alias: engineering_45and_45productWntSzPx5SJMeta?.alias || [],
    redirect: engineering_45and_45productWntSzPx5SJMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productWntSzPx5SJMeta?.name ?? "company-careers-departments-engineering-and-product___ca",
    path: engineering_45and_45productWntSzPx5SJMeta?.path ?? "/ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productWntSzPx5SJMeta || {},
    alias: engineering_45and_45productWntSzPx5SJMeta?.alias || [],
    redirect: engineering_45and_45productWntSzPx5SJMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productWntSzPx5SJMeta?.name ?? "company-careers-departments-engineering-and-product___nl",
    path: engineering_45and_45productWntSzPx5SJMeta?.path ?? "/nl/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productWntSzPx5SJMeta || {},
    alias: engineering_45and_45productWntSzPx5SJMeta?.alias || [],
    redirect: engineering_45and_45productWntSzPx5SJMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productWntSzPx5SJMeta?.name ?? "company-careers-departments-engineering-and-product___uk",
    path: engineering_45and_45productWntSzPx5SJMeta?.path ?? "/uk/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productWntSzPx5SJMeta || {},
    alias: engineering_45and_45productWntSzPx5SJMeta?.alias || [],
    redirect: engineering_45and_45productWntSzPx5SJMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productWntSzPx5SJMeta?.name ?? "company-careers-departments-engineering-and-product___de",
    path: engineering_45and_45productWntSzPx5SJMeta?.path ?? "/de/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productWntSzPx5SJMeta || {},
    alias: engineering_45and_45productWntSzPx5SJMeta?.alias || [],
    redirect: engineering_45and_45productWntSzPx5SJMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productWntSzPx5SJMeta?.name ?? "company-careers-departments-engineering-and-product___mx",
    path: engineering_45and_45productWntSzPx5SJMeta?.path ?? "/mx/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productWntSzPx5SJMeta || {},
    alias: engineering_45and_45productWntSzPx5SJMeta?.alias || [],
    redirect: engineering_45and_45productWntSzPx5SJMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productWntSzPx5SJMeta?.name ?? "company-careers-departments-engineering-and-product___fr",
    path: engineering_45and_45productWntSzPx5SJMeta?.path ?? "/fr/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productWntSzPx5SJMeta || {},
    alias: engineering_45and_45productWntSzPx5SJMeta?.alias || [],
    redirect: engineering_45and_45productWntSzPx5SJMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: engineering_45and_45productWntSzPx5SJMeta?.name ?? "company-careers-departments-engineering-and-product___fr-ca",
    path: engineering_45and_45productWntSzPx5SJMeta?.path ?? "/fr-ca/company/careers/departments/engineering-and-product",
    meta: engineering_45and_45productWntSzPx5SJMeta || {},
    alias: engineering_45and_45productWntSzPx5SJMeta?.alias || [],
    redirect: engineering_45and_45productWntSzPx5SJMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/engineering-and-product.vue").then(m => m.default || m)
  },
  {
    name: indexN9CB7NJQLlMeta?.name ?? "company-careers-departments___en-US",
    path: indexN9CB7NJQLlMeta?.path ?? "/company/careers/departments",
    meta: indexN9CB7NJQLlMeta || {},
    alias: indexN9CB7NJQLlMeta?.alias || [],
    redirect: indexN9CB7NJQLlMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexN9CB7NJQLlMeta?.name ?? "company-careers-departments___ca",
    path: indexN9CB7NJQLlMeta?.path ?? "/ca/company/careers/departments",
    meta: indexN9CB7NJQLlMeta || {},
    alias: indexN9CB7NJQLlMeta?.alias || [],
    redirect: indexN9CB7NJQLlMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexN9CB7NJQLlMeta?.name ?? "company-careers-departments___nl",
    path: indexN9CB7NJQLlMeta?.path ?? "/nl/company/careers/departments",
    meta: indexN9CB7NJQLlMeta || {},
    alias: indexN9CB7NJQLlMeta?.alias || [],
    redirect: indexN9CB7NJQLlMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexN9CB7NJQLlMeta?.name ?? "company-careers-departments___uk",
    path: indexN9CB7NJQLlMeta?.path ?? "/uk/company/careers/departments",
    meta: indexN9CB7NJQLlMeta || {},
    alias: indexN9CB7NJQLlMeta?.alias || [],
    redirect: indexN9CB7NJQLlMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexN9CB7NJQLlMeta?.name ?? "company-careers-departments___de",
    path: indexN9CB7NJQLlMeta?.path ?? "/de/company/careers/departments",
    meta: indexN9CB7NJQLlMeta || {},
    alias: indexN9CB7NJQLlMeta?.alias || [],
    redirect: indexN9CB7NJQLlMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexN9CB7NJQLlMeta?.name ?? "company-careers-departments___mx",
    path: indexN9CB7NJQLlMeta?.path ?? "/mx/company/careers/departments",
    meta: indexN9CB7NJQLlMeta || {},
    alias: indexN9CB7NJQLlMeta?.alias || [],
    redirect: indexN9CB7NJQLlMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexN9CB7NJQLlMeta?.name ?? "company-careers-departments___fr",
    path: indexN9CB7NJQLlMeta?.path ?? "/fr/company/careers/departments",
    meta: indexN9CB7NJQLlMeta || {},
    alias: indexN9CB7NJQLlMeta?.alias || [],
    redirect: indexN9CB7NJQLlMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: indexN9CB7NJQLlMeta?.name ?? "company-careers-departments___fr-ca",
    path: indexN9CB7NJQLlMeta?.path ?? "/fr-ca/company/careers/departments",
    meta: indexN9CB7NJQLlMeta || {},
    alias: indexN9CB7NJQLlMeta?.alias || [],
    redirect: indexN9CB7NJQLlMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/index.vue").then(m => m.default || m)
  },
  {
    name: salesExVt2duptrMeta?.name ?? "company-careers-departments-sales___en-US",
    path: salesExVt2duptrMeta?.path ?? "/company/careers/departments/sales",
    meta: salesExVt2duptrMeta || {},
    alias: salesExVt2duptrMeta?.alias || [],
    redirect: salesExVt2duptrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesExVt2duptrMeta?.name ?? "company-careers-departments-sales___ca",
    path: salesExVt2duptrMeta?.path ?? "/ca/company/careers/departments/sales",
    meta: salesExVt2duptrMeta || {},
    alias: salesExVt2duptrMeta?.alias || [],
    redirect: salesExVt2duptrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesExVt2duptrMeta?.name ?? "company-careers-departments-sales___nl",
    path: salesExVt2duptrMeta?.path ?? "/nl/company/careers/departments/sales",
    meta: salesExVt2duptrMeta || {},
    alias: salesExVt2duptrMeta?.alias || [],
    redirect: salesExVt2duptrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesExVt2duptrMeta?.name ?? "company-careers-departments-sales___uk",
    path: salesExVt2duptrMeta?.path ?? "/uk/company/careers/departments/sales",
    meta: salesExVt2duptrMeta || {},
    alias: salesExVt2duptrMeta?.alias || [],
    redirect: salesExVt2duptrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesExVt2duptrMeta?.name ?? "company-careers-departments-sales___de",
    path: salesExVt2duptrMeta?.path ?? "/de/company/careers/departments/sales",
    meta: salesExVt2duptrMeta || {},
    alias: salesExVt2duptrMeta?.alias || [],
    redirect: salesExVt2duptrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesExVt2duptrMeta?.name ?? "company-careers-departments-sales___mx",
    path: salesExVt2duptrMeta?.path ?? "/mx/company/careers/departments/sales",
    meta: salesExVt2duptrMeta || {},
    alias: salesExVt2duptrMeta?.alias || [],
    redirect: salesExVt2duptrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesExVt2duptrMeta?.name ?? "company-careers-departments-sales___fr",
    path: salesExVt2duptrMeta?.path ?? "/fr/company/careers/departments/sales",
    meta: salesExVt2duptrMeta || {},
    alias: salesExVt2duptrMeta?.alias || [],
    redirect: salesExVt2duptrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: salesExVt2duptrMeta?.name ?? "company-careers-departments-sales___fr-ca",
    path: salesExVt2duptrMeta?.path ?? "/fr-ca/company/careers/departments/sales",
    meta: salesExVt2duptrMeta || {},
    alias: salesExVt2duptrMeta?.alias || [],
    redirect: salesExVt2duptrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/departments/sales.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOWCcnqv7HdMeta?.name ?? "company-careers-emerging-talent___en-US",
    path: emerging_45talentOWCcnqv7HdMeta?.path ?? "/company/careers/emerging-talent",
    meta: emerging_45talentOWCcnqv7HdMeta || {},
    alias: emerging_45talentOWCcnqv7HdMeta?.alias || [],
    redirect: emerging_45talentOWCcnqv7HdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOWCcnqv7HdMeta?.name ?? "company-careers-emerging-talent___ca",
    path: emerging_45talentOWCcnqv7HdMeta?.path ?? "/ca/company/careers/emerging-talent",
    meta: emerging_45talentOWCcnqv7HdMeta || {},
    alias: emerging_45talentOWCcnqv7HdMeta?.alias || [],
    redirect: emerging_45talentOWCcnqv7HdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOWCcnqv7HdMeta?.name ?? "company-careers-emerging-talent___nl",
    path: emerging_45talentOWCcnqv7HdMeta?.path ?? "/nl/company/careers/emerging-talent",
    meta: emerging_45talentOWCcnqv7HdMeta || {},
    alias: emerging_45talentOWCcnqv7HdMeta?.alias || [],
    redirect: emerging_45talentOWCcnqv7HdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOWCcnqv7HdMeta?.name ?? "company-careers-emerging-talent___uk",
    path: emerging_45talentOWCcnqv7HdMeta?.path ?? "/uk/company/careers/emerging-talent",
    meta: emerging_45talentOWCcnqv7HdMeta || {},
    alias: emerging_45talentOWCcnqv7HdMeta?.alias || [],
    redirect: emerging_45talentOWCcnqv7HdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOWCcnqv7HdMeta?.name ?? "company-careers-emerging-talent___de",
    path: emerging_45talentOWCcnqv7HdMeta?.path ?? "/de/company/careers/emerging-talent",
    meta: emerging_45talentOWCcnqv7HdMeta || {},
    alias: emerging_45talentOWCcnqv7HdMeta?.alias || [],
    redirect: emerging_45talentOWCcnqv7HdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOWCcnqv7HdMeta?.name ?? "company-careers-emerging-talent___mx",
    path: emerging_45talentOWCcnqv7HdMeta?.path ?? "/mx/company/careers/emerging-talent",
    meta: emerging_45talentOWCcnqv7HdMeta || {},
    alias: emerging_45talentOWCcnqv7HdMeta?.alias || [],
    redirect: emerging_45talentOWCcnqv7HdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOWCcnqv7HdMeta?.name ?? "company-careers-emerging-talent___fr",
    path: emerging_45talentOWCcnqv7HdMeta?.path ?? "/fr/company/careers/emerging-talent",
    meta: emerging_45talentOWCcnqv7HdMeta || {},
    alias: emerging_45talentOWCcnqv7HdMeta?.alias || [],
    redirect: emerging_45talentOWCcnqv7HdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: emerging_45talentOWCcnqv7HdMeta?.name ?? "company-careers-emerging-talent___fr-ca",
    path: emerging_45talentOWCcnqv7HdMeta?.path ?? "/fr-ca/company/careers/emerging-talent",
    meta: emerging_45talentOWCcnqv7HdMeta || {},
    alias: emerging_45talentOWCcnqv7HdMeta?.alias || [],
    redirect: emerging_45talentOWCcnqv7HdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/emerging-talent.vue").then(m => m.default || m)
  },
  {
    name: indexUJ1zDydu3ZMeta?.name ?? "company-careers___en-US",
    path: indexUJ1zDydu3ZMeta?.path ?? "/company/careers",
    meta: indexUJ1zDydu3ZMeta || {},
    alias: indexUJ1zDydu3ZMeta?.alias || [],
    redirect: indexUJ1zDydu3ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexUJ1zDydu3ZMeta?.name ?? "company-careers___ca",
    path: indexUJ1zDydu3ZMeta?.path ?? "/ca/company/careers",
    meta: indexUJ1zDydu3ZMeta || {},
    alias: indexUJ1zDydu3ZMeta?.alias || [],
    redirect: indexUJ1zDydu3ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexUJ1zDydu3ZMeta?.name ?? "company-careers___nl",
    path: indexUJ1zDydu3ZMeta?.path ?? "/nl/company/careers",
    meta: indexUJ1zDydu3ZMeta || {},
    alias: indexUJ1zDydu3ZMeta?.alias || [],
    redirect: indexUJ1zDydu3ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexUJ1zDydu3ZMeta?.name ?? "company-careers___uk",
    path: indexUJ1zDydu3ZMeta?.path ?? "/uk/company/careers",
    meta: indexUJ1zDydu3ZMeta || {},
    alias: indexUJ1zDydu3ZMeta?.alias || [],
    redirect: indexUJ1zDydu3ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexUJ1zDydu3ZMeta?.name ?? "company-careers___de",
    path: indexUJ1zDydu3ZMeta?.path ?? "/de/company/careers",
    meta: indexUJ1zDydu3ZMeta || {},
    alias: indexUJ1zDydu3ZMeta?.alias || [],
    redirect: indexUJ1zDydu3ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexUJ1zDydu3ZMeta?.name ?? "company-careers___mx",
    path: indexUJ1zDydu3ZMeta?.path ?? "/mx/company/careers",
    meta: indexUJ1zDydu3ZMeta || {},
    alias: indexUJ1zDydu3ZMeta?.alias || [],
    redirect: indexUJ1zDydu3ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexUJ1zDydu3ZMeta?.name ?? "company-careers___fr",
    path: indexUJ1zDydu3ZMeta?.path ?? "/fr/company/careers",
    meta: indexUJ1zDydu3ZMeta || {},
    alias: indexUJ1zDydu3ZMeta?.alias || [],
    redirect: indexUJ1zDydu3ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: indexUJ1zDydu3ZMeta?.name ?? "company-careers___fr-ca",
    path: indexUJ1zDydu3ZMeta?.path ?? "/fr-ca/company/careers",
    meta: indexUJ1zDydu3ZMeta || {},
    alias: indexUJ1zDydu3ZMeta?.alias || [],
    redirect: indexUJ1zDydu3ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/index.vue").then(m => m.default || m)
  },
  {
    name: index3lF6yZUvdDMeta?.name ?? "company-careers-locations___en-US",
    path: index3lF6yZUvdDMeta?.path ?? "/company/careers/locations",
    meta: index3lF6yZUvdDMeta || {},
    alias: index3lF6yZUvdDMeta?.alias || [],
    redirect: index3lF6yZUvdDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index3lF6yZUvdDMeta?.name ?? "company-careers-locations___ca",
    path: index3lF6yZUvdDMeta?.path ?? "/ca/company/careers/locations",
    meta: index3lF6yZUvdDMeta || {},
    alias: index3lF6yZUvdDMeta?.alias || [],
    redirect: index3lF6yZUvdDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index3lF6yZUvdDMeta?.name ?? "company-careers-locations___nl",
    path: index3lF6yZUvdDMeta?.path ?? "/nl/company/careers/locations",
    meta: index3lF6yZUvdDMeta || {},
    alias: index3lF6yZUvdDMeta?.alias || [],
    redirect: index3lF6yZUvdDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index3lF6yZUvdDMeta?.name ?? "company-careers-locations___uk",
    path: index3lF6yZUvdDMeta?.path ?? "/uk/company/careers/locations",
    meta: index3lF6yZUvdDMeta || {},
    alias: index3lF6yZUvdDMeta?.alias || [],
    redirect: index3lF6yZUvdDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index3lF6yZUvdDMeta?.name ?? "company-careers-locations___de",
    path: index3lF6yZUvdDMeta?.path ?? "/de/company/careers/locations",
    meta: index3lF6yZUvdDMeta || {},
    alias: index3lF6yZUvdDMeta?.alias || [],
    redirect: index3lF6yZUvdDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index3lF6yZUvdDMeta?.name ?? "company-careers-locations___mx",
    path: index3lF6yZUvdDMeta?.path ?? "/mx/company/careers/locations",
    meta: index3lF6yZUvdDMeta || {},
    alias: index3lF6yZUvdDMeta?.alias || [],
    redirect: index3lF6yZUvdDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index3lF6yZUvdDMeta?.name ?? "company-careers-locations___fr",
    path: index3lF6yZUvdDMeta?.path ?? "/fr/company/careers/locations",
    meta: index3lF6yZUvdDMeta || {},
    alias: index3lF6yZUvdDMeta?.alias || [],
    redirect: index3lF6yZUvdDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: index3lF6yZUvdDMeta?.name ?? "company-careers-locations___fr-ca",
    path: index3lF6yZUvdDMeta?.path ?? "/fr-ca/company/careers/locations",
    meta: index3lF6yZUvdDMeta || {},
    alias: index3lF6yZUvdDMeta?.alias || [],
    redirect: index3lF6yZUvdDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/locations/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gU5FYqWjlOMeta?.name ?? "company-careers-roles-slug___en-US",
    path: _91slug_93gU5FYqWjlOMeta?.path ?? "/company/careers/roles/:slug()",
    meta: _91slug_93gU5FYqWjlOMeta || {},
    alias: _91slug_93gU5FYqWjlOMeta?.alias || [],
    redirect: _91slug_93gU5FYqWjlOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gU5FYqWjlOMeta?.name ?? "company-careers-roles-slug___ca",
    path: _91slug_93gU5FYqWjlOMeta?.path ?? "/ca/company/careers/roles/:slug()",
    meta: _91slug_93gU5FYqWjlOMeta || {},
    alias: _91slug_93gU5FYqWjlOMeta?.alias || [],
    redirect: _91slug_93gU5FYqWjlOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gU5FYqWjlOMeta?.name ?? "company-careers-roles-slug___nl",
    path: _91slug_93gU5FYqWjlOMeta?.path ?? "/nl/company/careers/roles/:slug()",
    meta: _91slug_93gU5FYqWjlOMeta || {},
    alias: _91slug_93gU5FYqWjlOMeta?.alias || [],
    redirect: _91slug_93gU5FYqWjlOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gU5FYqWjlOMeta?.name ?? "company-careers-roles-slug___uk",
    path: _91slug_93gU5FYqWjlOMeta?.path ?? "/uk/company/careers/roles/:slug()",
    meta: _91slug_93gU5FYqWjlOMeta || {},
    alias: _91slug_93gU5FYqWjlOMeta?.alias || [],
    redirect: _91slug_93gU5FYqWjlOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gU5FYqWjlOMeta?.name ?? "company-careers-roles-slug___de",
    path: _91slug_93gU5FYqWjlOMeta?.path ?? "/de/company/careers/roles/:slug()",
    meta: _91slug_93gU5FYqWjlOMeta || {},
    alias: _91slug_93gU5FYqWjlOMeta?.alias || [],
    redirect: _91slug_93gU5FYqWjlOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gU5FYqWjlOMeta?.name ?? "company-careers-roles-slug___mx",
    path: _91slug_93gU5FYqWjlOMeta?.path ?? "/mx/company/careers/roles/:slug()",
    meta: _91slug_93gU5FYqWjlOMeta || {},
    alias: _91slug_93gU5FYqWjlOMeta?.alias || [],
    redirect: _91slug_93gU5FYqWjlOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gU5FYqWjlOMeta?.name ?? "company-careers-roles-slug___fr",
    path: _91slug_93gU5FYqWjlOMeta?.path ?? "/fr/company/careers/roles/:slug()",
    meta: _91slug_93gU5FYqWjlOMeta || {},
    alias: _91slug_93gU5FYqWjlOMeta?.alias || [],
    redirect: _91slug_93gU5FYqWjlOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93gU5FYqWjlOMeta?.name ?? "company-careers-roles-slug___fr-ca",
    path: _91slug_93gU5FYqWjlOMeta?.path ?? "/fr-ca/company/careers/roles/:slug()",
    meta: _91slug_93gU5FYqWjlOMeta || {},
    alias: _91slug_93gU5FYqWjlOMeta?.alias || [],
    redirect: _91slug_93gU5FYqWjlOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexD6ZjgmTzvGMeta?.name ?? "company-careers-roles___en-US",
    path: indexD6ZjgmTzvGMeta?.path ?? "/company/careers/roles",
    meta: indexD6ZjgmTzvGMeta || {},
    alias: indexD6ZjgmTzvGMeta?.alias || [],
    redirect: indexD6ZjgmTzvGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexD6ZjgmTzvGMeta?.name ?? "company-careers-roles___ca",
    path: indexD6ZjgmTzvGMeta?.path ?? "/ca/company/careers/roles",
    meta: indexD6ZjgmTzvGMeta || {},
    alias: indexD6ZjgmTzvGMeta?.alias || [],
    redirect: indexD6ZjgmTzvGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexD6ZjgmTzvGMeta?.name ?? "company-careers-roles___nl",
    path: indexD6ZjgmTzvGMeta?.path ?? "/nl/company/careers/roles",
    meta: indexD6ZjgmTzvGMeta || {},
    alias: indexD6ZjgmTzvGMeta?.alias || [],
    redirect: indexD6ZjgmTzvGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexD6ZjgmTzvGMeta?.name ?? "company-careers-roles___uk",
    path: indexD6ZjgmTzvGMeta?.path ?? "/uk/company/careers/roles",
    meta: indexD6ZjgmTzvGMeta || {},
    alias: indexD6ZjgmTzvGMeta?.alias || [],
    redirect: indexD6ZjgmTzvGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexD6ZjgmTzvGMeta?.name ?? "company-careers-roles___de",
    path: indexD6ZjgmTzvGMeta?.path ?? "/de/company/careers/roles",
    meta: indexD6ZjgmTzvGMeta || {},
    alias: indexD6ZjgmTzvGMeta?.alias || [],
    redirect: indexD6ZjgmTzvGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexD6ZjgmTzvGMeta?.name ?? "company-careers-roles___mx",
    path: indexD6ZjgmTzvGMeta?.path ?? "/mx/company/careers/roles",
    meta: indexD6ZjgmTzvGMeta || {},
    alias: indexD6ZjgmTzvGMeta?.alias || [],
    redirect: indexD6ZjgmTzvGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexD6ZjgmTzvGMeta?.name ?? "company-careers-roles___fr",
    path: indexD6ZjgmTzvGMeta?.path ?? "/fr/company/careers/roles",
    meta: indexD6ZjgmTzvGMeta || {},
    alias: indexD6ZjgmTzvGMeta?.alias || [],
    redirect: indexD6ZjgmTzvGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: indexD6ZjgmTzvGMeta?.name ?? "company-careers-roles___fr-ca",
    path: indexD6ZjgmTzvGMeta?.path ?? "/fr-ca/company/careers/roles",
    meta: indexD6ZjgmTzvGMeta || {},
    alias: indexD6ZjgmTzvGMeta?.alias || [],
    redirect: indexD6ZjgmTzvGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/careers/roles/index.vue").then(m => m.default || m)
  },
  {
    name: contactNO77Lz8LqrMeta?.name ?? "company-contact___en-US",
    path: contactNO77Lz8LqrMeta?.path ?? "/company/contact",
    meta: contactNO77Lz8LqrMeta || {},
    alias: contactNO77Lz8LqrMeta?.alias || [],
    redirect: contactNO77Lz8LqrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNO77Lz8LqrMeta?.name ?? "company-contact___ca",
    path: contactNO77Lz8LqrMeta?.path ?? "/ca/company/contact",
    meta: contactNO77Lz8LqrMeta || {},
    alias: contactNO77Lz8LqrMeta?.alias || [],
    redirect: contactNO77Lz8LqrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNO77Lz8LqrMeta?.name ?? "company-contact___nl",
    path: contactNO77Lz8LqrMeta?.path ?? "/nl/company/contact",
    meta: contactNO77Lz8LqrMeta || {},
    alias: contactNO77Lz8LqrMeta?.alias || [],
    redirect: contactNO77Lz8LqrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNO77Lz8LqrMeta?.name ?? "company-contact___uk",
    path: contactNO77Lz8LqrMeta?.path ?? "/uk/company/contact",
    meta: contactNO77Lz8LqrMeta || {},
    alias: contactNO77Lz8LqrMeta?.alias || [],
    redirect: contactNO77Lz8LqrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNO77Lz8LqrMeta?.name ?? "company-contact___de",
    path: contactNO77Lz8LqrMeta?.path ?? "/de/company/contact",
    meta: contactNO77Lz8LqrMeta || {},
    alias: contactNO77Lz8LqrMeta?.alias || [],
    redirect: contactNO77Lz8LqrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNO77Lz8LqrMeta?.name ?? "company-contact___mx",
    path: contactNO77Lz8LqrMeta?.path ?? "/mx/company/contact",
    meta: contactNO77Lz8LqrMeta || {},
    alias: contactNO77Lz8LqrMeta?.alias || [],
    redirect: contactNO77Lz8LqrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNO77Lz8LqrMeta?.name ?? "company-contact___fr",
    path: contactNO77Lz8LqrMeta?.path ?? "/fr/company/contact",
    meta: contactNO77Lz8LqrMeta || {},
    alias: contactNO77Lz8LqrMeta?.alias || [],
    redirect: contactNO77Lz8LqrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: contactNO77Lz8LqrMeta?.name ?? "company-contact___fr-ca",
    path: contactNO77Lz8LqrMeta?.path ?? "/fr-ca/company/contact",
    meta: contactNO77Lz8LqrMeta || {},
    alias: contactNO77Lz8LqrMeta?.alias || [],
    redirect: contactNO77Lz8LqrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/contact.vue").then(m => m.default || m)
  },
  {
    name: diversitynWDc7wSJnEMeta?.name ?? "company-diversity___en-US",
    path: diversitynWDc7wSJnEMeta?.path ?? "/company/diversity",
    meta: diversitynWDc7wSJnEMeta || {},
    alias: diversitynWDc7wSJnEMeta?.alias || [],
    redirect: diversitynWDc7wSJnEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitynWDc7wSJnEMeta?.name ?? "company-diversity___ca",
    path: diversitynWDc7wSJnEMeta?.path ?? "/ca/company/diversity",
    meta: diversitynWDc7wSJnEMeta || {},
    alias: diversitynWDc7wSJnEMeta?.alias || [],
    redirect: diversitynWDc7wSJnEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitynWDc7wSJnEMeta?.name ?? "company-diversity___nl",
    path: diversitynWDc7wSJnEMeta?.path ?? "/nl/company/diversity",
    meta: diversitynWDc7wSJnEMeta || {},
    alias: diversitynWDc7wSJnEMeta?.alias || [],
    redirect: diversitynWDc7wSJnEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitynWDc7wSJnEMeta?.name ?? "company-diversity___uk",
    path: diversitynWDc7wSJnEMeta?.path ?? "/uk/company/diversity",
    meta: diversitynWDc7wSJnEMeta || {},
    alias: diversitynWDc7wSJnEMeta?.alias || [],
    redirect: diversitynWDc7wSJnEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitynWDc7wSJnEMeta?.name ?? "company-diversity___de",
    path: diversitynWDc7wSJnEMeta?.path ?? "/de/company/diversity",
    meta: diversitynWDc7wSJnEMeta || {},
    alias: diversitynWDc7wSJnEMeta?.alias || [],
    redirect: diversitynWDc7wSJnEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitynWDc7wSJnEMeta?.name ?? "company-diversity___mx",
    path: diversitynWDc7wSJnEMeta?.path ?? "/mx/company/diversity",
    meta: diversitynWDc7wSJnEMeta || {},
    alias: diversitynWDc7wSJnEMeta?.alias || [],
    redirect: diversitynWDc7wSJnEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitynWDc7wSJnEMeta?.name ?? "company-diversity___fr",
    path: diversitynWDc7wSJnEMeta?.path ?? "/fr/company/diversity",
    meta: diversitynWDc7wSJnEMeta || {},
    alias: diversitynWDc7wSJnEMeta?.alias || [],
    redirect: diversitynWDc7wSJnEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: diversitynWDc7wSJnEMeta?.name ?? "company-diversity___fr-ca",
    path: diversitynWDc7wSJnEMeta?.path ?? "/fr-ca/company/diversity",
    meta: diversitynWDc7wSJnEMeta || {},
    alias: diversitynWDc7wSJnEMeta?.alias || [],
    redirect: diversitynWDc7wSJnEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/diversity.vue").then(m => m.default || m)
  },
  {
    name: indexevbMVPIdLnMeta?.name ?? "company___en-US",
    path: indexevbMVPIdLnMeta?.path ?? "/company",
    meta: indexevbMVPIdLnMeta || {},
    alias: indexevbMVPIdLnMeta?.alias || [],
    redirect: indexevbMVPIdLnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexevbMVPIdLnMeta?.name ?? "company___ca",
    path: indexevbMVPIdLnMeta?.path ?? "/ca/company",
    meta: indexevbMVPIdLnMeta || {},
    alias: indexevbMVPIdLnMeta?.alias || [],
    redirect: indexevbMVPIdLnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexevbMVPIdLnMeta?.name ?? "company___nl",
    path: indexevbMVPIdLnMeta?.path ?? "/nl/company",
    meta: indexevbMVPIdLnMeta || {},
    alias: indexevbMVPIdLnMeta?.alias || [],
    redirect: indexevbMVPIdLnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexevbMVPIdLnMeta?.name ?? "company___uk",
    path: indexevbMVPIdLnMeta?.path ?? "/uk/company",
    meta: indexevbMVPIdLnMeta || {},
    alias: indexevbMVPIdLnMeta?.alias || [],
    redirect: indexevbMVPIdLnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexevbMVPIdLnMeta?.name ?? "company___de",
    path: indexevbMVPIdLnMeta?.path ?? "/de/company",
    meta: indexevbMVPIdLnMeta || {},
    alias: indexevbMVPIdLnMeta?.alias || [],
    redirect: indexevbMVPIdLnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexevbMVPIdLnMeta?.name ?? "company___mx",
    path: indexevbMVPIdLnMeta?.path ?? "/mx/company",
    meta: indexevbMVPIdLnMeta || {},
    alias: indexevbMVPIdLnMeta?.alias || [],
    redirect: indexevbMVPIdLnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexevbMVPIdLnMeta?.name ?? "company___fr",
    path: indexevbMVPIdLnMeta?.path ?? "/fr/company",
    meta: indexevbMVPIdLnMeta || {},
    alias: indexevbMVPIdLnMeta?.alias || [],
    redirect: indexevbMVPIdLnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: indexevbMVPIdLnMeta?.name ?? "company___fr-ca",
    path: indexevbMVPIdLnMeta?.path ?? "/fr-ca/company",
    meta: indexevbMVPIdLnMeta || {},
    alias: indexevbMVPIdLnMeta?.alias || [],
    redirect: indexevbMVPIdLnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: efficiencyz31xTybCWAMeta?.name ?? "company-mission-efficiency___en-US",
    path: efficiencyz31xTybCWAMeta?.path ?? "/company/mission/efficiency",
    meta: efficiencyz31xTybCWAMeta || {},
    alias: efficiencyz31xTybCWAMeta?.alias || [],
    redirect: efficiencyz31xTybCWAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyz31xTybCWAMeta?.name ?? "company-mission-efficiency___ca",
    path: efficiencyz31xTybCWAMeta?.path ?? "/ca/company/mission/efficiency",
    meta: efficiencyz31xTybCWAMeta || {},
    alias: efficiencyz31xTybCWAMeta?.alias || [],
    redirect: efficiencyz31xTybCWAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyz31xTybCWAMeta?.name ?? "company-mission-efficiency___nl",
    path: efficiencyz31xTybCWAMeta?.path ?? "/nl/company/mission/efficiency",
    meta: efficiencyz31xTybCWAMeta || {},
    alias: efficiencyz31xTybCWAMeta?.alias || [],
    redirect: efficiencyz31xTybCWAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyz31xTybCWAMeta?.name ?? "company-mission-efficiency___uk",
    path: efficiencyz31xTybCWAMeta?.path ?? "/uk/company/mission/efficiency",
    meta: efficiencyz31xTybCWAMeta || {},
    alias: efficiencyz31xTybCWAMeta?.alias || [],
    redirect: efficiencyz31xTybCWAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyz31xTybCWAMeta?.name ?? "company-mission-efficiency___de",
    path: efficiencyz31xTybCWAMeta?.path ?? "/de/company/mission/efficiency",
    meta: efficiencyz31xTybCWAMeta || {},
    alias: efficiencyz31xTybCWAMeta?.alias || [],
    redirect: efficiencyz31xTybCWAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyz31xTybCWAMeta?.name ?? "company-mission-efficiency___mx",
    path: efficiencyz31xTybCWAMeta?.path ?? "/mx/company/mission/efficiency",
    meta: efficiencyz31xTybCWAMeta || {},
    alias: efficiencyz31xTybCWAMeta?.alias || [],
    redirect: efficiencyz31xTybCWAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyz31xTybCWAMeta?.name ?? "company-mission-efficiency___fr",
    path: efficiencyz31xTybCWAMeta?.path ?? "/fr/company/mission/efficiency",
    meta: efficiencyz31xTybCWAMeta || {},
    alias: efficiencyz31xTybCWAMeta?.alias || [],
    redirect: efficiencyz31xTybCWAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: efficiencyz31xTybCWAMeta?.name ?? "company-mission-efficiency___fr-ca",
    path: efficiencyz31xTybCWAMeta?.path ?? "/fr-ca/company/mission/efficiency",
    meta: efficiencyz31xTybCWAMeta || {},
    alias: efficiencyz31xTybCWAMeta?.alias || [],
    redirect: efficiencyz31xTybCWAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/efficiency.vue").then(m => m.default || m)
  },
  {
    name: safetyeTXya5SD4hMeta?.name ?? "company-mission-safety___en-US",
    path: safetyeTXya5SD4hMeta?.path ?? "/company/mission/safety",
    meta: safetyeTXya5SD4hMeta || {},
    alias: safetyeTXya5SD4hMeta?.alias || [],
    redirect: safetyeTXya5SD4hMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyeTXya5SD4hMeta?.name ?? "company-mission-safety___ca",
    path: safetyeTXya5SD4hMeta?.path ?? "/ca/company/mission/safety",
    meta: safetyeTXya5SD4hMeta || {},
    alias: safetyeTXya5SD4hMeta?.alias || [],
    redirect: safetyeTXya5SD4hMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyeTXya5SD4hMeta?.name ?? "company-mission-safety___nl",
    path: safetyeTXya5SD4hMeta?.path ?? "/nl/company/mission/safety",
    meta: safetyeTXya5SD4hMeta || {},
    alias: safetyeTXya5SD4hMeta?.alias || [],
    redirect: safetyeTXya5SD4hMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyeTXya5SD4hMeta?.name ?? "company-mission-safety___uk",
    path: safetyeTXya5SD4hMeta?.path ?? "/uk/company/mission/safety",
    meta: safetyeTXya5SD4hMeta || {},
    alias: safetyeTXya5SD4hMeta?.alias || [],
    redirect: safetyeTXya5SD4hMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyeTXya5SD4hMeta?.name ?? "company-mission-safety___de",
    path: safetyeTXya5SD4hMeta?.path ?? "/de/company/mission/safety",
    meta: safetyeTXya5SD4hMeta || {},
    alias: safetyeTXya5SD4hMeta?.alias || [],
    redirect: safetyeTXya5SD4hMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyeTXya5SD4hMeta?.name ?? "company-mission-safety___mx",
    path: safetyeTXya5SD4hMeta?.path ?? "/mx/company/mission/safety",
    meta: safetyeTXya5SD4hMeta || {},
    alias: safetyeTXya5SD4hMeta?.alias || [],
    redirect: safetyeTXya5SD4hMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyeTXya5SD4hMeta?.name ?? "company-mission-safety___fr",
    path: safetyeTXya5SD4hMeta?.path ?? "/fr/company/mission/safety",
    meta: safetyeTXya5SD4hMeta || {},
    alias: safetyeTXya5SD4hMeta?.alias || [],
    redirect: safetyeTXya5SD4hMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: safetyeTXya5SD4hMeta?.name ?? "company-mission-safety___fr-ca",
    path: safetyeTXya5SD4hMeta?.path ?? "/fr-ca/company/mission/safety",
    meta: safetyeTXya5SD4hMeta || {},
    alias: safetyeTXya5SD4hMeta?.alias || [],
    redirect: safetyeTXya5SD4hMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/safety.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityyoZaXlHNmfMeta?.name ?? "company-mission-sustainability___en-US",
    path: sustainabilityyoZaXlHNmfMeta?.path ?? "/company/mission/sustainability",
    meta: sustainabilityyoZaXlHNmfMeta || {},
    alias: sustainabilityyoZaXlHNmfMeta?.alias || [],
    redirect: sustainabilityyoZaXlHNmfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityyoZaXlHNmfMeta?.name ?? "company-mission-sustainability___ca",
    path: sustainabilityyoZaXlHNmfMeta?.path ?? "/ca/company/mission/sustainability",
    meta: sustainabilityyoZaXlHNmfMeta || {},
    alias: sustainabilityyoZaXlHNmfMeta?.alias || [],
    redirect: sustainabilityyoZaXlHNmfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityyoZaXlHNmfMeta?.name ?? "company-mission-sustainability___nl",
    path: sustainabilityyoZaXlHNmfMeta?.path ?? "/nl/company/mission/sustainability",
    meta: sustainabilityyoZaXlHNmfMeta || {},
    alias: sustainabilityyoZaXlHNmfMeta?.alias || [],
    redirect: sustainabilityyoZaXlHNmfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityyoZaXlHNmfMeta?.name ?? "company-mission-sustainability___uk",
    path: sustainabilityyoZaXlHNmfMeta?.path ?? "/uk/company/mission/sustainability",
    meta: sustainabilityyoZaXlHNmfMeta || {},
    alias: sustainabilityyoZaXlHNmfMeta?.alias || [],
    redirect: sustainabilityyoZaXlHNmfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityyoZaXlHNmfMeta?.name ?? "company-mission-sustainability___de",
    path: sustainabilityyoZaXlHNmfMeta?.path ?? "/de/company/mission/sustainability",
    meta: sustainabilityyoZaXlHNmfMeta || {},
    alias: sustainabilityyoZaXlHNmfMeta?.alias || [],
    redirect: sustainabilityyoZaXlHNmfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityyoZaXlHNmfMeta?.name ?? "company-mission-sustainability___mx",
    path: sustainabilityyoZaXlHNmfMeta?.path ?? "/mx/company/mission/sustainability",
    meta: sustainabilityyoZaXlHNmfMeta || {},
    alias: sustainabilityyoZaXlHNmfMeta?.alias || [],
    redirect: sustainabilityyoZaXlHNmfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityyoZaXlHNmfMeta?.name ?? "company-mission-sustainability___fr",
    path: sustainabilityyoZaXlHNmfMeta?.path ?? "/fr/company/mission/sustainability",
    meta: sustainabilityyoZaXlHNmfMeta || {},
    alias: sustainabilityyoZaXlHNmfMeta?.alias || [],
    redirect: sustainabilityyoZaXlHNmfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityyoZaXlHNmfMeta?.name ?? "company-mission-sustainability___fr-ca",
    path: sustainabilityyoZaXlHNmfMeta?.path ?? "/fr-ca/company/mission/sustainability",
    meta: sustainabilityyoZaXlHNmfMeta || {},
    alias: sustainabilityyoZaXlHNmfMeta?.alias || [],
    redirect: sustainabilityyoZaXlHNmfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/mission/sustainability.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsUeNORlJnA7Meta?.name ?? "company-news-in-the-news___en-US",
    path: in_45the_45newsUeNORlJnA7Meta?.path ?? "/company/news/in-the-news",
    meta: in_45the_45newsUeNORlJnA7Meta || {},
    alias: in_45the_45newsUeNORlJnA7Meta?.alias || [],
    redirect: in_45the_45newsUeNORlJnA7Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsUeNORlJnA7Meta?.name ?? "company-news-in-the-news___ca",
    path: in_45the_45newsUeNORlJnA7Meta?.path ?? "/ca/company/news/in-the-news",
    meta: in_45the_45newsUeNORlJnA7Meta || {},
    alias: in_45the_45newsUeNORlJnA7Meta?.alias || [],
    redirect: in_45the_45newsUeNORlJnA7Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsUeNORlJnA7Meta?.name ?? "company-news-in-the-news___nl",
    path: in_45the_45newsUeNORlJnA7Meta?.path ?? "/nl/company/news/in-the-news",
    meta: in_45the_45newsUeNORlJnA7Meta || {},
    alias: in_45the_45newsUeNORlJnA7Meta?.alias || [],
    redirect: in_45the_45newsUeNORlJnA7Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsUeNORlJnA7Meta?.name ?? "company-news-in-the-news___uk",
    path: in_45the_45newsUeNORlJnA7Meta?.path ?? "/uk/company/news/in-the-news",
    meta: in_45the_45newsUeNORlJnA7Meta || {},
    alias: in_45the_45newsUeNORlJnA7Meta?.alias || [],
    redirect: in_45the_45newsUeNORlJnA7Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsUeNORlJnA7Meta?.name ?? "company-news-in-the-news___de",
    path: in_45the_45newsUeNORlJnA7Meta?.path ?? "/de/company/news/in-the-news",
    meta: in_45the_45newsUeNORlJnA7Meta || {},
    alias: in_45the_45newsUeNORlJnA7Meta?.alias || [],
    redirect: in_45the_45newsUeNORlJnA7Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsUeNORlJnA7Meta?.name ?? "company-news-in-the-news___mx",
    path: in_45the_45newsUeNORlJnA7Meta?.path ?? "/mx/company/news/in-the-news",
    meta: in_45the_45newsUeNORlJnA7Meta || {},
    alias: in_45the_45newsUeNORlJnA7Meta?.alias || [],
    redirect: in_45the_45newsUeNORlJnA7Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsUeNORlJnA7Meta?.name ?? "company-news-in-the-news___fr",
    path: in_45the_45newsUeNORlJnA7Meta?.path ?? "/fr/company/news/in-the-news",
    meta: in_45the_45newsUeNORlJnA7Meta || {},
    alias: in_45the_45newsUeNORlJnA7Meta?.alias || [],
    redirect: in_45the_45newsUeNORlJnA7Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45newsUeNORlJnA7Meta?.name ?? "company-news-in-the-news___fr-ca",
    path: in_45the_45newsUeNORlJnA7Meta?.path ?? "/fr-ca/company/news/in-the-news",
    meta: in_45the_45newsUeNORlJnA7Meta || {},
    alias: in_45the_45newsUeNORlJnA7Meta?.alias || [],
    redirect: in_45the_45newsUeNORlJnA7Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/in-the-news.vue").then(m => m.default || m)
  },
  {
    name: index7ZQbQDdUidMeta?.name ?? "company-news___en-US",
    path: index7ZQbQDdUidMeta?.path ?? "/company/news",
    meta: index7ZQbQDdUidMeta || {},
    alias: index7ZQbQDdUidMeta?.alias || [],
    redirect: index7ZQbQDdUidMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: index7ZQbQDdUidMeta?.name ?? "company-news___ca",
    path: index7ZQbQDdUidMeta?.path ?? "/ca/company/news",
    meta: index7ZQbQDdUidMeta || {},
    alias: index7ZQbQDdUidMeta?.alias || [],
    redirect: index7ZQbQDdUidMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: index7ZQbQDdUidMeta?.name ?? "company-news___mx",
    path: index7ZQbQDdUidMeta?.path ?? "/mx/company/news",
    meta: index7ZQbQDdUidMeta || {},
    alias: index7ZQbQDdUidMeta?.alias || [],
    redirect: index7ZQbQDdUidMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: index7ZQbQDdUidMeta?.name ?? "company-news___uk",
    path: index7ZQbQDdUidMeta?.path ?? "/uk/company/news",
    meta: index7ZQbQDdUidMeta || {},
    alias: index7ZQbQDdUidMeta?.alias || [],
    redirect: index7ZQbQDdUidMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/index.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesrL9NBAKyBwMeta?.name ?? "company-news-press-releases___en-US",
    path: press_45releasesrL9NBAKyBwMeta?.path ?? "/company/news/press-releases",
    meta: press_45releasesrL9NBAKyBwMeta || {},
    alias: press_45releasesrL9NBAKyBwMeta?.alias || [],
    redirect: press_45releasesrL9NBAKyBwMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesrL9NBAKyBwMeta?.name ?? "company-news-press-releases___ca",
    path: press_45releasesrL9NBAKyBwMeta?.path ?? "/ca/company/news/press-releases",
    meta: press_45releasesrL9NBAKyBwMeta || {},
    alias: press_45releasesrL9NBAKyBwMeta?.alias || [],
    redirect: press_45releasesrL9NBAKyBwMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesrL9NBAKyBwMeta?.name ?? "company-news-press-releases___nl",
    path: press_45releasesrL9NBAKyBwMeta?.path ?? "/nl/company/news/press-releases",
    meta: press_45releasesrL9NBAKyBwMeta || {},
    alias: press_45releasesrL9NBAKyBwMeta?.alias || [],
    redirect: press_45releasesrL9NBAKyBwMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesrL9NBAKyBwMeta?.name ?? "company-news-press-releases___uk",
    path: press_45releasesrL9NBAKyBwMeta?.path ?? "/uk/company/news/press-releases",
    meta: press_45releasesrL9NBAKyBwMeta || {},
    alias: press_45releasesrL9NBAKyBwMeta?.alias || [],
    redirect: press_45releasesrL9NBAKyBwMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesrL9NBAKyBwMeta?.name ?? "company-news-press-releases___de",
    path: press_45releasesrL9NBAKyBwMeta?.path ?? "/de/company/news/press-releases",
    meta: press_45releasesrL9NBAKyBwMeta || {},
    alias: press_45releasesrL9NBAKyBwMeta?.alias || [],
    redirect: press_45releasesrL9NBAKyBwMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesrL9NBAKyBwMeta?.name ?? "company-news-press-releases___mx",
    path: press_45releasesrL9NBAKyBwMeta?.path ?? "/mx/company/news/press-releases",
    meta: press_45releasesrL9NBAKyBwMeta || {},
    alias: press_45releasesrL9NBAKyBwMeta?.alias || [],
    redirect: press_45releasesrL9NBAKyBwMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesrL9NBAKyBwMeta?.name ?? "company-news-press-releases___fr",
    path: press_45releasesrL9NBAKyBwMeta?.path ?? "/fr/company/news/press-releases",
    meta: press_45releasesrL9NBAKyBwMeta || {},
    alias: press_45releasesrL9NBAKyBwMeta?.alias || [],
    redirect: press_45releasesrL9NBAKyBwMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: press_45releasesrL9NBAKyBwMeta?.name ?? "company-news-press-releases___fr-ca",
    path: press_45releasesrL9NBAKyBwMeta?.path ?? "/fr-ca/company/news/press-releases",
    meta: press_45releasesrL9NBAKyBwMeta || {},
    alias: press_45releasesrL9NBAKyBwMeta?.alias || [],
    redirect: press_45releasesrL9NBAKyBwMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/news/press-releases.vue").then(m => m.default || m)
  },
  {
    name: privacyNHPnoV7iZgMeta?.name ?? "company-privacy___en-US",
    path: privacyNHPnoV7iZgMeta?.path ?? "/company/privacy",
    meta: privacyNHPnoV7iZgMeta || {},
    alias: privacyNHPnoV7iZgMeta?.alias || [],
    redirect: privacyNHPnoV7iZgMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNHPnoV7iZgMeta?.name ?? "company-privacy___ca",
    path: privacyNHPnoV7iZgMeta?.path ?? "/ca/company/privacy",
    meta: privacyNHPnoV7iZgMeta || {},
    alias: privacyNHPnoV7iZgMeta?.alias || [],
    redirect: privacyNHPnoV7iZgMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNHPnoV7iZgMeta?.name ?? "company-privacy___nl",
    path: privacyNHPnoV7iZgMeta?.path ?? "/nl/company/privacy",
    meta: privacyNHPnoV7iZgMeta || {},
    alias: privacyNHPnoV7iZgMeta?.alias || [],
    redirect: privacyNHPnoV7iZgMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNHPnoV7iZgMeta?.name ?? "company-privacy___uk",
    path: privacyNHPnoV7iZgMeta?.path ?? "/uk/company/privacy",
    meta: privacyNHPnoV7iZgMeta || {},
    alias: privacyNHPnoV7iZgMeta?.alias || [],
    redirect: privacyNHPnoV7iZgMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNHPnoV7iZgMeta?.name ?? "company-privacy___de",
    path: privacyNHPnoV7iZgMeta?.path ?? "/de/company/privacy",
    meta: privacyNHPnoV7iZgMeta || {},
    alias: privacyNHPnoV7iZgMeta?.alias || [],
    redirect: privacyNHPnoV7iZgMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNHPnoV7iZgMeta?.name ?? "company-privacy___mx",
    path: privacyNHPnoV7iZgMeta?.path ?? "/mx/company/privacy",
    meta: privacyNHPnoV7iZgMeta || {},
    alias: privacyNHPnoV7iZgMeta?.alias || [],
    redirect: privacyNHPnoV7iZgMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNHPnoV7iZgMeta?.name ?? "company-privacy___fr",
    path: privacyNHPnoV7iZgMeta?.path ?? "/fr/company/privacy",
    meta: privacyNHPnoV7iZgMeta || {},
    alias: privacyNHPnoV7iZgMeta?.alias || [],
    redirect: privacyNHPnoV7iZgMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyNHPnoV7iZgMeta?.name ?? "company-privacy___fr-ca",
    path: privacyNHPnoV7iZgMeta?.path ?? "/fr-ca/company/privacy",
    meta: privacyNHPnoV7iZgMeta || {},
    alias: privacyNHPnoV7iZgMeta?.alias || [],
    redirect: privacyNHPnoV7iZgMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/company/privacy.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool99CLK0um8UMeta?.name ?? "cost-assessment-tool___en-US",
    path: cost_45assessment_45tool99CLK0um8UMeta?.path ?? "/cost-assessment-tool",
    meta: cost_45assessment_45tool99CLK0um8UMeta || {},
    alias: cost_45assessment_45tool99CLK0um8UMeta?.alias || [],
    redirect: cost_45assessment_45tool99CLK0um8UMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool99CLK0um8UMeta?.name ?? "cost-assessment-tool___ca",
    path: cost_45assessment_45tool99CLK0um8UMeta?.path ?? "/ca/cost-assessment-tool",
    meta: cost_45assessment_45tool99CLK0um8UMeta || {},
    alias: cost_45assessment_45tool99CLK0um8UMeta?.alias || [],
    redirect: cost_45assessment_45tool99CLK0um8UMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool99CLK0um8UMeta?.name ?? "cost-assessment-tool___nl",
    path: cost_45assessment_45tool99CLK0um8UMeta?.path ?? "/nl/cost-assessment-tool",
    meta: cost_45assessment_45tool99CLK0um8UMeta || {},
    alias: cost_45assessment_45tool99CLK0um8UMeta?.alias || [],
    redirect: cost_45assessment_45tool99CLK0um8UMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool99CLK0um8UMeta?.name ?? "cost-assessment-tool___uk",
    path: cost_45assessment_45tool99CLK0um8UMeta?.path ?? "/uk/cost-assessment-tool",
    meta: cost_45assessment_45tool99CLK0um8UMeta || {},
    alias: cost_45assessment_45tool99CLK0um8UMeta?.alias || [],
    redirect: cost_45assessment_45tool99CLK0um8UMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool99CLK0um8UMeta?.name ?? "cost-assessment-tool___de",
    path: cost_45assessment_45tool99CLK0um8UMeta?.path ?? "/de/cost-assessment-tool",
    meta: cost_45assessment_45tool99CLK0um8UMeta || {},
    alias: cost_45assessment_45tool99CLK0um8UMeta?.alias || [],
    redirect: cost_45assessment_45tool99CLK0um8UMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool99CLK0um8UMeta?.name ?? "cost-assessment-tool___mx",
    path: cost_45assessment_45tool99CLK0um8UMeta?.path ?? "/mx/cost-assessment-tool",
    meta: cost_45assessment_45tool99CLK0um8UMeta || {},
    alias: cost_45assessment_45tool99CLK0um8UMeta?.alias || [],
    redirect: cost_45assessment_45tool99CLK0um8UMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool99CLK0um8UMeta?.name ?? "cost-assessment-tool___fr",
    path: cost_45assessment_45tool99CLK0um8UMeta?.path ?? "/fr/cost-assessment-tool",
    meta: cost_45assessment_45tool99CLK0um8UMeta || {},
    alias: cost_45assessment_45tool99CLK0um8UMeta?.alias || [],
    redirect: cost_45assessment_45tool99CLK0um8UMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: cost_45assessment_45tool99CLK0um8UMeta?.name ?? "cost-assessment-tool___fr-ca",
    path: cost_45assessment_45tool99CLK0um8UMeta?.path ?? "/fr-ca/cost-assessment-tool",
    meta: cost_45assessment_45tool99CLK0um8UMeta || {},
    alias: cost_45assessment_45tool99CLK0um8UMeta?.alias || [],
    redirect: cost_45assessment_45tool99CLK0um8UMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/cost-assessment-tool.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JzIgXGX7DBMeta?.name ?? "customers-slug___en-US",
    path: _91slug_93JzIgXGX7DBMeta?.path ?? "/customers/:slug()",
    meta: _91slug_93JzIgXGX7DBMeta || {},
    alias: _91slug_93JzIgXGX7DBMeta?.alias || [],
    redirect: _91slug_93JzIgXGX7DBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JzIgXGX7DBMeta?.name ?? "customers-slug___ca",
    path: _91slug_93JzIgXGX7DBMeta?.path ?? "/ca/customers/:slug()",
    meta: _91slug_93JzIgXGX7DBMeta || {},
    alias: _91slug_93JzIgXGX7DBMeta?.alias || [],
    redirect: _91slug_93JzIgXGX7DBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JzIgXGX7DBMeta?.name ?? "customers-slug___nl",
    path: _91slug_93JzIgXGX7DBMeta?.path ?? "/nl/customers/:slug()",
    meta: _91slug_93JzIgXGX7DBMeta || {},
    alias: _91slug_93JzIgXGX7DBMeta?.alias || [],
    redirect: _91slug_93JzIgXGX7DBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JzIgXGX7DBMeta?.name ?? "customers-slug___uk",
    path: _91slug_93JzIgXGX7DBMeta?.path ?? "/uk/customers/:slug()",
    meta: _91slug_93JzIgXGX7DBMeta || {},
    alias: _91slug_93JzIgXGX7DBMeta?.alias || [],
    redirect: _91slug_93JzIgXGX7DBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JzIgXGX7DBMeta?.name ?? "customers-slug___de",
    path: _91slug_93JzIgXGX7DBMeta?.path ?? "/de/customers/:slug()",
    meta: _91slug_93JzIgXGX7DBMeta || {},
    alias: _91slug_93JzIgXGX7DBMeta?.alias || [],
    redirect: _91slug_93JzIgXGX7DBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JzIgXGX7DBMeta?.name ?? "customers-slug___mx",
    path: _91slug_93JzIgXGX7DBMeta?.path ?? "/mx/customers/:slug()",
    meta: _91slug_93JzIgXGX7DBMeta || {},
    alias: _91slug_93JzIgXGX7DBMeta?.alias || [],
    redirect: _91slug_93JzIgXGX7DBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JzIgXGX7DBMeta?.name ?? "customers-slug___fr",
    path: _91slug_93JzIgXGX7DBMeta?.path ?? "/fr/customers/:slug()",
    meta: _91slug_93JzIgXGX7DBMeta || {},
    alias: _91slug_93JzIgXGX7DBMeta?.alias || [],
    redirect: _91slug_93JzIgXGX7DBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JzIgXGX7DBMeta?.name ?? "customers-slug___fr-ca",
    path: _91slug_93JzIgXGX7DBMeta?.path ?? "/fr-ca/customers/:slug()",
    meta: _91slug_93JzIgXGX7DBMeta || {},
    alias: _91slug_93JzIgXGX7DBMeta?.alias || [],
    redirect: _91slug_93JzIgXGX7DBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HuXsaRkmAuMeta?.name ?? "customers-category-slug___en-US",
    path: _91slug_93HuXsaRkmAuMeta?.path ?? "/customers/category/:slug()",
    meta: _91slug_93HuXsaRkmAuMeta || {},
    alias: _91slug_93HuXsaRkmAuMeta?.alias || [],
    redirect: _91slug_93HuXsaRkmAuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HuXsaRkmAuMeta?.name ?? "customers-category-slug___ca",
    path: _91slug_93HuXsaRkmAuMeta?.path ?? "/ca/customers/category/:slug()",
    meta: _91slug_93HuXsaRkmAuMeta || {},
    alias: _91slug_93HuXsaRkmAuMeta?.alias || [],
    redirect: _91slug_93HuXsaRkmAuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HuXsaRkmAuMeta?.name ?? "customers-category-slug___nl",
    path: _91slug_93HuXsaRkmAuMeta?.path ?? "/nl/customers/category/:slug()",
    meta: _91slug_93HuXsaRkmAuMeta || {},
    alias: _91slug_93HuXsaRkmAuMeta?.alias || [],
    redirect: _91slug_93HuXsaRkmAuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HuXsaRkmAuMeta?.name ?? "customers-category-slug___uk",
    path: _91slug_93HuXsaRkmAuMeta?.path ?? "/uk/customers/category/:slug()",
    meta: _91slug_93HuXsaRkmAuMeta || {},
    alias: _91slug_93HuXsaRkmAuMeta?.alias || [],
    redirect: _91slug_93HuXsaRkmAuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HuXsaRkmAuMeta?.name ?? "customers-category-slug___de",
    path: _91slug_93HuXsaRkmAuMeta?.path ?? "/de/customers/category/:slug()",
    meta: _91slug_93HuXsaRkmAuMeta || {},
    alias: _91slug_93HuXsaRkmAuMeta?.alias || [],
    redirect: _91slug_93HuXsaRkmAuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HuXsaRkmAuMeta?.name ?? "customers-category-slug___mx",
    path: _91slug_93HuXsaRkmAuMeta?.path ?? "/mx/customers/category/:slug()",
    meta: _91slug_93HuXsaRkmAuMeta || {},
    alias: _91slug_93HuXsaRkmAuMeta?.alias || [],
    redirect: _91slug_93HuXsaRkmAuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HuXsaRkmAuMeta?.name ?? "customers-category-slug___fr",
    path: _91slug_93HuXsaRkmAuMeta?.path ?? "/fr/customers/category/:slug()",
    meta: _91slug_93HuXsaRkmAuMeta || {},
    alias: _91slug_93HuXsaRkmAuMeta?.alias || [],
    redirect: _91slug_93HuXsaRkmAuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HuXsaRkmAuMeta?.name ?? "customers-category-slug___fr-ca",
    path: _91slug_93HuXsaRkmAuMeta?.path ?? "/fr-ca/customers/category/:slug()",
    meta: _91slug_93HuXsaRkmAuMeta || {},
    alias: _91slug_93HuXsaRkmAuMeta?.alias || [],
    redirect: _91slug_93HuXsaRkmAuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexKxA0biOONhMeta?.name ?? "customers___en-US",
    path: indexKxA0biOONhMeta?.path ?? "/customers",
    meta: indexKxA0biOONhMeta || {},
    alias: indexKxA0biOONhMeta?.alias || [],
    redirect: indexKxA0biOONhMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxA0biOONhMeta?.name ?? "customers___ca",
    path: indexKxA0biOONhMeta?.path ?? "/ca/customers",
    meta: indexKxA0biOONhMeta || {},
    alias: indexKxA0biOONhMeta?.alias || [],
    redirect: indexKxA0biOONhMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxA0biOONhMeta?.name ?? "customers___nl",
    path: indexKxA0biOONhMeta?.path ?? "/nl/customers",
    meta: indexKxA0biOONhMeta || {},
    alias: indexKxA0biOONhMeta?.alias || [],
    redirect: indexKxA0biOONhMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxA0biOONhMeta?.name ?? "customers___uk",
    path: indexKxA0biOONhMeta?.path ?? "/uk/customers",
    meta: indexKxA0biOONhMeta || {},
    alias: indexKxA0biOONhMeta?.alias || [],
    redirect: indexKxA0biOONhMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxA0biOONhMeta?.name ?? "customers___de",
    path: indexKxA0biOONhMeta?.path ?? "/de/customers",
    meta: indexKxA0biOONhMeta || {},
    alias: indexKxA0biOONhMeta?.alias || [],
    redirect: indexKxA0biOONhMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxA0biOONhMeta?.name ?? "customers___mx",
    path: indexKxA0biOONhMeta?.path ?? "/mx/customers",
    meta: indexKxA0biOONhMeta || {},
    alias: indexKxA0biOONhMeta?.alias || [],
    redirect: indexKxA0biOONhMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxA0biOONhMeta?.name ?? "customers___fr",
    path: indexKxA0biOONhMeta?.path ?? "/fr/customers",
    meta: indexKxA0biOONhMeta || {},
    alias: indexKxA0biOONhMeta?.alias || [],
    redirect: indexKxA0biOONhMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexKxA0biOONhMeta?.name ?? "customers___fr-ca",
    path: indexKxA0biOONhMeta?.path ?? "/fr-ca/customers",
    meta: indexKxA0biOONhMeta || {},
    alias: indexKxA0biOONhMeta?.alias || [],
    redirect: indexKxA0biOONhMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FUKHLlF8jZMeta?.name ?? "customers-tips-slug___en-US",
    path: _91slug_93FUKHLlF8jZMeta?.path ?? "/customers/tips/:slug()",
    meta: _91slug_93FUKHLlF8jZMeta || {},
    alias: _91slug_93FUKHLlF8jZMeta?.alias || [],
    redirect: _91slug_93FUKHLlF8jZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FUKHLlF8jZMeta?.name ?? "customers-tips-slug___ca",
    path: _91slug_93FUKHLlF8jZMeta?.path ?? "/ca/customers/tips/:slug()",
    meta: _91slug_93FUKHLlF8jZMeta || {},
    alias: _91slug_93FUKHLlF8jZMeta?.alias || [],
    redirect: _91slug_93FUKHLlF8jZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FUKHLlF8jZMeta?.name ?? "customers-tips-slug___nl",
    path: _91slug_93FUKHLlF8jZMeta?.path ?? "/nl/customers/tips/:slug()",
    meta: _91slug_93FUKHLlF8jZMeta || {},
    alias: _91slug_93FUKHLlF8jZMeta?.alias || [],
    redirect: _91slug_93FUKHLlF8jZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FUKHLlF8jZMeta?.name ?? "customers-tips-slug___uk",
    path: _91slug_93FUKHLlF8jZMeta?.path ?? "/uk/customers/tips/:slug()",
    meta: _91slug_93FUKHLlF8jZMeta || {},
    alias: _91slug_93FUKHLlF8jZMeta?.alias || [],
    redirect: _91slug_93FUKHLlF8jZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FUKHLlF8jZMeta?.name ?? "customers-tips-slug___de",
    path: _91slug_93FUKHLlF8jZMeta?.path ?? "/de/customers/tips/:slug()",
    meta: _91slug_93FUKHLlF8jZMeta || {},
    alias: _91slug_93FUKHLlF8jZMeta?.alias || [],
    redirect: _91slug_93FUKHLlF8jZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FUKHLlF8jZMeta?.name ?? "customers-tips-slug___mx",
    path: _91slug_93FUKHLlF8jZMeta?.path ?? "/mx/customers/tips/:slug()",
    meta: _91slug_93FUKHLlF8jZMeta || {},
    alias: _91slug_93FUKHLlF8jZMeta?.alias || [],
    redirect: _91slug_93FUKHLlF8jZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FUKHLlF8jZMeta?.name ?? "customers-tips-slug___fr",
    path: _91slug_93FUKHLlF8jZMeta?.path ?? "/fr/customers/tips/:slug()",
    meta: _91slug_93FUKHLlF8jZMeta || {},
    alias: _91slug_93FUKHLlF8jZMeta?.alias || [],
    redirect: _91slug_93FUKHLlF8jZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93FUKHLlF8jZMeta?.name ?? "customers-tips-slug___fr-ca",
    path: _91slug_93FUKHLlF8jZMeta?.path ?? "/fr-ca/customers/tips/:slug()",
    meta: _91slug_93FUKHLlF8jZMeta || {},
    alias: _91slug_93FUKHLlF8jZMeta?.alias || [],
    redirect: _91slug_93FUKHLlF8jZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PQXsEvFUtMMeta?.name ?? "customers-tips-category-slug___en-US",
    path: _91slug_93PQXsEvFUtMMeta?.path ?? "/customers/tips/category/:slug()",
    meta: _91slug_93PQXsEvFUtMMeta || {},
    alias: _91slug_93PQXsEvFUtMMeta?.alias || [],
    redirect: _91slug_93PQXsEvFUtMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PQXsEvFUtMMeta?.name ?? "customers-tips-category-slug___ca",
    path: _91slug_93PQXsEvFUtMMeta?.path ?? "/ca/customers/tips/category/:slug()",
    meta: _91slug_93PQXsEvFUtMMeta || {},
    alias: _91slug_93PQXsEvFUtMMeta?.alias || [],
    redirect: _91slug_93PQXsEvFUtMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PQXsEvFUtMMeta?.name ?? "customers-tips-category-slug___nl",
    path: _91slug_93PQXsEvFUtMMeta?.path ?? "/nl/customers/tips/category/:slug()",
    meta: _91slug_93PQXsEvFUtMMeta || {},
    alias: _91slug_93PQXsEvFUtMMeta?.alias || [],
    redirect: _91slug_93PQXsEvFUtMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PQXsEvFUtMMeta?.name ?? "customers-tips-category-slug___uk",
    path: _91slug_93PQXsEvFUtMMeta?.path ?? "/uk/customers/tips/category/:slug()",
    meta: _91slug_93PQXsEvFUtMMeta || {},
    alias: _91slug_93PQXsEvFUtMMeta?.alias || [],
    redirect: _91slug_93PQXsEvFUtMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PQXsEvFUtMMeta?.name ?? "customers-tips-category-slug___de",
    path: _91slug_93PQXsEvFUtMMeta?.path ?? "/de/customers/tips/category/:slug()",
    meta: _91slug_93PQXsEvFUtMMeta || {},
    alias: _91slug_93PQXsEvFUtMMeta?.alias || [],
    redirect: _91slug_93PQXsEvFUtMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PQXsEvFUtMMeta?.name ?? "customers-tips-category-slug___mx",
    path: _91slug_93PQXsEvFUtMMeta?.path ?? "/mx/customers/tips/category/:slug()",
    meta: _91slug_93PQXsEvFUtMMeta || {},
    alias: _91slug_93PQXsEvFUtMMeta?.alias || [],
    redirect: _91slug_93PQXsEvFUtMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PQXsEvFUtMMeta?.name ?? "customers-tips-category-slug___fr",
    path: _91slug_93PQXsEvFUtMMeta?.path ?? "/fr/customers/tips/category/:slug()",
    meta: _91slug_93PQXsEvFUtMMeta || {},
    alias: _91slug_93PQXsEvFUtMMeta?.alias || [],
    redirect: _91slug_93PQXsEvFUtMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93PQXsEvFUtMMeta?.name ?? "customers-tips-category-slug___fr-ca",
    path: _91slug_93PQXsEvFUtMMeta?.path ?? "/fr-ca/customers/tips/category/:slug()",
    meta: _91slug_93PQXsEvFUtMMeta || {},
    alias: _91slug_93PQXsEvFUtMMeta?.alias || [],
    redirect: _91slug_93PQXsEvFUtMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexSV3z7gnmjPMeta?.name ?? "customers-tips___en-US",
    path: indexSV3z7gnmjPMeta?.path ?? "/customers/tips",
    meta: indexSV3z7gnmjPMeta || {},
    alias: indexSV3z7gnmjPMeta?.alias || [],
    redirect: indexSV3z7gnmjPMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexSV3z7gnmjPMeta?.name ?? "customers-tips___ca",
    path: indexSV3z7gnmjPMeta?.path ?? "/ca/customers/tips",
    meta: indexSV3z7gnmjPMeta || {},
    alias: indexSV3z7gnmjPMeta?.alias || [],
    redirect: indexSV3z7gnmjPMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexSV3z7gnmjPMeta?.name ?? "customers-tips___nl",
    path: indexSV3z7gnmjPMeta?.path ?? "/nl/customers/tips",
    meta: indexSV3z7gnmjPMeta || {},
    alias: indexSV3z7gnmjPMeta?.alias || [],
    redirect: indexSV3z7gnmjPMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexSV3z7gnmjPMeta?.name ?? "customers-tips___uk",
    path: indexSV3z7gnmjPMeta?.path ?? "/uk/customers/tips",
    meta: indexSV3z7gnmjPMeta || {},
    alias: indexSV3z7gnmjPMeta?.alias || [],
    redirect: indexSV3z7gnmjPMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexSV3z7gnmjPMeta?.name ?? "customers-tips___de",
    path: indexSV3z7gnmjPMeta?.path ?? "/de/customers/tips",
    meta: indexSV3z7gnmjPMeta || {},
    alias: indexSV3z7gnmjPMeta?.alias || [],
    redirect: indexSV3z7gnmjPMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexSV3z7gnmjPMeta?.name ?? "customers-tips___mx",
    path: indexSV3z7gnmjPMeta?.path ?? "/mx/customers/tips",
    meta: indexSV3z7gnmjPMeta || {},
    alias: indexSV3z7gnmjPMeta?.alias || [],
    redirect: indexSV3z7gnmjPMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexSV3z7gnmjPMeta?.name ?? "customers-tips___fr",
    path: indexSV3z7gnmjPMeta?.path ?? "/fr/customers/tips",
    meta: indexSV3z7gnmjPMeta || {},
    alias: indexSV3z7gnmjPMeta?.alias || [],
    redirect: indexSV3z7gnmjPMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: indexSV3z7gnmjPMeta?.name ?? "customers-tips___fr-ca",
    path: indexSV3z7gnmjPMeta?.path ?? "/fr-ca/customers/tips",
    meta: indexSV3z7gnmjPMeta || {},
    alias: indexSV3z7gnmjPMeta?.alias || [],
    redirect: indexSV3z7gnmjPMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/tips/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93rVIkrXYccyMeta?.name ?? "customers-topfleets-prefix-slug___en-US",
    path: _91_46_46_46slug_93rVIkrXYccyMeta?.path ?? "/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93rVIkrXYccyMeta || {},
    alias: _91_46_46_46slug_93rVIkrXYccyMeta?.alias || [],
    redirect: _91_46_46_46slug_93rVIkrXYccyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93rVIkrXYccyMeta?.name ?? "customers-topfleets-prefix-slug___ca",
    path: _91_46_46_46slug_93rVIkrXYccyMeta?.path ?? "/ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93rVIkrXYccyMeta || {},
    alias: _91_46_46_46slug_93rVIkrXYccyMeta?.alias || [],
    redirect: _91_46_46_46slug_93rVIkrXYccyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93rVIkrXYccyMeta?.name ?? "customers-topfleets-prefix-slug___nl",
    path: _91_46_46_46slug_93rVIkrXYccyMeta?.path ?? "/nl/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93rVIkrXYccyMeta || {},
    alias: _91_46_46_46slug_93rVIkrXYccyMeta?.alias || [],
    redirect: _91_46_46_46slug_93rVIkrXYccyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93rVIkrXYccyMeta?.name ?? "customers-topfleets-prefix-slug___uk",
    path: _91_46_46_46slug_93rVIkrXYccyMeta?.path ?? "/uk/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93rVIkrXYccyMeta || {},
    alias: _91_46_46_46slug_93rVIkrXYccyMeta?.alias || [],
    redirect: _91_46_46_46slug_93rVIkrXYccyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93rVIkrXYccyMeta?.name ?? "customers-topfleets-prefix-slug___de",
    path: _91_46_46_46slug_93rVIkrXYccyMeta?.path ?? "/de/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93rVIkrXYccyMeta || {},
    alias: _91_46_46_46slug_93rVIkrXYccyMeta?.alias || [],
    redirect: _91_46_46_46slug_93rVIkrXYccyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93rVIkrXYccyMeta?.name ?? "customers-topfleets-prefix-slug___mx",
    path: _91_46_46_46slug_93rVIkrXYccyMeta?.path ?? "/mx/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93rVIkrXYccyMeta || {},
    alias: _91_46_46_46slug_93rVIkrXYccyMeta?.alias || [],
    redirect: _91_46_46_46slug_93rVIkrXYccyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93rVIkrXYccyMeta?.name ?? "customers-topfleets-prefix-slug___fr",
    path: _91_46_46_46slug_93rVIkrXYccyMeta?.path ?? "/fr/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93rVIkrXYccyMeta || {},
    alias: _91_46_46_46slug_93rVIkrXYccyMeta?.alias || [],
    redirect: _91_46_46_46slug_93rVIkrXYccyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93rVIkrXYccyMeta?.name ?? "customers-topfleets-prefix-slug___fr-ca",
    path: _91_46_46_46slug_93rVIkrXYccyMeta?.path ?? "/fr-ca/customers/topfleets/:prefix()/:slug(.*)*",
    meta: _91_46_46_46slug_93rVIkrXYccyMeta || {},
    alias: _91_46_46_46slug_93rVIkrXYccyMeta?.alias || [],
    redirect: _91_46_46_46slug_93rVIkrXYccyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/[prefix]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVLjcKeMPk3Meta?.name ?? "customers-topfleets-2019-above-beyond___en-US",
    path: above_45beyondVLjcKeMPk3Meta?.path ?? "/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVLjcKeMPk3Meta || {},
    alias: above_45beyondVLjcKeMPk3Meta?.alias || [],
    redirect: above_45beyondVLjcKeMPk3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVLjcKeMPk3Meta?.name ?? "customers-topfleets-2019-above-beyond___ca",
    path: above_45beyondVLjcKeMPk3Meta?.path ?? "/ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVLjcKeMPk3Meta || {},
    alias: above_45beyondVLjcKeMPk3Meta?.alias || [],
    redirect: above_45beyondVLjcKeMPk3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVLjcKeMPk3Meta?.name ?? "customers-topfleets-2019-above-beyond___nl",
    path: above_45beyondVLjcKeMPk3Meta?.path ?? "/nl/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVLjcKeMPk3Meta || {},
    alias: above_45beyondVLjcKeMPk3Meta?.alias || [],
    redirect: above_45beyondVLjcKeMPk3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVLjcKeMPk3Meta?.name ?? "customers-topfleets-2019-above-beyond___uk",
    path: above_45beyondVLjcKeMPk3Meta?.path ?? "/uk/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVLjcKeMPk3Meta || {},
    alias: above_45beyondVLjcKeMPk3Meta?.alias || [],
    redirect: above_45beyondVLjcKeMPk3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVLjcKeMPk3Meta?.name ?? "customers-topfleets-2019-above-beyond___de",
    path: above_45beyondVLjcKeMPk3Meta?.path ?? "/de/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVLjcKeMPk3Meta || {},
    alias: above_45beyondVLjcKeMPk3Meta?.alias || [],
    redirect: above_45beyondVLjcKeMPk3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVLjcKeMPk3Meta?.name ?? "customers-topfleets-2019-above-beyond___mx",
    path: above_45beyondVLjcKeMPk3Meta?.path ?? "/mx/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVLjcKeMPk3Meta || {},
    alias: above_45beyondVLjcKeMPk3Meta?.alias || [],
    redirect: above_45beyondVLjcKeMPk3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVLjcKeMPk3Meta?.name ?? "customers-topfleets-2019-above-beyond___fr",
    path: above_45beyondVLjcKeMPk3Meta?.path ?? "/fr/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVLjcKeMPk3Meta || {},
    alias: above_45beyondVLjcKeMPk3Meta?.alias || [],
    redirect: above_45beyondVLjcKeMPk3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: above_45beyondVLjcKeMPk3Meta?.name ?? "customers-topfleets-2019-above-beyond___fr-ca",
    path: above_45beyondVLjcKeMPk3Meta?.path ?? "/fr-ca/customers/topfleets/2019/above-beyond",
    meta: above_45beyondVLjcKeMPk3Meta || {},
    alias: above_45beyondVLjcKeMPk3Meta?.alias || [],
    redirect: above_45beyondVLjcKeMPk3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/above-beyond.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeXEcxlN0RVMeta?.name ?? "customers-topfleets-2019-excellence-performance___en-US",
    path: excellence_45performanceeXEcxlN0RVMeta?.path ?? "/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeXEcxlN0RVMeta || {},
    alias: excellence_45performanceeXEcxlN0RVMeta?.alias || [],
    redirect: excellence_45performanceeXEcxlN0RVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeXEcxlN0RVMeta?.name ?? "customers-topfleets-2019-excellence-performance___ca",
    path: excellence_45performanceeXEcxlN0RVMeta?.path ?? "/ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeXEcxlN0RVMeta || {},
    alias: excellence_45performanceeXEcxlN0RVMeta?.alias || [],
    redirect: excellence_45performanceeXEcxlN0RVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeXEcxlN0RVMeta?.name ?? "customers-topfleets-2019-excellence-performance___nl",
    path: excellence_45performanceeXEcxlN0RVMeta?.path ?? "/nl/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeXEcxlN0RVMeta || {},
    alias: excellence_45performanceeXEcxlN0RVMeta?.alias || [],
    redirect: excellence_45performanceeXEcxlN0RVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeXEcxlN0RVMeta?.name ?? "customers-topfleets-2019-excellence-performance___uk",
    path: excellence_45performanceeXEcxlN0RVMeta?.path ?? "/uk/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeXEcxlN0RVMeta || {},
    alias: excellence_45performanceeXEcxlN0RVMeta?.alias || [],
    redirect: excellence_45performanceeXEcxlN0RVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeXEcxlN0RVMeta?.name ?? "customers-topfleets-2019-excellence-performance___de",
    path: excellence_45performanceeXEcxlN0RVMeta?.path ?? "/de/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeXEcxlN0RVMeta || {},
    alias: excellence_45performanceeXEcxlN0RVMeta?.alias || [],
    redirect: excellence_45performanceeXEcxlN0RVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeXEcxlN0RVMeta?.name ?? "customers-topfleets-2019-excellence-performance___mx",
    path: excellence_45performanceeXEcxlN0RVMeta?.path ?? "/mx/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeXEcxlN0RVMeta || {},
    alias: excellence_45performanceeXEcxlN0RVMeta?.alias || [],
    redirect: excellence_45performanceeXEcxlN0RVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeXEcxlN0RVMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr",
    path: excellence_45performanceeXEcxlN0RVMeta?.path ?? "/fr/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeXEcxlN0RVMeta || {},
    alias: excellence_45performanceeXEcxlN0RVMeta?.alias || [],
    redirect: excellence_45performanceeXEcxlN0RVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performanceeXEcxlN0RVMeta?.name ?? "customers-topfleets-2019-excellence-performance___fr-ca",
    path: excellence_45performanceeXEcxlN0RVMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-performance",
    meta: excellence_45performanceeXEcxlN0RVMeta || {},
    alias: excellence_45performanceeXEcxlN0RVMeta?.alias || [],
    redirect: excellence_45performanceeXEcxlN0RVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service5v3K8Hi7VWMeta?.name ?? "customers-topfleets-2019-excellence-service___en-US",
    path: excellence_45service5v3K8Hi7VWMeta?.path ?? "/customers/topfleets/2019/excellence-service",
    meta: excellence_45service5v3K8Hi7VWMeta || {},
    alias: excellence_45service5v3K8Hi7VWMeta?.alias || [],
    redirect: excellence_45service5v3K8Hi7VWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service5v3K8Hi7VWMeta?.name ?? "customers-topfleets-2019-excellence-service___ca",
    path: excellence_45service5v3K8Hi7VWMeta?.path ?? "/ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45service5v3K8Hi7VWMeta || {},
    alias: excellence_45service5v3K8Hi7VWMeta?.alias || [],
    redirect: excellence_45service5v3K8Hi7VWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service5v3K8Hi7VWMeta?.name ?? "customers-topfleets-2019-excellence-service___nl",
    path: excellence_45service5v3K8Hi7VWMeta?.path ?? "/nl/customers/topfleets/2019/excellence-service",
    meta: excellence_45service5v3K8Hi7VWMeta || {},
    alias: excellence_45service5v3K8Hi7VWMeta?.alias || [],
    redirect: excellence_45service5v3K8Hi7VWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service5v3K8Hi7VWMeta?.name ?? "customers-topfleets-2019-excellence-service___uk",
    path: excellence_45service5v3K8Hi7VWMeta?.path ?? "/uk/customers/topfleets/2019/excellence-service",
    meta: excellence_45service5v3K8Hi7VWMeta || {},
    alias: excellence_45service5v3K8Hi7VWMeta?.alias || [],
    redirect: excellence_45service5v3K8Hi7VWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service5v3K8Hi7VWMeta?.name ?? "customers-topfleets-2019-excellence-service___de",
    path: excellence_45service5v3K8Hi7VWMeta?.path ?? "/de/customers/topfleets/2019/excellence-service",
    meta: excellence_45service5v3K8Hi7VWMeta || {},
    alias: excellence_45service5v3K8Hi7VWMeta?.alias || [],
    redirect: excellence_45service5v3K8Hi7VWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service5v3K8Hi7VWMeta?.name ?? "customers-topfleets-2019-excellence-service___mx",
    path: excellence_45service5v3K8Hi7VWMeta?.path ?? "/mx/customers/topfleets/2019/excellence-service",
    meta: excellence_45service5v3K8Hi7VWMeta || {},
    alias: excellence_45service5v3K8Hi7VWMeta?.alias || [],
    redirect: excellence_45service5v3K8Hi7VWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service5v3K8Hi7VWMeta?.name ?? "customers-topfleets-2019-excellence-service___fr",
    path: excellence_45service5v3K8Hi7VWMeta?.path ?? "/fr/customers/topfleets/2019/excellence-service",
    meta: excellence_45service5v3K8Hi7VWMeta || {},
    alias: excellence_45service5v3K8Hi7VWMeta?.alias || [],
    redirect: excellence_45service5v3K8Hi7VWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: excellence_45service5v3K8Hi7VWMeta?.name ?? "customers-topfleets-2019-excellence-service___fr-ca",
    path: excellence_45service5v3K8Hi7VWMeta?.path ?? "/fr-ca/customers/topfleets/2019/excellence-service",
    meta: excellence_45service5v3K8Hi7VWMeta || {},
    alias: excellence_45service5v3K8Hi7VWMeta?.alias || [],
    redirect: excellence_45service5v3K8Hi7VWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/excellence-service.vue").then(m => m.default || m)
  },
  {
    name: indexdi38pAHVv0Meta?.name ?? "customers-topfleets-2019___en-US",
    path: indexdi38pAHVv0Meta?.path ?? "/customers/topfleets/2019",
    meta: indexdi38pAHVv0Meta || {},
    alias: indexdi38pAHVv0Meta?.alias || [],
    redirect: indexdi38pAHVv0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexdi38pAHVv0Meta?.name ?? "customers-topfleets-2019___ca",
    path: indexdi38pAHVv0Meta?.path ?? "/ca/customers/topfleets/2019",
    meta: indexdi38pAHVv0Meta || {},
    alias: indexdi38pAHVv0Meta?.alias || [],
    redirect: indexdi38pAHVv0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexdi38pAHVv0Meta?.name ?? "customers-topfleets-2019___nl",
    path: indexdi38pAHVv0Meta?.path ?? "/nl/customers/topfleets/2019",
    meta: indexdi38pAHVv0Meta || {},
    alias: indexdi38pAHVv0Meta?.alias || [],
    redirect: indexdi38pAHVv0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexdi38pAHVv0Meta?.name ?? "customers-topfleets-2019___uk",
    path: indexdi38pAHVv0Meta?.path ?? "/uk/customers/topfleets/2019",
    meta: indexdi38pAHVv0Meta || {},
    alias: indexdi38pAHVv0Meta?.alias || [],
    redirect: indexdi38pAHVv0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexdi38pAHVv0Meta?.name ?? "customers-topfleets-2019___de",
    path: indexdi38pAHVv0Meta?.path ?? "/de/customers/topfleets/2019",
    meta: indexdi38pAHVv0Meta || {},
    alias: indexdi38pAHVv0Meta?.alias || [],
    redirect: indexdi38pAHVv0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexdi38pAHVv0Meta?.name ?? "customers-topfleets-2019___mx",
    path: indexdi38pAHVv0Meta?.path ?? "/mx/customers/topfleets/2019",
    meta: indexdi38pAHVv0Meta || {},
    alias: indexdi38pAHVv0Meta?.alias || [],
    redirect: indexdi38pAHVv0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexdi38pAHVv0Meta?.name ?? "customers-topfleets-2019___fr",
    path: indexdi38pAHVv0Meta?.path ?? "/fr/customers/topfleets/2019",
    meta: indexdi38pAHVv0Meta || {},
    alias: indexdi38pAHVv0Meta?.alias || [],
    redirect: indexdi38pAHVv0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: indexdi38pAHVv0Meta?.name ?? "customers-topfleets-2019___fr-ca",
    path: indexdi38pAHVv0Meta?.path ?? "/fr-ca/customers/topfleets/2019",
    meta: indexdi38pAHVv0Meta || {},
    alias: indexdi38pAHVv0Meta?.alias || [],
    redirect: indexdi38pAHVv0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/index.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4iusJ5rBEdMeta?.name ?? "customers-topfleets-2019-safest-fleet___en-US",
    path: safest_45fleet4iusJ5rBEdMeta?.path ?? "/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4iusJ5rBEdMeta || {},
    alias: safest_45fleet4iusJ5rBEdMeta?.alias || [],
    redirect: safest_45fleet4iusJ5rBEdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4iusJ5rBEdMeta?.name ?? "customers-topfleets-2019-safest-fleet___ca",
    path: safest_45fleet4iusJ5rBEdMeta?.path ?? "/ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4iusJ5rBEdMeta || {},
    alias: safest_45fleet4iusJ5rBEdMeta?.alias || [],
    redirect: safest_45fleet4iusJ5rBEdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4iusJ5rBEdMeta?.name ?? "customers-topfleets-2019-safest-fleet___nl",
    path: safest_45fleet4iusJ5rBEdMeta?.path ?? "/nl/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4iusJ5rBEdMeta || {},
    alias: safest_45fleet4iusJ5rBEdMeta?.alias || [],
    redirect: safest_45fleet4iusJ5rBEdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4iusJ5rBEdMeta?.name ?? "customers-topfleets-2019-safest-fleet___uk",
    path: safest_45fleet4iusJ5rBEdMeta?.path ?? "/uk/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4iusJ5rBEdMeta || {},
    alias: safest_45fleet4iusJ5rBEdMeta?.alias || [],
    redirect: safest_45fleet4iusJ5rBEdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4iusJ5rBEdMeta?.name ?? "customers-topfleets-2019-safest-fleet___de",
    path: safest_45fleet4iusJ5rBEdMeta?.path ?? "/de/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4iusJ5rBEdMeta || {},
    alias: safest_45fleet4iusJ5rBEdMeta?.alias || [],
    redirect: safest_45fleet4iusJ5rBEdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4iusJ5rBEdMeta?.name ?? "customers-topfleets-2019-safest-fleet___mx",
    path: safest_45fleet4iusJ5rBEdMeta?.path ?? "/mx/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4iusJ5rBEdMeta || {},
    alias: safest_45fleet4iusJ5rBEdMeta?.alias || [],
    redirect: safest_45fleet4iusJ5rBEdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4iusJ5rBEdMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr",
    path: safest_45fleet4iusJ5rBEdMeta?.path ?? "/fr/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4iusJ5rBEdMeta || {},
    alias: safest_45fleet4iusJ5rBEdMeta?.alias || [],
    redirect: safest_45fleet4iusJ5rBEdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleet4iusJ5rBEdMeta?.name ?? "customers-topfleets-2019-safest-fleet___fr-ca",
    path: safest_45fleet4iusJ5rBEdMeta?.path ?? "/fr-ca/customers/topfleets/2019/safest-fleet",
    meta: safest_45fleet4iusJ5rBEdMeta || {},
    alias: safest_45fleet4iusJ5rBEdMeta?.alias || [],
    redirect: safest_45fleet4iusJ5rBEdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2019/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAd8EdgNEedMeta?.name ?? "customers-topfleets-2020-driver-performance___en-US",
    path: driver_45performanceAd8EdgNEedMeta?.path ?? "/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAd8EdgNEedMeta || {},
    alias: driver_45performanceAd8EdgNEedMeta?.alias || [],
    redirect: driver_45performanceAd8EdgNEedMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAd8EdgNEedMeta?.name ?? "customers-topfleets-2020-driver-performance___ca",
    path: driver_45performanceAd8EdgNEedMeta?.path ?? "/ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAd8EdgNEedMeta || {},
    alias: driver_45performanceAd8EdgNEedMeta?.alias || [],
    redirect: driver_45performanceAd8EdgNEedMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAd8EdgNEedMeta?.name ?? "customers-topfleets-2020-driver-performance___nl",
    path: driver_45performanceAd8EdgNEedMeta?.path ?? "/nl/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAd8EdgNEedMeta || {},
    alias: driver_45performanceAd8EdgNEedMeta?.alias || [],
    redirect: driver_45performanceAd8EdgNEedMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAd8EdgNEedMeta?.name ?? "customers-topfleets-2020-driver-performance___uk",
    path: driver_45performanceAd8EdgNEedMeta?.path ?? "/uk/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAd8EdgNEedMeta || {},
    alias: driver_45performanceAd8EdgNEedMeta?.alias || [],
    redirect: driver_45performanceAd8EdgNEedMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAd8EdgNEedMeta?.name ?? "customers-topfleets-2020-driver-performance___de",
    path: driver_45performanceAd8EdgNEedMeta?.path ?? "/de/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAd8EdgNEedMeta || {},
    alias: driver_45performanceAd8EdgNEedMeta?.alias || [],
    redirect: driver_45performanceAd8EdgNEedMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAd8EdgNEedMeta?.name ?? "customers-topfleets-2020-driver-performance___mx",
    path: driver_45performanceAd8EdgNEedMeta?.path ?? "/mx/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAd8EdgNEedMeta || {},
    alias: driver_45performanceAd8EdgNEedMeta?.alias || [],
    redirect: driver_45performanceAd8EdgNEedMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAd8EdgNEedMeta?.name ?? "customers-topfleets-2020-driver-performance___fr",
    path: driver_45performanceAd8EdgNEedMeta?.path ?? "/fr/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAd8EdgNEedMeta || {},
    alias: driver_45performanceAd8EdgNEedMeta?.alias || [],
    redirect: driver_45performanceAd8EdgNEedMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: driver_45performanceAd8EdgNEedMeta?.name ?? "customers-topfleets-2020-driver-performance___fr-ca",
    path: driver_45performanceAd8EdgNEedMeta?.path ?? "/fr-ca/customers/topfleets/2020/driver-performance",
    meta: driver_45performanceAd8EdgNEedMeta || {},
    alias: driver_45performanceAd8EdgNEedMeta?.alias || [],
    redirect: driver_45performanceAd8EdgNEedMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/driver-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancec2GZOTkCKFMeta?.name ?? "customers-topfleets-2020-excellence-performance___en-US",
    path: excellence_45performancec2GZOTkCKFMeta?.path ?? "/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancec2GZOTkCKFMeta || {},
    alias: excellence_45performancec2GZOTkCKFMeta?.alias || [],
    redirect: excellence_45performancec2GZOTkCKFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancec2GZOTkCKFMeta?.name ?? "customers-topfleets-2020-excellence-performance___ca",
    path: excellence_45performancec2GZOTkCKFMeta?.path ?? "/ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancec2GZOTkCKFMeta || {},
    alias: excellence_45performancec2GZOTkCKFMeta?.alias || [],
    redirect: excellence_45performancec2GZOTkCKFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancec2GZOTkCKFMeta?.name ?? "customers-topfleets-2020-excellence-performance___nl",
    path: excellence_45performancec2GZOTkCKFMeta?.path ?? "/nl/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancec2GZOTkCKFMeta || {},
    alias: excellence_45performancec2GZOTkCKFMeta?.alias || [],
    redirect: excellence_45performancec2GZOTkCKFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancec2GZOTkCKFMeta?.name ?? "customers-topfleets-2020-excellence-performance___uk",
    path: excellence_45performancec2GZOTkCKFMeta?.path ?? "/uk/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancec2GZOTkCKFMeta || {},
    alias: excellence_45performancec2GZOTkCKFMeta?.alias || [],
    redirect: excellence_45performancec2GZOTkCKFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancec2GZOTkCKFMeta?.name ?? "customers-topfleets-2020-excellence-performance___de",
    path: excellence_45performancec2GZOTkCKFMeta?.path ?? "/de/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancec2GZOTkCKFMeta || {},
    alias: excellence_45performancec2GZOTkCKFMeta?.alias || [],
    redirect: excellence_45performancec2GZOTkCKFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancec2GZOTkCKFMeta?.name ?? "customers-topfleets-2020-excellence-performance___mx",
    path: excellence_45performancec2GZOTkCKFMeta?.path ?? "/mx/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancec2GZOTkCKFMeta || {},
    alias: excellence_45performancec2GZOTkCKFMeta?.alias || [],
    redirect: excellence_45performancec2GZOTkCKFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancec2GZOTkCKFMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr",
    path: excellence_45performancec2GZOTkCKFMeta?.path ?? "/fr/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancec2GZOTkCKFMeta || {},
    alias: excellence_45performancec2GZOTkCKFMeta?.alias || [],
    redirect: excellence_45performancec2GZOTkCKFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: excellence_45performancec2GZOTkCKFMeta?.name ?? "customers-topfleets-2020-excellence-performance___fr-ca",
    path: excellence_45performancec2GZOTkCKFMeta?.path ?? "/fr-ca/customers/topfleets/2020/excellence-performance",
    meta: excellence_45performancec2GZOTkCKFMeta || {},
    alias: excellence_45performancec2GZOTkCKFMeta?.alias || [],
    redirect: excellence_45performancec2GZOTkCKFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/excellence-performance.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator7om76Jp5vRMeta?.name ?? "customers-topfleets-2020-fleet-innovator___en-US",
    path: fleet_45innovator7om76Jp5vRMeta?.path ?? "/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator7om76Jp5vRMeta || {},
    alias: fleet_45innovator7om76Jp5vRMeta?.alias || [],
    redirect: fleet_45innovator7om76Jp5vRMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator7om76Jp5vRMeta?.name ?? "customers-topfleets-2020-fleet-innovator___ca",
    path: fleet_45innovator7om76Jp5vRMeta?.path ?? "/ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator7om76Jp5vRMeta || {},
    alias: fleet_45innovator7om76Jp5vRMeta?.alias || [],
    redirect: fleet_45innovator7om76Jp5vRMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator7om76Jp5vRMeta?.name ?? "customers-topfleets-2020-fleet-innovator___nl",
    path: fleet_45innovator7om76Jp5vRMeta?.path ?? "/nl/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator7om76Jp5vRMeta || {},
    alias: fleet_45innovator7om76Jp5vRMeta?.alias || [],
    redirect: fleet_45innovator7om76Jp5vRMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator7om76Jp5vRMeta?.name ?? "customers-topfleets-2020-fleet-innovator___uk",
    path: fleet_45innovator7om76Jp5vRMeta?.path ?? "/uk/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator7om76Jp5vRMeta || {},
    alias: fleet_45innovator7om76Jp5vRMeta?.alias || [],
    redirect: fleet_45innovator7om76Jp5vRMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator7om76Jp5vRMeta?.name ?? "customers-topfleets-2020-fleet-innovator___de",
    path: fleet_45innovator7om76Jp5vRMeta?.path ?? "/de/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator7om76Jp5vRMeta || {},
    alias: fleet_45innovator7om76Jp5vRMeta?.alias || [],
    redirect: fleet_45innovator7om76Jp5vRMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator7om76Jp5vRMeta?.name ?? "customers-topfleets-2020-fleet-innovator___mx",
    path: fleet_45innovator7om76Jp5vRMeta?.path ?? "/mx/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator7om76Jp5vRMeta || {},
    alias: fleet_45innovator7om76Jp5vRMeta?.alias || [],
    redirect: fleet_45innovator7om76Jp5vRMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator7om76Jp5vRMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr",
    path: fleet_45innovator7om76Jp5vRMeta?.path ?? "/fr/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator7om76Jp5vRMeta || {},
    alias: fleet_45innovator7om76Jp5vRMeta?.alias || [],
    redirect: fleet_45innovator7om76Jp5vRMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: fleet_45innovator7om76Jp5vRMeta?.name ?? "customers-topfleets-2020-fleet-innovator___fr-ca",
    path: fleet_45innovator7om76Jp5vRMeta?.path ?? "/fr-ca/customers/topfleets/2020/fleet-innovator",
    meta: fleet_45innovator7om76Jp5vRMeta || {},
    alias: fleet_45innovator7om76Jp5vRMeta?.alias || [],
    redirect: fleet_45innovator7om76Jp5vRMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/fleet-innovator.vue").then(m => m.default || m)
  },
  {
    name: indexwuVwGfrfukMeta?.name ?? "customers-topfleets-2020___en-US",
    path: indexwuVwGfrfukMeta?.path ?? "/customers/topfleets/2020",
    meta: indexwuVwGfrfukMeta || {},
    alias: indexwuVwGfrfukMeta?.alias || [],
    redirect: indexwuVwGfrfukMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexwuVwGfrfukMeta?.name ?? "customers-topfleets-2020___ca",
    path: indexwuVwGfrfukMeta?.path ?? "/ca/customers/topfleets/2020",
    meta: indexwuVwGfrfukMeta || {},
    alias: indexwuVwGfrfukMeta?.alias || [],
    redirect: indexwuVwGfrfukMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexwuVwGfrfukMeta?.name ?? "customers-topfleets-2020___nl",
    path: indexwuVwGfrfukMeta?.path ?? "/nl/customers/topfleets/2020",
    meta: indexwuVwGfrfukMeta || {},
    alias: indexwuVwGfrfukMeta?.alias || [],
    redirect: indexwuVwGfrfukMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexwuVwGfrfukMeta?.name ?? "customers-topfleets-2020___uk",
    path: indexwuVwGfrfukMeta?.path ?? "/uk/customers/topfleets/2020",
    meta: indexwuVwGfrfukMeta || {},
    alias: indexwuVwGfrfukMeta?.alias || [],
    redirect: indexwuVwGfrfukMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexwuVwGfrfukMeta?.name ?? "customers-topfleets-2020___de",
    path: indexwuVwGfrfukMeta?.path ?? "/de/customers/topfleets/2020",
    meta: indexwuVwGfrfukMeta || {},
    alias: indexwuVwGfrfukMeta?.alias || [],
    redirect: indexwuVwGfrfukMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexwuVwGfrfukMeta?.name ?? "customers-topfleets-2020___mx",
    path: indexwuVwGfrfukMeta?.path ?? "/mx/customers/topfleets/2020",
    meta: indexwuVwGfrfukMeta || {},
    alias: indexwuVwGfrfukMeta?.alias || [],
    redirect: indexwuVwGfrfukMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexwuVwGfrfukMeta?.name ?? "customers-topfleets-2020___fr",
    path: indexwuVwGfrfukMeta?.path ?? "/fr/customers/topfleets/2020",
    meta: indexwuVwGfrfukMeta || {},
    alias: indexwuVwGfrfukMeta?.alias || [],
    redirect: indexwuVwGfrfukMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: indexwuVwGfrfukMeta?.name ?? "customers-topfleets-2020___fr-ca",
    path: indexwuVwGfrfukMeta?.path ?? "/fr-ca/customers/topfleets/2020",
    meta: indexwuVwGfrfukMeta || {},
    alias: indexwuVwGfrfukMeta?.alias || [],
    redirect: indexwuVwGfrfukMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/index.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetnkdJRZFFxHMeta?.name ?? "customers-topfleets-2020-public-fleet___en-US",
    path: public_45fleetnkdJRZFFxHMeta?.path ?? "/customers/topfleets/2020/public-fleet",
    meta: public_45fleetnkdJRZFFxHMeta || {},
    alias: public_45fleetnkdJRZFFxHMeta?.alias || [],
    redirect: public_45fleetnkdJRZFFxHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetnkdJRZFFxHMeta?.name ?? "customers-topfleets-2020-public-fleet___ca",
    path: public_45fleetnkdJRZFFxHMeta?.path ?? "/ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetnkdJRZFFxHMeta || {},
    alias: public_45fleetnkdJRZFFxHMeta?.alias || [],
    redirect: public_45fleetnkdJRZFFxHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetnkdJRZFFxHMeta?.name ?? "customers-topfleets-2020-public-fleet___nl",
    path: public_45fleetnkdJRZFFxHMeta?.path ?? "/nl/customers/topfleets/2020/public-fleet",
    meta: public_45fleetnkdJRZFFxHMeta || {},
    alias: public_45fleetnkdJRZFFxHMeta?.alias || [],
    redirect: public_45fleetnkdJRZFFxHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetnkdJRZFFxHMeta?.name ?? "customers-topfleets-2020-public-fleet___uk",
    path: public_45fleetnkdJRZFFxHMeta?.path ?? "/uk/customers/topfleets/2020/public-fleet",
    meta: public_45fleetnkdJRZFFxHMeta || {},
    alias: public_45fleetnkdJRZFFxHMeta?.alias || [],
    redirect: public_45fleetnkdJRZFFxHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetnkdJRZFFxHMeta?.name ?? "customers-topfleets-2020-public-fleet___de",
    path: public_45fleetnkdJRZFFxHMeta?.path ?? "/de/customers/topfleets/2020/public-fleet",
    meta: public_45fleetnkdJRZFFxHMeta || {},
    alias: public_45fleetnkdJRZFFxHMeta?.alias || [],
    redirect: public_45fleetnkdJRZFFxHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetnkdJRZFFxHMeta?.name ?? "customers-topfleets-2020-public-fleet___mx",
    path: public_45fleetnkdJRZFFxHMeta?.path ?? "/mx/customers/topfleets/2020/public-fleet",
    meta: public_45fleetnkdJRZFFxHMeta || {},
    alias: public_45fleetnkdJRZFFxHMeta?.alias || [],
    redirect: public_45fleetnkdJRZFFxHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetnkdJRZFFxHMeta?.name ?? "customers-topfleets-2020-public-fleet___fr",
    path: public_45fleetnkdJRZFFxHMeta?.path ?? "/fr/customers/topfleets/2020/public-fleet",
    meta: public_45fleetnkdJRZFFxHMeta || {},
    alias: public_45fleetnkdJRZFFxHMeta?.alias || [],
    redirect: public_45fleetnkdJRZFFxHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: public_45fleetnkdJRZFFxHMeta?.name ?? "customers-topfleets-2020-public-fleet___fr-ca",
    path: public_45fleetnkdJRZFFxHMeta?.path ?? "/fr-ca/customers/topfleets/2020/public-fleet",
    meta: public_45fleetnkdJRZFFxHMeta || {},
    alias: public_45fleetnkdJRZFFxHMeta?.alias || [],
    redirect: public_45fleetnkdJRZFFxHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/public-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetOe8iqgiBUoMeta?.name ?? "customers-topfleets-2020-safest-fleet___en-US",
    path: safest_45fleetOe8iqgiBUoMeta?.path ?? "/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetOe8iqgiBUoMeta || {},
    alias: safest_45fleetOe8iqgiBUoMeta?.alias || [],
    redirect: safest_45fleetOe8iqgiBUoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: safest_45fleetOe8iqgiBUoMeta?.name ?? "customers-topfleets-2020-safest-fleet___ca",
    path: safest_45fleetOe8iqgiBUoMeta?.path ?? "/ca/customers/topfleets/2020/safest-fleet",
    meta: safest_45fleetOe8iqgiBUoMeta || {},
    alias: safest_45fleetOe8iqgiBUoMeta?.alias || [],
    redirect: safest_45fleetOe8iqgiBUoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/2020/safest-fleet.vue").then(m => m.default || m)
  },
  {
    name: indexKFUUwVqHpcMeta?.name ?? "customers-topfleets___en-US",
    path: indexKFUUwVqHpcMeta?.path ?? "/customers/topfleets",
    meta: indexKFUUwVqHpcMeta || {},
    alias: indexKFUUwVqHpcMeta?.alias || [],
    redirect: indexKFUUwVqHpcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFUUwVqHpcMeta?.name ?? "customers-topfleets___ca",
    path: indexKFUUwVqHpcMeta?.path ?? "/ca/customers/topfleets",
    meta: indexKFUUwVqHpcMeta || {},
    alias: indexKFUUwVqHpcMeta?.alias || [],
    redirect: indexKFUUwVqHpcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFUUwVqHpcMeta?.name ?? "customers-topfleets___nl",
    path: indexKFUUwVqHpcMeta?.path ?? "/nl/customers/topfleets",
    meta: indexKFUUwVqHpcMeta || {},
    alias: indexKFUUwVqHpcMeta?.alias || [],
    redirect: indexKFUUwVqHpcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFUUwVqHpcMeta?.name ?? "customers-topfleets___uk",
    path: indexKFUUwVqHpcMeta?.path ?? "/uk/customers/topfleets",
    meta: indexKFUUwVqHpcMeta || {},
    alias: indexKFUUwVqHpcMeta?.alias || [],
    redirect: indexKFUUwVqHpcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFUUwVqHpcMeta?.name ?? "customers-topfleets___de",
    path: indexKFUUwVqHpcMeta?.path ?? "/de/customers/topfleets",
    meta: indexKFUUwVqHpcMeta || {},
    alias: indexKFUUwVqHpcMeta?.alias || [],
    redirect: indexKFUUwVqHpcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFUUwVqHpcMeta?.name ?? "customers-topfleets___mx",
    path: indexKFUUwVqHpcMeta?.path ?? "/mx/customers/topfleets",
    meta: indexKFUUwVqHpcMeta || {},
    alias: indexKFUUwVqHpcMeta?.alias || [],
    redirect: indexKFUUwVqHpcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFUUwVqHpcMeta?.name ?? "customers-topfleets___fr",
    path: indexKFUUwVqHpcMeta?.path ?? "/fr/customers/topfleets",
    meta: indexKFUUwVqHpcMeta || {},
    alias: indexKFUUwVqHpcMeta?.alias || [],
    redirect: indexKFUUwVqHpcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFUUwVqHpcMeta?.name ?? "customers-topfleets___fr-ca",
    path: indexKFUUwVqHpcMeta?.path ?? "/fr-ca/customers/topfleets",
    meta: indexKFUUwVqHpcMeta || {},
    alias: indexKFUUwVqHpcMeta?.alias || [],
    redirect: indexKFUUwVqHpcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/customers/topfleets/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_936KcMo1jQ2HMeta?.name ?? "events-slug___en-US",
    path: _91slug_936KcMo1jQ2HMeta?.path ?? "/events/:slug()",
    meta: _91slug_936KcMo1jQ2HMeta || {},
    alias: _91slug_936KcMo1jQ2HMeta?.alias || [],
    redirect: _91slug_936KcMo1jQ2HMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936KcMo1jQ2HMeta?.name ?? "events-slug___ca",
    path: _91slug_936KcMo1jQ2HMeta?.path ?? "/ca/events/:slug()",
    meta: _91slug_936KcMo1jQ2HMeta || {},
    alias: _91slug_936KcMo1jQ2HMeta?.alias || [],
    redirect: _91slug_936KcMo1jQ2HMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936KcMo1jQ2HMeta?.name ?? "events-slug___nl",
    path: _91slug_936KcMo1jQ2HMeta?.path ?? "/nl/events/:slug()",
    meta: _91slug_936KcMo1jQ2HMeta || {},
    alias: _91slug_936KcMo1jQ2HMeta?.alias || [],
    redirect: _91slug_936KcMo1jQ2HMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936KcMo1jQ2HMeta?.name ?? "events-slug___uk",
    path: _91slug_936KcMo1jQ2HMeta?.path ?? "/uk/events/:slug()",
    meta: _91slug_936KcMo1jQ2HMeta || {},
    alias: _91slug_936KcMo1jQ2HMeta?.alias || [],
    redirect: _91slug_936KcMo1jQ2HMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936KcMo1jQ2HMeta?.name ?? "events-slug___de",
    path: _91slug_936KcMo1jQ2HMeta?.path ?? "/de/events/:slug()",
    meta: _91slug_936KcMo1jQ2HMeta || {},
    alias: _91slug_936KcMo1jQ2HMeta?.alias || [],
    redirect: _91slug_936KcMo1jQ2HMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936KcMo1jQ2HMeta?.name ?? "events-slug___mx",
    path: _91slug_936KcMo1jQ2HMeta?.path ?? "/mx/events/:slug()",
    meta: _91slug_936KcMo1jQ2HMeta || {},
    alias: _91slug_936KcMo1jQ2HMeta?.alias || [],
    redirect: _91slug_936KcMo1jQ2HMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936KcMo1jQ2HMeta?.name ?? "events-slug___fr",
    path: _91slug_936KcMo1jQ2HMeta?.path ?? "/fr/events/:slug()",
    meta: _91slug_936KcMo1jQ2HMeta || {},
    alias: _91slug_936KcMo1jQ2HMeta?.alias || [],
    redirect: _91slug_936KcMo1jQ2HMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_936KcMo1jQ2HMeta?.name ?? "events-slug___fr-ca",
    path: _91slug_936KcMo1jQ2HMeta?.path ?? "/fr-ca/events/:slug()",
    meta: _91slug_936KcMo1jQ2HMeta || {},
    alias: _91slug_936KcMo1jQ2HMeta?.alias || [],
    redirect: _91slug_936KcMo1jQ2HMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: index7OItxuEU6iMeta?.name ?? "events___en-US",
    path: index7OItxuEU6iMeta?.path ?? "/events",
    meta: index7OItxuEU6iMeta || {},
    alias: index7OItxuEU6iMeta?.alias || [],
    redirect: index7OItxuEU6iMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index7OItxuEU6iMeta?.name ?? "events___ca",
    path: index7OItxuEU6iMeta?.path ?? "/ca/events",
    meta: index7OItxuEU6iMeta || {},
    alias: index7OItxuEU6iMeta?.alias || [],
    redirect: index7OItxuEU6iMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index7OItxuEU6iMeta?.name ?? "events___nl",
    path: index7OItxuEU6iMeta?.path ?? "/nl/events",
    meta: index7OItxuEU6iMeta || {},
    alias: index7OItxuEU6iMeta?.alias || [],
    redirect: index7OItxuEU6iMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index7OItxuEU6iMeta?.name ?? "events___uk",
    path: index7OItxuEU6iMeta?.path ?? "/uk/events",
    meta: index7OItxuEU6iMeta || {},
    alias: index7OItxuEU6iMeta?.alias || [],
    redirect: index7OItxuEU6iMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index7OItxuEU6iMeta?.name ?? "events___de",
    path: index7OItxuEU6iMeta?.path ?? "/de/events",
    meta: index7OItxuEU6iMeta || {},
    alias: index7OItxuEU6iMeta?.alias || [],
    redirect: index7OItxuEU6iMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index7OItxuEU6iMeta?.name ?? "events___mx",
    path: index7OItxuEU6iMeta?.path ?? "/mx/events",
    meta: index7OItxuEU6iMeta || {},
    alias: index7OItxuEU6iMeta?.alias || [],
    redirect: index7OItxuEU6iMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index7OItxuEU6iMeta?.name ?? "events___fr",
    path: index7OItxuEU6iMeta?.path ?? "/fr/events",
    meta: index7OItxuEU6iMeta || {},
    alias: index7OItxuEU6iMeta?.alias || [],
    redirect: index7OItxuEU6iMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: index7OItxuEU6iMeta?.name ?? "events___fr-ca",
    path: index7OItxuEU6iMeta?.path ?? "/fr-ca/events",
    meta: index7OItxuEU6iMeta || {},
    alias: index7OItxuEU6iMeta?.alias || [],
    redirect: index7OItxuEU6iMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4dAaq0CiDVMeta?.name ?? "fleet-app___en-US",
    path: fleet_45app4dAaq0CiDVMeta?.path ?? "/fleet-app",
    meta: fleet_45app4dAaq0CiDVMeta || {},
    alias: fleet_45app4dAaq0CiDVMeta?.alias || [],
    redirect: fleet_45app4dAaq0CiDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4dAaq0CiDVMeta?.name ?? "fleet-app___ca",
    path: fleet_45app4dAaq0CiDVMeta?.path ?? "/ca/fleet-app",
    meta: fleet_45app4dAaq0CiDVMeta || {},
    alias: fleet_45app4dAaq0CiDVMeta?.alias || [],
    redirect: fleet_45app4dAaq0CiDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4dAaq0CiDVMeta?.name ?? "fleet-app___nl",
    path: fleet_45app4dAaq0CiDVMeta?.path ?? "/nl/fleet-app",
    meta: fleet_45app4dAaq0CiDVMeta || {},
    alias: fleet_45app4dAaq0CiDVMeta?.alias || [],
    redirect: fleet_45app4dAaq0CiDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4dAaq0CiDVMeta?.name ?? "fleet-app___uk",
    path: fleet_45app4dAaq0CiDVMeta?.path ?? "/uk/fleet-app",
    meta: fleet_45app4dAaq0CiDVMeta || {},
    alias: fleet_45app4dAaq0CiDVMeta?.alias || [],
    redirect: fleet_45app4dAaq0CiDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4dAaq0CiDVMeta?.name ?? "fleet-app___de",
    path: fleet_45app4dAaq0CiDVMeta?.path ?? "/de/fleet-app",
    meta: fleet_45app4dAaq0CiDVMeta || {},
    alias: fleet_45app4dAaq0CiDVMeta?.alias || [],
    redirect: fleet_45app4dAaq0CiDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4dAaq0CiDVMeta?.name ?? "fleet-app___mx",
    path: fleet_45app4dAaq0CiDVMeta?.path ?? "/mx/fleet-app",
    meta: fleet_45app4dAaq0CiDVMeta || {},
    alias: fleet_45app4dAaq0CiDVMeta?.alias || [],
    redirect: fleet_45app4dAaq0CiDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4dAaq0CiDVMeta?.name ?? "fleet-app___fr",
    path: fleet_45app4dAaq0CiDVMeta?.path ?? "/fr/fleet-app",
    meta: fleet_45app4dAaq0CiDVMeta || {},
    alias: fleet_45app4dAaq0CiDVMeta?.alias || [],
    redirect: fleet_45app4dAaq0CiDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: fleet_45app4dAaq0CiDVMeta?.name ?? "fleet-app___fr-ca",
    path: fleet_45app4dAaq0CiDVMeta?.path ?? "/fr-ca/fleet-app",
    meta: fleet_45app4dAaq0CiDVMeta || {},
    alias: fleet_45app4dAaq0CiDVMeta?.alias || [],
    redirect: fleet_45app4dAaq0CiDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet-app.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b8oxRSMA45Meta?.name ?? "fleet-prefix-slug___en-US",
    path: _91slug_93b8oxRSMA45Meta?.path ?? "/fleet/:prefix()/:slug()",
    meta: _91slug_93b8oxRSMA45Meta || {},
    alias: _91slug_93b8oxRSMA45Meta?.alias || [],
    redirect: _91slug_93b8oxRSMA45Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b8oxRSMA45Meta?.name ?? "fleet-prefix-slug___ca",
    path: _91slug_93b8oxRSMA45Meta?.path ?? "/ca/fleet/:prefix()/:slug()",
    meta: _91slug_93b8oxRSMA45Meta || {},
    alias: _91slug_93b8oxRSMA45Meta?.alias || [],
    redirect: _91slug_93b8oxRSMA45Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b8oxRSMA45Meta?.name ?? "fleet-prefix-slug___nl",
    path: _91slug_93b8oxRSMA45Meta?.path ?? "/nl/fleet/:prefix()/:slug()",
    meta: _91slug_93b8oxRSMA45Meta || {},
    alias: _91slug_93b8oxRSMA45Meta?.alias || [],
    redirect: _91slug_93b8oxRSMA45Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b8oxRSMA45Meta?.name ?? "fleet-prefix-slug___uk",
    path: _91slug_93b8oxRSMA45Meta?.path ?? "/uk/fleet/:prefix()/:slug()",
    meta: _91slug_93b8oxRSMA45Meta || {},
    alias: _91slug_93b8oxRSMA45Meta?.alias || [],
    redirect: _91slug_93b8oxRSMA45Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b8oxRSMA45Meta?.name ?? "fleet-prefix-slug___de",
    path: _91slug_93b8oxRSMA45Meta?.path ?? "/de/fleet/:prefix()/:slug()",
    meta: _91slug_93b8oxRSMA45Meta || {},
    alias: _91slug_93b8oxRSMA45Meta?.alias || [],
    redirect: _91slug_93b8oxRSMA45Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b8oxRSMA45Meta?.name ?? "fleet-prefix-slug___mx",
    path: _91slug_93b8oxRSMA45Meta?.path ?? "/mx/fleet/:prefix()/:slug()",
    meta: _91slug_93b8oxRSMA45Meta || {},
    alias: _91slug_93b8oxRSMA45Meta?.alias || [],
    redirect: _91slug_93b8oxRSMA45Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b8oxRSMA45Meta?.name ?? "fleet-prefix-slug___fr",
    path: _91slug_93b8oxRSMA45Meta?.path ?? "/fr/fleet/:prefix()/:slug()",
    meta: _91slug_93b8oxRSMA45Meta || {},
    alias: _91slug_93b8oxRSMA45Meta?.alias || [],
    redirect: _91slug_93b8oxRSMA45Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93b8oxRSMA45Meta?.name ?? "fleet-prefix-slug___fr-ca",
    path: _91slug_93b8oxRSMA45Meta?.path ?? "/fr-ca/fleet/:prefix()/:slug()",
    meta: _91slug_93b8oxRSMA45Meta || {},
    alias: _91slug_93b8oxRSMA45Meta?.alias || [],
    redirect: _91slug_93b8oxRSMA45Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aAQ8b0OJbjMeta?.name ?? "fleet-slug___en-US",
    path: _91slug_93aAQ8b0OJbjMeta?.path ?? "/fleet/:slug()",
    meta: _91slug_93aAQ8b0OJbjMeta || {},
    alias: _91slug_93aAQ8b0OJbjMeta?.alias || [],
    redirect: _91slug_93aAQ8b0OJbjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aAQ8b0OJbjMeta?.name ?? "fleet-slug___ca",
    path: _91slug_93aAQ8b0OJbjMeta?.path ?? "/ca/fleet/:slug()",
    meta: _91slug_93aAQ8b0OJbjMeta || {},
    alias: _91slug_93aAQ8b0OJbjMeta?.alias || [],
    redirect: _91slug_93aAQ8b0OJbjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aAQ8b0OJbjMeta?.name ?? "fleet-slug___nl",
    path: _91slug_93aAQ8b0OJbjMeta?.path ?? "/nl/fleet/:slug()",
    meta: _91slug_93aAQ8b0OJbjMeta || {},
    alias: _91slug_93aAQ8b0OJbjMeta?.alias || [],
    redirect: _91slug_93aAQ8b0OJbjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aAQ8b0OJbjMeta?.name ?? "fleet-slug___uk",
    path: _91slug_93aAQ8b0OJbjMeta?.path ?? "/uk/fleet/:slug()",
    meta: _91slug_93aAQ8b0OJbjMeta || {},
    alias: _91slug_93aAQ8b0OJbjMeta?.alias || [],
    redirect: _91slug_93aAQ8b0OJbjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aAQ8b0OJbjMeta?.name ?? "fleet-slug___de",
    path: _91slug_93aAQ8b0OJbjMeta?.path ?? "/de/fleet/:slug()",
    meta: _91slug_93aAQ8b0OJbjMeta || {},
    alias: _91slug_93aAQ8b0OJbjMeta?.alias || [],
    redirect: _91slug_93aAQ8b0OJbjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aAQ8b0OJbjMeta?.name ?? "fleet-slug___mx",
    path: _91slug_93aAQ8b0OJbjMeta?.path ?? "/mx/fleet/:slug()",
    meta: _91slug_93aAQ8b0OJbjMeta || {},
    alias: _91slug_93aAQ8b0OJbjMeta?.alias || [],
    redirect: _91slug_93aAQ8b0OJbjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aAQ8b0OJbjMeta?.name ?? "fleet-slug___fr",
    path: _91slug_93aAQ8b0OJbjMeta?.path ?? "/fr/fleet/:slug()",
    meta: _91slug_93aAQ8b0OJbjMeta || {},
    alias: _91slug_93aAQ8b0OJbjMeta?.alias || [],
    redirect: _91slug_93aAQ8b0OJbjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aAQ8b0OJbjMeta?.name ?? "fleet-slug___fr-ca",
    path: _91slug_93aAQ8b0OJbjMeta?.path ?? "/fr-ca/fleet/:slug()",
    meta: _91slug_93aAQ8b0OJbjMeta || {},
    alias: _91slug_93aAQ8b0OJbjMeta?.alias || [],
    redirect: _91slug_93aAQ8b0OJbjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/fleet/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931QfdNBHRMqMeta?.name ?? "guides-slug___en-US",
    path: _91slug_931QfdNBHRMqMeta?.path ?? "/guides/:slug()",
    meta: _91slug_931QfdNBHRMqMeta || {},
    alias: _91slug_931QfdNBHRMqMeta?.alias || [],
    redirect: _91slug_931QfdNBHRMqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931QfdNBHRMqMeta?.name ?? "guides-slug___ca",
    path: _91slug_931QfdNBHRMqMeta?.path ?? "/ca/guides/:slug()",
    meta: _91slug_931QfdNBHRMqMeta || {},
    alias: _91slug_931QfdNBHRMqMeta?.alias || [],
    redirect: _91slug_931QfdNBHRMqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931QfdNBHRMqMeta?.name ?? "guides-slug___nl",
    path: _91slug_931QfdNBHRMqMeta?.path ?? "/nl/guides/:slug()",
    meta: _91slug_931QfdNBHRMqMeta || {},
    alias: _91slug_931QfdNBHRMqMeta?.alias || [],
    redirect: _91slug_931QfdNBHRMqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931QfdNBHRMqMeta?.name ?? "guides-slug___uk",
    path: _91slug_931QfdNBHRMqMeta?.path ?? "/uk/guides/:slug()",
    meta: _91slug_931QfdNBHRMqMeta || {},
    alias: _91slug_931QfdNBHRMqMeta?.alias || [],
    redirect: _91slug_931QfdNBHRMqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931QfdNBHRMqMeta?.name ?? "guides-slug___de",
    path: _91slug_931QfdNBHRMqMeta?.path ?? "/de/guides/:slug()",
    meta: _91slug_931QfdNBHRMqMeta || {},
    alias: _91slug_931QfdNBHRMqMeta?.alias || [],
    redirect: _91slug_931QfdNBHRMqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931QfdNBHRMqMeta?.name ?? "guides-slug___mx",
    path: _91slug_931QfdNBHRMqMeta?.path ?? "/mx/guides/:slug()",
    meta: _91slug_931QfdNBHRMqMeta || {},
    alias: _91slug_931QfdNBHRMqMeta?.alias || [],
    redirect: _91slug_931QfdNBHRMqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931QfdNBHRMqMeta?.name ?? "guides-slug___fr",
    path: _91slug_931QfdNBHRMqMeta?.path ?? "/fr/guides/:slug()",
    meta: _91slug_931QfdNBHRMqMeta || {},
    alias: _91slug_931QfdNBHRMqMeta?.alias || [],
    redirect: _91slug_931QfdNBHRMqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_931QfdNBHRMqMeta?.name ?? "guides-slug___fr-ca",
    path: _91slug_931QfdNBHRMqMeta?.path ?? "/fr-ca/guides/:slug()",
    meta: _91slug_931QfdNBHRMqMeta || {},
    alias: _91slug_931QfdNBHRMqMeta?.alias || [],
    redirect: _91slug_931QfdNBHRMqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/[slug].vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentp0XFeV6FiQMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___en-US",
    path: attract_45retain_45talentp0XFeV6FiQMeta?.path ?? "/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentp0XFeV6FiQMeta || {},
    alias: attract_45retain_45talentp0XFeV6FiQMeta?.alias || [],
    redirect: attract_45retain_45talentp0XFeV6FiQMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentp0XFeV6FiQMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___ca",
    path: attract_45retain_45talentp0XFeV6FiQMeta?.path ?? "/ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentp0XFeV6FiQMeta || {},
    alias: attract_45retain_45talentp0XFeV6FiQMeta?.alias || [],
    redirect: attract_45retain_45talentp0XFeV6FiQMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentp0XFeV6FiQMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___nl",
    path: attract_45retain_45talentp0XFeV6FiQMeta?.path ?? "/nl/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentp0XFeV6FiQMeta || {},
    alias: attract_45retain_45talentp0XFeV6FiQMeta?.alias || [],
    redirect: attract_45retain_45talentp0XFeV6FiQMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentp0XFeV6FiQMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___uk",
    path: attract_45retain_45talentp0XFeV6FiQMeta?.path ?? "/uk/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentp0XFeV6FiQMeta || {},
    alias: attract_45retain_45talentp0XFeV6FiQMeta?.alias || [],
    redirect: attract_45retain_45talentp0XFeV6FiQMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentp0XFeV6FiQMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___de",
    path: attract_45retain_45talentp0XFeV6FiQMeta?.path ?? "/de/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentp0XFeV6FiQMeta || {},
    alias: attract_45retain_45talentp0XFeV6FiQMeta?.alias || [],
    redirect: attract_45retain_45talentp0XFeV6FiQMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentp0XFeV6FiQMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___mx",
    path: attract_45retain_45talentp0XFeV6FiQMeta?.path ?? "/mx/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentp0XFeV6FiQMeta || {},
    alias: attract_45retain_45talentp0XFeV6FiQMeta?.alias || [],
    redirect: attract_45retain_45talentp0XFeV6FiQMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentp0XFeV6FiQMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr",
    path: attract_45retain_45talentp0XFeV6FiQMeta?.path ?? "/fr/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentp0XFeV6FiQMeta || {},
    alias: attract_45retain_45talentp0XFeV6FiQMeta?.alias || [],
    redirect: attract_45retain_45talentp0XFeV6FiQMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: attract_45retain_45talentp0XFeV6FiQMeta?.name ?? "guides-build-business-resilience-attract-retain-talent___fr-ca",
    path: attract_45retain_45talentp0XFeV6FiQMeta?.path ?? "/fr-ca/guides/build-business-resilience/attract-retain-talent",
    meta: attract_45retain_45talentp0XFeV6FiQMeta || {},
    alias: attract_45retain_45talentp0XFeV6FiQMeta?.alias || [],
    redirect: attract_45retain_45talentp0XFeV6FiQMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/attract-retain-talent.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___en-US",
    path: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.path ?? "/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta || {},
    alias: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___ca",
    path: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.path ?? "/ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta || {},
    alias: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___nl",
    path: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.path ?? "/nl/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta || {},
    alias: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___uk",
    path: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.path ?? "/uk/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta || {},
    alias: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___de",
    path: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.path ?? "/de/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta || {},
    alias: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___mx",
    path: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.path ?? "/mx/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta || {},
    alias: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr",
    path: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.path ?? "/fr/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta || {},
    alias: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.name ?? "guides-build-business-resilience-increase-efficiency-maximize-profits___fr-ca",
    path: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.path ?? "/fr-ca/guides/build-business-resilience/increase-efficiency-maximize-profits",
    meta: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta || {},
    alias: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.alias || [],
    redirect: increase_45efficiency_45maximize_45profitst0oAkqBeNAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/increase-efficiency-maximize-profits.vue").then(m => m.default || m)
  },
  {
    name: indexf075u1mE3xMeta?.name ?? "guides-build-business-resilience___en-US",
    path: indexf075u1mE3xMeta?.path ?? "/guides/build-business-resilience",
    meta: indexf075u1mE3xMeta || {},
    alias: indexf075u1mE3xMeta?.alias || [],
    redirect: indexf075u1mE3xMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexf075u1mE3xMeta?.name ?? "guides-build-business-resilience___ca",
    path: indexf075u1mE3xMeta?.path ?? "/ca/guides/build-business-resilience",
    meta: indexf075u1mE3xMeta || {},
    alias: indexf075u1mE3xMeta?.alias || [],
    redirect: indexf075u1mE3xMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexf075u1mE3xMeta?.name ?? "guides-build-business-resilience___nl",
    path: indexf075u1mE3xMeta?.path ?? "/nl/guides/build-business-resilience",
    meta: indexf075u1mE3xMeta || {},
    alias: indexf075u1mE3xMeta?.alias || [],
    redirect: indexf075u1mE3xMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexf075u1mE3xMeta?.name ?? "guides-build-business-resilience___uk",
    path: indexf075u1mE3xMeta?.path ?? "/uk/guides/build-business-resilience",
    meta: indexf075u1mE3xMeta || {},
    alias: indexf075u1mE3xMeta?.alias || [],
    redirect: indexf075u1mE3xMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexf075u1mE3xMeta?.name ?? "guides-build-business-resilience___de",
    path: indexf075u1mE3xMeta?.path ?? "/de/guides/build-business-resilience",
    meta: indexf075u1mE3xMeta || {},
    alias: indexf075u1mE3xMeta?.alias || [],
    redirect: indexf075u1mE3xMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexf075u1mE3xMeta?.name ?? "guides-build-business-resilience___mx",
    path: indexf075u1mE3xMeta?.path ?? "/mx/guides/build-business-resilience",
    meta: indexf075u1mE3xMeta || {},
    alias: indexf075u1mE3xMeta?.alias || [],
    redirect: indexf075u1mE3xMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexf075u1mE3xMeta?.name ?? "guides-build-business-resilience___fr",
    path: indexf075u1mE3xMeta?.path ?? "/fr/guides/build-business-resilience",
    meta: indexf075u1mE3xMeta || {},
    alias: indexf075u1mE3xMeta?.alias || [],
    redirect: indexf075u1mE3xMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: indexf075u1mE3xMeta?.name ?? "guides-build-business-resilience___fr-ca",
    path: indexf075u1mE3xMeta?.path ?? "/fr-ca/guides/build-business-resilience",
    meta: indexf075u1mE3xMeta || {},
    alias: indexf075u1mE3xMeta?.alias || [],
    redirect: indexf075u1mE3xMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/index.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspAFNpePIS3Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___en-US",
    path: protect_45optimize_45assetspAFNpePIS3Meta?.path ?? "/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspAFNpePIS3Meta || {},
    alias: protect_45optimize_45assetspAFNpePIS3Meta?.alias || [],
    redirect: protect_45optimize_45assetspAFNpePIS3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspAFNpePIS3Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___ca",
    path: protect_45optimize_45assetspAFNpePIS3Meta?.path ?? "/ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspAFNpePIS3Meta || {},
    alias: protect_45optimize_45assetspAFNpePIS3Meta?.alias || [],
    redirect: protect_45optimize_45assetspAFNpePIS3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspAFNpePIS3Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___nl",
    path: protect_45optimize_45assetspAFNpePIS3Meta?.path ?? "/nl/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspAFNpePIS3Meta || {},
    alias: protect_45optimize_45assetspAFNpePIS3Meta?.alias || [],
    redirect: protect_45optimize_45assetspAFNpePIS3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspAFNpePIS3Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___uk",
    path: protect_45optimize_45assetspAFNpePIS3Meta?.path ?? "/uk/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspAFNpePIS3Meta || {},
    alias: protect_45optimize_45assetspAFNpePIS3Meta?.alias || [],
    redirect: protect_45optimize_45assetspAFNpePIS3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspAFNpePIS3Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___de",
    path: protect_45optimize_45assetspAFNpePIS3Meta?.path ?? "/de/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspAFNpePIS3Meta || {},
    alias: protect_45optimize_45assetspAFNpePIS3Meta?.alias || [],
    redirect: protect_45optimize_45assetspAFNpePIS3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspAFNpePIS3Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___mx",
    path: protect_45optimize_45assetspAFNpePIS3Meta?.path ?? "/mx/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspAFNpePIS3Meta || {},
    alias: protect_45optimize_45assetspAFNpePIS3Meta?.alias || [],
    redirect: protect_45optimize_45assetspAFNpePIS3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspAFNpePIS3Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr",
    path: protect_45optimize_45assetspAFNpePIS3Meta?.path ?? "/fr/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspAFNpePIS3Meta || {},
    alias: protect_45optimize_45assetspAFNpePIS3Meta?.alias || [],
    redirect: protect_45optimize_45assetspAFNpePIS3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: protect_45optimize_45assetspAFNpePIS3Meta?.name ?? "guides-build-business-resilience-protect-optimize-assets___fr-ca",
    path: protect_45optimize_45assetspAFNpePIS3Meta?.path ?? "/fr-ca/guides/build-business-resilience/protect-optimize-assets",
    meta: protect_45optimize_45assetspAFNpePIS3Meta || {},
    alias: protect_45optimize_45assetspAFNpePIS3Meta?.alias || [],
    redirect: protect_45optimize_45assetspAFNpePIS3Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/build-business-resilience/protect-optimize-assets.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsejvmk0iCPHMeta?.name ?? "guides-fleet-safety-best-dash-cams___en-US",
    path: best_45dash_45camsejvmk0iCPHMeta?.path ?? "/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsejvmk0iCPHMeta || {},
    alias: best_45dash_45camsejvmk0iCPHMeta?.alias || [],
    redirect: best_45dash_45camsejvmk0iCPHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsejvmk0iCPHMeta?.name ?? "guides-fleet-safety-best-dash-cams___ca",
    path: best_45dash_45camsejvmk0iCPHMeta?.path ?? "/ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsejvmk0iCPHMeta || {},
    alias: best_45dash_45camsejvmk0iCPHMeta?.alias || [],
    redirect: best_45dash_45camsejvmk0iCPHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsejvmk0iCPHMeta?.name ?? "guides-fleet-safety-best-dash-cams___nl",
    path: best_45dash_45camsejvmk0iCPHMeta?.path ?? "/nl/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsejvmk0iCPHMeta || {},
    alias: best_45dash_45camsejvmk0iCPHMeta?.alias || [],
    redirect: best_45dash_45camsejvmk0iCPHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsejvmk0iCPHMeta?.name ?? "guides-fleet-safety-best-dash-cams___uk",
    path: best_45dash_45camsejvmk0iCPHMeta?.path ?? "/uk/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsejvmk0iCPHMeta || {},
    alias: best_45dash_45camsejvmk0iCPHMeta?.alias || [],
    redirect: best_45dash_45camsejvmk0iCPHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsejvmk0iCPHMeta?.name ?? "guides-fleet-safety-best-dash-cams___de",
    path: best_45dash_45camsejvmk0iCPHMeta?.path ?? "/de/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsejvmk0iCPHMeta || {},
    alias: best_45dash_45camsejvmk0iCPHMeta?.alias || [],
    redirect: best_45dash_45camsejvmk0iCPHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsejvmk0iCPHMeta?.name ?? "guides-fleet-safety-best-dash-cams___mx",
    path: best_45dash_45camsejvmk0iCPHMeta?.path ?? "/mx/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsejvmk0iCPHMeta || {},
    alias: best_45dash_45camsejvmk0iCPHMeta?.alias || [],
    redirect: best_45dash_45camsejvmk0iCPHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsejvmk0iCPHMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr",
    path: best_45dash_45camsejvmk0iCPHMeta?.path ?? "/fr/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsejvmk0iCPHMeta || {},
    alias: best_45dash_45camsejvmk0iCPHMeta?.alias || [],
    redirect: best_45dash_45camsejvmk0iCPHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsejvmk0iCPHMeta?.name ?? "guides-fleet-safety-best-dash-cams___fr-ca",
    path: best_45dash_45camsejvmk0iCPHMeta?.path ?? "/fr-ca/guides/fleet-safety/best-dash-cams",
    meta: best_45dash_45camsejvmk0iCPHMeta || {},
    alias: best_45dash_45camsejvmk0iCPHMeta?.alias || [],
    redirect: best_45dash_45camsejvmk0iCPHMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: indexHQFmG19AtDMeta?.name ?? "guides-fleet-safety___en-US",
    path: indexHQFmG19AtDMeta?.path ?? "/guides/fleet-safety",
    meta: indexHQFmG19AtDMeta || {},
    alias: indexHQFmG19AtDMeta?.alias || [],
    redirect: indexHQFmG19AtDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexHQFmG19AtDMeta?.name ?? "guides-fleet-safety___ca",
    path: indexHQFmG19AtDMeta?.path ?? "/ca/guides/fleet-safety",
    meta: indexHQFmG19AtDMeta || {},
    alias: indexHQFmG19AtDMeta?.alias || [],
    redirect: indexHQFmG19AtDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexHQFmG19AtDMeta?.name ?? "guides-fleet-safety___nl",
    path: indexHQFmG19AtDMeta?.path ?? "/nl/guides/fleet-safety",
    meta: indexHQFmG19AtDMeta || {},
    alias: indexHQFmG19AtDMeta?.alias || [],
    redirect: indexHQFmG19AtDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexHQFmG19AtDMeta?.name ?? "guides-fleet-safety___uk",
    path: indexHQFmG19AtDMeta?.path ?? "/uk/guides/fleet-safety",
    meta: indexHQFmG19AtDMeta || {},
    alias: indexHQFmG19AtDMeta?.alias || [],
    redirect: indexHQFmG19AtDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexHQFmG19AtDMeta?.name ?? "guides-fleet-safety___de",
    path: indexHQFmG19AtDMeta?.path ?? "/de/guides/fleet-safety",
    meta: indexHQFmG19AtDMeta || {},
    alias: indexHQFmG19AtDMeta?.alias || [],
    redirect: indexHQFmG19AtDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexHQFmG19AtDMeta?.name ?? "guides-fleet-safety___mx",
    path: indexHQFmG19AtDMeta?.path ?? "/mx/guias/seguridad-flotas",
    meta: indexHQFmG19AtDMeta || {},
    alias: indexHQFmG19AtDMeta?.alias || [],
    redirect: indexHQFmG19AtDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexHQFmG19AtDMeta?.name ?? "guides-fleet-safety___fr",
    path: indexHQFmG19AtDMeta?.path ?? "/fr/guides/fleet-safety",
    meta: indexHQFmG19AtDMeta || {},
    alias: indexHQFmG19AtDMeta?.alias || [],
    redirect: indexHQFmG19AtDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: indexHQFmG19AtDMeta?.name ?? "guides-fleet-safety___fr-ca",
    path: indexHQFmG19AtDMeta?.path ?? "/fr-ca/guides/fleet-safety",
    meta: indexHQFmG19AtDMeta || {},
    alias: indexHQFmG19AtDMeta?.alias || [],
    redirect: indexHQFmG19AtDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/index.vue").then(m => m.default || m)
  },
  {
    name: safety_45programArLHyjidYoMeta?.name ?? "guides-fleet-safety-safety-program___en-US",
    path: safety_45programArLHyjidYoMeta?.path ?? "/guides/fleet-safety/safety-program",
    meta: safety_45programArLHyjidYoMeta || {},
    alias: safety_45programArLHyjidYoMeta?.alias || [],
    redirect: safety_45programArLHyjidYoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programArLHyjidYoMeta?.name ?? "guides-fleet-safety-safety-program___ca",
    path: safety_45programArLHyjidYoMeta?.path ?? "/ca/guides/fleet-safety/safety-program",
    meta: safety_45programArLHyjidYoMeta || {},
    alias: safety_45programArLHyjidYoMeta?.alias || [],
    redirect: safety_45programArLHyjidYoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programArLHyjidYoMeta?.name ?? "guides-fleet-safety-safety-program___nl",
    path: safety_45programArLHyjidYoMeta?.path ?? "/nl/guides/fleet-safety/safety-program",
    meta: safety_45programArLHyjidYoMeta || {},
    alias: safety_45programArLHyjidYoMeta?.alias || [],
    redirect: safety_45programArLHyjidYoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programArLHyjidYoMeta?.name ?? "guides-fleet-safety-safety-program___uk",
    path: safety_45programArLHyjidYoMeta?.path ?? "/uk/guides/fleet-safety/safety-program",
    meta: safety_45programArLHyjidYoMeta || {},
    alias: safety_45programArLHyjidYoMeta?.alias || [],
    redirect: safety_45programArLHyjidYoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programArLHyjidYoMeta?.name ?? "guides-fleet-safety-safety-program___de",
    path: safety_45programArLHyjidYoMeta?.path ?? "/de/guides/fleet-safety/safety-program",
    meta: safety_45programArLHyjidYoMeta || {},
    alias: safety_45programArLHyjidYoMeta?.alias || [],
    redirect: safety_45programArLHyjidYoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programArLHyjidYoMeta?.name ?? "guides-fleet-safety-safety-program___mx",
    path: safety_45programArLHyjidYoMeta?.path ?? "/mx/guides/fleet-safety/safety-program",
    meta: safety_45programArLHyjidYoMeta || {},
    alias: safety_45programArLHyjidYoMeta?.alias || [],
    redirect: safety_45programArLHyjidYoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programArLHyjidYoMeta?.name ?? "guides-fleet-safety-safety-program___fr",
    path: safety_45programArLHyjidYoMeta?.path ?? "/fr/guides/fleet-safety/safety-program",
    meta: safety_45programArLHyjidYoMeta || {},
    alias: safety_45programArLHyjidYoMeta?.alias || [],
    redirect: safety_45programArLHyjidYoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45programArLHyjidYoMeta?.name ?? "guides-fleet-safety-safety-program___fr-ca",
    path: safety_45programArLHyjidYoMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-program",
    meta: safety_45programArLHyjidYoMeta || {},
    alias: safety_45programArLHyjidYoMeta?.alias || [],
    redirect: safety_45programArLHyjidYoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-program.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiBNE4ZNt9joMeta?.name ?? "guides-fleet-safety-safety-roi___en-US",
    path: safety_45roiBNE4ZNt9joMeta?.path ?? "/guides/fleet-safety/safety-roi",
    meta: safety_45roiBNE4ZNt9joMeta || {},
    alias: safety_45roiBNE4ZNt9joMeta?.alias || [],
    redirect: safety_45roiBNE4ZNt9joMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiBNE4ZNt9joMeta?.name ?? "guides-fleet-safety-safety-roi___ca",
    path: safety_45roiBNE4ZNt9joMeta?.path ?? "/ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiBNE4ZNt9joMeta || {},
    alias: safety_45roiBNE4ZNt9joMeta?.alias || [],
    redirect: safety_45roiBNE4ZNt9joMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiBNE4ZNt9joMeta?.name ?? "guides-fleet-safety-safety-roi___nl",
    path: safety_45roiBNE4ZNt9joMeta?.path ?? "/nl/guides/fleet-safety/safety-roi",
    meta: safety_45roiBNE4ZNt9joMeta || {},
    alias: safety_45roiBNE4ZNt9joMeta?.alias || [],
    redirect: safety_45roiBNE4ZNt9joMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiBNE4ZNt9joMeta?.name ?? "guides-fleet-safety-safety-roi___uk",
    path: safety_45roiBNE4ZNt9joMeta?.path ?? "/uk/guides/fleet-safety/safety-roi",
    meta: safety_45roiBNE4ZNt9joMeta || {},
    alias: safety_45roiBNE4ZNt9joMeta?.alias || [],
    redirect: safety_45roiBNE4ZNt9joMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiBNE4ZNt9joMeta?.name ?? "guides-fleet-safety-safety-roi___de",
    path: safety_45roiBNE4ZNt9joMeta?.path ?? "/de/guides/fleet-safety/safety-roi",
    meta: safety_45roiBNE4ZNt9joMeta || {},
    alias: safety_45roiBNE4ZNt9joMeta?.alias || [],
    redirect: safety_45roiBNE4ZNt9joMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiBNE4ZNt9joMeta?.name ?? "guides-fleet-safety-safety-roi___mx",
    path: safety_45roiBNE4ZNt9joMeta?.path ?? "/mx/guides/fleet-safety/safety-roi",
    meta: safety_45roiBNE4ZNt9joMeta || {},
    alias: safety_45roiBNE4ZNt9joMeta?.alias || [],
    redirect: safety_45roiBNE4ZNt9joMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiBNE4ZNt9joMeta?.name ?? "guides-fleet-safety-safety-roi___fr",
    path: safety_45roiBNE4ZNt9joMeta?.path ?? "/fr/guides/fleet-safety/safety-roi",
    meta: safety_45roiBNE4ZNt9joMeta || {},
    alias: safety_45roiBNE4ZNt9joMeta?.alias || [],
    redirect: safety_45roiBNE4ZNt9joMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: safety_45roiBNE4ZNt9joMeta?.name ?? "guides-fleet-safety-safety-roi___fr-ca",
    path: safety_45roiBNE4ZNt9joMeta?.path ?? "/fr-ca/guides/fleet-safety/safety-roi",
    meta: safety_45roiBNE4ZNt9joMeta || {},
    alias: safety_45roiBNE4ZNt9joMeta?.alias || [],
    redirect: safety_45roiBNE4ZNt9joMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/fleet-safety/safety-roi.vue").then(m => m.default || m)
  },
  {
    name: indexXbQAoLRc4sMeta?.name ?? "guides___en-US",
    path: indexXbQAoLRc4sMeta?.path ?? "/guides",
    meta: indexXbQAoLRc4sMeta || {},
    alias: indexXbQAoLRc4sMeta?.alias || [],
    redirect: indexXbQAoLRc4sMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexXbQAoLRc4sMeta?.name ?? "guides___ca",
    path: indexXbQAoLRc4sMeta?.path ?? "/ca/guides",
    meta: indexXbQAoLRc4sMeta || {},
    alias: indexXbQAoLRc4sMeta?.alias || [],
    redirect: indexXbQAoLRc4sMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexXbQAoLRc4sMeta?.name ?? "guides___nl",
    path: indexXbQAoLRc4sMeta?.path ?? "/nl/guides",
    meta: indexXbQAoLRc4sMeta || {},
    alias: indexXbQAoLRc4sMeta?.alias || [],
    redirect: indexXbQAoLRc4sMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexXbQAoLRc4sMeta?.name ?? "guides___uk",
    path: indexXbQAoLRc4sMeta?.path ?? "/uk/guides",
    meta: indexXbQAoLRc4sMeta || {},
    alias: indexXbQAoLRc4sMeta?.alias || [],
    redirect: indexXbQAoLRc4sMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexXbQAoLRc4sMeta?.name ?? "guides___de",
    path: indexXbQAoLRc4sMeta?.path ?? "/de/guides",
    meta: indexXbQAoLRc4sMeta || {},
    alias: indexXbQAoLRc4sMeta?.alias || [],
    redirect: indexXbQAoLRc4sMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexXbQAoLRc4sMeta?.name ?? "guides___mx",
    path: indexXbQAoLRc4sMeta?.path ?? "/mx/guides",
    meta: indexXbQAoLRc4sMeta || {},
    alias: indexXbQAoLRc4sMeta?.alias || [],
    redirect: indexXbQAoLRc4sMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexXbQAoLRc4sMeta?.name ?? "guides___fr",
    path: indexXbQAoLRc4sMeta?.path ?? "/fr/guides",
    meta: indexXbQAoLRc4sMeta || {},
    alias: indexXbQAoLRc4sMeta?.alias || [],
    redirect: indexXbQAoLRc4sMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: indexXbQAoLRc4sMeta?.name ?? "guides___fr-ca",
    path: indexXbQAoLRc4sMeta?.path ?? "/fr-ca/guides",
    meta: indexXbQAoLRc4sMeta || {},
    alias: indexXbQAoLRc4sMeta?.alias || [],
    redirect: indexXbQAoLRc4sMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/index.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurebqEf9upeFjMeta?.name ?? "guides-make-more-possible-build-for-the-future___en-US",
    path: build_45for_45the_45futurebqEf9upeFjMeta?.path ?? "/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurebqEf9upeFjMeta || {},
    alias: build_45for_45the_45futurebqEf9upeFjMeta?.alias || [],
    redirect: build_45for_45the_45futurebqEf9upeFjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurebqEf9upeFjMeta?.name ?? "guides-make-more-possible-build-for-the-future___ca",
    path: build_45for_45the_45futurebqEf9upeFjMeta?.path ?? "/ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurebqEf9upeFjMeta || {},
    alias: build_45for_45the_45futurebqEf9upeFjMeta?.alias || [],
    redirect: build_45for_45the_45futurebqEf9upeFjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurebqEf9upeFjMeta?.name ?? "guides-make-more-possible-build-for-the-future___nl",
    path: build_45for_45the_45futurebqEf9upeFjMeta?.path ?? "/nl/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurebqEf9upeFjMeta || {},
    alias: build_45for_45the_45futurebqEf9upeFjMeta?.alias || [],
    redirect: build_45for_45the_45futurebqEf9upeFjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurebqEf9upeFjMeta?.name ?? "guides-make-more-possible-build-for-the-future___uk",
    path: build_45for_45the_45futurebqEf9upeFjMeta?.path ?? "/uk/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurebqEf9upeFjMeta || {},
    alias: build_45for_45the_45futurebqEf9upeFjMeta?.alias || [],
    redirect: build_45for_45the_45futurebqEf9upeFjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurebqEf9upeFjMeta?.name ?? "guides-make-more-possible-build-for-the-future___de",
    path: build_45for_45the_45futurebqEf9upeFjMeta?.path ?? "/de/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurebqEf9upeFjMeta || {},
    alias: build_45for_45the_45futurebqEf9upeFjMeta?.alias || [],
    redirect: build_45for_45the_45futurebqEf9upeFjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurebqEf9upeFjMeta?.name ?? "guides-make-more-possible-build-for-the-future___mx",
    path: build_45for_45the_45futurebqEf9upeFjMeta?.path ?? "/mx/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurebqEf9upeFjMeta || {},
    alias: build_45for_45the_45futurebqEf9upeFjMeta?.alias || [],
    redirect: build_45for_45the_45futurebqEf9upeFjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurebqEf9upeFjMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr",
    path: build_45for_45the_45futurebqEf9upeFjMeta?.path ?? "/fr/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurebqEf9upeFjMeta || {},
    alias: build_45for_45the_45futurebqEf9upeFjMeta?.alias || [],
    redirect: build_45for_45the_45futurebqEf9upeFjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: build_45for_45the_45futurebqEf9upeFjMeta?.name ?? "guides-make-more-possible-build-for-the-future___fr-ca",
    path: build_45for_45the_45futurebqEf9upeFjMeta?.path ?? "/fr-ca/guides/make-more-possible/build-for-the-future",
    meta: build_45for_45the_45futurebqEf9upeFjMeta || {},
    alias: build_45for_45the_45futurebqEf9upeFjMeta?.alias || [],
    redirect: build_45for_45the_45futurebqEf9upeFjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/build-for-the-future.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teams1vulEWPybtMeta?.name ?? "guides-make-more-possible-empower-your-teams___en-US",
    path: empower_45your_45teams1vulEWPybtMeta?.path ?? "/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teams1vulEWPybtMeta || {},
    alias: empower_45your_45teams1vulEWPybtMeta?.alias || [],
    redirect: empower_45your_45teams1vulEWPybtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teams1vulEWPybtMeta?.name ?? "guides-make-more-possible-empower-your-teams___ca",
    path: empower_45your_45teams1vulEWPybtMeta?.path ?? "/ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teams1vulEWPybtMeta || {},
    alias: empower_45your_45teams1vulEWPybtMeta?.alias || [],
    redirect: empower_45your_45teams1vulEWPybtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teams1vulEWPybtMeta?.name ?? "guides-make-more-possible-empower-your-teams___nl",
    path: empower_45your_45teams1vulEWPybtMeta?.path ?? "/nl/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teams1vulEWPybtMeta || {},
    alias: empower_45your_45teams1vulEWPybtMeta?.alias || [],
    redirect: empower_45your_45teams1vulEWPybtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teams1vulEWPybtMeta?.name ?? "guides-make-more-possible-empower-your-teams___uk",
    path: empower_45your_45teams1vulEWPybtMeta?.path ?? "/uk/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teams1vulEWPybtMeta || {},
    alias: empower_45your_45teams1vulEWPybtMeta?.alias || [],
    redirect: empower_45your_45teams1vulEWPybtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teams1vulEWPybtMeta?.name ?? "guides-make-more-possible-empower-your-teams___de",
    path: empower_45your_45teams1vulEWPybtMeta?.path ?? "/de/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teams1vulEWPybtMeta || {},
    alias: empower_45your_45teams1vulEWPybtMeta?.alias || [],
    redirect: empower_45your_45teams1vulEWPybtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teams1vulEWPybtMeta?.name ?? "guides-make-more-possible-empower-your-teams___mx",
    path: empower_45your_45teams1vulEWPybtMeta?.path ?? "/mx/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teams1vulEWPybtMeta || {},
    alias: empower_45your_45teams1vulEWPybtMeta?.alias || [],
    redirect: empower_45your_45teams1vulEWPybtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teams1vulEWPybtMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr",
    path: empower_45your_45teams1vulEWPybtMeta?.path ?? "/fr/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teams1vulEWPybtMeta || {},
    alias: empower_45your_45teams1vulEWPybtMeta?.alias || [],
    redirect: empower_45your_45teams1vulEWPybtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: empower_45your_45teams1vulEWPybtMeta?.name ?? "guides-make-more-possible-empower-your-teams___fr-ca",
    path: empower_45your_45teams1vulEWPybtMeta?.path ?? "/fr-ca/guides/make-more-possible/empower-your-teams",
    meta: empower_45your_45teams1vulEWPybtMeta || {},
    alias: empower_45your_45teams1vulEWPybtMeta?.alias || [],
    redirect: empower_45your_45teams1vulEWPybtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/empower-your-teams.vue").then(m => m.default || m)
  },
  {
    name: indexfvuRr4wttDMeta?.name ?? "guides-make-more-possible___en-US",
    path: indexfvuRr4wttDMeta?.path ?? "/guides/make-more-possible",
    meta: indexfvuRr4wttDMeta || {},
    alias: indexfvuRr4wttDMeta?.alias || [],
    redirect: indexfvuRr4wttDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexfvuRr4wttDMeta?.name ?? "guides-make-more-possible___ca",
    path: indexfvuRr4wttDMeta?.path ?? "/ca/guides/make-more-possible",
    meta: indexfvuRr4wttDMeta || {},
    alias: indexfvuRr4wttDMeta?.alias || [],
    redirect: indexfvuRr4wttDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexfvuRr4wttDMeta?.name ?? "guides-make-more-possible___nl",
    path: indexfvuRr4wttDMeta?.path ?? "/nl/guides/make-more-possible",
    meta: indexfvuRr4wttDMeta || {},
    alias: indexfvuRr4wttDMeta?.alias || [],
    redirect: indexfvuRr4wttDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexfvuRr4wttDMeta?.name ?? "guides-make-more-possible___uk",
    path: indexfvuRr4wttDMeta?.path ?? "/uk/guides/make-more-possible",
    meta: indexfvuRr4wttDMeta || {},
    alias: indexfvuRr4wttDMeta?.alias || [],
    redirect: indexfvuRr4wttDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexfvuRr4wttDMeta?.name ?? "guides-make-more-possible___de",
    path: indexfvuRr4wttDMeta?.path ?? "/de/guides/make-more-possible",
    meta: indexfvuRr4wttDMeta || {},
    alias: indexfvuRr4wttDMeta?.alias || [],
    redirect: indexfvuRr4wttDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexfvuRr4wttDMeta?.name ?? "guides-make-more-possible___mx",
    path: indexfvuRr4wttDMeta?.path ?? "/mx/guides/make-more-possible",
    meta: indexfvuRr4wttDMeta || {},
    alias: indexfvuRr4wttDMeta?.alias || [],
    redirect: indexfvuRr4wttDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexfvuRr4wttDMeta?.name ?? "guides-make-more-possible___fr",
    path: indexfvuRr4wttDMeta?.path ?? "/fr/guides/make-more-possible",
    meta: indexfvuRr4wttDMeta || {},
    alias: indexfvuRr4wttDMeta?.alias || [],
    redirect: indexfvuRr4wttDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: indexfvuRr4wttDMeta?.name ?? "guides-make-more-possible___fr-ca",
    path: indexfvuRr4wttDMeta?.path ?? "/fr-ca/guides/make-more-possible",
    meta: indexfvuRr4wttDMeta || {},
    alias: indexfvuRr4wttDMeta?.alias || [],
    redirect: indexfvuRr4wttDMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/index.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataB5o6ggP2WAMeta?.name ?? "guides-make-more-possible-take-action-with-data___en-US",
    path: take_45action_45with_45dataB5o6ggP2WAMeta?.path ?? "/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataB5o6ggP2WAMeta || {},
    alias: take_45action_45with_45dataB5o6ggP2WAMeta?.alias || [],
    redirect: take_45action_45with_45dataB5o6ggP2WAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataB5o6ggP2WAMeta?.name ?? "guides-make-more-possible-take-action-with-data___ca",
    path: take_45action_45with_45dataB5o6ggP2WAMeta?.path ?? "/ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataB5o6ggP2WAMeta || {},
    alias: take_45action_45with_45dataB5o6ggP2WAMeta?.alias || [],
    redirect: take_45action_45with_45dataB5o6ggP2WAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataB5o6ggP2WAMeta?.name ?? "guides-make-more-possible-take-action-with-data___nl",
    path: take_45action_45with_45dataB5o6ggP2WAMeta?.path ?? "/nl/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataB5o6ggP2WAMeta || {},
    alias: take_45action_45with_45dataB5o6ggP2WAMeta?.alias || [],
    redirect: take_45action_45with_45dataB5o6ggP2WAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataB5o6ggP2WAMeta?.name ?? "guides-make-more-possible-take-action-with-data___uk",
    path: take_45action_45with_45dataB5o6ggP2WAMeta?.path ?? "/uk/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataB5o6ggP2WAMeta || {},
    alias: take_45action_45with_45dataB5o6ggP2WAMeta?.alias || [],
    redirect: take_45action_45with_45dataB5o6ggP2WAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataB5o6ggP2WAMeta?.name ?? "guides-make-more-possible-take-action-with-data___de",
    path: take_45action_45with_45dataB5o6ggP2WAMeta?.path ?? "/de/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataB5o6ggP2WAMeta || {},
    alias: take_45action_45with_45dataB5o6ggP2WAMeta?.alias || [],
    redirect: take_45action_45with_45dataB5o6ggP2WAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataB5o6ggP2WAMeta?.name ?? "guides-make-more-possible-take-action-with-data___mx",
    path: take_45action_45with_45dataB5o6ggP2WAMeta?.path ?? "/mx/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataB5o6ggP2WAMeta || {},
    alias: take_45action_45with_45dataB5o6ggP2WAMeta?.alias || [],
    redirect: take_45action_45with_45dataB5o6ggP2WAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataB5o6ggP2WAMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr",
    path: take_45action_45with_45dataB5o6ggP2WAMeta?.path ?? "/fr/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataB5o6ggP2WAMeta || {},
    alias: take_45action_45with_45dataB5o6ggP2WAMeta?.alias || [],
    redirect: take_45action_45with_45dataB5o6ggP2WAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: take_45action_45with_45dataB5o6ggP2WAMeta?.name ?? "guides-make-more-possible-take-action-with-data___fr-ca",
    path: take_45action_45with_45dataB5o6ggP2WAMeta?.path ?? "/fr-ca/guides/make-more-possible/take-action-with-data",
    meta: take_45action_45with_45dataB5o6ggP2WAMeta || {},
    alias: take_45action_45with_45dataB5o6ggP2WAMeta?.alias || [],
    redirect: take_45action_45with_45dataB5o6ggP2WAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/make-more-possible/take-action-with-data.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EW0AVVOXopMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___en-US",
    path: _91slug_93EW0AVVOXopMeta?.path ?? "/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93EW0AVVOXopMeta || {},
    alias: _91slug_93EW0AVVOXopMeta?.alias || [],
    redirect: _91slug_93EW0AVVOXopMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EW0AVVOXopMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___ca",
    path: _91slug_93EW0AVVOXopMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93EW0AVVOXopMeta || {},
    alias: _91slug_93EW0AVVOXopMeta?.alias || [],
    redirect: _91slug_93EW0AVVOXopMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EW0AVVOXopMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___nl",
    path: _91slug_93EW0AVVOXopMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93EW0AVVOXopMeta || {},
    alias: _91slug_93EW0AVVOXopMeta?.alias || [],
    redirect: _91slug_93EW0AVVOXopMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EW0AVVOXopMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___uk",
    path: _91slug_93EW0AVVOXopMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93EW0AVVOXopMeta || {},
    alias: _91slug_93EW0AVVOXopMeta?.alias || [],
    redirect: _91slug_93EW0AVVOXopMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EW0AVVOXopMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___de",
    path: _91slug_93EW0AVVOXopMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93EW0AVVOXopMeta || {},
    alias: _91slug_93EW0AVVOXopMeta?.alias || [],
    redirect: _91slug_93EW0AVVOXopMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EW0AVVOXopMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___mx",
    path: _91slug_93EW0AVVOXopMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93EW0AVVOXopMeta || {},
    alias: _91slug_93EW0AVVOXopMeta?.alias || [],
    redirect: _91slug_93EW0AVVOXopMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EW0AVVOXopMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr",
    path: _91slug_93EW0AVVOXopMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93EW0AVVOXopMeta || {},
    alias: _91slug_93EW0AVVOXopMeta?.alias || [],
    redirect: _91slug_93EW0AVVOXopMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93EW0AVVOXopMeta?.name ?? "guides-your-partner-for-the-road-ahead-slug___fr-ca",
    path: _91slug_93EW0AVVOXopMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead/:slug()",
    meta: _91slug_93EW0AVVOXopMeta || {},
    alias: _91slug_93EW0AVVOXopMeta?.alias || [],
    redirect: _91slug_93EW0AVVOXopMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTqvdHjOZQyMeta?.name ?? "guides-your-partner-for-the-road-ahead___en-US",
    path: indexTqvdHjOZQyMeta?.path ?? "/guides/your-partner-for-the-road-ahead",
    meta: indexTqvdHjOZQyMeta || {},
    alias: indexTqvdHjOZQyMeta?.alias || [],
    redirect: indexTqvdHjOZQyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexTqvdHjOZQyMeta?.name ?? "guides-your-partner-for-the-road-ahead___ca",
    path: indexTqvdHjOZQyMeta?.path ?? "/ca/guides/your-partner-for-the-road-ahead",
    meta: indexTqvdHjOZQyMeta || {},
    alias: indexTqvdHjOZQyMeta?.alias || [],
    redirect: indexTqvdHjOZQyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexTqvdHjOZQyMeta?.name ?? "guides-your-partner-for-the-road-ahead___nl",
    path: indexTqvdHjOZQyMeta?.path ?? "/nl/guides/your-partner-for-the-road-ahead",
    meta: indexTqvdHjOZQyMeta || {},
    alias: indexTqvdHjOZQyMeta?.alias || [],
    redirect: indexTqvdHjOZQyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexTqvdHjOZQyMeta?.name ?? "guides-your-partner-for-the-road-ahead___uk",
    path: indexTqvdHjOZQyMeta?.path ?? "/uk/guides/your-partner-for-the-road-ahead",
    meta: indexTqvdHjOZQyMeta || {},
    alias: indexTqvdHjOZQyMeta?.alias || [],
    redirect: indexTqvdHjOZQyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexTqvdHjOZQyMeta?.name ?? "guides-your-partner-for-the-road-ahead___de",
    path: indexTqvdHjOZQyMeta?.path ?? "/de/guides/your-partner-for-the-road-ahead",
    meta: indexTqvdHjOZQyMeta || {},
    alias: indexTqvdHjOZQyMeta?.alias || [],
    redirect: indexTqvdHjOZQyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexTqvdHjOZQyMeta?.name ?? "guides-your-partner-for-the-road-ahead___mx",
    path: indexTqvdHjOZQyMeta?.path ?? "/mx/guides/your-partner-for-the-road-ahead",
    meta: indexTqvdHjOZQyMeta || {},
    alias: indexTqvdHjOZQyMeta?.alias || [],
    redirect: indexTqvdHjOZQyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexTqvdHjOZQyMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr",
    path: indexTqvdHjOZQyMeta?.path ?? "/fr/guides/your-partner-for-the-road-ahead",
    meta: indexTqvdHjOZQyMeta || {},
    alias: indexTqvdHjOZQyMeta?.alias || [],
    redirect: indexTqvdHjOZQyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexTqvdHjOZQyMeta?.name ?? "guides-your-partner-for-the-road-ahead___fr-ca",
    path: indexTqvdHjOZQyMeta?.path ?? "/fr-ca/guides/your-partner-for-the-road-ahead",
    meta: indexTqvdHjOZQyMeta || {},
    alias: indexTqvdHjOZQyMeta?.alias || [],
    redirect: indexTqvdHjOZQyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/guides/your-partner-for-the-road-ahead/index.vue").then(m => m.default || m)
  },
  {
    name: indexwH1a3v3OQkMeta?.name ?? "index___en-US",
    path: indexwH1a3v3OQkMeta?.path ?? "/",
    meta: indexwH1a3v3OQkMeta || {},
    alias: indexwH1a3v3OQkMeta?.alias || [],
    redirect: indexwH1a3v3OQkMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwH1a3v3OQkMeta?.name ?? "index___ca",
    path: indexwH1a3v3OQkMeta?.path ?? "/ca",
    meta: indexwH1a3v3OQkMeta || {},
    alias: indexwH1a3v3OQkMeta?.alias || [],
    redirect: indexwH1a3v3OQkMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwH1a3v3OQkMeta?.name ?? "index___nl",
    path: indexwH1a3v3OQkMeta?.path ?? "/nl",
    meta: indexwH1a3v3OQkMeta || {},
    alias: indexwH1a3v3OQkMeta?.alias || [],
    redirect: indexwH1a3v3OQkMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwH1a3v3OQkMeta?.name ?? "index___uk",
    path: indexwH1a3v3OQkMeta?.path ?? "/uk",
    meta: indexwH1a3v3OQkMeta || {},
    alias: indexwH1a3v3OQkMeta?.alias || [],
    redirect: indexwH1a3v3OQkMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwH1a3v3OQkMeta?.name ?? "index___de",
    path: indexwH1a3v3OQkMeta?.path ?? "/de",
    meta: indexwH1a3v3OQkMeta || {},
    alias: indexwH1a3v3OQkMeta?.alias || [],
    redirect: indexwH1a3v3OQkMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwH1a3v3OQkMeta?.name ?? "index___mx",
    path: indexwH1a3v3OQkMeta?.path ?? "/mx",
    meta: indexwH1a3v3OQkMeta || {},
    alias: indexwH1a3v3OQkMeta?.alias || [],
    redirect: indexwH1a3v3OQkMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwH1a3v3OQkMeta?.name ?? "index___fr",
    path: indexwH1a3v3OQkMeta?.path ?? "/fr",
    meta: indexwH1a3v3OQkMeta || {},
    alias: indexwH1a3v3OQkMeta?.alias || [],
    redirect: indexwH1a3v3OQkMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwH1a3v3OQkMeta?.name ?? "index___fr-ca",
    path: indexwH1a3v3OQkMeta?.path ?? "/fr-ca",
    meta: indexwH1a3v3OQkMeta || {},
    alias: indexwH1a3v3OQkMeta?.alias || [],
    redirect: indexwH1a3v3OQkMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o9EB4ptuqnMeta?.name ?? "industrial-slug___en-US",
    path: _91slug_93o9EB4ptuqnMeta?.path ?? "/industrial/:slug()",
    meta: _91slug_93o9EB4ptuqnMeta || {},
    alias: _91slug_93o9EB4ptuqnMeta?.alias || [],
    redirect: _91slug_93o9EB4ptuqnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o9EB4ptuqnMeta?.name ?? "industrial-slug___ca",
    path: _91slug_93o9EB4ptuqnMeta?.path ?? "/ca/industrial/:slug()",
    meta: _91slug_93o9EB4ptuqnMeta || {},
    alias: _91slug_93o9EB4ptuqnMeta?.alias || [],
    redirect: _91slug_93o9EB4ptuqnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o9EB4ptuqnMeta?.name ?? "industrial-slug___nl",
    path: _91slug_93o9EB4ptuqnMeta?.path ?? "/nl/industrial/:slug()",
    meta: _91slug_93o9EB4ptuqnMeta || {},
    alias: _91slug_93o9EB4ptuqnMeta?.alias || [],
    redirect: _91slug_93o9EB4ptuqnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o9EB4ptuqnMeta?.name ?? "industrial-slug___uk",
    path: _91slug_93o9EB4ptuqnMeta?.path ?? "/uk/industrial/:slug()",
    meta: _91slug_93o9EB4ptuqnMeta || {},
    alias: _91slug_93o9EB4ptuqnMeta?.alias || [],
    redirect: _91slug_93o9EB4ptuqnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o9EB4ptuqnMeta?.name ?? "industrial-slug___de",
    path: _91slug_93o9EB4ptuqnMeta?.path ?? "/de/industrial/:slug()",
    meta: _91slug_93o9EB4ptuqnMeta || {},
    alias: _91slug_93o9EB4ptuqnMeta?.alias || [],
    redirect: _91slug_93o9EB4ptuqnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o9EB4ptuqnMeta?.name ?? "industrial-slug___mx",
    path: _91slug_93o9EB4ptuqnMeta?.path ?? "/mx/industrial/:slug()",
    meta: _91slug_93o9EB4ptuqnMeta || {},
    alias: _91slug_93o9EB4ptuqnMeta?.alias || [],
    redirect: _91slug_93o9EB4ptuqnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o9EB4ptuqnMeta?.name ?? "industrial-slug___fr",
    path: _91slug_93o9EB4ptuqnMeta?.path ?? "/fr/industrial/:slug()",
    meta: _91slug_93o9EB4ptuqnMeta || {},
    alias: _91slug_93o9EB4ptuqnMeta?.alias || [],
    redirect: _91slug_93o9EB4ptuqnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o9EB4ptuqnMeta?.name ?? "industrial-slug___fr-ca",
    path: _91slug_93o9EB4ptuqnMeta?.path ?? "/fr-ca/industrial/:slug()",
    meta: _91slug_93o9EB4ptuqnMeta || {},
    alias: _91slug_93o9EB4ptuqnMeta?.alias || [],
    redirect: _91slug_93o9EB4ptuqnMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industrial/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93t9kBIowUUtMeta?.name ?? "industries-slug___en-US",
    path: _91slug_93t9kBIowUUtMeta?.path ?? "/industries/:slug()",
    meta: _91slug_93t9kBIowUUtMeta || {},
    alias: _91slug_93t9kBIowUUtMeta?.alias || [],
    redirect: _91slug_93t9kBIowUUtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93t9kBIowUUtMeta?.name ?? "industries-slug___ca",
    path: _91slug_93t9kBIowUUtMeta?.path ?? "/ca/industries/:slug()",
    meta: _91slug_93t9kBIowUUtMeta || {},
    alias: _91slug_93t9kBIowUUtMeta?.alias || [],
    redirect: _91slug_93t9kBIowUUtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93t9kBIowUUtMeta?.name ?? "industries-slug___nl",
    path: _91slug_93t9kBIowUUtMeta?.path ?? "/nl/industries/:slug()",
    meta: _91slug_93t9kBIowUUtMeta || {},
    alias: _91slug_93t9kBIowUUtMeta?.alias || [],
    redirect: _91slug_93t9kBIowUUtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93t9kBIowUUtMeta?.name ?? "industries-slug___uk",
    path: _91slug_93t9kBIowUUtMeta?.path ?? "/uk/industries/:slug()",
    meta: _91slug_93t9kBIowUUtMeta || {},
    alias: _91slug_93t9kBIowUUtMeta?.alias || [],
    redirect: _91slug_93t9kBIowUUtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93t9kBIowUUtMeta?.name ?? "industries-slug___de",
    path: _91slug_93t9kBIowUUtMeta?.path ?? "/de/industries/:slug()",
    meta: _91slug_93t9kBIowUUtMeta || {},
    alias: _91slug_93t9kBIowUUtMeta?.alias || [],
    redirect: _91slug_93t9kBIowUUtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93t9kBIowUUtMeta?.name ?? "industries-slug___mx",
    path: _91slug_93t9kBIowUUtMeta?.path ?? "/mx/industries/:slug()",
    meta: _91slug_93t9kBIowUUtMeta || {},
    alias: _91slug_93t9kBIowUUtMeta?.alias || [],
    redirect: _91slug_93t9kBIowUUtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93t9kBIowUUtMeta?.name ?? "industries-slug___fr",
    path: _91slug_93t9kBIowUUtMeta?.path ?? "/fr/industries/:slug()",
    meta: _91slug_93t9kBIowUUtMeta || {},
    alias: _91slug_93t9kBIowUUtMeta?.alias || [],
    redirect: _91slug_93t9kBIowUUtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93t9kBIowUUtMeta?.name ?? "industries-slug___fr-ca",
    path: _91slug_93t9kBIowUUtMeta?.path ?? "/fr-ca/industries/:slug()",
    meta: _91slug_93t9kBIowUUtMeta || {},
    alias: _91slug_93t9kBIowUUtMeta?.alias || [],
    redirect: _91slug_93t9kBIowUUtMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexXnA36DpsnjMeta?.name ?? "industries___en-US",
    path: indexXnA36DpsnjMeta?.path ?? "/industries",
    meta: indexXnA36DpsnjMeta || {},
    alias: indexXnA36DpsnjMeta?.alias || [],
    redirect: indexXnA36DpsnjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexXnA36DpsnjMeta?.name ?? "industries___ca",
    path: indexXnA36DpsnjMeta?.path ?? "/ca/industries",
    meta: indexXnA36DpsnjMeta || {},
    alias: indexXnA36DpsnjMeta?.alias || [],
    redirect: indexXnA36DpsnjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexXnA36DpsnjMeta?.name ?? "industries___nl",
    path: indexXnA36DpsnjMeta?.path ?? "/nl/industries",
    meta: indexXnA36DpsnjMeta || {},
    alias: indexXnA36DpsnjMeta?.alias || [],
    redirect: indexXnA36DpsnjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexXnA36DpsnjMeta?.name ?? "industries___uk",
    path: indexXnA36DpsnjMeta?.path ?? "/uk/industries",
    meta: indexXnA36DpsnjMeta || {},
    alias: indexXnA36DpsnjMeta?.alias || [],
    redirect: indexXnA36DpsnjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexXnA36DpsnjMeta?.name ?? "industries___de",
    path: indexXnA36DpsnjMeta?.path ?? "/de/industries",
    meta: indexXnA36DpsnjMeta || {},
    alias: indexXnA36DpsnjMeta?.alias || [],
    redirect: indexXnA36DpsnjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexXnA36DpsnjMeta?.name ?? "industries___mx",
    path: indexXnA36DpsnjMeta?.path ?? "/mx/industries",
    meta: indexXnA36DpsnjMeta || {},
    alias: indexXnA36DpsnjMeta?.alias || [],
    redirect: indexXnA36DpsnjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexXnA36DpsnjMeta?.name ?? "industries___fr",
    path: indexXnA36DpsnjMeta?.path ?? "/fr/industries",
    meta: indexXnA36DpsnjMeta || {},
    alias: indexXnA36DpsnjMeta?.alias || [],
    redirect: indexXnA36DpsnjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: indexXnA36DpsnjMeta?.name ?? "industries___fr-ca",
    path: indexXnA36DpsnjMeta?.path ?? "/fr-ca/industries",
    meta: indexXnA36DpsnjMeta || {},
    alias: indexXnA36DpsnjMeta?.alias || [],
    redirect: indexXnA36DpsnjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/industries/index.vue").then(m => m.default || m)
  },
  {
    name: citiespKSah627rWMeta?.name ?? "insights-cities___en-US",
    path: citiespKSah627rWMeta?.path ?? "/insights/cities",
    meta: citiespKSah627rWMeta || {},
    alias: citiespKSah627rWMeta?.alias || [],
    redirect: citiespKSah627rWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiespKSah627rWMeta?.name ?? "insights-cities___ca",
    path: citiespKSah627rWMeta?.path ?? "/ca/insights/cities",
    meta: citiespKSah627rWMeta || {},
    alias: citiespKSah627rWMeta?.alias || [],
    redirect: citiespKSah627rWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiespKSah627rWMeta?.name ?? "insights-cities___nl",
    path: citiespKSah627rWMeta?.path ?? "/nl/insights/cities",
    meta: citiespKSah627rWMeta || {},
    alias: citiespKSah627rWMeta?.alias || [],
    redirect: citiespKSah627rWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiespKSah627rWMeta?.name ?? "insights-cities___uk",
    path: citiespKSah627rWMeta?.path ?? "/uk/insights/cities",
    meta: citiespKSah627rWMeta || {},
    alias: citiespKSah627rWMeta?.alias || [],
    redirect: citiespKSah627rWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiespKSah627rWMeta?.name ?? "insights-cities___de",
    path: citiespKSah627rWMeta?.path ?? "/de/insights/cities",
    meta: citiespKSah627rWMeta || {},
    alias: citiespKSah627rWMeta?.alias || [],
    redirect: citiespKSah627rWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiespKSah627rWMeta?.name ?? "insights-cities___mx",
    path: citiespKSah627rWMeta?.path ?? "/mx/insights/cities",
    meta: citiespKSah627rWMeta || {},
    alias: citiespKSah627rWMeta?.alias || [],
    redirect: citiespKSah627rWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiespKSah627rWMeta?.name ?? "insights-cities___fr",
    path: citiespKSah627rWMeta?.path ?? "/fr/insights/cities",
    meta: citiespKSah627rWMeta || {},
    alias: citiespKSah627rWMeta?.alias || [],
    redirect: citiespKSah627rWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: citiespKSah627rWMeta?.name ?? "insights-cities___fr-ca",
    path: citiespKSah627rWMeta?.path ?? "/fr-ca/insights/cities",
    meta: citiespKSah627rWMeta || {},
    alias: citiespKSah627rWMeta?.alias || [],
    redirect: citiespKSah627rWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/insights/cities.vue").then(m => m.default || m)
  },
  {
    name: index8Jh7n9HAosMeta?.name ?? "integrations___en-US",
    path: index8Jh7n9HAosMeta?.path ?? "/integrations",
    meta: index8Jh7n9HAosMeta || {},
    alias: index8Jh7n9HAosMeta?.alias || [],
    redirect: index8Jh7n9HAosMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index8Jh7n9HAosMeta?.name ?? "integrations___ca",
    path: index8Jh7n9HAosMeta?.path ?? "/ca/integrations",
    meta: index8Jh7n9HAosMeta || {},
    alias: index8Jh7n9HAosMeta?.alias || [],
    redirect: index8Jh7n9HAosMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index8Jh7n9HAosMeta?.name ?? "integrations___nl",
    path: index8Jh7n9HAosMeta?.path ?? "/nl/integrations",
    meta: index8Jh7n9HAosMeta || {},
    alias: index8Jh7n9HAosMeta?.alias || [],
    redirect: index8Jh7n9HAosMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index8Jh7n9HAosMeta?.name ?? "integrations___uk",
    path: index8Jh7n9HAosMeta?.path ?? "/uk/integrations",
    meta: index8Jh7n9HAosMeta || {},
    alias: index8Jh7n9HAosMeta?.alias || [],
    redirect: index8Jh7n9HAosMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index8Jh7n9HAosMeta?.name ?? "integrations___de",
    path: index8Jh7n9HAosMeta?.path ?? "/de/integrations",
    meta: index8Jh7n9HAosMeta || {},
    alias: index8Jh7n9HAosMeta?.alias || [],
    redirect: index8Jh7n9HAosMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index8Jh7n9HAosMeta?.name ?? "integrations___mx",
    path: index8Jh7n9HAosMeta?.path ?? "/mx/integrations",
    meta: index8Jh7n9HAosMeta || {},
    alias: index8Jh7n9HAosMeta?.alias || [],
    redirect: index8Jh7n9HAosMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index8Jh7n9HAosMeta?.name ?? "integrations___fr",
    path: index8Jh7n9HAosMeta?.path ?? "/fr/integrations",
    meta: index8Jh7n9HAosMeta || {},
    alias: index8Jh7n9HAosMeta?.alias || [],
    redirect: index8Jh7n9HAosMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index8Jh7n9HAosMeta?.name ?? "integrations___fr-ca",
    path: index8Jh7n9HAosMeta?.path ?? "/fr-ca/integrations",
    meta: index8Jh7n9HAosMeta || {},
    alias: index8Jh7n9HAosMeta?.alias || [],
    redirect: index8Jh7n9HAosMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: index0FnYcgUsyCMeta?.name ?? "invite___en-US",
    path: index0FnYcgUsyCMeta?.path ?? "/invite",
    meta: index0FnYcgUsyCMeta || {},
    alias: index0FnYcgUsyCMeta?.alias || [],
    redirect: index0FnYcgUsyCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index0FnYcgUsyCMeta?.name ?? "invite___ca",
    path: index0FnYcgUsyCMeta?.path ?? "/ca/invite",
    meta: index0FnYcgUsyCMeta || {},
    alias: index0FnYcgUsyCMeta?.alias || [],
    redirect: index0FnYcgUsyCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index0FnYcgUsyCMeta?.name ?? "invite___nl",
    path: index0FnYcgUsyCMeta?.path ?? "/nl/invite",
    meta: index0FnYcgUsyCMeta || {},
    alias: index0FnYcgUsyCMeta?.alias || [],
    redirect: index0FnYcgUsyCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index0FnYcgUsyCMeta?.name ?? "invite___uk",
    path: index0FnYcgUsyCMeta?.path ?? "/uk/invite",
    meta: index0FnYcgUsyCMeta || {},
    alias: index0FnYcgUsyCMeta?.alias || [],
    redirect: index0FnYcgUsyCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index0FnYcgUsyCMeta?.name ?? "invite___de",
    path: index0FnYcgUsyCMeta?.path ?? "/de/invite",
    meta: index0FnYcgUsyCMeta || {},
    alias: index0FnYcgUsyCMeta?.alias || [],
    redirect: index0FnYcgUsyCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index0FnYcgUsyCMeta?.name ?? "invite___mx",
    path: index0FnYcgUsyCMeta?.path ?? "/mx/invite",
    meta: index0FnYcgUsyCMeta || {},
    alias: index0FnYcgUsyCMeta?.alias || [],
    redirect: index0FnYcgUsyCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index0FnYcgUsyCMeta?.name ?? "invite___fr",
    path: index0FnYcgUsyCMeta?.path ?? "/fr/invite",
    meta: index0FnYcgUsyCMeta || {},
    alias: index0FnYcgUsyCMeta?.alias || [],
    redirect: index0FnYcgUsyCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: index0FnYcgUsyCMeta?.name ?? "invite___fr-ca",
    path: index0FnYcgUsyCMeta?.path ?? "/fr-ca/invite",
    meta: index0FnYcgUsyCMeta || {},
    alias: index0FnYcgUsyCMeta?.alias || [],
    redirect: index0FnYcgUsyCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreyoet67FiBUMeta?.name ?? "learn-more___en-US",
    path: learn_45moreyoet67FiBUMeta?.path ?? "/learn-more",
    meta: learn_45moreyoet67FiBUMeta || {},
    alias: learn_45moreyoet67FiBUMeta?.alias || [],
    redirect: learn_45moreyoet67FiBUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreyoet67FiBUMeta?.name ?? "learn-more___ca",
    path: learn_45moreyoet67FiBUMeta?.path ?? "/ca/learn-more",
    meta: learn_45moreyoet67FiBUMeta || {},
    alias: learn_45moreyoet67FiBUMeta?.alias || [],
    redirect: learn_45moreyoet67FiBUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreyoet67FiBUMeta?.name ?? "learn-more___nl",
    path: learn_45moreyoet67FiBUMeta?.path ?? "/nl/learn-more",
    meta: learn_45moreyoet67FiBUMeta || {},
    alias: learn_45moreyoet67FiBUMeta?.alias || [],
    redirect: learn_45moreyoet67FiBUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreyoet67FiBUMeta?.name ?? "learn-more___uk",
    path: learn_45moreyoet67FiBUMeta?.path ?? "/uk/learn-more",
    meta: learn_45moreyoet67FiBUMeta || {},
    alias: learn_45moreyoet67FiBUMeta?.alias || [],
    redirect: learn_45moreyoet67FiBUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreyoet67FiBUMeta?.name ?? "learn-more___de",
    path: learn_45moreyoet67FiBUMeta?.path ?? "/de/learn-more",
    meta: learn_45moreyoet67FiBUMeta || {},
    alias: learn_45moreyoet67FiBUMeta?.alias || [],
    redirect: learn_45moreyoet67FiBUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreyoet67FiBUMeta?.name ?? "learn-more___mx",
    path: learn_45moreyoet67FiBUMeta?.path ?? "/mx/learn-more",
    meta: learn_45moreyoet67FiBUMeta || {},
    alias: learn_45moreyoet67FiBUMeta?.alias || [],
    redirect: learn_45moreyoet67FiBUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreyoet67FiBUMeta?.name ?? "learn-more___fr",
    path: learn_45moreyoet67FiBUMeta?.path ?? "/fr/learn-more",
    meta: learn_45moreyoet67FiBUMeta || {},
    alias: learn_45moreyoet67FiBUMeta?.alias || [],
    redirect: learn_45moreyoet67FiBUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: learn_45moreyoet67FiBUMeta?.name ?? "learn-more___fr-ca",
    path: learn_45moreyoet67FiBUMeta?.path ?? "/fr-ca/learn-more",
    meta: learn_45moreyoet67FiBUMeta || {},
    alias: learn_45moreyoet67FiBUMeta?.alias || [],
    redirect: learn_45moreyoet67FiBUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/learn-more.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeUVShCPQ2PyMeta?.name ?? "legal-candidate-privacy-notice___en-US",
    path: candidate_45privacy_45noticeUVShCPQ2PyMeta?.path ?? "/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeUVShCPQ2PyMeta || {},
    alias: candidate_45privacy_45noticeUVShCPQ2PyMeta?.alias || [],
    redirect: candidate_45privacy_45noticeUVShCPQ2PyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeUVShCPQ2PyMeta?.name ?? "legal-candidate-privacy-notice___ca",
    path: candidate_45privacy_45noticeUVShCPQ2PyMeta?.path ?? "/ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeUVShCPQ2PyMeta || {},
    alias: candidate_45privacy_45noticeUVShCPQ2PyMeta?.alias || [],
    redirect: candidate_45privacy_45noticeUVShCPQ2PyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeUVShCPQ2PyMeta?.name ?? "legal-candidate-privacy-notice___nl",
    path: candidate_45privacy_45noticeUVShCPQ2PyMeta?.path ?? "/nl/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeUVShCPQ2PyMeta || {},
    alias: candidate_45privacy_45noticeUVShCPQ2PyMeta?.alias || [],
    redirect: candidate_45privacy_45noticeUVShCPQ2PyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeUVShCPQ2PyMeta?.name ?? "legal-candidate-privacy-notice___uk",
    path: candidate_45privacy_45noticeUVShCPQ2PyMeta?.path ?? "/uk/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeUVShCPQ2PyMeta || {},
    alias: candidate_45privacy_45noticeUVShCPQ2PyMeta?.alias || [],
    redirect: candidate_45privacy_45noticeUVShCPQ2PyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeUVShCPQ2PyMeta?.name ?? "legal-candidate-privacy-notice___de",
    path: candidate_45privacy_45noticeUVShCPQ2PyMeta?.path ?? "/de/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeUVShCPQ2PyMeta || {},
    alias: candidate_45privacy_45noticeUVShCPQ2PyMeta?.alias || [],
    redirect: candidate_45privacy_45noticeUVShCPQ2PyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeUVShCPQ2PyMeta?.name ?? "legal-candidate-privacy-notice___mx",
    path: candidate_45privacy_45noticeUVShCPQ2PyMeta?.path ?? "/mx/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeUVShCPQ2PyMeta || {},
    alias: candidate_45privacy_45noticeUVShCPQ2PyMeta?.alias || [],
    redirect: candidate_45privacy_45noticeUVShCPQ2PyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeUVShCPQ2PyMeta?.name ?? "legal-candidate-privacy-notice___fr",
    path: candidate_45privacy_45noticeUVShCPQ2PyMeta?.path ?? "/fr/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeUVShCPQ2PyMeta || {},
    alias: candidate_45privacy_45noticeUVShCPQ2PyMeta?.alias || [],
    redirect: candidate_45privacy_45noticeUVShCPQ2PyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: candidate_45privacy_45noticeUVShCPQ2PyMeta?.name ?? "legal-candidate-privacy-notice___fr-ca",
    path: candidate_45privacy_45noticeUVShCPQ2PyMeta?.path ?? "/fr-ca/legal/candidate-privacy-notice",
    meta: candidate_45privacy_45noticeUVShCPQ2PyMeta || {},
    alias: candidate_45privacy_45noticeUVShCPQ2PyMeta?.alias || [],
    redirect: candidate_45privacy_45noticeUVShCPQ2PyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/candidate-privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentjxXnF8xg22Meta?.name ?? "legal-cookie-consent___en-US",
    path: cookie_45consentjxXnF8xg22Meta?.path ?? "/legal/cookie-consent",
    meta: cookie_45consentjxXnF8xg22Meta || {},
    alias: cookie_45consentjxXnF8xg22Meta?.alias || [],
    redirect: cookie_45consentjxXnF8xg22Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentjxXnF8xg22Meta?.name ?? "legal-cookie-consent___ca",
    path: cookie_45consentjxXnF8xg22Meta?.path ?? "/ca/legal/cookie-consent",
    meta: cookie_45consentjxXnF8xg22Meta || {},
    alias: cookie_45consentjxXnF8xg22Meta?.alias || [],
    redirect: cookie_45consentjxXnF8xg22Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentjxXnF8xg22Meta?.name ?? "legal-cookie-consent___nl",
    path: cookie_45consentjxXnF8xg22Meta?.path ?? "/nl/legal/cookie-consent",
    meta: cookie_45consentjxXnF8xg22Meta || {},
    alias: cookie_45consentjxXnF8xg22Meta?.alias || [],
    redirect: cookie_45consentjxXnF8xg22Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentjxXnF8xg22Meta?.name ?? "legal-cookie-consent___uk",
    path: cookie_45consentjxXnF8xg22Meta?.path ?? "/uk/legal/cookie-consent",
    meta: cookie_45consentjxXnF8xg22Meta || {},
    alias: cookie_45consentjxXnF8xg22Meta?.alias || [],
    redirect: cookie_45consentjxXnF8xg22Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentjxXnF8xg22Meta?.name ?? "legal-cookie-consent___de",
    path: cookie_45consentjxXnF8xg22Meta?.path ?? "/de/legal/cookie-consent",
    meta: cookie_45consentjxXnF8xg22Meta || {},
    alias: cookie_45consentjxXnF8xg22Meta?.alias || [],
    redirect: cookie_45consentjxXnF8xg22Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentjxXnF8xg22Meta?.name ?? "legal-cookie-consent___mx",
    path: cookie_45consentjxXnF8xg22Meta?.path ?? "/mx/legal/cookie-consent",
    meta: cookie_45consentjxXnF8xg22Meta || {},
    alias: cookie_45consentjxXnF8xg22Meta?.alias || [],
    redirect: cookie_45consentjxXnF8xg22Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentjxXnF8xg22Meta?.name ?? "legal-cookie-consent___fr",
    path: cookie_45consentjxXnF8xg22Meta?.path ?? "/fr/legal/cookie-consent",
    meta: cookie_45consentjxXnF8xg22Meta || {},
    alias: cookie_45consentjxXnF8xg22Meta?.alias || [],
    redirect: cookie_45consentjxXnF8xg22Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45consentjxXnF8xg22Meta?.name ?? "legal-cookie-consent___fr-ca",
    path: cookie_45consentjxXnF8xg22Meta?.path ?? "/fr-ca/legal/cookie-consent",
    meta: cookie_45consentjxXnF8xg22Meta || {},
    alias: cookie_45consentjxXnF8xg22Meta?.alias || [],
    redirect: cookie_45consentjxXnF8xg22Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-consent.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policymB7ioutq42Meta?.name ?? "legal-cookie-policy___en-US",
    path: cookie_45policymB7ioutq42Meta?.path ?? "/legal/cookie-policy",
    meta: cookie_45policymB7ioutq42Meta || {},
    alias: cookie_45policymB7ioutq42Meta?.alias || [],
    redirect: cookie_45policymB7ioutq42Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policymB7ioutq42Meta?.name ?? "legal-cookie-policy___ca",
    path: cookie_45policymB7ioutq42Meta?.path ?? "/ca/legal/cookie-policy",
    meta: cookie_45policymB7ioutq42Meta || {},
    alias: cookie_45policymB7ioutq42Meta?.alias || [],
    redirect: cookie_45policymB7ioutq42Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policymB7ioutq42Meta?.name ?? "legal-cookie-policy___nl",
    path: cookie_45policymB7ioutq42Meta?.path ?? "/nl/legal/cookie-policy",
    meta: cookie_45policymB7ioutq42Meta || {},
    alias: cookie_45policymB7ioutq42Meta?.alias || [],
    redirect: cookie_45policymB7ioutq42Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policymB7ioutq42Meta?.name ?? "legal-cookie-policy___uk",
    path: cookie_45policymB7ioutq42Meta?.path ?? "/uk/legal/cookie-policy",
    meta: cookie_45policymB7ioutq42Meta || {},
    alias: cookie_45policymB7ioutq42Meta?.alias || [],
    redirect: cookie_45policymB7ioutq42Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policymB7ioutq42Meta?.name ?? "legal-cookie-policy___de",
    path: cookie_45policymB7ioutq42Meta?.path ?? "/de/legal/cookie-policy",
    meta: cookie_45policymB7ioutq42Meta || {},
    alias: cookie_45policymB7ioutq42Meta?.alias || [],
    redirect: cookie_45policymB7ioutq42Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policymB7ioutq42Meta?.name ?? "legal-cookie-policy___mx",
    path: cookie_45policymB7ioutq42Meta?.path ?? "/mx/legal/cookie-policy",
    meta: cookie_45policymB7ioutq42Meta || {},
    alias: cookie_45policymB7ioutq42Meta?.alias || [],
    redirect: cookie_45policymB7ioutq42Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policymB7ioutq42Meta?.name ?? "legal-cookie-policy___fr",
    path: cookie_45policymB7ioutq42Meta?.path ?? "/fr/legal/cookie-policy",
    meta: cookie_45policymB7ioutq42Meta || {},
    alias: cookie_45policymB7ioutq42Meta?.alias || [],
    redirect: cookie_45policymB7ioutq42Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policymB7ioutq42Meta?.name ?? "legal-cookie-policy___fr-ca",
    path: cookie_45policymB7ioutq42Meta?.path ?? "/fr-ca/legal/cookie-policy",
    meta: cookie_45policymB7ioutq42Meta || {},
    alias: cookie_45policymB7ioutq42Meta?.alias || [],
    redirect: cookie_45policymB7ioutq42Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBzeFTvtt8OMeta?.name ?? "legal-data-protection-addendum___en-US",
    path: data_45protection_45addendumBzeFTvtt8OMeta?.path ?? "/legal/data-protection-addendum",
    meta: data_45protection_45addendumBzeFTvtt8OMeta || {},
    alias: data_45protection_45addendumBzeFTvtt8OMeta?.alias || [],
    redirect: data_45protection_45addendumBzeFTvtt8OMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBzeFTvtt8OMeta?.name ?? "legal-data-protection-addendum___ca",
    path: data_45protection_45addendumBzeFTvtt8OMeta?.path ?? "/ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumBzeFTvtt8OMeta || {},
    alias: data_45protection_45addendumBzeFTvtt8OMeta?.alias || [],
    redirect: data_45protection_45addendumBzeFTvtt8OMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBzeFTvtt8OMeta?.name ?? "legal-data-protection-addendum___nl",
    path: data_45protection_45addendumBzeFTvtt8OMeta?.path ?? "/nl/legal/data-protection-addendum",
    meta: data_45protection_45addendumBzeFTvtt8OMeta || {},
    alias: data_45protection_45addendumBzeFTvtt8OMeta?.alias || [],
    redirect: data_45protection_45addendumBzeFTvtt8OMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBzeFTvtt8OMeta?.name ?? "legal-data-protection-addendum___uk",
    path: data_45protection_45addendumBzeFTvtt8OMeta?.path ?? "/uk/legal/data-protection-addendum",
    meta: data_45protection_45addendumBzeFTvtt8OMeta || {},
    alias: data_45protection_45addendumBzeFTvtt8OMeta?.alias || [],
    redirect: data_45protection_45addendumBzeFTvtt8OMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBzeFTvtt8OMeta?.name ?? "legal-data-protection-addendum___de",
    path: data_45protection_45addendumBzeFTvtt8OMeta?.path ?? "/de/legal/data-protection-addendum",
    meta: data_45protection_45addendumBzeFTvtt8OMeta || {},
    alias: data_45protection_45addendumBzeFTvtt8OMeta?.alias || [],
    redirect: data_45protection_45addendumBzeFTvtt8OMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBzeFTvtt8OMeta?.name ?? "legal-data-protection-addendum___mx",
    path: data_45protection_45addendumBzeFTvtt8OMeta?.path ?? "/mx/legal/data-protection-addendum",
    meta: data_45protection_45addendumBzeFTvtt8OMeta || {},
    alias: data_45protection_45addendumBzeFTvtt8OMeta?.alias || [],
    redirect: data_45protection_45addendumBzeFTvtt8OMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBzeFTvtt8OMeta?.name ?? "legal-data-protection-addendum___fr",
    path: data_45protection_45addendumBzeFTvtt8OMeta?.path ?? "/fr/legal/data-protection-addendum",
    meta: data_45protection_45addendumBzeFTvtt8OMeta || {},
    alias: data_45protection_45addendumBzeFTvtt8OMeta?.alias || [],
    redirect: data_45protection_45addendumBzeFTvtt8OMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: data_45protection_45addendumBzeFTvtt8OMeta?.name ?? "legal-data-protection-addendum___fr-ca",
    path: data_45protection_45addendumBzeFTvtt8OMeta?.path ?? "/fr-ca/legal/data-protection-addendum",
    meta: data_45protection_45addendumBzeFTvtt8OMeta || {},
    alias: data_45protection_45addendumBzeFTvtt8OMeta?.alias || [],
    redirect: data_45protection_45addendumBzeFTvtt8OMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/data-protection-addendum.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsZBhmvffk8wMeta?.name ?? "legal-express-order-terms___en-US",
    path: express_45order_45termsZBhmvffk8wMeta?.path ?? "/legal/express-order-terms",
    meta: express_45order_45termsZBhmvffk8wMeta || {},
    alias: express_45order_45termsZBhmvffk8wMeta?.alias || [],
    redirect: express_45order_45termsZBhmvffk8wMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsZBhmvffk8wMeta?.name ?? "legal-express-order-terms___ca",
    path: express_45order_45termsZBhmvffk8wMeta?.path ?? "/ca/legal/express-order-terms",
    meta: express_45order_45termsZBhmvffk8wMeta || {},
    alias: express_45order_45termsZBhmvffk8wMeta?.alias || [],
    redirect: express_45order_45termsZBhmvffk8wMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsZBhmvffk8wMeta?.name ?? "legal-express-order-terms___nl",
    path: express_45order_45termsZBhmvffk8wMeta?.path ?? "/nl/legal/express-order-terms",
    meta: express_45order_45termsZBhmvffk8wMeta || {},
    alias: express_45order_45termsZBhmvffk8wMeta?.alias || [],
    redirect: express_45order_45termsZBhmvffk8wMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsZBhmvffk8wMeta?.name ?? "legal-express-order-terms___uk",
    path: express_45order_45termsZBhmvffk8wMeta?.path ?? "/uk/legal/express-order-terms",
    meta: express_45order_45termsZBhmvffk8wMeta || {},
    alias: express_45order_45termsZBhmvffk8wMeta?.alias || [],
    redirect: express_45order_45termsZBhmvffk8wMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsZBhmvffk8wMeta?.name ?? "legal-express-order-terms___de",
    path: express_45order_45termsZBhmvffk8wMeta?.path ?? "/de/legal/express-order-terms",
    meta: express_45order_45termsZBhmvffk8wMeta || {},
    alias: express_45order_45termsZBhmvffk8wMeta?.alias || [],
    redirect: express_45order_45termsZBhmvffk8wMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsZBhmvffk8wMeta?.name ?? "legal-express-order-terms___mx",
    path: express_45order_45termsZBhmvffk8wMeta?.path ?? "/mx/legal/express-order-terms",
    meta: express_45order_45termsZBhmvffk8wMeta || {},
    alias: express_45order_45termsZBhmvffk8wMeta?.alias || [],
    redirect: express_45order_45termsZBhmvffk8wMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsZBhmvffk8wMeta?.name ?? "legal-express-order-terms___fr",
    path: express_45order_45termsZBhmvffk8wMeta?.path ?? "/fr/legal/express-order-terms",
    meta: express_45order_45termsZBhmvffk8wMeta || {},
    alias: express_45order_45termsZBhmvffk8wMeta?.alias || [],
    redirect: express_45order_45termsZBhmvffk8wMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: express_45order_45termsZBhmvffk8wMeta?.name ?? "legal-express-order-terms___fr-ca",
    path: express_45order_45termsZBhmvffk8wMeta?.path ?? "/fr-ca/legal/express-order-terms",
    meta: express_45order_45termsZBhmvffk8wMeta || {},
    alias: express_45order_45termsZBhmvffk8wMeta?.alias || [],
    redirect: express_45order_45termsZBhmvffk8wMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/express-order-terms.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyiNQzXCJD9zMeta?.name ?? "legal-hardware-warranty___en-US",
    path: hardware_45warrantyiNQzXCJD9zMeta?.path ?? "/legal/hardware-warranty",
    meta: hardware_45warrantyiNQzXCJD9zMeta || {},
    alias: hardware_45warrantyiNQzXCJD9zMeta?.alias || [],
    redirect: hardware_45warrantyiNQzXCJD9zMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyiNQzXCJD9zMeta?.name ?? "legal-hardware-warranty___ca",
    path: hardware_45warrantyiNQzXCJD9zMeta?.path ?? "/ca/legal/hardware-warranty",
    meta: hardware_45warrantyiNQzXCJD9zMeta || {},
    alias: hardware_45warrantyiNQzXCJD9zMeta?.alias || [],
    redirect: hardware_45warrantyiNQzXCJD9zMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyiNQzXCJD9zMeta?.name ?? "legal-hardware-warranty___nl",
    path: hardware_45warrantyiNQzXCJD9zMeta?.path ?? "/nl/legal/hardware-warranty",
    meta: hardware_45warrantyiNQzXCJD9zMeta || {},
    alias: hardware_45warrantyiNQzXCJD9zMeta?.alias || [],
    redirect: hardware_45warrantyiNQzXCJD9zMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyiNQzXCJD9zMeta?.name ?? "legal-hardware-warranty___uk",
    path: hardware_45warrantyiNQzXCJD9zMeta?.path ?? "/uk/legal/hardware-warranty",
    meta: hardware_45warrantyiNQzXCJD9zMeta || {},
    alias: hardware_45warrantyiNQzXCJD9zMeta?.alias || [],
    redirect: hardware_45warrantyiNQzXCJD9zMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyiNQzXCJD9zMeta?.name ?? "legal-hardware-warranty___de",
    path: hardware_45warrantyiNQzXCJD9zMeta?.path ?? "/de/legal/hardware-warranty",
    meta: hardware_45warrantyiNQzXCJD9zMeta || {},
    alias: hardware_45warrantyiNQzXCJD9zMeta?.alias || [],
    redirect: hardware_45warrantyiNQzXCJD9zMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyiNQzXCJD9zMeta?.name ?? "legal-hardware-warranty___mx",
    path: hardware_45warrantyiNQzXCJD9zMeta?.path ?? "/mx/legal/hardware-warranty",
    meta: hardware_45warrantyiNQzXCJD9zMeta || {},
    alias: hardware_45warrantyiNQzXCJD9zMeta?.alias || [],
    redirect: hardware_45warrantyiNQzXCJD9zMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyiNQzXCJD9zMeta?.name ?? "legal-hardware-warranty___fr",
    path: hardware_45warrantyiNQzXCJD9zMeta?.path ?? "/fr/legal/hardware-warranty",
    meta: hardware_45warrantyiNQzXCJD9zMeta || {},
    alias: hardware_45warrantyiNQzXCJD9zMeta?.alias || [],
    redirect: hardware_45warrantyiNQzXCJD9zMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hardware_45warrantyiNQzXCJD9zMeta?.name ?? "legal-hardware-warranty___fr-ca",
    path: hardware_45warrantyiNQzXCJD9zMeta?.path ?? "/fr-ca/legal/hardware-warranty",
    meta: hardware_45warrantyiNQzXCJD9zMeta || {},
    alias: hardware_45warrantyiNQzXCJD9zMeta?.alias || [],
    redirect: hardware_45warrantyiNQzXCJD9zMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hardware-warranty.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slawGLX6bb1cSMeta?.name ?? "legal-hosted-software-sla___en-US",
    path: hosted_45software_45slawGLX6bb1cSMeta?.path ?? "/legal/hosted-software-sla",
    meta: hosted_45software_45slawGLX6bb1cSMeta || {},
    alias: hosted_45software_45slawGLX6bb1cSMeta?.alias || [],
    redirect: hosted_45software_45slawGLX6bb1cSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slawGLX6bb1cSMeta?.name ?? "legal-hosted-software-sla___ca",
    path: hosted_45software_45slawGLX6bb1cSMeta?.path ?? "/ca/legal/hosted-software-sla",
    meta: hosted_45software_45slawGLX6bb1cSMeta || {},
    alias: hosted_45software_45slawGLX6bb1cSMeta?.alias || [],
    redirect: hosted_45software_45slawGLX6bb1cSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slawGLX6bb1cSMeta?.name ?? "legal-hosted-software-sla___nl",
    path: hosted_45software_45slawGLX6bb1cSMeta?.path ?? "/nl/legal/hosted-software-sla",
    meta: hosted_45software_45slawGLX6bb1cSMeta || {},
    alias: hosted_45software_45slawGLX6bb1cSMeta?.alias || [],
    redirect: hosted_45software_45slawGLX6bb1cSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slawGLX6bb1cSMeta?.name ?? "legal-hosted-software-sla___uk",
    path: hosted_45software_45slawGLX6bb1cSMeta?.path ?? "/uk/legal/hosted-software-sla",
    meta: hosted_45software_45slawGLX6bb1cSMeta || {},
    alias: hosted_45software_45slawGLX6bb1cSMeta?.alias || [],
    redirect: hosted_45software_45slawGLX6bb1cSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slawGLX6bb1cSMeta?.name ?? "legal-hosted-software-sla___de",
    path: hosted_45software_45slawGLX6bb1cSMeta?.path ?? "/de/legal/hosted-software-sla",
    meta: hosted_45software_45slawGLX6bb1cSMeta || {},
    alias: hosted_45software_45slawGLX6bb1cSMeta?.alias || [],
    redirect: hosted_45software_45slawGLX6bb1cSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slawGLX6bb1cSMeta?.name ?? "legal-hosted-software-sla___mx",
    path: hosted_45software_45slawGLX6bb1cSMeta?.path ?? "/mx/legal/hosted-software-sla",
    meta: hosted_45software_45slawGLX6bb1cSMeta || {},
    alias: hosted_45software_45slawGLX6bb1cSMeta?.alias || [],
    redirect: hosted_45software_45slawGLX6bb1cSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slawGLX6bb1cSMeta?.name ?? "legal-hosted-software-sla___fr",
    path: hosted_45software_45slawGLX6bb1cSMeta?.path ?? "/fr/legal/hosted-software-sla",
    meta: hosted_45software_45slawGLX6bb1cSMeta || {},
    alias: hosted_45software_45slawGLX6bb1cSMeta?.alias || [],
    redirect: hosted_45software_45slawGLX6bb1cSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: hosted_45software_45slawGLX6bb1cSMeta?.name ?? "legal-hosted-software-sla___fr-ca",
    path: hosted_45software_45slawGLX6bb1cSMeta?.path ?? "/fr-ca/legal/hosted-software-sla",
    meta: hosted_45software_45slawGLX6bb1cSMeta || {},
    alias: hosted_45software_45slawGLX6bb1cSMeta?.alias || [],
    redirect: hosted_45software_45slawGLX6bb1cSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/hosted-software-sla.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductnAjYmZ9zFVMeta?.name ?? "legal-partner-code-of-conduct___en-US",
    path: partner_45code_45of_45conductnAjYmZ9zFVMeta?.path ?? "/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductnAjYmZ9zFVMeta || {},
    alias: partner_45code_45of_45conductnAjYmZ9zFVMeta?.alias || [],
    redirect: partner_45code_45of_45conductnAjYmZ9zFVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductnAjYmZ9zFVMeta?.name ?? "legal-partner-code-of-conduct___ca",
    path: partner_45code_45of_45conductnAjYmZ9zFVMeta?.path ?? "/ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductnAjYmZ9zFVMeta || {},
    alias: partner_45code_45of_45conductnAjYmZ9zFVMeta?.alias || [],
    redirect: partner_45code_45of_45conductnAjYmZ9zFVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductnAjYmZ9zFVMeta?.name ?? "legal-partner-code-of-conduct___nl",
    path: partner_45code_45of_45conductnAjYmZ9zFVMeta?.path ?? "/nl/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductnAjYmZ9zFVMeta || {},
    alias: partner_45code_45of_45conductnAjYmZ9zFVMeta?.alias || [],
    redirect: partner_45code_45of_45conductnAjYmZ9zFVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductnAjYmZ9zFVMeta?.name ?? "legal-partner-code-of-conduct___uk",
    path: partner_45code_45of_45conductnAjYmZ9zFVMeta?.path ?? "/uk/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductnAjYmZ9zFVMeta || {},
    alias: partner_45code_45of_45conductnAjYmZ9zFVMeta?.alias || [],
    redirect: partner_45code_45of_45conductnAjYmZ9zFVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductnAjYmZ9zFVMeta?.name ?? "legal-partner-code-of-conduct___de",
    path: partner_45code_45of_45conductnAjYmZ9zFVMeta?.path ?? "/de/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductnAjYmZ9zFVMeta || {},
    alias: partner_45code_45of_45conductnAjYmZ9zFVMeta?.alias || [],
    redirect: partner_45code_45of_45conductnAjYmZ9zFVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductnAjYmZ9zFVMeta?.name ?? "legal-partner-code-of-conduct___mx",
    path: partner_45code_45of_45conductnAjYmZ9zFVMeta?.path ?? "/mx/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductnAjYmZ9zFVMeta || {},
    alias: partner_45code_45of_45conductnAjYmZ9zFVMeta?.alias || [],
    redirect: partner_45code_45of_45conductnAjYmZ9zFVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductnAjYmZ9zFVMeta?.name ?? "legal-partner-code-of-conduct___fr",
    path: partner_45code_45of_45conductnAjYmZ9zFVMeta?.path ?? "/fr/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductnAjYmZ9zFVMeta || {},
    alias: partner_45code_45of_45conductnAjYmZ9zFVMeta?.alias || [],
    redirect: partner_45code_45of_45conductnAjYmZ9zFVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: partner_45code_45of_45conductnAjYmZ9zFVMeta?.name ?? "legal-partner-code-of-conduct___fr-ca",
    path: partner_45code_45of_45conductnAjYmZ9zFVMeta?.path ?? "/fr-ca/legal/partner-code-of-conduct",
    meta: partner_45code_45of_45conductnAjYmZ9zFVMeta || {},
    alias: partner_45code_45of_45conductnAjYmZ9zFVMeta?.alias || [],
    redirect: partner_45code_45of_45conductnAjYmZ9zFVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/partner-code-of-conduct.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicex49KjrKiEXMeta?.name ?? "legal-platform-terms-of-service___en-US",
    path: platform_45terms_45of_45servicex49KjrKiEXMeta?.path ?? "/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicex49KjrKiEXMeta || {},
    alias: platform_45terms_45of_45servicex49KjrKiEXMeta?.alias || [],
    redirect: platform_45terms_45of_45servicex49KjrKiEXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicex49KjrKiEXMeta?.name ?? "legal-platform-terms-of-service___ca",
    path: platform_45terms_45of_45servicex49KjrKiEXMeta?.path ?? "/ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicex49KjrKiEXMeta || {},
    alias: platform_45terms_45of_45servicex49KjrKiEXMeta?.alias || [],
    redirect: platform_45terms_45of_45servicex49KjrKiEXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicex49KjrKiEXMeta?.name ?? "legal-platform-terms-of-service___nl",
    path: platform_45terms_45of_45servicex49KjrKiEXMeta?.path ?? "/nl/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicex49KjrKiEXMeta || {},
    alias: platform_45terms_45of_45servicex49KjrKiEXMeta?.alias || [],
    redirect: platform_45terms_45of_45servicex49KjrKiEXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicex49KjrKiEXMeta?.name ?? "legal-platform-terms-of-service___uk",
    path: platform_45terms_45of_45servicex49KjrKiEXMeta?.path ?? "/uk/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicex49KjrKiEXMeta || {},
    alias: platform_45terms_45of_45servicex49KjrKiEXMeta?.alias || [],
    redirect: platform_45terms_45of_45servicex49KjrKiEXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicex49KjrKiEXMeta?.name ?? "legal-platform-terms-of-service___de",
    path: platform_45terms_45of_45servicex49KjrKiEXMeta?.path ?? "/de/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicex49KjrKiEXMeta || {},
    alias: platform_45terms_45of_45servicex49KjrKiEXMeta?.alias || [],
    redirect: platform_45terms_45of_45servicex49KjrKiEXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicex49KjrKiEXMeta?.name ?? "legal-platform-terms-of-service___mx",
    path: platform_45terms_45of_45servicex49KjrKiEXMeta?.path ?? "/mx/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicex49KjrKiEXMeta || {},
    alias: platform_45terms_45of_45servicex49KjrKiEXMeta?.alias || [],
    redirect: platform_45terms_45of_45servicex49KjrKiEXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicex49KjrKiEXMeta?.name ?? "legal-platform-terms-of-service___fr",
    path: platform_45terms_45of_45servicex49KjrKiEXMeta?.path ?? "/fr/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicex49KjrKiEXMeta || {},
    alias: platform_45terms_45of_45servicex49KjrKiEXMeta?.alias || [],
    redirect: platform_45terms_45of_45servicex49KjrKiEXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: platform_45terms_45of_45servicex49KjrKiEXMeta?.name ?? "legal-platform-terms-of-service___fr-ca",
    path: platform_45terms_45of_45servicex49KjrKiEXMeta?.path ?? "/fr-ca/legal/platform-terms-of-service",
    meta: platform_45terms_45of_45servicex49KjrKiEXMeta || {},
    alias: platform_45terms_45of_45servicex49KjrKiEXMeta?.alias || [],
    redirect: platform_45terms_45of_45servicex49KjrKiEXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: privacyWA1KeDmi5rMeta?.name ?? "legal-privacy___en-US",
    path: privacyWA1KeDmi5rMeta?.path ?? "/legal/privacy",
    meta: privacyWA1KeDmi5rMeta || {},
    alias: privacyWA1KeDmi5rMeta?.alias || [],
    redirect: privacyWA1KeDmi5rMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyWA1KeDmi5rMeta?.name ?? "legal-privacy___ca",
    path: privacyWA1KeDmi5rMeta?.path ?? "/ca/legal/privacy",
    meta: privacyWA1KeDmi5rMeta || {},
    alias: privacyWA1KeDmi5rMeta?.alias || [],
    redirect: privacyWA1KeDmi5rMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyWA1KeDmi5rMeta?.name ?? "legal-privacy___nl",
    path: privacyWA1KeDmi5rMeta?.path ?? "/nl/legal/privacy",
    meta: privacyWA1KeDmi5rMeta || {},
    alias: privacyWA1KeDmi5rMeta?.alias || [],
    redirect: privacyWA1KeDmi5rMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyWA1KeDmi5rMeta?.name ?? "legal-privacy___uk",
    path: privacyWA1KeDmi5rMeta?.path ?? "/uk/legal/privacy",
    meta: privacyWA1KeDmi5rMeta || {},
    alias: privacyWA1KeDmi5rMeta?.alias || [],
    redirect: privacyWA1KeDmi5rMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyWA1KeDmi5rMeta?.name ?? "legal-privacy___de",
    path: privacyWA1KeDmi5rMeta?.path ?? "/de/legal/privacy",
    meta: privacyWA1KeDmi5rMeta || {},
    alias: privacyWA1KeDmi5rMeta?.alias || [],
    redirect: privacyWA1KeDmi5rMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyWA1KeDmi5rMeta?.name ?? "legal-privacy___mx",
    path: privacyWA1KeDmi5rMeta?.path ?? "/mx/legal/privacy",
    meta: privacyWA1KeDmi5rMeta || {},
    alias: privacyWA1KeDmi5rMeta?.alias || [],
    redirect: privacyWA1KeDmi5rMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyWA1KeDmi5rMeta?.name ?? "legal-privacy___fr",
    path: privacyWA1KeDmi5rMeta?.path ?? "/fr/legal/privacy",
    meta: privacyWA1KeDmi5rMeta || {},
    alias: privacyWA1KeDmi5rMeta?.alias || [],
    redirect: privacyWA1KeDmi5rMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: privacyWA1KeDmi5rMeta?.name ?? "legal-privacy___fr-ca",
    path: privacyWA1KeDmi5rMeta?.path ?? "/fr-ca/legal/privacy",
    meta: privacyWA1KeDmi5rMeta || {},
    alias: privacyWA1KeDmi5rMeta?.alias || [],
    redirect: privacyWA1KeDmi5rMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/privacy.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___en-US",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.path ?? "/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.path ?? "/ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___nl",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.path ?? "/nl/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___uk",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.path ?? "/uk/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___de",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.path ?? "/de/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___mx",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.path ?? "/mx/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.path ?? "/fr/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.name ?? "legal-public-sector-customers-platform-terms-of-service___fr-ca",
    path: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.path ?? "/fr-ca/legal/public-sector-customers-platform-terms-of-service",
    meta: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta || {},
    alias: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.alias || [],
    redirect: public_45sector_45customers_45platform_45terms_45of_45serviceW9q8JAct57Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/public-sector-customers-platform-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataUi8BU8YMgLMeta?.name ?? "legal-requestingcustomerdata___en-US",
    path: requestingcustomerdataUi8BU8YMgLMeta?.path ?? "/legal/requestingcustomerdata",
    meta: requestingcustomerdataUi8BU8YMgLMeta || {},
    alias: requestingcustomerdataUi8BU8YMgLMeta?.alias || [],
    redirect: requestingcustomerdataUi8BU8YMgLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataUi8BU8YMgLMeta?.name ?? "legal-requestingcustomerdata___ca",
    path: requestingcustomerdataUi8BU8YMgLMeta?.path ?? "/ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataUi8BU8YMgLMeta || {},
    alias: requestingcustomerdataUi8BU8YMgLMeta?.alias || [],
    redirect: requestingcustomerdataUi8BU8YMgLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataUi8BU8YMgLMeta?.name ?? "legal-requestingcustomerdata___nl",
    path: requestingcustomerdataUi8BU8YMgLMeta?.path ?? "/nl/legal/requestingcustomerdata",
    meta: requestingcustomerdataUi8BU8YMgLMeta || {},
    alias: requestingcustomerdataUi8BU8YMgLMeta?.alias || [],
    redirect: requestingcustomerdataUi8BU8YMgLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataUi8BU8YMgLMeta?.name ?? "legal-requestingcustomerdata___uk",
    path: requestingcustomerdataUi8BU8YMgLMeta?.path ?? "/uk/legal/requestingcustomerdata",
    meta: requestingcustomerdataUi8BU8YMgLMeta || {},
    alias: requestingcustomerdataUi8BU8YMgLMeta?.alias || [],
    redirect: requestingcustomerdataUi8BU8YMgLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataUi8BU8YMgLMeta?.name ?? "legal-requestingcustomerdata___de",
    path: requestingcustomerdataUi8BU8YMgLMeta?.path ?? "/de/legal/requestingcustomerdata",
    meta: requestingcustomerdataUi8BU8YMgLMeta || {},
    alias: requestingcustomerdataUi8BU8YMgLMeta?.alias || [],
    redirect: requestingcustomerdataUi8BU8YMgLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataUi8BU8YMgLMeta?.name ?? "legal-requestingcustomerdata___mx",
    path: requestingcustomerdataUi8BU8YMgLMeta?.path ?? "/mx/legal/requestingcustomerdata",
    meta: requestingcustomerdataUi8BU8YMgLMeta || {},
    alias: requestingcustomerdataUi8BU8YMgLMeta?.alias || [],
    redirect: requestingcustomerdataUi8BU8YMgLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataUi8BU8YMgLMeta?.name ?? "legal-requestingcustomerdata___fr",
    path: requestingcustomerdataUi8BU8YMgLMeta?.path ?? "/fr/legal/requestingcustomerdata",
    meta: requestingcustomerdataUi8BU8YMgLMeta || {},
    alias: requestingcustomerdataUi8BU8YMgLMeta?.alias || [],
    redirect: requestingcustomerdataUi8BU8YMgLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: requestingcustomerdataUi8BU8YMgLMeta?.name ?? "legal-requestingcustomerdata___fr-ca",
    path: requestingcustomerdataUi8BU8YMgLMeta?.path ?? "/fr-ca/legal/requestingcustomerdata",
    meta: requestingcustomerdataUi8BU8YMgLMeta || {},
    alias: requestingcustomerdataUi8BU8YMgLMeta?.alias || [],
    redirect: requestingcustomerdataUi8BU8YMgLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/requestingcustomerdata.vue").then(m => m.default || m)
  },
  {
    name: securityQR1MEnlhCIMeta?.name ?? "legal-security___en-US",
    path: securityQR1MEnlhCIMeta?.path ?? "/legal/security",
    meta: securityQR1MEnlhCIMeta || {},
    alias: securityQR1MEnlhCIMeta?.alias || [],
    redirect: securityQR1MEnlhCIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityQR1MEnlhCIMeta?.name ?? "legal-security___ca",
    path: securityQR1MEnlhCIMeta?.path ?? "/ca/legal/security",
    meta: securityQR1MEnlhCIMeta || {},
    alias: securityQR1MEnlhCIMeta?.alias || [],
    redirect: securityQR1MEnlhCIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityQR1MEnlhCIMeta?.name ?? "legal-security___nl",
    path: securityQR1MEnlhCIMeta?.path ?? "/nl/legal/security",
    meta: securityQR1MEnlhCIMeta || {},
    alias: securityQR1MEnlhCIMeta?.alias || [],
    redirect: securityQR1MEnlhCIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityQR1MEnlhCIMeta?.name ?? "legal-security___uk",
    path: securityQR1MEnlhCIMeta?.path ?? "/uk/legal/security",
    meta: securityQR1MEnlhCIMeta || {},
    alias: securityQR1MEnlhCIMeta?.alias || [],
    redirect: securityQR1MEnlhCIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityQR1MEnlhCIMeta?.name ?? "legal-security___de",
    path: securityQR1MEnlhCIMeta?.path ?? "/de/legal/security",
    meta: securityQR1MEnlhCIMeta || {},
    alias: securityQR1MEnlhCIMeta?.alias || [],
    redirect: securityQR1MEnlhCIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityQR1MEnlhCIMeta?.name ?? "legal-security___mx",
    path: securityQR1MEnlhCIMeta?.path ?? "/mx/legal/security",
    meta: securityQR1MEnlhCIMeta || {},
    alias: securityQR1MEnlhCIMeta?.alias || [],
    redirect: securityQR1MEnlhCIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityQR1MEnlhCIMeta?.name ?? "legal-security___fr",
    path: securityQR1MEnlhCIMeta?.path ?? "/fr/legal/security",
    meta: securityQR1MEnlhCIMeta || {},
    alias: securityQR1MEnlhCIMeta?.alias || [],
    redirect: securityQR1MEnlhCIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: securityQR1MEnlhCIMeta?.name ?? "legal-security___fr-ca",
    path: securityQR1MEnlhCIMeta?.path ?? "/fr-ca/legal/security",
    meta: securityQR1MEnlhCIMeta || {},
    alias: securityQR1MEnlhCIMeta?.alias || [],
    redirect: securityQR1MEnlhCIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/security.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporthuvpAvDR0FMeta?.name ?? "legal-transparencyreport___en-US",
    path: transparencyreporthuvpAvDR0FMeta?.path ?? "/legal/transparencyreport",
    meta: transparencyreporthuvpAvDR0FMeta || {},
    alias: transparencyreporthuvpAvDR0FMeta?.alias || [],
    redirect: transparencyreporthuvpAvDR0FMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporthuvpAvDR0FMeta?.name ?? "legal-transparencyreport___ca",
    path: transparencyreporthuvpAvDR0FMeta?.path ?? "/ca/legal/transparencyreport",
    meta: transparencyreporthuvpAvDR0FMeta || {},
    alias: transparencyreporthuvpAvDR0FMeta?.alias || [],
    redirect: transparencyreporthuvpAvDR0FMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporthuvpAvDR0FMeta?.name ?? "legal-transparencyreport___nl",
    path: transparencyreporthuvpAvDR0FMeta?.path ?? "/nl/legal/transparencyreport",
    meta: transparencyreporthuvpAvDR0FMeta || {},
    alias: transparencyreporthuvpAvDR0FMeta?.alias || [],
    redirect: transparencyreporthuvpAvDR0FMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporthuvpAvDR0FMeta?.name ?? "legal-transparencyreport___uk",
    path: transparencyreporthuvpAvDR0FMeta?.path ?? "/uk/legal/transparencyreport",
    meta: transparencyreporthuvpAvDR0FMeta || {},
    alias: transparencyreporthuvpAvDR0FMeta?.alias || [],
    redirect: transparencyreporthuvpAvDR0FMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporthuvpAvDR0FMeta?.name ?? "legal-transparencyreport___de",
    path: transparencyreporthuvpAvDR0FMeta?.path ?? "/de/legal/transparencyreport",
    meta: transparencyreporthuvpAvDR0FMeta || {},
    alias: transparencyreporthuvpAvDR0FMeta?.alias || [],
    redirect: transparencyreporthuvpAvDR0FMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporthuvpAvDR0FMeta?.name ?? "legal-transparencyreport___mx",
    path: transparencyreporthuvpAvDR0FMeta?.path ?? "/mx/legal/transparencyreport",
    meta: transparencyreporthuvpAvDR0FMeta || {},
    alias: transparencyreporthuvpAvDR0FMeta?.alias || [],
    redirect: transparencyreporthuvpAvDR0FMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporthuvpAvDR0FMeta?.name ?? "legal-transparencyreport___fr",
    path: transparencyreporthuvpAvDR0FMeta?.path ?? "/fr/legal/transparencyreport",
    meta: transparencyreporthuvpAvDR0FMeta || {},
    alias: transparencyreporthuvpAvDR0FMeta?.alias || [],
    redirect: transparencyreporthuvpAvDR0FMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: transparencyreporthuvpAvDR0FMeta?.name ?? "legal-transparencyreport___fr-ca",
    path: transparencyreporthuvpAvDR0FMeta?.path ?? "/fr-ca/legal/transparencyreport",
    meta: transparencyreporthuvpAvDR0FMeta || {},
    alias: transparencyreporthuvpAvDR0FMeta?.alias || [],
    redirect: transparencyreporthuvpAvDR0FMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/transparencyreport.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicenxppPwVMIYMeta?.name ?? "legal-website-terms-of-service___en-US",
    path: website_45terms_45of_45servicenxppPwVMIYMeta?.path ?? "/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicenxppPwVMIYMeta || {},
    alias: website_45terms_45of_45servicenxppPwVMIYMeta?.alias || [],
    redirect: website_45terms_45of_45servicenxppPwVMIYMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicenxppPwVMIYMeta?.name ?? "legal-website-terms-of-service___ca",
    path: website_45terms_45of_45servicenxppPwVMIYMeta?.path ?? "/ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicenxppPwVMIYMeta || {},
    alias: website_45terms_45of_45servicenxppPwVMIYMeta?.alias || [],
    redirect: website_45terms_45of_45servicenxppPwVMIYMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicenxppPwVMIYMeta?.name ?? "legal-website-terms-of-service___nl",
    path: website_45terms_45of_45servicenxppPwVMIYMeta?.path ?? "/nl/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicenxppPwVMIYMeta || {},
    alias: website_45terms_45of_45servicenxppPwVMIYMeta?.alias || [],
    redirect: website_45terms_45of_45servicenxppPwVMIYMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicenxppPwVMIYMeta?.name ?? "legal-website-terms-of-service___uk",
    path: website_45terms_45of_45servicenxppPwVMIYMeta?.path ?? "/uk/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicenxppPwVMIYMeta || {},
    alias: website_45terms_45of_45servicenxppPwVMIYMeta?.alias || [],
    redirect: website_45terms_45of_45servicenxppPwVMIYMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicenxppPwVMIYMeta?.name ?? "legal-website-terms-of-service___de",
    path: website_45terms_45of_45servicenxppPwVMIYMeta?.path ?? "/de/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicenxppPwVMIYMeta || {},
    alias: website_45terms_45of_45servicenxppPwVMIYMeta?.alias || [],
    redirect: website_45terms_45of_45servicenxppPwVMIYMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicenxppPwVMIYMeta?.name ?? "legal-website-terms-of-service___mx",
    path: website_45terms_45of_45servicenxppPwVMIYMeta?.path ?? "/mx/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicenxppPwVMIYMeta || {},
    alias: website_45terms_45of_45servicenxppPwVMIYMeta?.alias || [],
    redirect: website_45terms_45of_45servicenxppPwVMIYMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicenxppPwVMIYMeta?.name ?? "legal-website-terms-of-service___fr",
    path: website_45terms_45of_45servicenxppPwVMIYMeta?.path ?? "/fr/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicenxppPwVMIYMeta || {},
    alias: website_45terms_45of_45servicenxppPwVMIYMeta?.alias || [],
    redirect: website_45terms_45of_45servicenxppPwVMIYMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: website_45terms_45of_45servicenxppPwVMIYMeta?.name ?? "legal-website-terms-of-service___fr-ca",
    path: website_45terms_45of_45servicenxppPwVMIYMeta?.path ?? "/fr-ca/legal/website-terms-of-service",
    meta: website_45terms_45of_45servicenxppPwVMIYMeta || {},
    alias: website_45terms_45of_45servicenxppPwVMIYMeta?.alias || [],
    redirect: website_45terms_45of_45servicenxppPwVMIYMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/website-terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicestbLr2jvrTyMeta?.name ?? "legal-your-privacy-choices___en-US",
    path: your_45privacy_45choicestbLr2jvrTyMeta?.path ?? "/legal/your-privacy-choices",
    meta: your_45privacy_45choicestbLr2jvrTyMeta || {},
    alias: your_45privacy_45choicestbLr2jvrTyMeta?.alias || [],
    redirect: your_45privacy_45choicestbLr2jvrTyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicestbLr2jvrTyMeta?.name ?? "legal-your-privacy-choices___ca",
    path: your_45privacy_45choicestbLr2jvrTyMeta?.path ?? "/ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicestbLr2jvrTyMeta || {},
    alias: your_45privacy_45choicestbLr2jvrTyMeta?.alias || [],
    redirect: your_45privacy_45choicestbLr2jvrTyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicestbLr2jvrTyMeta?.name ?? "legal-your-privacy-choices___nl",
    path: your_45privacy_45choicestbLr2jvrTyMeta?.path ?? "/nl/legal/your-privacy-choices",
    meta: your_45privacy_45choicestbLr2jvrTyMeta || {},
    alias: your_45privacy_45choicestbLr2jvrTyMeta?.alias || [],
    redirect: your_45privacy_45choicestbLr2jvrTyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicestbLr2jvrTyMeta?.name ?? "legal-your-privacy-choices___uk",
    path: your_45privacy_45choicestbLr2jvrTyMeta?.path ?? "/uk/legal/your-privacy-choices",
    meta: your_45privacy_45choicestbLr2jvrTyMeta || {},
    alias: your_45privacy_45choicestbLr2jvrTyMeta?.alias || [],
    redirect: your_45privacy_45choicestbLr2jvrTyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicestbLr2jvrTyMeta?.name ?? "legal-your-privacy-choices___de",
    path: your_45privacy_45choicestbLr2jvrTyMeta?.path ?? "/de/legal/your-privacy-choices",
    meta: your_45privacy_45choicestbLr2jvrTyMeta || {},
    alias: your_45privacy_45choicestbLr2jvrTyMeta?.alias || [],
    redirect: your_45privacy_45choicestbLr2jvrTyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicestbLr2jvrTyMeta?.name ?? "legal-your-privacy-choices___mx",
    path: your_45privacy_45choicestbLr2jvrTyMeta?.path ?? "/mx/legal/your-privacy-choices",
    meta: your_45privacy_45choicestbLr2jvrTyMeta || {},
    alias: your_45privacy_45choicestbLr2jvrTyMeta?.alias || [],
    redirect: your_45privacy_45choicestbLr2jvrTyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicestbLr2jvrTyMeta?.name ?? "legal-your-privacy-choices___fr",
    path: your_45privacy_45choicestbLr2jvrTyMeta?.path ?? "/fr/legal/your-privacy-choices",
    meta: your_45privacy_45choicestbLr2jvrTyMeta || {},
    alias: your_45privacy_45choicestbLr2jvrTyMeta?.alias || [],
    redirect: your_45privacy_45choicestbLr2jvrTyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: your_45privacy_45choicestbLr2jvrTyMeta?.name ?? "legal-your-privacy-choices___fr-ca",
    path: your_45privacy_45choicestbLr2jvrTyMeta?.path ?? "/fr-ca/legal/your-privacy-choices",
    meta: your_45privacy_45choicestbLr2jvrTyMeta || {},
    alias: your_45privacy_45choicestbLr2jvrTyMeta?.alias || [],
    redirect: your_45privacy_45choicestbLr2jvrTyMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/legal/your-privacy-choices.vue").then(m => m.default || m)
  },
  {
    name: pricingG6h1fx7u0ZMeta?.name ?? "page-pricing___en-US",
    path: pricingG6h1fx7u0ZMeta?.path ?? "/page/pricing",
    meta: pricingG6h1fx7u0ZMeta || {},
    alias: pricingG6h1fx7u0ZMeta?.alias || [],
    redirect: pricingG6h1fx7u0ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingG6h1fx7u0ZMeta?.name ?? "page-pricing___ca",
    path: pricingG6h1fx7u0ZMeta?.path ?? "/ca/page/pricing",
    meta: pricingG6h1fx7u0ZMeta || {},
    alias: pricingG6h1fx7u0ZMeta?.alias || [],
    redirect: pricingG6h1fx7u0ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingG6h1fx7u0ZMeta?.name ?? "page-pricing___nl",
    path: pricingG6h1fx7u0ZMeta?.path ?? "/nl/page/pricing",
    meta: pricingG6h1fx7u0ZMeta || {},
    alias: pricingG6h1fx7u0ZMeta?.alias || [],
    redirect: pricingG6h1fx7u0ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingG6h1fx7u0ZMeta?.name ?? "page-pricing___uk",
    path: pricingG6h1fx7u0ZMeta?.path ?? "/uk/page/pricing",
    meta: pricingG6h1fx7u0ZMeta || {},
    alias: pricingG6h1fx7u0ZMeta?.alias || [],
    redirect: pricingG6h1fx7u0ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingG6h1fx7u0ZMeta?.name ?? "page-pricing___de",
    path: pricingG6h1fx7u0ZMeta?.path ?? "/de/page/pricing",
    meta: pricingG6h1fx7u0ZMeta || {},
    alias: pricingG6h1fx7u0ZMeta?.alias || [],
    redirect: pricingG6h1fx7u0ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingG6h1fx7u0ZMeta?.name ?? "page-pricing___mx",
    path: pricingG6h1fx7u0ZMeta?.path ?? "/mx/page/pricing",
    meta: pricingG6h1fx7u0ZMeta || {},
    alias: pricingG6h1fx7u0ZMeta?.alias || [],
    redirect: pricingG6h1fx7u0ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingG6h1fx7u0ZMeta?.name ?? "page-pricing___fr",
    path: pricingG6h1fx7u0ZMeta?.path ?? "/fr/page/pricing",
    meta: pricingG6h1fx7u0ZMeta || {},
    alias: pricingG6h1fx7u0ZMeta?.alias || [],
    redirect: pricingG6h1fx7u0ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingG6h1fx7u0ZMeta?.name ?? "page-pricing___fr-ca",
    path: pricingG6h1fx7u0ZMeta?.path ?? "/fr-ca/page/pricing",
    meta: pricingG6h1fx7u0ZMeta || {},
    alias: pricingG6h1fx7u0ZMeta?.alias || [],
    redirect: pricingG6h1fx7u0ZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/page/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DWLyFsxVkIMeta?.name ?? "pages-prefix-slug___en-US",
    path: _91slug_93DWLyFsxVkIMeta?.path ?? "/pages/:prefix()/:slug()",
    meta: _91slug_93DWLyFsxVkIMeta || {},
    alias: _91slug_93DWLyFsxVkIMeta?.alias || [],
    redirect: _91slug_93DWLyFsxVkIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DWLyFsxVkIMeta?.name ?? "pages-prefix-slug___ca",
    path: _91slug_93DWLyFsxVkIMeta?.path ?? "/ca/pages/:prefix()/:slug()",
    meta: _91slug_93DWLyFsxVkIMeta || {},
    alias: _91slug_93DWLyFsxVkIMeta?.alias || [],
    redirect: _91slug_93DWLyFsxVkIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DWLyFsxVkIMeta?.name ?? "pages-prefix-slug___nl",
    path: _91slug_93DWLyFsxVkIMeta?.path ?? "/nl/pages/:prefix()/:slug()",
    meta: _91slug_93DWLyFsxVkIMeta || {},
    alias: _91slug_93DWLyFsxVkIMeta?.alias || [],
    redirect: _91slug_93DWLyFsxVkIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DWLyFsxVkIMeta?.name ?? "pages-prefix-slug___uk",
    path: _91slug_93DWLyFsxVkIMeta?.path ?? "/uk/pages/:prefix()/:slug()",
    meta: _91slug_93DWLyFsxVkIMeta || {},
    alias: _91slug_93DWLyFsxVkIMeta?.alias || [],
    redirect: _91slug_93DWLyFsxVkIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DWLyFsxVkIMeta?.name ?? "pages-prefix-slug___de",
    path: _91slug_93DWLyFsxVkIMeta?.path ?? "/de/pages/:prefix()/:slug()",
    meta: _91slug_93DWLyFsxVkIMeta || {},
    alias: _91slug_93DWLyFsxVkIMeta?.alias || [],
    redirect: _91slug_93DWLyFsxVkIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DWLyFsxVkIMeta?.name ?? "pages-prefix-slug___mx",
    path: _91slug_93DWLyFsxVkIMeta?.path ?? "/mx/pages/:prefix()/:slug()",
    meta: _91slug_93DWLyFsxVkIMeta || {},
    alias: _91slug_93DWLyFsxVkIMeta?.alias || [],
    redirect: _91slug_93DWLyFsxVkIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DWLyFsxVkIMeta?.name ?? "pages-prefix-slug___fr",
    path: _91slug_93DWLyFsxVkIMeta?.path ?? "/fr/pages/:prefix()/:slug()",
    meta: _91slug_93DWLyFsxVkIMeta || {},
    alias: _91slug_93DWLyFsxVkIMeta?.alias || [],
    redirect: _91slug_93DWLyFsxVkIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DWLyFsxVkIMeta?.name ?? "pages-prefix-slug___fr-ca",
    path: _91slug_93DWLyFsxVkIMeta?.path ?? "/fr-ca/pages/:prefix()/:slug()",
    meta: _91slug_93DWLyFsxVkIMeta || {},
    alias: _91slug_93DWLyFsxVkIMeta?.alias || [],
    redirect: _91slug_93DWLyFsxVkIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p59oG4YytIMeta?.name ?? "pages-slug___en-US",
    path: _91slug_93p59oG4YytIMeta?.path ?? "/pages/:slug()",
    meta: _91slug_93p59oG4YytIMeta || {},
    alias: _91slug_93p59oG4YytIMeta?.alias || [],
    redirect: _91slug_93p59oG4YytIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p59oG4YytIMeta?.name ?? "pages-slug___ca",
    path: _91slug_93p59oG4YytIMeta?.path ?? "/ca/pages/:slug()",
    meta: _91slug_93p59oG4YytIMeta || {},
    alias: _91slug_93p59oG4YytIMeta?.alias || [],
    redirect: _91slug_93p59oG4YytIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p59oG4YytIMeta?.name ?? "pages-slug___nl",
    path: _91slug_93p59oG4YytIMeta?.path ?? "/nl/pages/:slug()",
    meta: _91slug_93p59oG4YytIMeta || {},
    alias: _91slug_93p59oG4YytIMeta?.alias || [],
    redirect: _91slug_93p59oG4YytIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p59oG4YytIMeta?.name ?? "pages-slug___uk",
    path: _91slug_93p59oG4YytIMeta?.path ?? "/uk/pages/:slug()",
    meta: _91slug_93p59oG4YytIMeta || {},
    alias: _91slug_93p59oG4YytIMeta?.alias || [],
    redirect: _91slug_93p59oG4YytIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p59oG4YytIMeta?.name ?? "pages-slug___de",
    path: _91slug_93p59oG4YytIMeta?.path ?? "/de/pages/:slug()",
    meta: _91slug_93p59oG4YytIMeta || {},
    alias: _91slug_93p59oG4YytIMeta?.alias || [],
    redirect: _91slug_93p59oG4YytIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p59oG4YytIMeta?.name ?? "pages-slug___mx",
    path: _91slug_93p59oG4YytIMeta?.path ?? "/mx/pages/:slug()",
    meta: _91slug_93p59oG4YytIMeta || {},
    alias: _91slug_93p59oG4YytIMeta?.alias || [],
    redirect: _91slug_93p59oG4YytIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p59oG4YytIMeta?.name ?? "pages-slug___fr",
    path: _91slug_93p59oG4YytIMeta?.path ?? "/fr/pages/:slug()",
    meta: _91slug_93p59oG4YytIMeta || {},
    alias: _91slug_93p59oG4YytIMeta?.alias || [],
    redirect: _91slug_93p59oG4YytIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93p59oG4YytIMeta?.name ?? "pages-slug___fr-ca",
    path: _91slug_93p59oG4YytIMeta?.path ?? "/fr-ca/pages/:slug()",
    meta: _91slug_93p59oG4YytIMeta || {},
    alias: _91slug_93p59oG4YytIMeta?.alias || [],
    redirect: _91slug_93p59oG4YytIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingb5XqkqMAUqMeta?.name ?? "pages-asset-tracking___en-US",
    path: asset_45trackingb5XqkqMAUqMeta?.path ?? "/pages/asset-tracking",
    meta: asset_45trackingb5XqkqMAUqMeta || {},
    alias: asset_45trackingb5XqkqMAUqMeta?.alias || [],
    redirect: asset_45trackingb5XqkqMAUqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingb5XqkqMAUqMeta?.name ?? "pages-asset-tracking___ca",
    path: asset_45trackingb5XqkqMAUqMeta?.path ?? "/ca/pages/asset-tracking",
    meta: asset_45trackingb5XqkqMAUqMeta || {},
    alias: asset_45trackingb5XqkqMAUqMeta?.alias || [],
    redirect: asset_45trackingb5XqkqMAUqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingb5XqkqMAUqMeta?.name ?? "pages-asset-tracking___nl",
    path: asset_45trackingb5XqkqMAUqMeta?.path ?? "/nl/pages/asset-tracking",
    meta: asset_45trackingb5XqkqMAUqMeta || {},
    alias: asset_45trackingb5XqkqMAUqMeta?.alias || [],
    redirect: asset_45trackingb5XqkqMAUqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingb5XqkqMAUqMeta?.name ?? "pages-asset-tracking___uk",
    path: asset_45trackingb5XqkqMAUqMeta?.path ?? "/uk/pages/asset-tracking",
    meta: asset_45trackingb5XqkqMAUqMeta || {},
    alias: asset_45trackingb5XqkqMAUqMeta?.alias || [],
    redirect: asset_45trackingb5XqkqMAUqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingb5XqkqMAUqMeta?.name ?? "pages-asset-tracking___de",
    path: asset_45trackingb5XqkqMAUqMeta?.path ?? "/de/pages/asset-tracking",
    meta: asset_45trackingb5XqkqMAUqMeta || {},
    alias: asset_45trackingb5XqkqMAUqMeta?.alias || [],
    redirect: asset_45trackingb5XqkqMAUqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingb5XqkqMAUqMeta?.name ?? "pages-asset-tracking___mx",
    path: asset_45trackingb5XqkqMAUqMeta?.path ?? "/mx/pages/asset-tracking",
    meta: asset_45trackingb5XqkqMAUqMeta || {},
    alias: asset_45trackingb5XqkqMAUqMeta?.alias || [],
    redirect: asset_45trackingb5XqkqMAUqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingb5XqkqMAUqMeta?.name ?? "pages-asset-tracking___fr",
    path: asset_45trackingb5XqkqMAUqMeta?.path ?? "/fr/pages/asset-tracking",
    meta: asset_45trackingb5XqkqMAUqMeta || {},
    alias: asset_45trackingb5XqkqMAUqMeta?.alias || [],
    redirect: asset_45trackingb5XqkqMAUqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingb5XqkqMAUqMeta?.name ?? "pages-asset-tracking___fr-ca",
    path: asset_45trackingb5XqkqMAUqMeta?.path ?? "/fr-ca/pages/asset-tracking",
    meta: asset_45trackingb5XqkqMAUqMeta || {},
    alias: asset_45trackingb5XqkqMAUqMeta?.alias || [],
    redirect: asset_45trackingb5XqkqMAUqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsO0FzeRaZQOMeta?.name ?? "pages-best-dash-cams___en-US",
    path: best_45dash_45camsO0FzeRaZQOMeta?.path ?? "/pages/best-dash-cams",
    meta: best_45dash_45camsO0FzeRaZQOMeta || {},
    alias: best_45dash_45camsO0FzeRaZQOMeta?.alias || [],
    redirect: best_45dash_45camsO0FzeRaZQOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsO0FzeRaZQOMeta?.name ?? "pages-best-dash-cams___ca",
    path: best_45dash_45camsO0FzeRaZQOMeta?.path ?? "/ca/pages/best-dash-cams",
    meta: best_45dash_45camsO0FzeRaZQOMeta || {},
    alias: best_45dash_45camsO0FzeRaZQOMeta?.alias || [],
    redirect: best_45dash_45camsO0FzeRaZQOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsO0FzeRaZQOMeta?.name ?? "pages-best-dash-cams___nl",
    path: best_45dash_45camsO0FzeRaZQOMeta?.path ?? "/nl/pages/best-dash-cams",
    meta: best_45dash_45camsO0FzeRaZQOMeta || {},
    alias: best_45dash_45camsO0FzeRaZQOMeta?.alias || [],
    redirect: best_45dash_45camsO0FzeRaZQOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsO0FzeRaZQOMeta?.name ?? "pages-best-dash-cams___uk",
    path: best_45dash_45camsO0FzeRaZQOMeta?.path ?? "/uk/pages/best-dash-cams",
    meta: best_45dash_45camsO0FzeRaZQOMeta || {},
    alias: best_45dash_45camsO0FzeRaZQOMeta?.alias || [],
    redirect: best_45dash_45camsO0FzeRaZQOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsO0FzeRaZQOMeta?.name ?? "pages-best-dash-cams___de",
    path: best_45dash_45camsO0FzeRaZQOMeta?.path ?? "/de/pages/best-dash-cams",
    meta: best_45dash_45camsO0FzeRaZQOMeta || {},
    alias: best_45dash_45camsO0FzeRaZQOMeta?.alias || [],
    redirect: best_45dash_45camsO0FzeRaZQOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsO0FzeRaZQOMeta?.name ?? "pages-best-dash-cams___mx",
    path: best_45dash_45camsO0FzeRaZQOMeta?.path ?? "/mx/pages/best-dash-cams",
    meta: best_45dash_45camsO0FzeRaZQOMeta || {},
    alias: best_45dash_45camsO0FzeRaZQOMeta?.alias || [],
    redirect: best_45dash_45camsO0FzeRaZQOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsO0FzeRaZQOMeta?.name ?? "pages-best-dash-cams___fr",
    path: best_45dash_45camsO0FzeRaZQOMeta?.path ?? "/fr/pages/best-dash-cams",
    meta: best_45dash_45camsO0FzeRaZQOMeta || {},
    alias: best_45dash_45camsO0FzeRaZQOMeta?.alias || [],
    redirect: best_45dash_45camsO0FzeRaZQOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: best_45dash_45camsO0FzeRaZQOMeta?.name ?? "pages-best-dash-cams___fr-ca",
    path: best_45dash_45camsO0FzeRaZQOMeta?.path ?? "/fr-ca/pages/best-dash-cams",
    meta: best_45dash_45camsO0FzeRaZQOMeta || {},
    alias: best_45dash_45camsO0FzeRaZQOMeta?.alias || [],
    redirect: best_45dash_45camsO0FzeRaZQOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/best-dash-cams.vue").then(m => m.default || m)
  },
  {
    name: constructionXTK8xlPrSXMeta?.name ?? "pages-construction___en-US",
    path: constructionXTK8xlPrSXMeta?.path ?? "/pages/construction",
    meta: constructionXTK8xlPrSXMeta || {},
    alias: constructionXTK8xlPrSXMeta?.alias || [],
    redirect: constructionXTK8xlPrSXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionXTK8xlPrSXMeta?.name ?? "pages-construction___ca",
    path: constructionXTK8xlPrSXMeta?.path ?? "/ca/pages/construction",
    meta: constructionXTK8xlPrSXMeta || {},
    alias: constructionXTK8xlPrSXMeta?.alias || [],
    redirect: constructionXTK8xlPrSXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionXTK8xlPrSXMeta?.name ?? "pages-construction___nl",
    path: constructionXTK8xlPrSXMeta?.path ?? "/nl/pages/construction",
    meta: constructionXTK8xlPrSXMeta || {},
    alias: constructionXTK8xlPrSXMeta?.alias || [],
    redirect: constructionXTK8xlPrSXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionXTK8xlPrSXMeta?.name ?? "pages-construction___uk",
    path: constructionXTK8xlPrSXMeta?.path ?? "/uk/pages/construction",
    meta: constructionXTK8xlPrSXMeta || {},
    alias: constructionXTK8xlPrSXMeta?.alias || [],
    redirect: constructionXTK8xlPrSXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionXTK8xlPrSXMeta?.name ?? "pages-construction___de",
    path: constructionXTK8xlPrSXMeta?.path ?? "/de/pages/construction",
    meta: constructionXTK8xlPrSXMeta || {},
    alias: constructionXTK8xlPrSXMeta?.alias || [],
    redirect: constructionXTK8xlPrSXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionXTK8xlPrSXMeta?.name ?? "pages-construction___mx",
    path: constructionXTK8xlPrSXMeta?.path ?? "/mx/pages/construction",
    meta: constructionXTK8xlPrSXMeta || {},
    alias: constructionXTK8xlPrSXMeta?.alias || [],
    redirect: constructionXTK8xlPrSXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionXTK8xlPrSXMeta?.name ?? "pages-construction___fr",
    path: constructionXTK8xlPrSXMeta?.path ?? "/fr/pages/construction",
    meta: constructionXTK8xlPrSXMeta || {},
    alias: constructionXTK8xlPrSXMeta?.alias || [],
    redirect: constructionXTK8xlPrSXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: constructionXTK8xlPrSXMeta?.name ?? "pages-construction___fr-ca",
    path: constructionXTK8xlPrSXMeta?.path ?? "/fr-ca/pages/construction",
    meta: constructionXTK8xlPrSXMeta || {},
    alias: constructionXTK8xlPrSXMeta?.alias || [],
    redirect: constructionXTK8xlPrSXMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/construction.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementmLHQSBR3RaMeta?.name ?? "pages-fleet-management___en-US",
    path: fleet_45managementmLHQSBR3RaMeta?.path ?? "/pages/fleet-management",
    meta: fleet_45managementmLHQSBR3RaMeta || {},
    alias: fleet_45managementmLHQSBR3RaMeta?.alias || [],
    redirect: fleet_45managementmLHQSBR3RaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementmLHQSBR3RaMeta?.name ?? "pages-fleet-management___ca",
    path: fleet_45managementmLHQSBR3RaMeta?.path ?? "/ca/pages/fleet-management",
    meta: fleet_45managementmLHQSBR3RaMeta || {},
    alias: fleet_45managementmLHQSBR3RaMeta?.alias || [],
    redirect: fleet_45managementmLHQSBR3RaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementmLHQSBR3RaMeta?.name ?? "pages-fleet-management___nl",
    path: fleet_45managementmLHQSBR3RaMeta?.path ?? "/nl/pages/fleet-management",
    meta: fleet_45managementmLHQSBR3RaMeta || {},
    alias: fleet_45managementmLHQSBR3RaMeta?.alias || [],
    redirect: fleet_45managementmLHQSBR3RaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementmLHQSBR3RaMeta?.name ?? "pages-fleet-management___uk",
    path: fleet_45managementmLHQSBR3RaMeta?.path ?? "/uk/pages/fleet-management",
    meta: fleet_45managementmLHQSBR3RaMeta || {},
    alias: fleet_45managementmLHQSBR3RaMeta?.alias || [],
    redirect: fleet_45managementmLHQSBR3RaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementmLHQSBR3RaMeta?.name ?? "pages-fleet-management___de",
    path: fleet_45managementmLHQSBR3RaMeta?.path ?? "/de/pages/fleet-management",
    meta: fleet_45managementmLHQSBR3RaMeta || {},
    alias: fleet_45managementmLHQSBR3RaMeta?.alias || [],
    redirect: fleet_45managementmLHQSBR3RaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementmLHQSBR3RaMeta?.name ?? "pages-fleet-management___mx",
    path: fleet_45managementmLHQSBR3RaMeta?.path ?? "/mx/pages/fleet-management",
    meta: fleet_45managementmLHQSBR3RaMeta || {},
    alias: fleet_45managementmLHQSBR3RaMeta?.alias || [],
    redirect: fleet_45managementmLHQSBR3RaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementmLHQSBR3RaMeta?.name ?? "pages-fleet-management___fr",
    path: fleet_45managementmLHQSBR3RaMeta?.path ?? "/fr/pages/fleet-management",
    meta: fleet_45managementmLHQSBR3RaMeta || {},
    alias: fleet_45managementmLHQSBR3RaMeta?.alias || [],
    redirect: fleet_45managementmLHQSBR3RaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: fleet_45managementmLHQSBR3RaMeta?.name ?? "pages-fleet-management___fr-ca",
    path: fleet_45managementmLHQSBR3RaMeta?.path ?? "/fr-ca/pages/fleet-management",
    meta: fleet_45managementmLHQSBR3RaMeta || {},
    alias: fleet_45managementmLHQSBR3RaMeta?.alias || [],
    redirect: fleet_45managementmLHQSBR3RaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/fleet-management.vue").then(m => m.default || m)
  },
  {
    name: nationwide1NSSYMoYKpMeta?.name ?? "pages-nationwide___en-US",
    path: nationwide1NSSYMoYKpMeta?.path ?? "/pages/nationwide",
    meta: nationwide1NSSYMoYKpMeta || {},
    alias: nationwide1NSSYMoYKpMeta?.alias || [],
    redirect: nationwide1NSSYMoYKpMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1NSSYMoYKpMeta?.name ?? "pages-nationwide___ca",
    path: nationwide1NSSYMoYKpMeta?.path ?? "/ca/pages/nationwide",
    meta: nationwide1NSSYMoYKpMeta || {},
    alias: nationwide1NSSYMoYKpMeta?.alias || [],
    redirect: nationwide1NSSYMoYKpMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1NSSYMoYKpMeta?.name ?? "pages-nationwide___nl",
    path: nationwide1NSSYMoYKpMeta?.path ?? "/nl/pages/nationwide",
    meta: nationwide1NSSYMoYKpMeta || {},
    alias: nationwide1NSSYMoYKpMeta?.alias || [],
    redirect: nationwide1NSSYMoYKpMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1NSSYMoYKpMeta?.name ?? "pages-nationwide___uk",
    path: nationwide1NSSYMoYKpMeta?.path ?? "/uk/pages/nationwide",
    meta: nationwide1NSSYMoYKpMeta || {},
    alias: nationwide1NSSYMoYKpMeta?.alias || [],
    redirect: nationwide1NSSYMoYKpMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1NSSYMoYKpMeta?.name ?? "pages-nationwide___de",
    path: nationwide1NSSYMoYKpMeta?.path ?? "/de/pages/nationwide",
    meta: nationwide1NSSYMoYKpMeta || {},
    alias: nationwide1NSSYMoYKpMeta?.alias || [],
    redirect: nationwide1NSSYMoYKpMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1NSSYMoYKpMeta?.name ?? "pages-nationwide___mx",
    path: nationwide1NSSYMoYKpMeta?.path ?? "/mx/pages/nationwide",
    meta: nationwide1NSSYMoYKpMeta || {},
    alias: nationwide1NSSYMoYKpMeta?.alias || [],
    redirect: nationwide1NSSYMoYKpMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1NSSYMoYKpMeta?.name ?? "pages-nationwide___fr",
    path: nationwide1NSSYMoYKpMeta?.path ?? "/fr/pages/nationwide",
    meta: nationwide1NSSYMoYKpMeta || {},
    alias: nationwide1NSSYMoYKpMeta?.alias || [],
    redirect: nationwide1NSSYMoYKpMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: nationwide1NSSYMoYKpMeta?.name ?? "pages-nationwide___fr-ca",
    path: nationwide1NSSYMoYKpMeta?.path ?? "/fr-ca/pages/nationwide",
    meta: nationwide1NSSYMoYKpMeta || {},
    alias: nationwide1NSSYMoYKpMeta?.alias || [],
    redirect: nationwide1NSSYMoYKpMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/nationwide.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching1DLjoi67QWMeta?.name ?? "pages-safety-coaching___en-US",
    path: safety_45coaching1DLjoi67QWMeta?.path ?? "/pages/safety-coaching",
    meta: safety_45coaching1DLjoi67QWMeta || {},
    alias: safety_45coaching1DLjoi67QWMeta?.alias || [],
    redirect: safety_45coaching1DLjoi67QWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching1DLjoi67QWMeta?.name ?? "pages-safety-coaching___ca",
    path: safety_45coaching1DLjoi67QWMeta?.path ?? "/ca/pages/safety-coaching",
    meta: safety_45coaching1DLjoi67QWMeta || {},
    alias: safety_45coaching1DLjoi67QWMeta?.alias || [],
    redirect: safety_45coaching1DLjoi67QWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching1DLjoi67QWMeta?.name ?? "pages-safety-coaching___nl",
    path: safety_45coaching1DLjoi67QWMeta?.path ?? "/nl/pages/safety-coaching",
    meta: safety_45coaching1DLjoi67QWMeta || {},
    alias: safety_45coaching1DLjoi67QWMeta?.alias || [],
    redirect: safety_45coaching1DLjoi67QWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching1DLjoi67QWMeta?.name ?? "pages-safety-coaching___uk",
    path: safety_45coaching1DLjoi67QWMeta?.path ?? "/uk/pages/safety-coaching",
    meta: safety_45coaching1DLjoi67QWMeta || {},
    alias: safety_45coaching1DLjoi67QWMeta?.alias || [],
    redirect: safety_45coaching1DLjoi67QWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching1DLjoi67QWMeta?.name ?? "pages-safety-coaching___de",
    path: safety_45coaching1DLjoi67QWMeta?.path ?? "/de/pages/safety-coaching",
    meta: safety_45coaching1DLjoi67QWMeta || {},
    alias: safety_45coaching1DLjoi67QWMeta?.alias || [],
    redirect: safety_45coaching1DLjoi67QWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching1DLjoi67QWMeta?.name ?? "pages-safety-coaching___mx",
    path: safety_45coaching1DLjoi67QWMeta?.path ?? "/mx/pages/safety-coaching",
    meta: safety_45coaching1DLjoi67QWMeta || {},
    alias: safety_45coaching1DLjoi67QWMeta?.alias || [],
    redirect: safety_45coaching1DLjoi67QWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching1DLjoi67QWMeta?.name ?? "pages-safety-coaching___fr",
    path: safety_45coaching1DLjoi67QWMeta?.path ?? "/fr/pages/safety-coaching",
    meta: safety_45coaching1DLjoi67QWMeta || {},
    alias: safety_45coaching1DLjoi67QWMeta?.alias || [],
    redirect: safety_45coaching1DLjoi67QWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: safety_45coaching1DLjoi67QWMeta?.name ?? "pages-safety-coaching___fr-ca",
    path: safety_45coaching1DLjoi67QWMeta?.path ?? "/fr-ca/pages/safety-coaching",
    meta: safety_45coaching1DLjoi67QWMeta || {},
    alias: safety_45coaching1DLjoi67QWMeta?.alias || [],
    redirect: safety_45coaching1DLjoi67QWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pages/safety-coaching.vue").then(m => m.default || m)
  },
  {
    name: pricingJmzX0NdIiMMeta?.name ?? "pricing___en-US",
    path: pricingJmzX0NdIiMMeta?.path ?? "/pricing",
    meta: pricingJmzX0NdIiMMeta || {},
    alias: pricingJmzX0NdIiMMeta?.alias || [],
    redirect: pricingJmzX0NdIiMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingJmzX0NdIiMMeta?.name ?? "pricing___ca",
    path: pricingJmzX0NdIiMMeta?.path ?? "/ca/pricing",
    meta: pricingJmzX0NdIiMMeta || {},
    alias: pricingJmzX0NdIiMMeta?.alias || [],
    redirect: pricingJmzX0NdIiMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingJmzX0NdIiMMeta?.name ?? "pricing___nl",
    path: pricingJmzX0NdIiMMeta?.path ?? "/nl/pricing",
    meta: pricingJmzX0NdIiMMeta || {},
    alias: pricingJmzX0NdIiMMeta?.alias || [],
    redirect: pricingJmzX0NdIiMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingJmzX0NdIiMMeta?.name ?? "pricing___uk",
    path: pricingJmzX0NdIiMMeta?.path ?? "/uk/pricing",
    meta: pricingJmzX0NdIiMMeta || {},
    alias: pricingJmzX0NdIiMMeta?.alias || [],
    redirect: pricingJmzX0NdIiMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingJmzX0NdIiMMeta?.name ?? "pricing___de",
    path: pricingJmzX0NdIiMMeta?.path ?? "/de/pricing",
    meta: pricingJmzX0NdIiMMeta || {},
    alias: pricingJmzX0NdIiMMeta?.alias || [],
    redirect: pricingJmzX0NdIiMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingJmzX0NdIiMMeta?.name ?? "pricing___mx",
    path: pricingJmzX0NdIiMMeta?.path ?? "/mx/prueba-gratis",
    meta: pricingJmzX0NdIiMMeta || {},
    alias: pricingJmzX0NdIiMMeta?.alias || [],
    redirect: pricingJmzX0NdIiMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingJmzX0NdIiMMeta?.name ?? "pricing___fr",
    path: pricingJmzX0NdIiMMeta?.path ?? "/fr/pricing",
    meta: pricingJmzX0NdIiMMeta || {},
    alias: pricingJmzX0NdIiMMeta?.alias || [],
    redirect: pricingJmzX0NdIiMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingJmzX0NdIiMMeta?.name ?? "pricing___fr-ca",
    path: pricingJmzX0NdIiMMeta?.path ?? "/fr-ca/pricing",
    meta: pricingJmzX0NdIiMMeta || {},
    alias: pricingJmzX0NdIiMMeta?.alias || [],
    redirect: pricingJmzX0NdIiMMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKFVoDkTQqMeta?.name ?? "products-prefix-slug___en-US",
    path: _91slug_93yKFVoDkTQqMeta?.path ?? "/products/:prefix()/:slug()",
    meta: _91slug_93yKFVoDkTQqMeta || {},
    alias: _91slug_93yKFVoDkTQqMeta?.alias || [],
    redirect: _91slug_93yKFVoDkTQqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKFVoDkTQqMeta?.name ?? "products-prefix-slug___ca",
    path: _91slug_93yKFVoDkTQqMeta?.path ?? "/ca/products/:prefix()/:slug()",
    meta: _91slug_93yKFVoDkTQqMeta || {},
    alias: _91slug_93yKFVoDkTQqMeta?.alias || [],
    redirect: _91slug_93yKFVoDkTQqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKFVoDkTQqMeta?.name ?? "products-prefix-slug___nl",
    path: _91slug_93yKFVoDkTQqMeta?.path ?? "/nl/products/:prefix()/:slug()",
    meta: _91slug_93yKFVoDkTQqMeta || {},
    alias: _91slug_93yKFVoDkTQqMeta?.alias || [],
    redirect: _91slug_93yKFVoDkTQqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKFVoDkTQqMeta?.name ?? "products-prefix-slug___uk",
    path: _91slug_93yKFVoDkTQqMeta?.path ?? "/uk/products/:prefix()/:slug()",
    meta: _91slug_93yKFVoDkTQqMeta || {},
    alias: _91slug_93yKFVoDkTQqMeta?.alias || [],
    redirect: _91slug_93yKFVoDkTQqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKFVoDkTQqMeta?.name ?? "products-prefix-slug___de",
    path: _91slug_93yKFVoDkTQqMeta?.path ?? "/de/products/:prefix()/:slug()",
    meta: _91slug_93yKFVoDkTQqMeta || {},
    alias: _91slug_93yKFVoDkTQqMeta?.alias || [],
    redirect: _91slug_93yKFVoDkTQqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKFVoDkTQqMeta?.name ?? "products-prefix-slug___mx",
    path: _91slug_93yKFVoDkTQqMeta?.path ?? "/mx/products/:prefix()/:slug()",
    meta: _91slug_93yKFVoDkTQqMeta || {},
    alias: _91slug_93yKFVoDkTQqMeta?.alias || [],
    redirect: _91slug_93yKFVoDkTQqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKFVoDkTQqMeta?.name ?? "products-prefix-slug___fr",
    path: _91slug_93yKFVoDkTQqMeta?.path ?? "/fr/products/:prefix()/:slug()",
    meta: _91slug_93yKFVoDkTQqMeta || {},
    alias: _91slug_93yKFVoDkTQqMeta?.alias || [],
    redirect: _91slug_93yKFVoDkTQqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93yKFVoDkTQqMeta?.name ?? "products-prefix-slug___fr-ca",
    path: _91slug_93yKFVoDkTQqMeta?.path ?? "/fr-ca/products/:prefix()/:slug()",
    meta: _91slug_93yKFVoDkTQqMeta || {},
    alias: _91slug_93yKFVoDkTQqMeta?.alias || [],
    redirect: _91slug_93yKFVoDkTQqMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eBZ0yF3w8RMeta?.name ?? "products-slug___en-US",
    path: _91slug_93eBZ0yF3w8RMeta?.path ?? "/products/:slug()",
    meta: _91slug_93eBZ0yF3w8RMeta || {},
    alias: _91slug_93eBZ0yF3w8RMeta?.alias || [],
    redirect: _91slug_93eBZ0yF3w8RMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eBZ0yF3w8RMeta?.name ?? "products-slug___ca",
    path: _91slug_93eBZ0yF3w8RMeta?.path ?? "/ca/products/:slug()",
    meta: _91slug_93eBZ0yF3w8RMeta || {},
    alias: _91slug_93eBZ0yF3w8RMeta?.alias || [],
    redirect: _91slug_93eBZ0yF3w8RMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eBZ0yF3w8RMeta?.name ?? "products-slug___nl",
    path: _91slug_93eBZ0yF3w8RMeta?.path ?? "/nl/products/:slug()",
    meta: _91slug_93eBZ0yF3w8RMeta || {},
    alias: _91slug_93eBZ0yF3w8RMeta?.alias || [],
    redirect: _91slug_93eBZ0yF3w8RMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eBZ0yF3w8RMeta?.name ?? "products-slug___uk",
    path: _91slug_93eBZ0yF3w8RMeta?.path ?? "/uk/products/:slug()",
    meta: _91slug_93eBZ0yF3w8RMeta || {},
    alias: _91slug_93eBZ0yF3w8RMeta?.alias || [],
    redirect: _91slug_93eBZ0yF3w8RMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eBZ0yF3w8RMeta?.name ?? "products-slug___de",
    path: _91slug_93eBZ0yF3w8RMeta?.path ?? "/de/products/:slug()",
    meta: _91slug_93eBZ0yF3w8RMeta || {},
    alias: _91slug_93eBZ0yF3w8RMeta?.alias || [],
    redirect: _91slug_93eBZ0yF3w8RMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eBZ0yF3w8RMeta?.name ?? "products-slug___mx",
    path: _91slug_93eBZ0yF3w8RMeta?.path ?? "/mx/products/:slug()",
    meta: _91slug_93eBZ0yF3w8RMeta || {},
    alias: _91slug_93eBZ0yF3w8RMeta?.alias || [],
    redirect: _91slug_93eBZ0yF3w8RMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eBZ0yF3w8RMeta?.name ?? "products-slug___fr",
    path: _91slug_93eBZ0yF3w8RMeta?.path ?? "/fr/products/:slug()",
    meta: _91slug_93eBZ0yF3w8RMeta || {},
    alias: _91slug_93eBZ0yF3w8RMeta?.alias || [],
    redirect: _91slug_93eBZ0yF3w8RMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eBZ0yF3w8RMeta?.name ?? "products-slug___fr-ca",
    path: _91slug_93eBZ0yF3w8RMeta?.path ?? "/fr-ca/products/:slug()",
    meta: _91slug_93eBZ0yF3w8RMeta || {},
    alias: _91slug_93eBZ0yF3w8RMeta?.alias || [],
    redirect: _91slug_93eBZ0yF3w8RMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingZSw2p8sjgrMeta?.name ?? "products-equipment-monitoring-asset-tracking___en-US",
    path: asset_45trackingZSw2p8sjgrMeta?.path ?? "/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingZSw2p8sjgrMeta || {},
    alias: asset_45trackingZSw2p8sjgrMeta?.alias || [],
    redirect: asset_45trackingZSw2p8sjgrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingZSw2p8sjgrMeta?.name ?? "products-equipment-monitoring-asset-tracking___ca",
    path: asset_45trackingZSw2p8sjgrMeta?.path ?? "/ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingZSw2p8sjgrMeta || {},
    alias: asset_45trackingZSw2p8sjgrMeta?.alias || [],
    redirect: asset_45trackingZSw2p8sjgrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingZSw2p8sjgrMeta?.name ?? "products-equipment-monitoring-asset-tracking___nl",
    path: asset_45trackingZSw2p8sjgrMeta?.path ?? "/nl/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingZSw2p8sjgrMeta || {},
    alias: asset_45trackingZSw2p8sjgrMeta?.alias || [],
    redirect: asset_45trackingZSw2p8sjgrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingZSw2p8sjgrMeta?.name ?? "products-equipment-monitoring-asset-tracking___uk",
    path: asset_45trackingZSw2p8sjgrMeta?.path ?? "/uk/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingZSw2p8sjgrMeta || {},
    alias: asset_45trackingZSw2p8sjgrMeta?.alias || [],
    redirect: asset_45trackingZSw2p8sjgrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingZSw2p8sjgrMeta?.name ?? "products-equipment-monitoring-asset-tracking___de",
    path: asset_45trackingZSw2p8sjgrMeta?.path ?? "/de/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingZSw2p8sjgrMeta || {},
    alias: asset_45trackingZSw2p8sjgrMeta?.alias || [],
    redirect: asset_45trackingZSw2p8sjgrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingZSw2p8sjgrMeta?.name ?? "products-equipment-monitoring-asset-tracking___mx",
    path: asset_45trackingZSw2p8sjgrMeta?.path ?? "/mx/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingZSw2p8sjgrMeta || {},
    alias: asset_45trackingZSw2p8sjgrMeta?.alias || [],
    redirect: asset_45trackingZSw2p8sjgrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingZSw2p8sjgrMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr",
    path: asset_45trackingZSw2p8sjgrMeta?.path ?? "/fr/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingZSw2p8sjgrMeta || {},
    alias: asset_45trackingZSw2p8sjgrMeta?.alias || [],
    redirect: asset_45trackingZSw2p8sjgrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: asset_45trackingZSw2p8sjgrMeta?.name ?? "products-equipment-monitoring-asset-tracking___fr-ca",
    path: asset_45trackingZSw2p8sjgrMeta?.path ?? "/fr-ca/products/equipment-monitoring/asset-tracking",
    meta: asset_45trackingZSw2p8sjgrMeta || {},
    alias: asset_45trackingZSw2p8sjgrMeta?.alias || [],
    redirect: asset_45trackingZSw2p8sjgrMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/asset-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingKvHS985TiIMeta?.name ?? "products-equipment-monitoring-equipment-tracking___en-US",
    path: equipment_45trackingKvHS985TiIMeta?.path ?? "/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingKvHS985TiIMeta || {},
    alias: equipment_45trackingKvHS985TiIMeta?.alias || [],
    redirect: equipment_45trackingKvHS985TiIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingKvHS985TiIMeta?.name ?? "products-equipment-monitoring-equipment-tracking___ca",
    path: equipment_45trackingKvHS985TiIMeta?.path ?? "/ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingKvHS985TiIMeta || {},
    alias: equipment_45trackingKvHS985TiIMeta?.alias || [],
    redirect: equipment_45trackingKvHS985TiIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingKvHS985TiIMeta?.name ?? "products-equipment-monitoring-equipment-tracking___nl",
    path: equipment_45trackingKvHS985TiIMeta?.path ?? "/nl/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingKvHS985TiIMeta || {},
    alias: equipment_45trackingKvHS985TiIMeta?.alias || [],
    redirect: equipment_45trackingKvHS985TiIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingKvHS985TiIMeta?.name ?? "products-equipment-monitoring-equipment-tracking___uk",
    path: equipment_45trackingKvHS985TiIMeta?.path ?? "/uk/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingKvHS985TiIMeta || {},
    alias: equipment_45trackingKvHS985TiIMeta?.alias || [],
    redirect: equipment_45trackingKvHS985TiIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingKvHS985TiIMeta?.name ?? "products-equipment-monitoring-equipment-tracking___de",
    path: equipment_45trackingKvHS985TiIMeta?.path ?? "/de/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingKvHS985TiIMeta || {},
    alias: equipment_45trackingKvHS985TiIMeta?.alias || [],
    redirect: equipment_45trackingKvHS985TiIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingKvHS985TiIMeta?.name ?? "products-equipment-monitoring-equipment-tracking___mx",
    path: equipment_45trackingKvHS985TiIMeta?.path ?? "/mx/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingKvHS985TiIMeta || {},
    alias: equipment_45trackingKvHS985TiIMeta?.alias || [],
    redirect: equipment_45trackingKvHS985TiIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingKvHS985TiIMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr",
    path: equipment_45trackingKvHS985TiIMeta?.path ?? "/fr/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingKvHS985TiIMeta || {},
    alias: equipment_45trackingKvHS985TiIMeta?.alias || [],
    redirect: equipment_45trackingKvHS985TiIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: equipment_45trackingKvHS985TiIMeta?.name ?? "products-equipment-monitoring-equipment-tracking___fr-ca",
    path: equipment_45trackingKvHS985TiIMeta?.path ?? "/fr-ca/products/equipment-monitoring/equipment-tracking",
    meta: equipment_45trackingKvHS985TiIMeta || {},
    alias: equipment_45trackingKvHS985TiIMeta?.alias || [],
    redirect: equipment_45trackingKvHS985TiIMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/equipment-tracking.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringupIKotA9G9Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___en-US",
    path: reefer_45monitoringupIKotA9G9Meta?.path ?? "/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringupIKotA9G9Meta || {},
    alias: reefer_45monitoringupIKotA9G9Meta?.alias || [],
    redirect: reefer_45monitoringupIKotA9G9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringupIKotA9G9Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___ca",
    path: reefer_45monitoringupIKotA9G9Meta?.path ?? "/ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringupIKotA9G9Meta || {},
    alias: reefer_45monitoringupIKotA9G9Meta?.alias || [],
    redirect: reefer_45monitoringupIKotA9G9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringupIKotA9G9Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___nl",
    path: reefer_45monitoringupIKotA9G9Meta?.path ?? "/nl/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringupIKotA9G9Meta || {},
    alias: reefer_45monitoringupIKotA9G9Meta?.alias || [],
    redirect: reefer_45monitoringupIKotA9G9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringupIKotA9G9Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___uk",
    path: reefer_45monitoringupIKotA9G9Meta?.path ?? "/uk/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringupIKotA9G9Meta || {},
    alias: reefer_45monitoringupIKotA9G9Meta?.alias || [],
    redirect: reefer_45monitoringupIKotA9G9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringupIKotA9G9Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___de",
    path: reefer_45monitoringupIKotA9G9Meta?.path ?? "/de/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringupIKotA9G9Meta || {},
    alias: reefer_45monitoringupIKotA9G9Meta?.alias || [],
    redirect: reefer_45monitoringupIKotA9G9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringupIKotA9G9Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___mx",
    path: reefer_45monitoringupIKotA9G9Meta?.path ?? "/mx/productos/rastreo-equipos/refrigerados",
    meta: reefer_45monitoringupIKotA9G9Meta || {},
    alias: reefer_45monitoringupIKotA9G9Meta?.alias || [],
    redirect: reefer_45monitoringupIKotA9G9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringupIKotA9G9Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr",
    path: reefer_45monitoringupIKotA9G9Meta?.path ?? "/fr/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringupIKotA9G9Meta || {},
    alias: reefer_45monitoringupIKotA9G9Meta?.alias || [],
    redirect: reefer_45monitoringupIKotA9G9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: reefer_45monitoringupIKotA9G9Meta?.name ?? "products-equipment-monitoring-reefer-monitoring___fr-ca",
    path: reefer_45monitoringupIKotA9G9Meta?.path ?? "/fr-ca/products/equipment-monitoring/reefer-monitoring",
    meta: reefer_45monitoringupIKotA9G9Meta || {},
    alias: reefer_45monitoringupIKotA9G9Meta?.alias || [],
    redirect: reefer_45monitoringupIKotA9G9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/reefer-monitoring.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersRF1z8SK0sTMeta?.name ?? "products-equipment-monitoring-smart-trailers___en-US",
    path: smart_45trailersRF1z8SK0sTMeta?.path ?? "/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersRF1z8SK0sTMeta || {},
    alias: smart_45trailersRF1z8SK0sTMeta?.alias || [],
    redirect: smart_45trailersRF1z8SK0sTMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersRF1z8SK0sTMeta?.name ?? "products-equipment-monitoring-smart-trailers___ca",
    path: smart_45trailersRF1z8SK0sTMeta?.path ?? "/ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersRF1z8SK0sTMeta || {},
    alias: smart_45trailersRF1z8SK0sTMeta?.alias || [],
    redirect: smart_45trailersRF1z8SK0sTMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersRF1z8SK0sTMeta?.name ?? "products-equipment-monitoring-smart-trailers___nl",
    path: smart_45trailersRF1z8SK0sTMeta?.path ?? "/nl/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersRF1z8SK0sTMeta || {},
    alias: smart_45trailersRF1z8SK0sTMeta?.alias || [],
    redirect: smart_45trailersRF1z8SK0sTMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersRF1z8SK0sTMeta?.name ?? "products-equipment-monitoring-smart-trailers___uk",
    path: smart_45trailersRF1z8SK0sTMeta?.path ?? "/uk/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersRF1z8SK0sTMeta || {},
    alias: smart_45trailersRF1z8SK0sTMeta?.alias || [],
    redirect: smart_45trailersRF1z8SK0sTMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersRF1z8SK0sTMeta?.name ?? "products-equipment-monitoring-smart-trailers___de",
    path: smart_45trailersRF1z8SK0sTMeta?.path ?? "/de/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersRF1z8SK0sTMeta || {},
    alias: smart_45trailersRF1z8SK0sTMeta?.alias || [],
    redirect: smart_45trailersRF1z8SK0sTMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersRF1z8SK0sTMeta?.name ?? "products-equipment-monitoring-smart-trailers___mx",
    path: smart_45trailersRF1z8SK0sTMeta?.path ?? "/mx/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersRF1z8SK0sTMeta || {},
    alias: smart_45trailersRF1z8SK0sTMeta?.alias || [],
    redirect: smart_45trailersRF1z8SK0sTMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersRF1z8SK0sTMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr",
    path: smart_45trailersRF1z8SK0sTMeta?.path ?? "/fr/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersRF1z8SK0sTMeta || {},
    alias: smart_45trailersRF1z8SK0sTMeta?.alias || [],
    redirect: smart_45trailersRF1z8SK0sTMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: smart_45trailersRF1z8SK0sTMeta?.name ?? "products-equipment-monitoring-smart-trailers___fr-ca",
    path: smart_45trailersRF1z8SK0sTMeta?.path ?? "/fr-ca/products/equipment-monitoring/smart-trailers",
    meta: smart_45trailersRF1z8SK0sTMeta || {},
    alias: smart_45trailersRF1z8SK0sTMeta?.alias || [],
    redirect: smart_45trailersRF1z8SK0sTMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/smart-trailers.vue").then(m => m.default || m)
  },
  {
    name: trailer_45tracking5yog54i72WMeta?.name ?? "products-equipment-monitoring-trailer-tracking___en-US",
    path: trailer_45tracking5yog54i72WMeta?.path ?? "/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45tracking5yog54i72WMeta || {},
    alias: trailer_45tracking5yog54i72WMeta?.alias || [],
    redirect: trailer_45tracking5yog54i72WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45tracking5yog54i72WMeta?.name ?? "products-equipment-monitoring-trailer-tracking___ca",
    path: trailer_45tracking5yog54i72WMeta?.path ?? "/ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45tracking5yog54i72WMeta || {},
    alias: trailer_45tracking5yog54i72WMeta?.alias || [],
    redirect: trailer_45tracking5yog54i72WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45tracking5yog54i72WMeta?.name ?? "products-equipment-monitoring-trailer-tracking___nl",
    path: trailer_45tracking5yog54i72WMeta?.path ?? "/nl/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45tracking5yog54i72WMeta || {},
    alias: trailer_45tracking5yog54i72WMeta?.alias || [],
    redirect: trailer_45tracking5yog54i72WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45tracking5yog54i72WMeta?.name ?? "products-equipment-monitoring-trailer-tracking___uk",
    path: trailer_45tracking5yog54i72WMeta?.path ?? "/uk/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45tracking5yog54i72WMeta || {},
    alias: trailer_45tracking5yog54i72WMeta?.alias || [],
    redirect: trailer_45tracking5yog54i72WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45tracking5yog54i72WMeta?.name ?? "products-equipment-monitoring-trailer-tracking___de",
    path: trailer_45tracking5yog54i72WMeta?.path ?? "/de/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45tracking5yog54i72WMeta || {},
    alias: trailer_45tracking5yog54i72WMeta?.alias || [],
    redirect: trailer_45tracking5yog54i72WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45tracking5yog54i72WMeta?.name ?? "products-equipment-monitoring-trailer-tracking___mx",
    path: trailer_45tracking5yog54i72WMeta?.path ?? "/mx/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45tracking5yog54i72WMeta || {},
    alias: trailer_45tracking5yog54i72WMeta?.alias || [],
    redirect: trailer_45tracking5yog54i72WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45tracking5yog54i72WMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr",
    path: trailer_45tracking5yog54i72WMeta?.path ?? "/fr/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45tracking5yog54i72WMeta || {},
    alias: trailer_45tracking5yog54i72WMeta?.alias || [],
    redirect: trailer_45tracking5yog54i72WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: trailer_45tracking5yog54i72WMeta?.name ?? "products-equipment-monitoring-trailer-tracking___fr-ca",
    path: trailer_45tracking5yog54i72WMeta?.path ?? "/fr-ca/products/equipment-monitoring/trailer-tracking",
    meta: trailer_45tracking5yog54i72WMeta || {},
    alias: trailer_45tracking5yog54i72WMeta?.alias || [],
    redirect: trailer_45tracking5yog54i72WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/equipment-monitoring/trailer-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexuQKDX2Xae9Meta?.name ?? "products___en-US",
    path: indexuQKDX2Xae9Meta?.path ?? "/products",
    meta: indexuQKDX2Xae9Meta || {},
    alias: indexuQKDX2Xae9Meta?.alias || [],
    redirect: indexuQKDX2Xae9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexuQKDX2Xae9Meta?.name ?? "products___ca",
    path: indexuQKDX2Xae9Meta?.path ?? "/ca/products",
    meta: indexuQKDX2Xae9Meta || {},
    alias: indexuQKDX2Xae9Meta?.alias || [],
    redirect: indexuQKDX2Xae9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexuQKDX2Xae9Meta?.name ?? "products___nl",
    path: indexuQKDX2Xae9Meta?.path ?? "/nl/products",
    meta: indexuQKDX2Xae9Meta || {},
    alias: indexuQKDX2Xae9Meta?.alias || [],
    redirect: indexuQKDX2Xae9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexuQKDX2Xae9Meta?.name ?? "products___uk",
    path: indexuQKDX2Xae9Meta?.path ?? "/uk/products",
    meta: indexuQKDX2Xae9Meta || {},
    alias: indexuQKDX2Xae9Meta?.alias || [],
    redirect: indexuQKDX2Xae9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexuQKDX2Xae9Meta?.name ?? "products___de",
    path: indexuQKDX2Xae9Meta?.path ?? "/de/products",
    meta: indexuQKDX2Xae9Meta || {},
    alias: indexuQKDX2Xae9Meta?.alias || [],
    redirect: indexuQKDX2Xae9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexuQKDX2Xae9Meta?.name ?? "products___mx",
    path: indexuQKDX2Xae9Meta?.path ?? "/mx/products",
    meta: indexuQKDX2Xae9Meta || {},
    alias: indexuQKDX2Xae9Meta?.alias || [],
    redirect: indexuQKDX2Xae9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexuQKDX2Xae9Meta?.name ?? "products___fr",
    path: indexuQKDX2Xae9Meta?.path ?? "/fr/products",
    meta: indexuQKDX2Xae9Meta || {},
    alias: indexuQKDX2Xae9Meta?.alias || [],
    redirect: indexuQKDX2Xae9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: indexuQKDX2Xae9Meta?.name ?? "products___fr-ca",
    path: indexuQKDX2Xae9Meta?.path ?? "/fr-ca/products",
    meta: indexuQKDX2Xae9Meta || {},
    alias: indexuQKDX2Xae9Meta?.alias || [],
    redirect: indexuQKDX2Xae9Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kmLfz6QxHZMeta?.name ?? "products-models-slug___en-US",
    path: _91slug_93kmLfz6QxHZMeta?.path ?? "/products/models/:slug()",
    meta: _91slug_93kmLfz6QxHZMeta || {},
    alias: _91slug_93kmLfz6QxHZMeta?.alias || [],
    redirect: _91slug_93kmLfz6QxHZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kmLfz6QxHZMeta?.name ?? "products-models-slug___ca",
    path: _91slug_93kmLfz6QxHZMeta?.path ?? "/ca/products/models/:slug()",
    meta: _91slug_93kmLfz6QxHZMeta || {},
    alias: _91slug_93kmLfz6QxHZMeta?.alias || [],
    redirect: _91slug_93kmLfz6QxHZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kmLfz6QxHZMeta?.name ?? "products-models-slug___nl",
    path: _91slug_93kmLfz6QxHZMeta?.path ?? "/nl/products/models/:slug()",
    meta: _91slug_93kmLfz6QxHZMeta || {},
    alias: _91slug_93kmLfz6QxHZMeta?.alias || [],
    redirect: _91slug_93kmLfz6QxHZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kmLfz6QxHZMeta?.name ?? "products-models-slug___uk",
    path: _91slug_93kmLfz6QxHZMeta?.path ?? "/uk/products/models/:slug()",
    meta: _91slug_93kmLfz6QxHZMeta || {},
    alias: _91slug_93kmLfz6QxHZMeta?.alias || [],
    redirect: _91slug_93kmLfz6QxHZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kmLfz6QxHZMeta?.name ?? "products-models-slug___de",
    path: _91slug_93kmLfz6QxHZMeta?.path ?? "/de/products/models/:slug()",
    meta: _91slug_93kmLfz6QxHZMeta || {},
    alias: _91slug_93kmLfz6QxHZMeta?.alias || [],
    redirect: _91slug_93kmLfz6QxHZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kmLfz6QxHZMeta?.name ?? "products-models-slug___mx",
    path: _91slug_93kmLfz6QxHZMeta?.path ?? "/mx/products/models/:slug()",
    meta: _91slug_93kmLfz6QxHZMeta || {},
    alias: _91slug_93kmLfz6QxHZMeta?.alias || [],
    redirect: _91slug_93kmLfz6QxHZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kmLfz6QxHZMeta?.name ?? "products-models-slug___fr",
    path: _91slug_93kmLfz6QxHZMeta?.path ?? "/fr/products/models/:slug()",
    meta: _91slug_93kmLfz6QxHZMeta || {},
    alias: _91slug_93kmLfz6QxHZMeta?.alias || [],
    redirect: _91slug_93kmLfz6QxHZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93kmLfz6QxHZMeta?.name ?? "products-models-slug___fr-ca",
    path: _91slug_93kmLfz6QxHZMeta?.path ?? "/fr-ca/products/models/:slug()",
    meta: _91slug_93kmLfz6QxHZMeta || {},
    alias: _91slug_93kmLfz6QxHZMeta?.alias || [],
    redirect: _91slug_93kmLfz6QxHZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexmRYLkwozHuMeta?.name ?? "products-models___en-US",
    path: indexmRYLkwozHuMeta?.path ?? "/products/models",
    meta: indexmRYLkwozHuMeta || {},
    alias: indexmRYLkwozHuMeta?.alias || [],
    redirect: indexmRYLkwozHuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmRYLkwozHuMeta?.name ?? "products-models___ca",
    path: indexmRYLkwozHuMeta?.path ?? "/ca/products/models",
    meta: indexmRYLkwozHuMeta || {},
    alias: indexmRYLkwozHuMeta?.alias || [],
    redirect: indexmRYLkwozHuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmRYLkwozHuMeta?.name ?? "products-models___nl",
    path: indexmRYLkwozHuMeta?.path ?? "/nl/products/models",
    meta: indexmRYLkwozHuMeta || {},
    alias: indexmRYLkwozHuMeta?.alias || [],
    redirect: indexmRYLkwozHuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmRYLkwozHuMeta?.name ?? "products-models___uk",
    path: indexmRYLkwozHuMeta?.path ?? "/uk/products/models",
    meta: indexmRYLkwozHuMeta || {},
    alias: indexmRYLkwozHuMeta?.alias || [],
    redirect: indexmRYLkwozHuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmRYLkwozHuMeta?.name ?? "products-models___de",
    path: indexmRYLkwozHuMeta?.path ?? "/de/products/models",
    meta: indexmRYLkwozHuMeta || {},
    alias: indexmRYLkwozHuMeta?.alias || [],
    redirect: indexmRYLkwozHuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmRYLkwozHuMeta?.name ?? "products-models___mx",
    path: indexmRYLkwozHuMeta?.path ?? "/mx/products/models",
    meta: indexmRYLkwozHuMeta || {},
    alias: indexmRYLkwozHuMeta?.alias || [],
    redirect: indexmRYLkwozHuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmRYLkwozHuMeta?.name ?? "products-models___fr",
    path: indexmRYLkwozHuMeta?.path ?? "/fr/products/models",
    meta: indexmRYLkwozHuMeta || {},
    alias: indexmRYLkwozHuMeta?.alias || [],
    redirect: indexmRYLkwozHuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: indexmRYLkwozHuMeta?.name ?? "products-models___fr-ca",
    path: indexmRYLkwozHuMeta?.path ?? "/fr-ca/products/models",
    meta: indexmRYLkwozHuMeta || {},
    alias: indexmRYLkwozHuMeta?.alias || [],
    redirect: indexmRYLkwozHuMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/models/index.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfgRuofwOE5Meta?.name ?? "products-telematics-gps-fleet-tracking___en-US",
    path: gps_45fleet_45trackingfgRuofwOE5Meta?.path ?? "/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingfgRuofwOE5Meta || {},
    alias: gps_45fleet_45trackingfgRuofwOE5Meta?.alias || [],
    redirect: gps_45fleet_45trackingfgRuofwOE5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfgRuofwOE5Meta?.name ?? "products-telematics-gps-fleet-tracking___ca",
    path: gps_45fleet_45trackingfgRuofwOE5Meta?.path ?? "/ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingfgRuofwOE5Meta || {},
    alias: gps_45fleet_45trackingfgRuofwOE5Meta?.alias || [],
    redirect: gps_45fleet_45trackingfgRuofwOE5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfgRuofwOE5Meta?.name ?? "products-telematics-gps-fleet-tracking___nl",
    path: gps_45fleet_45trackingfgRuofwOE5Meta?.path ?? "/nl/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingfgRuofwOE5Meta || {},
    alias: gps_45fleet_45trackingfgRuofwOE5Meta?.alias || [],
    redirect: gps_45fleet_45trackingfgRuofwOE5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfgRuofwOE5Meta?.name ?? "products-telematics-gps-fleet-tracking___uk",
    path: gps_45fleet_45trackingfgRuofwOE5Meta?.path ?? "/uk/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingfgRuofwOE5Meta || {},
    alias: gps_45fleet_45trackingfgRuofwOE5Meta?.alias || [],
    redirect: gps_45fleet_45trackingfgRuofwOE5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfgRuofwOE5Meta?.name ?? "products-telematics-gps-fleet-tracking___de",
    path: gps_45fleet_45trackingfgRuofwOE5Meta?.path ?? "/de/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingfgRuofwOE5Meta || {},
    alias: gps_45fleet_45trackingfgRuofwOE5Meta?.alias || [],
    redirect: gps_45fleet_45trackingfgRuofwOE5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfgRuofwOE5Meta?.name ?? "products-telematics-gps-fleet-tracking___mx",
    path: gps_45fleet_45trackingfgRuofwOE5Meta?.path ?? "/mx/productos/telematica/rastreo-gps-flota",
    meta: gps_45fleet_45trackingfgRuofwOE5Meta || {},
    alias: gps_45fleet_45trackingfgRuofwOE5Meta?.alias || [],
    redirect: gps_45fleet_45trackingfgRuofwOE5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfgRuofwOE5Meta?.name ?? "products-telematics-gps-fleet-tracking___fr",
    path: gps_45fleet_45trackingfgRuofwOE5Meta?.path ?? "/fr/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingfgRuofwOE5Meta || {},
    alias: gps_45fleet_45trackingfgRuofwOE5Meta?.alias || [],
    redirect: gps_45fleet_45trackingfgRuofwOE5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: gps_45fleet_45trackingfgRuofwOE5Meta?.name ?? "products-telematics-gps-fleet-tracking___fr-ca",
    path: gps_45fleet_45trackingfgRuofwOE5Meta?.path ?? "/fr-ca/products/telematics/gps-fleet-tracking",
    meta: gps_45fleet_45trackingfgRuofwOE5Meta || {},
    alias: gps_45fleet_45trackingfgRuofwOE5Meta?.alias || [],
    redirect: gps_45fleet_45trackingfgRuofwOE5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/gps-fleet-tracking.vue").then(m => m.default || m)
  },
  {
    name: indexAYKIxQMafCMeta?.name ?? "products-telematics___en-US",
    path: indexAYKIxQMafCMeta?.path ?? "/products/telematics",
    meta: indexAYKIxQMafCMeta || {},
    alias: indexAYKIxQMafCMeta?.alias || [],
    redirect: indexAYKIxQMafCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexAYKIxQMafCMeta?.name ?? "products-telematics___ca",
    path: indexAYKIxQMafCMeta?.path ?? "/ca/products/telematics",
    meta: indexAYKIxQMafCMeta || {},
    alias: indexAYKIxQMafCMeta?.alias || [],
    redirect: indexAYKIxQMafCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexAYKIxQMafCMeta?.name ?? "products-telematics___nl",
    path: indexAYKIxQMafCMeta?.path ?? "/nl/products/telematics",
    meta: indexAYKIxQMafCMeta || {},
    alias: indexAYKIxQMafCMeta?.alias || [],
    redirect: indexAYKIxQMafCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexAYKIxQMafCMeta?.name ?? "products-telematics___uk",
    path: indexAYKIxQMafCMeta?.path ?? "/uk/products/telematics",
    meta: indexAYKIxQMafCMeta || {},
    alias: indexAYKIxQMafCMeta?.alias || [],
    redirect: indexAYKIxQMafCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexAYKIxQMafCMeta?.name ?? "products-telematics___de",
    path: indexAYKIxQMafCMeta?.path ?? "/de/products/telematics",
    meta: indexAYKIxQMafCMeta || {},
    alias: indexAYKIxQMafCMeta?.alias || [],
    redirect: indexAYKIxQMafCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexAYKIxQMafCMeta?.name ?? "products-telematics___mx",
    path: indexAYKIxQMafCMeta?.path ?? "/mx/products/flota",
    meta: indexAYKIxQMafCMeta || {},
    alias: indexAYKIxQMafCMeta?.alias || [],
    redirect: indexAYKIxQMafCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexAYKIxQMafCMeta?.name ?? "products-telematics___fr",
    path: indexAYKIxQMafCMeta?.path ?? "/fr/products/telematics",
    meta: indexAYKIxQMafCMeta || {},
    alias: indexAYKIxQMafCMeta?.alias || [],
    redirect: indexAYKIxQMafCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: indexAYKIxQMafCMeta?.name ?? "products-telematics___fr-ca",
    path: indexAYKIxQMafCMeta?.path ?? "/fr-ca/products/telematics",
    meta: indexAYKIxQMafCMeta || {},
    alias: indexAYKIxQMafCMeta?.alias || [],
    redirect: indexAYKIxQMafCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/products/telematics/index.vue").then(m => m.default || m)
  },
  {
    name: referralsCz6JFEcY5WMeta?.name ?? "referrals___en-US",
    path: referralsCz6JFEcY5WMeta?.path ?? "/referrals",
    meta: referralsCz6JFEcY5WMeta || {},
    alias: referralsCz6JFEcY5WMeta?.alias || [],
    redirect: referralsCz6JFEcY5WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsCz6JFEcY5WMeta?.name ?? "referrals___ca",
    path: referralsCz6JFEcY5WMeta?.path ?? "/ca/referrals",
    meta: referralsCz6JFEcY5WMeta || {},
    alias: referralsCz6JFEcY5WMeta?.alias || [],
    redirect: referralsCz6JFEcY5WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsCz6JFEcY5WMeta?.name ?? "referrals___nl",
    path: referralsCz6JFEcY5WMeta?.path ?? "/nl/referrals",
    meta: referralsCz6JFEcY5WMeta || {},
    alias: referralsCz6JFEcY5WMeta?.alias || [],
    redirect: referralsCz6JFEcY5WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsCz6JFEcY5WMeta?.name ?? "referrals___uk",
    path: referralsCz6JFEcY5WMeta?.path ?? "/uk/referrals",
    meta: referralsCz6JFEcY5WMeta || {},
    alias: referralsCz6JFEcY5WMeta?.alias || [],
    redirect: referralsCz6JFEcY5WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsCz6JFEcY5WMeta?.name ?? "referrals___de",
    path: referralsCz6JFEcY5WMeta?.path ?? "/de/referrals",
    meta: referralsCz6JFEcY5WMeta || {},
    alias: referralsCz6JFEcY5WMeta?.alias || [],
    redirect: referralsCz6JFEcY5WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsCz6JFEcY5WMeta?.name ?? "referrals___mx",
    path: referralsCz6JFEcY5WMeta?.path ?? "/mx/referrals",
    meta: referralsCz6JFEcY5WMeta || {},
    alias: referralsCz6JFEcY5WMeta?.alias || [],
    redirect: referralsCz6JFEcY5WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsCz6JFEcY5WMeta?.name ?? "referrals___fr",
    path: referralsCz6JFEcY5WMeta?.path ?? "/fr/referrals",
    meta: referralsCz6JFEcY5WMeta || {},
    alias: referralsCz6JFEcY5WMeta?.alias || [],
    redirect: referralsCz6JFEcY5WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: referralsCz6JFEcY5WMeta?.name ?? "referrals___fr-ca",
    path: referralsCz6JFEcY5WMeta?.path ?? "/fr-ca/referrals",
    meta: referralsCz6JFEcY5WMeta || {},
    alias: referralsCz6JFEcY5WMeta?.alias || [],
    redirect: referralsCz6JFEcY5WMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/referrals.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s5XoXltRBBMeta?.name ?? "resources-slug___en-US",
    path: _91slug_93s5XoXltRBBMeta?.path ?? "/resources/:slug()",
    meta: _91slug_93s5XoXltRBBMeta || {},
    alias: _91slug_93s5XoXltRBBMeta?.alias || [],
    redirect: _91slug_93s5XoXltRBBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s5XoXltRBBMeta?.name ?? "resources-slug___ca",
    path: _91slug_93s5XoXltRBBMeta?.path ?? "/ca/resources/:slug()",
    meta: _91slug_93s5XoXltRBBMeta || {},
    alias: _91slug_93s5XoXltRBBMeta?.alias || [],
    redirect: _91slug_93s5XoXltRBBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s5XoXltRBBMeta?.name ?? "resources-slug___nl",
    path: _91slug_93s5XoXltRBBMeta?.path ?? "/nl/resources/:slug()",
    meta: _91slug_93s5XoXltRBBMeta || {},
    alias: _91slug_93s5XoXltRBBMeta?.alias || [],
    redirect: _91slug_93s5XoXltRBBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s5XoXltRBBMeta?.name ?? "resources-slug___uk",
    path: _91slug_93s5XoXltRBBMeta?.path ?? "/uk/resources/:slug()",
    meta: _91slug_93s5XoXltRBBMeta || {},
    alias: _91slug_93s5XoXltRBBMeta?.alias || [],
    redirect: _91slug_93s5XoXltRBBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s5XoXltRBBMeta?.name ?? "resources-slug___de",
    path: _91slug_93s5XoXltRBBMeta?.path ?? "/de/resources/:slug()",
    meta: _91slug_93s5XoXltRBBMeta || {},
    alias: _91slug_93s5XoXltRBBMeta?.alias || [],
    redirect: _91slug_93s5XoXltRBBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s5XoXltRBBMeta?.name ?? "resources-slug___mx",
    path: _91slug_93s5XoXltRBBMeta?.path ?? "/mx/resources/:slug()",
    meta: _91slug_93s5XoXltRBBMeta || {},
    alias: _91slug_93s5XoXltRBBMeta?.alias || [],
    redirect: _91slug_93s5XoXltRBBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s5XoXltRBBMeta?.name ?? "resources-slug___fr",
    path: _91slug_93s5XoXltRBBMeta?.path ?? "/fr/resources/:slug()",
    meta: _91slug_93s5XoXltRBBMeta || {},
    alias: _91slug_93s5XoXltRBBMeta?.alias || [],
    redirect: _91slug_93s5XoXltRBBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93s5XoXltRBBMeta?.name ?? "resources-slug___fr-ca",
    path: _91slug_93s5XoXltRBBMeta?.path ?? "/fr-ca/resources/:slug()",
    meta: _91slug_93s5XoXltRBBMeta || {},
    alias: _91slug_93s5XoXltRBBMeta?.alias || [],
    redirect: _91slug_93s5XoXltRBBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/[slug].vue").then(m => m.default || m)
  },
  {
    name: brand_45assetspS10vHQYfLMeta?.name ?? "resources-brand-assets___en-US",
    path: brand_45assetspS10vHQYfLMeta?.path ?? "/resources/brand-assets",
    meta: brand_45assetspS10vHQYfLMeta || {},
    alias: brand_45assetspS10vHQYfLMeta?.alias || [],
    redirect: brand_45assetspS10vHQYfLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetspS10vHQYfLMeta?.name ?? "resources-brand-assets___ca",
    path: brand_45assetspS10vHQYfLMeta?.path ?? "/ca/resources/brand-assets",
    meta: brand_45assetspS10vHQYfLMeta || {},
    alias: brand_45assetspS10vHQYfLMeta?.alias || [],
    redirect: brand_45assetspS10vHQYfLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetspS10vHQYfLMeta?.name ?? "resources-brand-assets___nl",
    path: brand_45assetspS10vHQYfLMeta?.path ?? "/nl/resources/brand-assets",
    meta: brand_45assetspS10vHQYfLMeta || {},
    alias: brand_45assetspS10vHQYfLMeta?.alias || [],
    redirect: brand_45assetspS10vHQYfLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetspS10vHQYfLMeta?.name ?? "resources-brand-assets___uk",
    path: brand_45assetspS10vHQYfLMeta?.path ?? "/uk/resources/brand-assets",
    meta: brand_45assetspS10vHQYfLMeta || {},
    alias: brand_45assetspS10vHQYfLMeta?.alias || [],
    redirect: brand_45assetspS10vHQYfLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetspS10vHQYfLMeta?.name ?? "resources-brand-assets___de",
    path: brand_45assetspS10vHQYfLMeta?.path ?? "/de/resources/brand-assets",
    meta: brand_45assetspS10vHQYfLMeta || {},
    alias: brand_45assetspS10vHQYfLMeta?.alias || [],
    redirect: brand_45assetspS10vHQYfLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetspS10vHQYfLMeta?.name ?? "resources-brand-assets___mx",
    path: brand_45assetspS10vHQYfLMeta?.path ?? "/mx/resources/brand-assets",
    meta: brand_45assetspS10vHQYfLMeta || {},
    alias: brand_45assetspS10vHQYfLMeta?.alias || [],
    redirect: brand_45assetspS10vHQYfLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetspS10vHQYfLMeta?.name ?? "resources-brand-assets___fr",
    path: brand_45assetspS10vHQYfLMeta?.path ?? "/fr/resources/brand-assets",
    meta: brand_45assetspS10vHQYfLMeta || {},
    alias: brand_45assetspS10vHQYfLMeta?.alias || [],
    redirect: brand_45assetspS10vHQYfLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: brand_45assetspS10vHQYfLMeta?.name ?? "resources-brand-assets___fr-ca",
    path: brand_45assetspS10vHQYfLMeta?.path ?? "/fr-ca/resources/brand-assets",
    meta: brand_45assetspS10vHQYfLMeta || {},
    alias: brand_45assetspS10vHQYfLMeta?.alias || [],
    redirect: brand_45assetspS10vHQYfLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/brand-assets.vue").then(m => m.default || m)
  },
  {
    name: _91slug_930ne7ZB6G3nMeta?.name ?? "resources-content-slug___en-US",
    path: _91slug_930ne7ZB6G3nMeta?.path ?? "/resources/content/:slug()",
    meta: _91slug_930ne7ZB6G3nMeta || {},
    alias: _91slug_930ne7ZB6G3nMeta?.alias || [],
    redirect: _91slug_930ne7ZB6G3nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930ne7ZB6G3nMeta?.name ?? "resources-content-slug___ca",
    path: _91slug_930ne7ZB6G3nMeta?.path ?? "/ca/resources/content/:slug()",
    meta: _91slug_930ne7ZB6G3nMeta || {},
    alias: _91slug_930ne7ZB6G3nMeta?.alias || [],
    redirect: _91slug_930ne7ZB6G3nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930ne7ZB6G3nMeta?.name ?? "resources-content-slug___nl",
    path: _91slug_930ne7ZB6G3nMeta?.path ?? "/nl/resources/content/:slug()",
    meta: _91slug_930ne7ZB6G3nMeta || {},
    alias: _91slug_930ne7ZB6G3nMeta?.alias || [],
    redirect: _91slug_930ne7ZB6G3nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930ne7ZB6G3nMeta?.name ?? "resources-content-slug___uk",
    path: _91slug_930ne7ZB6G3nMeta?.path ?? "/uk/resources/content/:slug()",
    meta: _91slug_930ne7ZB6G3nMeta || {},
    alias: _91slug_930ne7ZB6G3nMeta?.alias || [],
    redirect: _91slug_930ne7ZB6G3nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930ne7ZB6G3nMeta?.name ?? "resources-content-slug___de",
    path: _91slug_930ne7ZB6G3nMeta?.path ?? "/de/resources/content/:slug()",
    meta: _91slug_930ne7ZB6G3nMeta || {},
    alias: _91slug_930ne7ZB6G3nMeta?.alias || [],
    redirect: _91slug_930ne7ZB6G3nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930ne7ZB6G3nMeta?.name ?? "resources-content-slug___mx",
    path: _91slug_930ne7ZB6G3nMeta?.path ?? "/mx/resources/content/:slug()",
    meta: _91slug_930ne7ZB6G3nMeta || {},
    alias: _91slug_930ne7ZB6G3nMeta?.alias || [],
    redirect: _91slug_930ne7ZB6G3nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930ne7ZB6G3nMeta?.name ?? "resources-content-slug___fr",
    path: _91slug_930ne7ZB6G3nMeta?.path ?? "/fr/resources/content/:slug()",
    meta: _91slug_930ne7ZB6G3nMeta || {},
    alias: _91slug_930ne7ZB6G3nMeta?.alias || [],
    redirect: _91slug_930ne7ZB6G3nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_930ne7ZB6G3nMeta?.name ?? "resources-content-slug___fr-ca",
    path: _91slug_930ne7ZB6G3nMeta?.path ?? "/fr-ca/resources/content/:slug()",
    meta: _91slug_930ne7ZB6G3nMeta || {},
    alias: _91slug_930ne7ZB6G3nMeta?.alias || [],
    redirect: _91slug_930ne7ZB6G3nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/[slug].vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerN3Pzald1hoMeta?.name ?? "resources-content-email-preferences-center___en-US",
    path: email_45preferences_45centerN3Pzald1hoMeta?.path ?? "/resources/content/email-preferences-center",
    meta: email_45preferences_45centerN3Pzald1hoMeta || {},
    alias: email_45preferences_45centerN3Pzald1hoMeta?.alias || [],
    redirect: email_45preferences_45centerN3Pzald1hoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerN3Pzald1hoMeta?.name ?? "resources-content-email-preferences-center___ca",
    path: email_45preferences_45centerN3Pzald1hoMeta?.path ?? "/ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerN3Pzald1hoMeta || {},
    alias: email_45preferences_45centerN3Pzald1hoMeta?.alias || [],
    redirect: email_45preferences_45centerN3Pzald1hoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerN3Pzald1hoMeta?.name ?? "resources-content-email-preferences-center___nl",
    path: email_45preferences_45centerN3Pzald1hoMeta?.path ?? "/nl/resources/content/email-preferences-center",
    meta: email_45preferences_45centerN3Pzald1hoMeta || {},
    alias: email_45preferences_45centerN3Pzald1hoMeta?.alias || [],
    redirect: email_45preferences_45centerN3Pzald1hoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerN3Pzald1hoMeta?.name ?? "resources-content-email-preferences-center___uk",
    path: email_45preferences_45centerN3Pzald1hoMeta?.path ?? "/uk/resources/content/email-preferences-center",
    meta: email_45preferences_45centerN3Pzald1hoMeta || {},
    alias: email_45preferences_45centerN3Pzald1hoMeta?.alias || [],
    redirect: email_45preferences_45centerN3Pzald1hoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerN3Pzald1hoMeta?.name ?? "resources-content-email-preferences-center___de",
    path: email_45preferences_45centerN3Pzald1hoMeta?.path ?? "/de/resources/content/email-preferences-center",
    meta: email_45preferences_45centerN3Pzald1hoMeta || {},
    alias: email_45preferences_45centerN3Pzald1hoMeta?.alias || [],
    redirect: email_45preferences_45centerN3Pzald1hoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerN3Pzald1hoMeta?.name ?? "resources-content-email-preferences-center___mx",
    path: email_45preferences_45centerN3Pzald1hoMeta?.path ?? "/mx/resources/content/email-preferences-center",
    meta: email_45preferences_45centerN3Pzald1hoMeta || {},
    alias: email_45preferences_45centerN3Pzald1hoMeta?.alias || [],
    redirect: email_45preferences_45centerN3Pzald1hoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerN3Pzald1hoMeta?.name ?? "resources-content-email-preferences-center___fr",
    path: email_45preferences_45centerN3Pzald1hoMeta?.path ?? "/fr/resources/content/email-preferences-center",
    meta: email_45preferences_45centerN3Pzald1hoMeta || {},
    alias: email_45preferences_45centerN3Pzald1hoMeta?.alias || [],
    redirect: email_45preferences_45centerN3Pzald1hoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: email_45preferences_45centerN3Pzald1hoMeta?.name ?? "resources-content-email-preferences-center___fr-ca",
    path: email_45preferences_45centerN3Pzald1hoMeta?.path ?? "/fr-ca/resources/content/email-preferences-center",
    meta: email_45preferences_45centerN3Pzald1hoMeta || {},
    alias: email_45preferences_45centerN3Pzald1hoMeta?.alias || [],
    redirect: email_45preferences_45centerN3Pzald1hoMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/email-preferences-center.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeI9x0uE3mLsMeta?.name ?? "resources-content-one-click-unsubscribe___en-US",
    path: one_45click_45unsubscribeI9x0uE3mLsMeta?.path ?? "/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeI9x0uE3mLsMeta || {},
    alias: one_45click_45unsubscribeI9x0uE3mLsMeta?.alias || [],
    redirect: one_45click_45unsubscribeI9x0uE3mLsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeI9x0uE3mLsMeta?.name ?? "resources-content-one-click-unsubscribe___ca",
    path: one_45click_45unsubscribeI9x0uE3mLsMeta?.path ?? "/ca/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeI9x0uE3mLsMeta || {},
    alias: one_45click_45unsubscribeI9x0uE3mLsMeta?.alias || [],
    redirect: one_45click_45unsubscribeI9x0uE3mLsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeI9x0uE3mLsMeta?.name ?? "resources-content-one-click-unsubscribe___nl",
    path: one_45click_45unsubscribeI9x0uE3mLsMeta?.path ?? "/nl/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeI9x0uE3mLsMeta || {},
    alias: one_45click_45unsubscribeI9x0uE3mLsMeta?.alias || [],
    redirect: one_45click_45unsubscribeI9x0uE3mLsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeI9x0uE3mLsMeta?.name ?? "resources-content-one-click-unsubscribe___uk",
    path: one_45click_45unsubscribeI9x0uE3mLsMeta?.path ?? "/uk/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeI9x0uE3mLsMeta || {},
    alias: one_45click_45unsubscribeI9x0uE3mLsMeta?.alias || [],
    redirect: one_45click_45unsubscribeI9x0uE3mLsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeI9x0uE3mLsMeta?.name ?? "resources-content-one-click-unsubscribe___de",
    path: one_45click_45unsubscribeI9x0uE3mLsMeta?.path ?? "/de/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeI9x0uE3mLsMeta || {},
    alias: one_45click_45unsubscribeI9x0uE3mLsMeta?.alias || [],
    redirect: one_45click_45unsubscribeI9x0uE3mLsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeI9x0uE3mLsMeta?.name ?? "resources-content-one-click-unsubscribe___mx",
    path: one_45click_45unsubscribeI9x0uE3mLsMeta?.path ?? "/mx/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeI9x0uE3mLsMeta || {},
    alias: one_45click_45unsubscribeI9x0uE3mLsMeta?.alias || [],
    redirect: one_45click_45unsubscribeI9x0uE3mLsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeI9x0uE3mLsMeta?.name ?? "resources-content-one-click-unsubscribe___fr",
    path: one_45click_45unsubscribeI9x0uE3mLsMeta?.path ?? "/fr/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeI9x0uE3mLsMeta || {},
    alias: one_45click_45unsubscribeI9x0uE3mLsMeta?.alias || [],
    redirect: one_45click_45unsubscribeI9x0uE3mLsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: one_45click_45unsubscribeI9x0uE3mLsMeta?.name ?? "resources-content-one-click-unsubscribe___fr-ca",
    path: one_45click_45unsubscribeI9x0uE3mLsMeta?.path ?? "/fr-ca/resources/content/one-click-unsubscribe",
    meta: one_45click_45unsubscribeI9x0uE3mLsMeta || {},
    alias: one_45click_45unsubscribeI9x0uE3mLsMeta?.alias || [],
    redirect: one_45click_45unsubscribeI9x0uE3mLsMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/content/one-click-unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9396AXK4GmJVMeta?.name ?? "resources-experts-slug___en-US",
    path: _91slug_9396AXK4GmJVMeta?.path ?? "/resources/experts/:slug()",
    meta: _91slug_9396AXK4GmJVMeta || {},
    alias: _91slug_9396AXK4GmJVMeta?.alias || [],
    redirect: _91slug_9396AXK4GmJVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9396AXK4GmJVMeta?.name ?? "resources-experts-slug___ca",
    path: _91slug_9396AXK4GmJVMeta?.path ?? "/ca/resources/experts/:slug()",
    meta: _91slug_9396AXK4GmJVMeta || {},
    alias: _91slug_9396AXK4GmJVMeta?.alias || [],
    redirect: _91slug_9396AXK4GmJVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9396AXK4GmJVMeta?.name ?? "resources-experts-slug___nl",
    path: _91slug_9396AXK4GmJVMeta?.path ?? "/nl/resources/experts/:slug()",
    meta: _91slug_9396AXK4GmJVMeta || {},
    alias: _91slug_9396AXK4GmJVMeta?.alias || [],
    redirect: _91slug_9396AXK4GmJVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9396AXK4GmJVMeta?.name ?? "resources-experts-slug___uk",
    path: _91slug_9396AXK4GmJVMeta?.path ?? "/uk/resources/experts/:slug()",
    meta: _91slug_9396AXK4GmJVMeta || {},
    alias: _91slug_9396AXK4GmJVMeta?.alias || [],
    redirect: _91slug_9396AXK4GmJVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9396AXK4GmJVMeta?.name ?? "resources-experts-slug___de",
    path: _91slug_9396AXK4GmJVMeta?.path ?? "/de/resources/experts/:slug()",
    meta: _91slug_9396AXK4GmJVMeta || {},
    alias: _91slug_9396AXK4GmJVMeta?.alias || [],
    redirect: _91slug_9396AXK4GmJVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9396AXK4GmJVMeta?.name ?? "resources-experts-slug___mx",
    path: _91slug_9396AXK4GmJVMeta?.path ?? "/mx/resources/experts/:slug()",
    meta: _91slug_9396AXK4GmJVMeta || {},
    alias: _91slug_9396AXK4GmJVMeta?.alias || [],
    redirect: _91slug_9396AXK4GmJVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9396AXK4GmJVMeta?.name ?? "resources-experts-slug___fr",
    path: _91slug_9396AXK4GmJVMeta?.path ?? "/fr/resources/experts/:slug()",
    meta: _91slug_9396AXK4GmJVMeta || {},
    alias: _91slug_9396AXK4GmJVMeta?.alias || [],
    redirect: _91slug_9396AXK4GmJVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9396AXK4GmJVMeta?.name ?? "resources-experts-slug___fr-ca",
    path: _91slug_9396AXK4GmJVMeta?.path ?? "/fr-ca/resources/experts/:slug()",
    meta: _91slug_9396AXK4GmJVMeta || {},
    alias: _91slug_9396AXK4GmJVMeta?.alias || [],
    redirect: _91slug_9396AXK4GmJVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l5SYcbNHvNMeta?.name ?? "resources-experts-category-slug___en-US",
    path: _91slug_93l5SYcbNHvNMeta?.path ?? "/resources/experts/category/:slug()",
    meta: _91slug_93l5SYcbNHvNMeta || {},
    alias: _91slug_93l5SYcbNHvNMeta?.alias || [],
    redirect: _91slug_93l5SYcbNHvNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l5SYcbNHvNMeta?.name ?? "resources-experts-category-slug___ca",
    path: _91slug_93l5SYcbNHvNMeta?.path ?? "/ca/resources/experts/category/:slug()",
    meta: _91slug_93l5SYcbNHvNMeta || {},
    alias: _91slug_93l5SYcbNHvNMeta?.alias || [],
    redirect: _91slug_93l5SYcbNHvNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l5SYcbNHvNMeta?.name ?? "resources-experts-category-slug___nl",
    path: _91slug_93l5SYcbNHvNMeta?.path ?? "/nl/resources/experts/category/:slug()",
    meta: _91slug_93l5SYcbNHvNMeta || {},
    alias: _91slug_93l5SYcbNHvNMeta?.alias || [],
    redirect: _91slug_93l5SYcbNHvNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l5SYcbNHvNMeta?.name ?? "resources-experts-category-slug___uk",
    path: _91slug_93l5SYcbNHvNMeta?.path ?? "/uk/resources/experts/category/:slug()",
    meta: _91slug_93l5SYcbNHvNMeta || {},
    alias: _91slug_93l5SYcbNHvNMeta?.alias || [],
    redirect: _91slug_93l5SYcbNHvNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l5SYcbNHvNMeta?.name ?? "resources-experts-category-slug___de",
    path: _91slug_93l5SYcbNHvNMeta?.path ?? "/de/resources/experts/category/:slug()",
    meta: _91slug_93l5SYcbNHvNMeta || {},
    alias: _91slug_93l5SYcbNHvNMeta?.alias || [],
    redirect: _91slug_93l5SYcbNHvNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l5SYcbNHvNMeta?.name ?? "resources-experts-category-slug___mx",
    path: _91slug_93l5SYcbNHvNMeta?.path ?? "/mx/resources/experts/category/:slug()",
    meta: _91slug_93l5SYcbNHvNMeta || {},
    alias: _91slug_93l5SYcbNHvNMeta?.alias || [],
    redirect: _91slug_93l5SYcbNHvNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l5SYcbNHvNMeta?.name ?? "resources-experts-category-slug___fr",
    path: _91slug_93l5SYcbNHvNMeta?.path ?? "/fr/resources/experts/category/:slug()",
    meta: _91slug_93l5SYcbNHvNMeta || {},
    alias: _91slug_93l5SYcbNHvNMeta?.alias || [],
    redirect: _91slug_93l5SYcbNHvNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93l5SYcbNHvNMeta?.name ?? "resources-experts-category-slug___fr-ca",
    path: _91slug_93l5SYcbNHvNMeta?.path ?? "/fr-ca/resources/experts/category/:slug()",
    meta: _91slug_93l5SYcbNHvNMeta || {},
    alias: _91slug_93l5SYcbNHvNMeta?.alias || [],
    redirect: _91slug_93l5SYcbNHvNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4Otwyzf88BMeta?.name ?? "resources-experts___en-US",
    path: index4Otwyzf88BMeta?.path ?? "/resources/experts",
    meta: index4Otwyzf88BMeta || {},
    alias: index4Otwyzf88BMeta?.alias || [],
    redirect: index4Otwyzf88BMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index4Otwyzf88BMeta?.name ?? "resources-experts___ca",
    path: index4Otwyzf88BMeta?.path ?? "/ca/resources/experts",
    meta: index4Otwyzf88BMeta || {},
    alias: index4Otwyzf88BMeta?.alias || [],
    redirect: index4Otwyzf88BMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index4Otwyzf88BMeta?.name ?? "resources-experts___nl",
    path: index4Otwyzf88BMeta?.path ?? "/nl/resources/experts",
    meta: index4Otwyzf88BMeta || {},
    alias: index4Otwyzf88BMeta?.alias || [],
    redirect: index4Otwyzf88BMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index4Otwyzf88BMeta?.name ?? "resources-experts___uk",
    path: index4Otwyzf88BMeta?.path ?? "/uk/resources/experts",
    meta: index4Otwyzf88BMeta || {},
    alias: index4Otwyzf88BMeta?.alias || [],
    redirect: index4Otwyzf88BMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index4Otwyzf88BMeta?.name ?? "resources-experts___de",
    path: index4Otwyzf88BMeta?.path ?? "/de/resources/experts",
    meta: index4Otwyzf88BMeta || {},
    alias: index4Otwyzf88BMeta?.alias || [],
    redirect: index4Otwyzf88BMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index4Otwyzf88BMeta?.name ?? "resources-experts___mx",
    path: index4Otwyzf88BMeta?.path ?? "/mx/resources/experts",
    meta: index4Otwyzf88BMeta || {},
    alias: index4Otwyzf88BMeta?.alias || [],
    redirect: index4Otwyzf88BMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index4Otwyzf88BMeta?.name ?? "resources-experts___fr",
    path: index4Otwyzf88BMeta?.path ?? "/fr/resources/experts",
    meta: index4Otwyzf88BMeta || {},
    alias: index4Otwyzf88BMeta?.alias || [],
    redirect: index4Otwyzf88BMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index4Otwyzf88BMeta?.name ?? "resources-experts___fr-ca",
    path: index4Otwyzf88BMeta?.path ?? "/fr-ca/resources/experts",
    meta: index4Otwyzf88BMeta || {},
    alias: index4Otwyzf88BMeta?.alias || [],
    redirect: index4Otwyzf88BMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/experts/index.vue").then(m => m.default || m)
  },
  {
    name: index0scpmezXseMeta?.name ?? "resources___en-US",
    path: index0scpmezXseMeta?.path ?? "/resources",
    meta: index0scpmezXseMeta || {},
    alias: index0scpmezXseMeta?.alias || [],
    redirect: index0scpmezXseMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0scpmezXseMeta?.name ?? "resources___ca",
    path: index0scpmezXseMeta?.path ?? "/ca/resources",
    meta: index0scpmezXseMeta || {},
    alias: index0scpmezXseMeta?.alias || [],
    redirect: index0scpmezXseMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0scpmezXseMeta?.name ?? "resources___nl",
    path: index0scpmezXseMeta?.path ?? "/nl/resources",
    meta: index0scpmezXseMeta || {},
    alias: index0scpmezXseMeta?.alias || [],
    redirect: index0scpmezXseMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0scpmezXseMeta?.name ?? "resources___uk",
    path: index0scpmezXseMeta?.path ?? "/uk/resources",
    meta: index0scpmezXseMeta || {},
    alias: index0scpmezXseMeta?.alias || [],
    redirect: index0scpmezXseMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0scpmezXseMeta?.name ?? "resources___de",
    path: index0scpmezXseMeta?.path ?? "/de/resources",
    meta: index0scpmezXseMeta || {},
    alias: index0scpmezXseMeta?.alias || [],
    redirect: index0scpmezXseMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0scpmezXseMeta?.name ?? "resources___mx",
    path: index0scpmezXseMeta?.path ?? "/mx/resources",
    meta: index0scpmezXseMeta || {},
    alias: index0scpmezXseMeta?.alias || [],
    redirect: index0scpmezXseMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0scpmezXseMeta?.name ?? "resources___fr",
    path: index0scpmezXseMeta?.path ?? "/fr/resources",
    meta: index0scpmezXseMeta || {},
    alias: index0scpmezXseMeta?.alias || [],
    redirect: index0scpmezXseMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: index0scpmezXseMeta?.name ?? "resources___fr-ca",
    path: index0scpmezXseMeta?.path ?? "/fr-ca/resources",
    meta: index0scpmezXseMeta || {},
    alias: index0scpmezXseMeta?.alias || [],
    redirect: index0scpmezXseMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_939MnvXT7plBMeta?.name ?? "resources-marketplace-slug___en-US",
    path: _91slug_939MnvXT7plBMeta?.path ?? "/resources/marketplace/:slug()",
    meta: _91slug_939MnvXT7plBMeta || {},
    alias: _91slug_939MnvXT7plBMeta?.alias || [],
    redirect: _91slug_939MnvXT7plBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939MnvXT7plBMeta?.name ?? "resources-marketplace-slug___ca",
    path: _91slug_939MnvXT7plBMeta?.path ?? "/ca/resources/marketplace/:slug()",
    meta: _91slug_939MnvXT7plBMeta || {},
    alias: _91slug_939MnvXT7plBMeta?.alias || [],
    redirect: _91slug_939MnvXT7plBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939MnvXT7plBMeta?.name ?? "resources-marketplace-slug___nl",
    path: _91slug_939MnvXT7plBMeta?.path ?? "/nl/resources/marketplace/:slug()",
    meta: _91slug_939MnvXT7plBMeta || {},
    alias: _91slug_939MnvXT7plBMeta?.alias || [],
    redirect: _91slug_939MnvXT7plBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939MnvXT7plBMeta?.name ?? "resources-marketplace-slug___uk",
    path: _91slug_939MnvXT7plBMeta?.path ?? "/uk/resources/marketplace/:slug()",
    meta: _91slug_939MnvXT7plBMeta || {},
    alias: _91slug_939MnvXT7plBMeta?.alias || [],
    redirect: _91slug_939MnvXT7plBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939MnvXT7plBMeta?.name ?? "resources-marketplace-slug___de",
    path: _91slug_939MnvXT7plBMeta?.path ?? "/de/resources/marketplace/:slug()",
    meta: _91slug_939MnvXT7plBMeta || {},
    alias: _91slug_939MnvXT7plBMeta?.alias || [],
    redirect: _91slug_939MnvXT7plBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939MnvXT7plBMeta?.name ?? "resources-marketplace-slug___mx",
    path: _91slug_939MnvXT7plBMeta?.path ?? "/mx/resources/marketplace/:slug()",
    meta: _91slug_939MnvXT7plBMeta || {},
    alias: _91slug_939MnvXT7plBMeta?.alias || [],
    redirect: _91slug_939MnvXT7plBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939MnvXT7plBMeta?.name ?? "resources-marketplace-slug___fr",
    path: _91slug_939MnvXT7plBMeta?.path ?? "/fr/resources/marketplace/:slug()",
    meta: _91slug_939MnvXT7plBMeta || {},
    alias: _91slug_939MnvXT7plBMeta?.alias || [],
    redirect: _91slug_939MnvXT7plBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939MnvXT7plBMeta?.name ?? "resources-marketplace-slug___fr-ca",
    path: _91slug_939MnvXT7plBMeta?.path ?? "/fr-ca/resources/marketplace/:slug()",
    meta: _91slug_939MnvXT7plBMeta || {},
    alias: _91slug_939MnvXT7plBMeta?.alias || [],
    redirect: _91slug_939MnvXT7plBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mGdAb4vNkjMeta?.name ?? "resources-marketplace-category-slug___en-US",
    path: _91slug_93mGdAb4vNkjMeta?.path ?? "/resources/marketplace/category/:slug()",
    meta: _91slug_93mGdAb4vNkjMeta || {},
    alias: _91slug_93mGdAb4vNkjMeta?.alias || [],
    redirect: _91slug_93mGdAb4vNkjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mGdAb4vNkjMeta?.name ?? "resources-marketplace-category-slug___ca",
    path: _91slug_93mGdAb4vNkjMeta?.path ?? "/ca/resources/marketplace/category/:slug()",
    meta: _91slug_93mGdAb4vNkjMeta || {},
    alias: _91slug_93mGdAb4vNkjMeta?.alias || [],
    redirect: _91slug_93mGdAb4vNkjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mGdAb4vNkjMeta?.name ?? "resources-marketplace-category-slug___nl",
    path: _91slug_93mGdAb4vNkjMeta?.path ?? "/nl/resources/marketplace/category/:slug()",
    meta: _91slug_93mGdAb4vNkjMeta || {},
    alias: _91slug_93mGdAb4vNkjMeta?.alias || [],
    redirect: _91slug_93mGdAb4vNkjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mGdAb4vNkjMeta?.name ?? "resources-marketplace-category-slug___uk",
    path: _91slug_93mGdAb4vNkjMeta?.path ?? "/uk/resources/marketplace/category/:slug()",
    meta: _91slug_93mGdAb4vNkjMeta || {},
    alias: _91slug_93mGdAb4vNkjMeta?.alias || [],
    redirect: _91slug_93mGdAb4vNkjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mGdAb4vNkjMeta?.name ?? "resources-marketplace-category-slug___de",
    path: _91slug_93mGdAb4vNkjMeta?.path ?? "/de/resources/marketplace/category/:slug()",
    meta: _91slug_93mGdAb4vNkjMeta || {},
    alias: _91slug_93mGdAb4vNkjMeta?.alias || [],
    redirect: _91slug_93mGdAb4vNkjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mGdAb4vNkjMeta?.name ?? "resources-marketplace-category-slug___mx",
    path: _91slug_93mGdAb4vNkjMeta?.path ?? "/mx/resources/marketplace/category/:slug()",
    meta: _91slug_93mGdAb4vNkjMeta || {},
    alias: _91slug_93mGdAb4vNkjMeta?.alias || [],
    redirect: _91slug_93mGdAb4vNkjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mGdAb4vNkjMeta?.name ?? "resources-marketplace-category-slug___fr",
    path: _91slug_93mGdAb4vNkjMeta?.path ?? "/fr/resources/marketplace/category/:slug()",
    meta: _91slug_93mGdAb4vNkjMeta || {},
    alias: _91slug_93mGdAb4vNkjMeta?.alias || [],
    redirect: _91slug_93mGdAb4vNkjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93mGdAb4vNkjMeta?.name ?? "resources-marketplace-category-slug___fr-ca",
    path: _91slug_93mGdAb4vNkjMeta?.path ?? "/fr-ca/resources/marketplace/category/:slug()",
    meta: _91slug_93mGdAb4vNkjMeta || {},
    alias: _91slug_93mGdAb4vNkjMeta?.alias || [],
    redirect: _91slug_93mGdAb4vNkjMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: index8JYl3T8JUKMeta?.name ?? "resources-marketplace___en-US",
    path: index8JYl3T8JUKMeta?.path ?? "/resources/marketplace",
    meta: index8JYl3T8JUKMeta || {},
    alias: index8JYl3T8JUKMeta?.alias || [],
    redirect: index8JYl3T8JUKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index8JYl3T8JUKMeta?.name ?? "resources-marketplace___ca",
    path: index8JYl3T8JUKMeta?.path ?? "/ca/resources/marketplace",
    meta: index8JYl3T8JUKMeta || {},
    alias: index8JYl3T8JUKMeta?.alias || [],
    redirect: index8JYl3T8JUKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index8JYl3T8JUKMeta?.name ?? "resources-marketplace___nl",
    path: index8JYl3T8JUKMeta?.path ?? "/nl/resources/marketplace",
    meta: index8JYl3T8JUKMeta || {},
    alias: index8JYl3T8JUKMeta?.alias || [],
    redirect: index8JYl3T8JUKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index8JYl3T8JUKMeta?.name ?? "resources-marketplace___uk",
    path: index8JYl3T8JUKMeta?.path ?? "/uk/resources/marketplace",
    meta: index8JYl3T8JUKMeta || {},
    alias: index8JYl3T8JUKMeta?.alias || [],
    redirect: index8JYl3T8JUKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index8JYl3T8JUKMeta?.name ?? "resources-marketplace___de",
    path: index8JYl3T8JUKMeta?.path ?? "/de/resources/marketplace",
    meta: index8JYl3T8JUKMeta || {},
    alias: index8JYl3T8JUKMeta?.alias || [],
    redirect: index8JYl3T8JUKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index8JYl3T8JUKMeta?.name ?? "resources-marketplace___mx",
    path: index8JYl3T8JUKMeta?.path ?? "/mx/resources/marketplace",
    meta: index8JYl3T8JUKMeta || {},
    alias: index8JYl3T8JUKMeta?.alias || [],
    redirect: index8JYl3T8JUKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index8JYl3T8JUKMeta?.name ?? "resources-marketplace___fr",
    path: index8JYl3T8JUKMeta?.path ?? "/fr/resources/marketplace",
    meta: index8JYl3T8JUKMeta || {},
    alias: index8JYl3T8JUKMeta?.alias || [],
    redirect: index8JYl3T8JUKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: index8JYl3T8JUKMeta?.name ?? "resources-marketplace___fr-ca",
    path: index8JYl3T8JUKMeta?.path ?? "/fr-ca/resources/marketplace",
    meta: index8JYl3T8JUKMeta || {},
    alias: index8JYl3T8JUKMeta?.alias || [],
    redirect: index8JYl3T8JUKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/marketplace/index.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraaDfCI4QL6cMeta?.name ?? "resources-partner-programs-build-with-samsara___en-US",
    path: build_45with_45samsaraaDfCI4QL6cMeta?.path ?? "/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraaDfCI4QL6cMeta || {},
    alias: build_45with_45samsaraaDfCI4QL6cMeta?.alias || [],
    redirect: build_45with_45samsaraaDfCI4QL6cMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraaDfCI4QL6cMeta?.name ?? "resources-partner-programs-build-with-samsara___ca",
    path: build_45with_45samsaraaDfCI4QL6cMeta?.path ?? "/ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraaDfCI4QL6cMeta || {},
    alias: build_45with_45samsaraaDfCI4QL6cMeta?.alias || [],
    redirect: build_45with_45samsaraaDfCI4QL6cMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraaDfCI4QL6cMeta?.name ?? "resources-partner-programs-build-with-samsara___nl",
    path: build_45with_45samsaraaDfCI4QL6cMeta?.path ?? "/nl/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraaDfCI4QL6cMeta || {},
    alias: build_45with_45samsaraaDfCI4QL6cMeta?.alias || [],
    redirect: build_45with_45samsaraaDfCI4QL6cMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraaDfCI4QL6cMeta?.name ?? "resources-partner-programs-build-with-samsara___uk",
    path: build_45with_45samsaraaDfCI4QL6cMeta?.path ?? "/uk/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraaDfCI4QL6cMeta || {},
    alias: build_45with_45samsaraaDfCI4QL6cMeta?.alias || [],
    redirect: build_45with_45samsaraaDfCI4QL6cMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraaDfCI4QL6cMeta?.name ?? "resources-partner-programs-build-with-samsara___de",
    path: build_45with_45samsaraaDfCI4QL6cMeta?.path ?? "/de/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraaDfCI4QL6cMeta || {},
    alias: build_45with_45samsaraaDfCI4QL6cMeta?.alias || [],
    redirect: build_45with_45samsaraaDfCI4QL6cMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraaDfCI4QL6cMeta?.name ?? "resources-partner-programs-build-with-samsara___mx",
    path: build_45with_45samsaraaDfCI4QL6cMeta?.path ?? "/mx/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraaDfCI4QL6cMeta || {},
    alias: build_45with_45samsaraaDfCI4QL6cMeta?.alias || [],
    redirect: build_45with_45samsaraaDfCI4QL6cMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraaDfCI4QL6cMeta?.name ?? "resources-partner-programs-build-with-samsara___fr",
    path: build_45with_45samsaraaDfCI4QL6cMeta?.path ?? "/fr/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraaDfCI4QL6cMeta || {},
    alias: build_45with_45samsaraaDfCI4QL6cMeta?.alias || [],
    redirect: build_45with_45samsaraaDfCI4QL6cMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: build_45with_45samsaraaDfCI4QL6cMeta?.name ?? "resources-partner-programs-build-with-samsara___fr-ca",
    path: build_45with_45samsaraaDfCI4QL6cMeta?.path ?? "/fr-ca/resources/partner-programs/build-with-samsara",
    meta: build_45with_45samsaraaDfCI4QL6cMeta || {},
    alias: build_45with_45samsaraaDfCI4QL6cMeta?.alias || [],
    redirect: build_45with_45samsaraaDfCI4QL6cMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/build-with-samsara.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs143iOuyndOMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___en-US",
    path: evolve_45insurance_45programs143iOuyndOMeta?.path ?? "/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs143iOuyndOMeta || {},
    alias: evolve_45insurance_45programs143iOuyndOMeta?.alias || [],
    redirect: evolve_45insurance_45programs143iOuyndOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs143iOuyndOMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___ca",
    path: evolve_45insurance_45programs143iOuyndOMeta?.path ?? "/ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs143iOuyndOMeta || {},
    alias: evolve_45insurance_45programs143iOuyndOMeta?.alias || [],
    redirect: evolve_45insurance_45programs143iOuyndOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs143iOuyndOMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___nl",
    path: evolve_45insurance_45programs143iOuyndOMeta?.path ?? "/nl/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs143iOuyndOMeta || {},
    alias: evolve_45insurance_45programs143iOuyndOMeta?.alias || [],
    redirect: evolve_45insurance_45programs143iOuyndOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs143iOuyndOMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___uk",
    path: evolve_45insurance_45programs143iOuyndOMeta?.path ?? "/uk/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs143iOuyndOMeta || {},
    alias: evolve_45insurance_45programs143iOuyndOMeta?.alias || [],
    redirect: evolve_45insurance_45programs143iOuyndOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs143iOuyndOMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___de",
    path: evolve_45insurance_45programs143iOuyndOMeta?.path ?? "/de/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs143iOuyndOMeta || {},
    alias: evolve_45insurance_45programs143iOuyndOMeta?.alias || [],
    redirect: evolve_45insurance_45programs143iOuyndOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs143iOuyndOMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___mx",
    path: evolve_45insurance_45programs143iOuyndOMeta?.path ?? "/mx/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs143iOuyndOMeta || {},
    alias: evolve_45insurance_45programs143iOuyndOMeta?.alias || [],
    redirect: evolve_45insurance_45programs143iOuyndOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs143iOuyndOMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr",
    path: evolve_45insurance_45programs143iOuyndOMeta?.path ?? "/fr/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs143iOuyndOMeta || {},
    alias: evolve_45insurance_45programs143iOuyndOMeta?.alias || [],
    redirect: evolve_45insurance_45programs143iOuyndOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: evolve_45insurance_45programs143iOuyndOMeta?.name ?? "resources-partner-programs-evolve-insurance-programs___fr-ca",
    path: evolve_45insurance_45programs143iOuyndOMeta?.path ?? "/fr-ca/resources/partner-programs/evolve-insurance-programs",
    meta: evolve_45insurance_45programs143iOuyndOMeta || {},
    alias: evolve_45insurance_45programs143iOuyndOMeta?.alias || [],
    redirect: evolve_45insurance_45programs143iOuyndOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/evolve-insurance-programs.vue").then(m => m.default || m)
  },
  {
    name: index9yOZRw47FcMeta?.name ?? "resources-partner-programs___en-US",
    path: index9yOZRw47FcMeta?.path ?? "/resources/partner-programs",
    meta: index9yOZRw47FcMeta || {},
    alias: index9yOZRw47FcMeta?.alias || [],
    redirect: index9yOZRw47FcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index9yOZRw47FcMeta?.name ?? "resources-partner-programs___ca",
    path: index9yOZRw47FcMeta?.path ?? "/ca/resources/partner-programs",
    meta: index9yOZRw47FcMeta || {},
    alias: index9yOZRw47FcMeta?.alias || [],
    redirect: index9yOZRw47FcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index9yOZRw47FcMeta?.name ?? "resources-partner-programs___nl",
    path: index9yOZRw47FcMeta?.path ?? "/nl/resources/partner-programs",
    meta: index9yOZRw47FcMeta || {},
    alias: index9yOZRw47FcMeta?.alias || [],
    redirect: index9yOZRw47FcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index9yOZRw47FcMeta?.name ?? "resources-partner-programs___uk",
    path: index9yOZRw47FcMeta?.path ?? "/uk/resources/partner-programs",
    meta: index9yOZRw47FcMeta || {},
    alias: index9yOZRw47FcMeta?.alias || [],
    redirect: index9yOZRw47FcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index9yOZRw47FcMeta?.name ?? "resources-partner-programs___de",
    path: index9yOZRw47FcMeta?.path ?? "/de/resources/partner-programs",
    meta: index9yOZRw47FcMeta || {},
    alias: index9yOZRw47FcMeta?.alias || [],
    redirect: index9yOZRw47FcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index9yOZRw47FcMeta?.name ?? "resources-partner-programs___mx",
    path: index9yOZRw47FcMeta?.path ?? "/mx/resources/partner-programs",
    meta: index9yOZRw47FcMeta || {},
    alias: index9yOZRw47FcMeta?.alias || [],
    redirect: index9yOZRw47FcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index9yOZRw47FcMeta?.name ?? "resources-partner-programs___fr",
    path: index9yOZRw47FcMeta?.path ?? "/fr/resources/partner-programs",
    meta: index9yOZRw47FcMeta || {},
    alias: index9yOZRw47FcMeta?.alias || [],
    redirect: index9yOZRw47FcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: index9yOZRw47FcMeta?.name ?? "resources-partner-programs___fr-ca",
    path: index9yOZRw47FcMeta?.path ?? "/fr-ca/resources/partner-programs",
    meta: index9yOZRw47FcMeta || {},
    alias: index9yOZRw47FcMeta?.alias || [],
    redirect: index9yOZRw47FcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/index.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesha5MjDQpD4Meta?.name ?? "resources-partner-programs-provide-services___en-US",
    path: provide_45servicesha5MjDQpD4Meta?.path ?? "/resources/partner-programs/provide-services",
    meta: provide_45servicesha5MjDQpD4Meta || {},
    alias: provide_45servicesha5MjDQpD4Meta?.alias || [],
    redirect: provide_45servicesha5MjDQpD4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesha5MjDQpD4Meta?.name ?? "resources-partner-programs-provide-services___ca",
    path: provide_45servicesha5MjDQpD4Meta?.path ?? "/ca/resources/partner-programs/provide-services",
    meta: provide_45servicesha5MjDQpD4Meta || {},
    alias: provide_45servicesha5MjDQpD4Meta?.alias || [],
    redirect: provide_45servicesha5MjDQpD4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesha5MjDQpD4Meta?.name ?? "resources-partner-programs-provide-services___nl",
    path: provide_45servicesha5MjDQpD4Meta?.path ?? "/nl/resources/partner-programs/provide-services",
    meta: provide_45servicesha5MjDQpD4Meta || {},
    alias: provide_45servicesha5MjDQpD4Meta?.alias || [],
    redirect: provide_45servicesha5MjDQpD4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesha5MjDQpD4Meta?.name ?? "resources-partner-programs-provide-services___uk",
    path: provide_45servicesha5MjDQpD4Meta?.path ?? "/uk/resources/partner-programs/provide-services",
    meta: provide_45servicesha5MjDQpD4Meta || {},
    alias: provide_45servicesha5MjDQpD4Meta?.alias || [],
    redirect: provide_45servicesha5MjDQpD4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesha5MjDQpD4Meta?.name ?? "resources-partner-programs-provide-services___de",
    path: provide_45servicesha5MjDQpD4Meta?.path ?? "/de/resources/partner-programs/provide-services",
    meta: provide_45servicesha5MjDQpD4Meta || {},
    alias: provide_45servicesha5MjDQpD4Meta?.alias || [],
    redirect: provide_45servicesha5MjDQpD4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesha5MjDQpD4Meta?.name ?? "resources-partner-programs-provide-services___mx",
    path: provide_45servicesha5MjDQpD4Meta?.path ?? "/mx/resources/partner-programs/provide-services",
    meta: provide_45servicesha5MjDQpD4Meta || {},
    alias: provide_45servicesha5MjDQpD4Meta?.alias || [],
    redirect: provide_45servicesha5MjDQpD4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesha5MjDQpD4Meta?.name ?? "resources-partner-programs-provide-services___fr",
    path: provide_45servicesha5MjDQpD4Meta?.path ?? "/fr/resources/partner-programs/provide-services",
    meta: provide_45servicesha5MjDQpD4Meta || {},
    alias: provide_45servicesha5MjDQpD4Meta?.alias || [],
    redirect: provide_45servicesha5MjDQpD4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: provide_45servicesha5MjDQpD4Meta?.name ?? "resources-partner-programs-provide-services___fr-ca",
    path: provide_45servicesha5MjDQpD4Meta?.path ?? "/fr-ca/resources/partner-programs/provide-services",
    meta: provide_45servicesha5MjDQpD4Meta || {},
    alias: provide_45servicesha5MjDQpD4Meta?.alias || [],
    redirect: provide_45servicesha5MjDQpD4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/provide-services.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara4PfcS2YPyGMeta?.name ?? "resources-partner-programs-sell-samsara___en-US",
    path: sell_45samsara4PfcS2YPyGMeta?.path ?? "/resources/partner-programs/sell-samsara",
    meta: sell_45samsara4PfcS2YPyGMeta || {},
    alias: sell_45samsara4PfcS2YPyGMeta?.alias || [],
    redirect: sell_45samsara4PfcS2YPyGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara4PfcS2YPyGMeta?.name ?? "resources-partner-programs-sell-samsara___ca",
    path: sell_45samsara4PfcS2YPyGMeta?.path ?? "/ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsara4PfcS2YPyGMeta || {},
    alias: sell_45samsara4PfcS2YPyGMeta?.alias || [],
    redirect: sell_45samsara4PfcS2YPyGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara4PfcS2YPyGMeta?.name ?? "resources-partner-programs-sell-samsara___nl",
    path: sell_45samsara4PfcS2YPyGMeta?.path ?? "/nl/resources/partner-programs/sell-samsara",
    meta: sell_45samsara4PfcS2YPyGMeta || {},
    alias: sell_45samsara4PfcS2YPyGMeta?.alias || [],
    redirect: sell_45samsara4PfcS2YPyGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara4PfcS2YPyGMeta?.name ?? "resources-partner-programs-sell-samsara___uk",
    path: sell_45samsara4PfcS2YPyGMeta?.path ?? "/uk/resources/partner-programs/sell-samsara",
    meta: sell_45samsara4PfcS2YPyGMeta || {},
    alias: sell_45samsara4PfcS2YPyGMeta?.alias || [],
    redirect: sell_45samsara4PfcS2YPyGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara4PfcS2YPyGMeta?.name ?? "resources-partner-programs-sell-samsara___de",
    path: sell_45samsara4PfcS2YPyGMeta?.path ?? "/de/resources/partner-programs/sell-samsara",
    meta: sell_45samsara4PfcS2YPyGMeta || {},
    alias: sell_45samsara4PfcS2YPyGMeta?.alias || [],
    redirect: sell_45samsara4PfcS2YPyGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara4PfcS2YPyGMeta?.name ?? "resources-partner-programs-sell-samsara___mx",
    path: sell_45samsara4PfcS2YPyGMeta?.path ?? "/mx/resources/partner-programs/sell-samsara",
    meta: sell_45samsara4PfcS2YPyGMeta || {},
    alias: sell_45samsara4PfcS2YPyGMeta?.alias || [],
    redirect: sell_45samsara4PfcS2YPyGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara4PfcS2YPyGMeta?.name ?? "resources-partner-programs-sell-samsara___fr",
    path: sell_45samsara4PfcS2YPyGMeta?.path ?? "/fr/resources/partner-programs/sell-samsara",
    meta: sell_45samsara4PfcS2YPyGMeta || {},
    alias: sell_45samsara4PfcS2YPyGMeta?.alias || [],
    redirect: sell_45samsara4PfcS2YPyGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: sell_45samsara4PfcS2YPyGMeta?.name ?? "resources-partner-programs-sell-samsara___fr-ca",
    path: sell_45samsara4PfcS2YPyGMeta?.path ?? "/fr-ca/resources/partner-programs/sell-samsara",
    meta: sell_45samsara4PfcS2YPyGMeta || {},
    alias: sell_45samsara4PfcS2YPyGMeta?.alias || [],
    redirect: sell_45samsara4PfcS2YPyGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/partner-programs/sell-samsara.vue").then(m => m.default || m)
  },
  {
    name: tourNbtqZmSqgfMeta?.name ?? "resources-tour___en-US",
    path: tourNbtqZmSqgfMeta?.path ?? "/resources/tour",
    meta: tourNbtqZmSqgfMeta || {},
    alias: tourNbtqZmSqgfMeta?.alias || [],
    redirect: tourNbtqZmSqgfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourNbtqZmSqgfMeta?.name ?? "resources-tour___ca",
    path: tourNbtqZmSqgfMeta?.path ?? "/ca/resources/tour",
    meta: tourNbtqZmSqgfMeta || {},
    alias: tourNbtqZmSqgfMeta?.alias || [],
    redirect: tourNbtqZmSqgfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourNbtqZmSqgfMeta?.name ?? "resources-tour___nl",
    path: tourNbtqZmSqgfMeta?.path ?? "/nl/resources/tour",
    meta: tourNbtqZmSqgfMeta || {},
    alias: tourNbtqZmSqgfMeta?.alias || [],
    redirect: tourNbtqZmSqgfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourNbtqZmSqgfMeta?.name ?? "resources-tour___uk",
    path: tourNbtqZmSqgfMeta?.path ?? "/uk/resources/tour",
    meta: tourNbtqZmSqgfMeta || {},
    alias: tourNbtqZmSqgfMeta?.alias || [],
    redirect: tourNbtqZmSqgfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourNbtqZmSqgfMeta?.name ?? "resources-tour___de",
    path: tourNbtqZmSqgfMeta?.path ?? "/de/resources/tour",
    meta: tourNbtqZmSqgfMeta || {},
    alias: tourNbtqZmSqgfMeta?.alias || [],
    redirect: tourNbtqZmSqgfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourNbtqZmSqgfMeta?.name ?? "resources-tour___mx",
    path: tourNbtqZmSqgfMeta?.path ?? "/mx/resources/tour",
    meta: tourNbtqZmSqgfMeta || {},
    alias: tourNbtqZmSqgfMeta?.alias || [],
    redirect: tourNbtqZmSqgfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourNbtqZmSqgfMeta?.name ?? "resources-tour___fr",
    path: tourNbtqZmSqgfMeta?.path ?? "/fr/resources/tour",
    meta: tourNbtqZmSqgfMeta || {},
    alias: tourNbtqZmSqgfMeta?.alias || [],
    redirect: tourNbtqZmSqgfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: tourNbtqZmSqgfMeta?.name ?? "resources-tour___fr-ca",
    path: tourNbtqZmSqgfMeta?.path ?? "/fr-ca/resources/tour",
    meta: tourNbtqZmSqgfMeta || {},
    alias: tourNbtqZmSqgfMeta?.alias || [],
    redirect: tourNbtqZmSqgfMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/tour.vue").then(m => m.default || m)
  },
  {
    name: videoszMpIapMiYiMeta?.name ?? "resources-videos___en-US",
    path: videoszMpIapMiYiMeta?.path ?? "/resources/videos",
    meta: videoszMpIapMiYiMeta || {},
    alias: videoszMpIapMiYiMeta?.alias || [],
    redirect: videoszMpIapMiYiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videoszMpIapMiYiMeta?.name ?? "resources-videos___ca",
    path: videoszMpIapMiYiMeta?.path ?? "/ca/resources/videos",
    meta: videoszMpIapMiYiMeta || {},
    alias: videoszMpIapMiYiMeta?.alias || [],
    redirect: videoszMpIapMiYiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videoszMpIapMiYiMeta?.name ?? "resources-videos___nl",
    path: videoszMpIapMiYiMeta?.path ?? "/nl/resources/videos",
    meta: videoszMpIapMiYiMeta || {},
    alias: videoszMpIapMiYiMeta?.alias || [],
    redirect: videoszMpIapMiYiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videoszMpIapMiYiMeta?.name ?? "resources-videos___uk",
    path: videoszMpIapMiYiMeta?.path ?? "/uk/resources/videos",
    meta: videoszMpIapMiYiMeta || {},
    alias: videoszMpIapMiYiMeta?.alias || [],
    redirect: videoszMpIapMiYiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videoszMpIapMiYiMeta?.name ?? "resources-videos___de",
    path: videoszMpIapMiYiMeta?.path ?? "/de/resources/videos",
    meta: videoszMpIapMiYiMeta || {},
    alias: videoszMpIapMiYiMeta?.alias || [],
    redirect: videoszMpIapMiYiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videoszMpIapMiYiMeta?.name ?? "resources-videos___mx",
    path: videoszMpIapMiYiMeta?.path ?? "/mx/resources/videos",
    meta: videoszMpIapMiYiMeta || {},
    alias: videoszMpIapMiYiMeta?.alias || [],
    redirect: videoszMpIapMiYiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videoszMpIapMiYiMeta?.name ?? "resources-videos___fr",
    path: videoszMpIapMiYiMeta?.path ?? "/fr/resources/videos",
    meta: videoszMpIapMiYiMeta || {},
    alias: videoszMpIapMiYiMeta?.alias || [],
    redirect: videoszMpIapMiYiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: videoszMpIapMiYiMeta?.name ?? "resources-videos___fr-ca",
    path: videoszMpIapMiYiMeta?.path ?? "/fr-ca/resources/videos",
    meta: videoszMpIapMiYiMeta || {},
    alias: videoszMpIapMiYiMeta?.alias || [],
    redirect: videoszMpIapMiYiMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/resources/videos.vue").then(m => m.default || m)
  },
  {
    name: indexaWrgMgYvmBMeta?.name ?? "roi-calculator___en-US",
    path: indexaWrgMgYvmBMeta?.path ?? "/roi-calculator",
    meta: indexaWrgMgYvmBMeta || {},
    alias: indexaWrgMgYvmBMeta?.alias || [],
    redirect: indexaWrgMgYvmBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWrgMgYvmBMeta?.name ?? "roi-calculator___ca",
    path: indexaWrgMgYvmBMeta?.path ?? "/ca/roi-calculator",
    meta: indexaWrgMgYvmBMeta || {},
    alias: indexaWrgMgYvmBMeta?.alias || [],
    redirect: indexaWrgMgYvmBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWrgMgYvmBMeta?.name ?? "roi-calculator___nl",
    path: indexaWrgMgYvmBMeta?.path ?? "/nl/roi-calculator",
    meta: indexaWrgMgYvmBMeta || {},
    alias: indexaWrgMgYvmBMeta?.alias || [],
    redirect: indexaWrgMgYvmBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWrgMgYvmBMeta?.name ?? "roi-calculator___uk",
    path: indexaWrgMgYvmBMeta?.path ?? "/uk/roi-calculator",
    meta: indexaWrgMgYvmBMeta || {},
    alias: indexaWrgMgYvmBMeta?.alias || [],
    redirect: indexaWrgMgYvmBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWrgMgYvmBMeta?.name ?? "roi-calculator___de",
    path: indexaWrgMgYvmBMeta?.path ?? "/de/roi-calculator",
    meta: indexaWrgMgYvmBMeta || {},
    alias: indexaWrgMgYvmBMeta?.alias || [],
    redirect: indexaWrgMgYvmBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWrgMgYvmBMeta?.name ?? "roi-calculator___mx",
    path: indexaWrgMgYvmBMeta?.path ?? "/mx/roi-calculator",
    meta: indexaWrgMgYvmBMeta || {},
    alias: indexaWrgMgYvmBMeta?.alias || [],
    redirect: indexaWrgMgYvmBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWrgMgYvmBMeta?.name ?? "roi-calculator___fr",
    path: indexaWrgMgYvmBMeta?.path ?? "/fr/roi-calculator",
    meta: indexaWrgMgYvmBMeta || {},
    alias: indexaWrgMgYvmBMeta?.alias || [],
    redirect: indexaWrgMgYvmBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexaWrgMgYvmBMeta?.name ?? "roi-calculator___fr-ca",
    path: indexaWrgMgYvmBMeta?.path ?? "/fr-ca/roi-calculator",
    meta: indexaWrgMgYvmBMeta || {},
    alias: indexaWrgMgYvmBMeta?.alias || [],
    redirect: indexaWrgMgYvmBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/roi-calculator/index.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturespEEkMTCYM5Meta?.name ?? "samsara-ventures___en-US",
    path: samsara_45venturespEEkMTCYM5Meta?.path ?? "/samsara-ventures",
    meta: samsara_45venturespEEkMTCYM5Meta || {},
    alias: samsara_45venturespEEkMTCYM5Meta?.alias || [],
    redirect: samsara_45venturespEEkMTCYM5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturespEEkMTCYM5Meta?.name ?? "samsara-ventures___ca",
    path: samsara_45venturespEEkMTCYM5Meta?.path ?? "/ca/samsara-ventures",
    meta: samsara_45venturespEEkMTCYM5Meta || {},
    alias: samsara_45venturespEEkMTCYM5Meta?.alias || [],
    redirect: samsara_45venturespEEkMTCYM5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturespEEkMTCYM5Meta?.name ?? "samsara-ventures___nl",
    path: samsara_45venturespEEkMTCYM5Meta?.path ?? "/nl/samsara-ventures",
    meta: samsara_45venturespEEkMTCYM5Meta || {},
    alias: samsara_45venturespEEkMTCYM5Meta?.alias || [],
    redirect: samsara_45venturespEEkMTCYM5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturespEEkMTCYM5Meta?.name ?? "samsara-ventures___uk",
    path: samsara_45venturespEEkMTCYM5Meta?.path ?? "/uk/samsara-ventures",
    meta: samsara_45venturespEEkMTCYM5Meta || {},
    alias: samsara_45venturespEEkMTCYM5Meta?.alias || [],
    redirect: samsara_45venturespEEkMTCYM5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturespEEkMTCYM5Meta?.name ?? "samsara-ventures___de",
    path: samsara_45venturespEEkMTCYM5Meta?.path ?? "/de/samsara-ventures",
    meta: samsara_45venturespEEkMTCYM5Meta || {},
    alias: samsara_45venturespEEkMTCYM5Meta?.alias || [],
    redirect: samsara_45venturespEEkMTCYM5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturespEEkMTCYM5Meta?.name ?? "samsara-ventures___mx",
    path: samsara_45venturespEEkMTCYM5Meta?.path ?? "/mx/samsara-ventures",
    meta: samsara_45venturespEEkMTCYM5Meta || {},
    alias: samsara_45venturespEEkMTCYM5Meta?.alias || [],
    redirect: samsara_45venturespEEkMTCYM5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturespEEkMTCYM5Meta?.name ?? "samsara-ventures___fr",
    path: samsara_45venturespEEkMTCYM5Meta?.path ?? "/fr/samsara-ventures",
    meta: samsara_45venturespEEkMTCYM5Meta || {},
    alias: samsara_45venturespEEkMTCYM5Meta?.alias || [],
    redirect: samsara_45venturespEEkMTCYM5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: samsara_45venturespEEkMTCYM5Meta?.name ?? "samsara-ventures___fr-ca",
    path: samsara_45venturespEEkMTCYM5Meta?.path ?? "/fr-ca/samsara-ventures",
    meta: samsara_45venturespEEkMTCYM5Meta || {},
    alias: samsara_45venturespEEkMTCYM5Meta?.alias || [],
    redirect: samsara_45venturespEEkMTCYM5Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/samsara-ventures.vue").then(m => m.default || m)
  },
  {
    name: security_45summitXiWbsYAbEWMeta?.name ?? "security-summit___en-US",
    path: security_45summitXiWbsYAbEWMeta?.path ?? "/security-summit",
    meta: security_45summitXiWbsYAbEWMeta || {},
    alias: security_45summitXiWbsYAbEWMeta?.alias || [],
    redirect: security_45summitXiWbsYAbEWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitXiWbsYAbEWMeta?.name ?? "security-summit___ca",
    path: security_45summitXiWbsYAbEWMeta?.path ?? "/ca/security-summit",
    meta: security_45summitXiWbsYAbEWMeta || {},
    alias: security_45summitXiWbsYAbEWMeta?.alias || [],
    redirect: security_45summitXiWbsYAbEWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitXiWbsYAbEWMeta?.name ?? "security-summit___nl",
    path: security_45summitXiWbsYAbEWMeta?.path ?? "/nl/security-summit",
    meta: security_45summitXiWbsYAbEWMeta || {},
    alias: security_45summitXiWbsYAbEWMeta?.alias || [],
    redirect: security_45summitXiWbsYAbEWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitXiWbsYAbEWMeta?.name ?? "security-summit___uk",
    path: security_45summitXiWbsYAbEWMeta?.path ?? "/uk/security-summit",
    meta: security_45summitXiWbsYAbEWMeta || {},
    alias: security_45summitXiWbsYAbEWMeta?.alias || [],
    redirect: security_45summitXiWbsYAbEWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitXiWbsYAbEWMeta?.name ?? "security-summit___de",
    path: security_45summitXiWbsYAbEWMeta?.path ?? "/de/security-summit",
    meta: security_45summitXiWbsYAbEWMeta || {},
    alias: security_45summitXiWbsYAbEWMeta?.alias || [],
    redirect: security_45summitXiWbsYAbEWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitXiWbsYAbEWMeta?.name ?? "security-summit___mx",
    path: security_45summitXiWbsYAbEWMeta?.path ?? "/mx/security-summit",
    meta: security_45summitXiWbsYAbEWMeta || {},
    alias: security_45summitXiWbsYAbEWMeta?.alias || [],
    redirect: security_45summitXiWbsYAbEWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitXiWbsYAbEWMeta?.name ?? "security-summit___fr",
    path: security_45summitXiWbsYAbEWMeta?.path ?? "/fr/security-summit",
    meta: security_45summitXiWbsYAbEWMeta || {},
    alias: security_45summitXiWbsYAbEWMeta?.alias || [],
    redirect: security_45summitXiWbsYAbEWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: security_45summitXiWbsYAbEWMeta?.name ?? "security-summit___fr-ca",
    path: security_45summitXiWbsYAbEWMeta?.path ?? "/fr-ca/security-summit",
    meta: security_45summitXiWbsYAbEWMeta || {},
    alias: security_45summitXiWbsYAbEWMeta?.alias || [],
    redirect: security_45summitXiWbsYAbEWMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/security-summit.vue").then(m => m.default || m)
  },
  {
    name: _91slug_933Whhx14nJAMeta?.name ?? "solutions-slug___en-US",
    path: _91slug_933Whhx14nJAMeta?.path ?? "/solutions/:slug()",
    meta: _91slug_933Whhx14nJAMeta || {},
    alias: _91slug_933Whhx14nJAMeta?.alias || [],
    redirect: _91slug_933Whhx14nJAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933Whhx14nJAMeta?.name ?? "solutions-slug___ca",
    path: _91slug_933Whhx14nJAMeta?.path ?? "/ca/solutions/:slug()",
    meta: _91slug_933Whhx14nJAMeta || {},
    alias: _91slug_933Whhx14nJAMeta?.alias || [],
    redirect: _91slug_933Whhx14nJAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933Whhx14nJAMeta?.name ?? "solutions-slug___nl",
    path: _91slug_933Whhx14nJAMeta?.path ?? "/nl/solutions/:slug()",
    meta: _91slug_933Whhx14nJAMeta || {},
    alias: _91slug_933Whhx14nJAMeta?.alias || [],
    redirect: _91slug_933Whhx14nJAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933Whhx14nJAMeta?.name ?? "solutions-slug___uk",
    path: _91slug_933Whhx14nJAMeta?.path ?? "/uk/solutions/:slug()",
    meta: _91slug_933Whhx14nJAMeta || {},
    alias: _91slug_933Whhx14nJAMeta?.alias || [],
    redirect: _91slug_933Whhx14nJAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933Whhx14nJAMeta?.name ?? "solutions-slug___de",
    path: _91slug_933Whhx14nJAMeta?.path ?? "/de/solutions/:slug()",
    meta: _91slug_933Whhx14nJAMeta || {},
    alias: _91slug_933Whhx14nJAMeta?.alias || [],
    redirect: _91slug_933Whhx14nJAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933Whhx14nJAMeta?.name ?? "solutions-slug___mx",
    path: _91slug_933Whhx14nJAMeta?.path ?? "/mx/solutions/:slug()",
    meta: _91slug_933Whhx14nJAMeta || {},
    alias: _91slug_933Whhx14nJAMeta?.alias || [],
    redirect: _91slug_933Whhx14nJAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933Whhx14nJAMeta?.name ?? "solutions-slug___fr",
    path: _91slug_933Whhx14nJAMeta?.path ?? "/fr/solutions/:slug()",
    meta: _91slug_933Whhx14nJAMeta || {},
    alias: _91slug_933Whhx14nJAMeta?.alias || [],
    redirect: _91slug_933Whhx14nJAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933Whhx14nJAMeta?.name ?? "solutions-slug___fr-ca",
    path: _91slug_933Whhx14nJAMeta?.path ?? "/fr-ca/solutions/:slug()",
    meta: _91slug_933Whhx14nJAMeta || {},
    alias: _91slug_933Whhx14nJAMeta?.alias || [],
    redirect: _91slug_933Whhx14nJAMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexPiynYGTCDVMeta?.name ?? "solutions___en-US",
    path: indexPiynYGTCDVMeta?.path ?? "/solutions",
    meta: indexPiynYGTCDVMeta || {},
    alias: indexPiynYGTCDVMeta?.alias || [],
    redirect: indexPiynYGTCDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiynYGTCDVMeta?.name ?? "solutions___ca",
    path: indexPiynYGTCDVMeta?.path ?? "/ca/solutions",
    meta: indexPiynYGTCDVMeta || {},
    alias: indexPiynYGTCDVMeta?.alias || [],
    redirect: indexPiynYGTCDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiynYGTCDVMeta?.name ?? "solutions___nl",
    path: indexPiynYGTCDVMeta?.path ?? "/nl/solutions",
    meta: indexPiynYGTCDVMeta || {},
    alias: indexPiynYGTCDVMeta?.alias || [],
    redirect: indexPiynYGTCDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiynYGTCDVMeta?.name ?? "solutions___uk",
    path: indexPiynYGTCDVMeta?.path ?? "/uk/solutions",
    meta: indexPiynYGTCDVMeta || {},
    alias: indexPiynYGTCDVMeta?.alias || [],
    redirect: indexPiynYGTCDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiynYGTCDVMeta?.name ?? "solutions___de",
    path: indexPiynYGTCDVMeta?.path ?? "/de/solutions",
    meta: indexPiynYGTCDVMeta || {},
    alias: indexPiynYGTCDVMeta?.alias || [],
    redirect: indexPiynYGTCDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiynYGTCDVMeta?.name ?? "solutions___mx",
    path: indexPiynYGTCDVMeta?.path ?? "/mx/solutions",
    meta: indexPiynYGTCDVMeta || {},
    alias: indexPiynYGTCDVMeta?.alias || [],
    redirect: indexPiynYGTCDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiynYGTCDVMeta?.name ?? "solutions___fr",
    path: indexPiynYGTCDVMeta?.path ?? "/fr/solutions",
    meta: indexPiynYGTCDVMeta || {},
    alias: indexPiynYGTCDVMeta?.alias || [],
    redirect: indexPiynYGTCDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexPiynYGTCDVMeta?.name ?? "solutions___fr-ca",
    path: indexPiynYGTCDVMeta?.path ?? "/fr-ca/solutions",
    meta: indexPiynYGTCDVMeta || {},
    alias: indexPiynYGTCDVMeta?.alias || [],
    redirect: indexPiynYGTCDVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: securityOreqRDijW6Meta?.name ?? "solutions-security___en-US",
    path: securityOreqRDijW6Meta?.path ?? "/solutions/security",
    meta: securityOreqRDijW6Meta || {},
    alias: securityOreqRDijW6Meta?.alias || [],
    redirect: securityOreqRDijW6Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityOreqRDijW6Meta?.name ?? "solutions-security___ca",
    path: securityOreqRDijW6Meta?.path ?? "/ca/solutions/security",
    meta: securityOreqRDijW6Meta || {},
    alias: securityOreqRDijW6Meta?.alias || [],
    redirect: securityOreqRDijW6Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityOreqRDijW6Meta?.name ?? "solutions-security___nl",
    path: securityOreqRDijW6Meta?.path ?? "/nl/solutions/security",
    meta: securityOreqRDijW6Meta || {},
    alias: securityOreqRDijW6Meta?.alias || [],
    redirect: securityOreqRDijW6Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityOreqRDijW6Meta?.name ?? "solutions-security___uk",
    path: securityOreqRDijW6Meta?.path ?? "/uk/solutions/security",
    meta: securityOreqRDijW6Meta || {},
    alias: securityOreqRDijW6Meta?.alias || [],
    redirect: securityOreqRDijW6Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityOreqRDijW6Meta?.name ?? "solutions-security___de",
    path: securityOreqRDijW6Meta?.path ?? "/de/solutions/security",
    meta: securityOreqRDijW6Meta || {},
    alias: securityOreqRDijW6Meta?.alias || [],
    redirect: securityOreqRDijW6Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityOreqRDijW6Meta?.name ?? "solutions-security___mx",
    path: securityOreqRDijW6Meta?.path ?? "/mx/soluciones/seguridad-patrimonial",
    meta: securityOreqRDijW6Meta || {},
    alias: securityOreqRDijW6Meta?.alias || [],
    redirect: securityOreqRDijW6Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityOreqRDijW6Meta?.name ?? "solutions-security___fr",
    path: securityOreqRDijW6Meta?.path ?? "/fr/solutions/security",
    meta: securityOreqRDijW6Meta || {},
    alias: securityOreqRDijW6Meta?.alias || [],
    redirect: securityOreqRDijW6Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: securityOreqRDijW6Meta?.name ?? "solutions-security___fr-ca",
    path: securityOreqRDijW6Meta?.path ?? "/fr-ca/solutions/security",
    meta: securityOreqRDijW6Meta || {},
    alias: securityOreqRDijW6Meta?.alias || [],
    redirect: securityOreqRDijW6Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/solutions/security.vue").then(m => m.default || m)
  },
  {
    name: spacehzw0Vn71UCMeta?.name ?? "space___en-US",
    path: spacehzw0Vn71UCMeta?.path ?? "/space",
    meta: spacehzw0Vn71UCMeta || {},
    alias: spacehzw0Vn71UCMeta?.alias || [],
    redirect: spacehzw0Vn71UCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacehzw0Vn71UCMeta?.name ?? "space___ca",
    path: spacehzw0Vn71UCMeta?.path ?? "/ca/space",
    meta: spacehzw0Vn71UCMeta || {},
    alias: spacehzw0Vn71UCMeta?.alias || [],
    redirect: spacehzw0Vn71UCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacehzw0Vn71UCMeta?.name ?? "space___nl",
    path: spacehzw0Vn71UCMeta?.path ?? "/nl/space",
    meta: spacehzw0Vn71UCMeta || {},
    alias: spacehzw0Vn71UCMeta?.alias || [],
    redirect: spacehzw0Vn71UCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacehzw0Vn71UCMeta?.name ?? "space___uk",
    path: spacehzw0Vn71UCMeta?.path ?? "/uk/space",
    meta: spacehzw0Vn71UCMeta || {},
    alias: spacehzw0Vn71UCMeta?.alias || [],
    redirect: spacehzw0Vn71UCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacehzw0Vn71UCMeta?.name ?? "space___de",
    path: spacehzw0Vn71UCMeta?.path ?? "/de/space",
    meta: spacehzw0Vn71UCMeta || {},
    alias: spacehzw0Vn71UCMeta?.alias || [],
    redirect: spacehzw0Vn71UCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacehzw0Vn71UCMeta?.name ?? "space___mx",
    path: spacehzw0Vn71UCMeta?.path ?? "/mx/space",
    meta: spacehzw0Vn71UCMeta || {},
    alias: spacehzw0Vn71UCMeta?.alias || [],
    redirect: spacehzw0Vn71UCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacehzw0Vn71UCMeta?.name ?? "space___fr",
    path: spacehzw0Vn71UCMeta?.path ?? "/fr/space",
    meta: spacehzw0Vn71UCMeta || {},
    alias: spacehzw0Vn71UCMeta?.alias || [],
    redirect: spacehzw0Vn71UCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spacehzw0Vn71UCMeta?.name ?? "space___fr-ca",
    path: spacehzw0Vn71UCMeta?.path ?? "/fr-ca/space",
    meta: spacehzw0Vn71UCMeta || {},
    alias: spacehzw0Vn71UCMeta?.alias || [],
    redirect: spacehzw0Vn71UCMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/space.vue").then(m => m.default || m)
  },
  {
    name: spark1Wko5nXLaUMeta?.name ?? "spark___en-US",
    path: spark1Wko5nXLaUMeta?.path ?? "/spark",
    meta: spark1Wko5nXLaUMeta || {},
    alias: spark1Wko5nXLaUMeta?.alias || [],
    redirect: spark1Wko5nXLaUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark1Wko5nXLaUMeta?.name ?? "spark___ca",
    path: spark1Wko5nXLaUMeta?.path ?? "/ca/spark",
    meta: spark1Wko5nXLaUMeta || {},
    alias: spark1Wko5nXLaUMeta?.alias || [],
    redirect: spark1Wko5nXLaUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark1Wko5nXLaUMeta?.name ?? "spark___nl",
    path: spark1Wko5nXLaUMeta?.path ?? "/nl/spark",
    meta: spark1Wko5nXLaUMeta || {},
    alias: spark1Wko5nXLaUMeta?.alias || [],
    redirect: spark1Wko5nXLaUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark1Wko5nXLaUMeta?.name ?? "spark___uk",
    path: spark1Wko5nXLaUMeta?.path ?? "/uk/spark",
    meta: spark1Wko5nXLaUMeta || {},
    alias: spark1Wko5nXLaUMeta?.alias || [],
    redirect: spark1Wko5nXLaUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark1Wko5nXLaUMeta?.name ?? "spark___de",
    path: spark1Wko5nXLaUMeta?.path ?? "/de/spark",
    meta: spark1Wko5nXLaUMeta || {},
    alias: spark1Wko5nXLaUMeta?.alias || [],
    redirect: spark1Wko5nXLaUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark1Wko5nXLaUMeta?.name ?? "spark___mx",
    path: spark1Wko5nXLaUMeta?.path ?? "/mx/spark",
    meta: spark1Wko5nXLaUMeta || {},
    alias: spark1Wko5nXLaUMeta?.alias || [],
    redirect: spark1Wko5nXLaUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark1Wko5nXLaUMeta?.name ?? "spark___fr",
    path: spark1Wko5nXLaUMeta?.path ?? "/fr/spark",
    meta: spark1Wko5nXLaUMeta || {},
    alias: spark1Wko5nXLaUMeta?.alias || [],
    redirect: spark1Wko5nXLaUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: spark1Wko5nXLaUMeta?.name ?? "spark___fr-ca",
    path: spark1Wko5nXLaUMeta?.path ?? "/fr-ca/spark",
    meta: spark1Wko5nXLaUMeta || {},
    alias: spark1Wko5nXLaUMeta?.alias || [],
    redirect: spark1Wko5nXLaUMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/spark.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VeS4OweZF1Meta?.name ?? "styles-slug___en-US",
    path: _91slug_93VeS4OweZF1Meta?.path ?? "/styles/:slug()",
    meta: _91slug_93VeS4OweZF1Meta || {},
    alias: _91slug_93VeS4OweZF1Meta?.alias || [],
    redirect: _91slug_93VeS4OweZF1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VeS4OweZF1Meta?.name ?? "styles-slug___ca",
    path: _91slug_93VeS4OweZF1Meta?.path ?? "/ca/styles/:slug()",
    meta: _91slug_93VeS4OweZF1Meta || {},
    alias: _91slug_93VeS4OweZF1Meta?.alias || [],
    redirect: _91slug_93VeS4OweZF1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VeS4OweZF1Meta?.name ?? "styles-slug___nl",
    path: _91slug_93VeS4OweZF1Meta?.path ?? "/nl/styles/:slug()",
    meta: _91slug_93VeS4OweZF1Meta || {},
    alias: _91slug_93VeS4OweZF1Meta?.alias || [],
    redirect: _91slug_93VeS4OweZF1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VeS4OweZF1Meta?.name ?? "styles-slug___uk",
    path: _91slug_93VeS4OweZF1Meta?.path ?? "/uk/styles/:slug()",
    meta: _91slug_93VeS4OweZF1Meta || {},
    alias: _91slug_93VeS4OweZF1Meta?.alias || [],
    redirect: _91slug_93VeS4OweZF1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VeS4OweZF1Meta?.name ?? "styles-slug___de",
    path: _91slug_93VeS4OweZF1Meta?.path ?? "/de/styles/:slug()",
    meta: _91slug_93VeS4OweZF1Meta || {},
    alias: _91slug_93VeS4OweZF1Meta?.alias || [],
    redirect: _91slug_93VeS4OweZF1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VeS4OweZF1Meta?.name ?? "styles-slug___mx",
    path: _91slug_93VeS4OweZF1Meta?.path ?? "/mx/styles/:slug()",
    meta: _91slug_93VeS4OweZF1Meta || {},
    alias: _91slug_93VeS4OweZF1Meta?.alias || [],
    redirect: _91slug_93VeS4OweZF1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VeS4OweZF1Meta?.name ?? "styles-slug___fr",
    path: _91slug_93VeS4OweZF1Meta?.path ?? "/fr/styles/:slug()",
    meta: _91slug_93VeS4OweZF1Meta || {},
    alias: _91slug_93VeS4OweZF1Meta?.alias || [],
    redirect: _91slug_93VeS4OweZF1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VeS4OweZF1Meta?.name ?? "styles-slug___fr-ca",
    path: _91slug_93VeS4OweZF1Meta?.path ?? "/fr-ca/styles/:slug()",
    meta: _91slug_93VeS4OweZF1Meta || {},
    alias: _91slug_93VeS4OweZF1Meta?.alias || [],
    redirect: _91slug_93VeS4OweZF1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/styles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SfQGVby4qeMeta?.name ?? "support-prefix-slug___en-US",
    path: _91slug_93SfQGVby4qeMeta?.path ?? "/support/:prefix()/:slug()",
    meta: _91slug_93SfQGVby4qeMeta || {},
    alias: _91slug_93SfQGVby4qeMeta?.alias || [],
    redirect: _91slug_93SfQGVby4qeMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SfQGVby4qeMeta?.name ?? "support-prefix-slug___ca",
    path: _91slug_93SfQGVby4qeMeta?.path ?? "/ca/support/:prefix()/:slug()",
    meta: _91slug_93SfQGVby4qeMeta || {},
    alias: _91slug_93SfQGVby4qeMeta?.alias || [],
    redirect: _91slug_93SfQGVby4qeMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SfQGVby4qeMeta?.name ?? "support-prefix-slug___nl",
    path: _91slug_93SfQGVby4qeMeta?.path ?? "/nl/support/:prefix()/:slug()",
    meta: _91slug_93SfQGVby4qeMeta || {},
    alias: _91slug_93SfQGVby4qeMeta?.alias || [],
    redirect: _91slug_93SfQGVby4qeMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SfQGVby4qeMeta?.name ?? "support-prefix-slug___uk",
    path: _91slug_93SfQGVby4qeMeta?.path ?? "/uk/support/:prefix()/:slug()",
    meta: _91slug_93SfQGVby4qeMeta || {},
    alias: _91slug_93SfQGVby4qeMeta?.alias || [],
    redirect: _91slug_93SfQGVby4qeMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SfQGVby4qeMeta?.name ?? "support-prefix-slug___de",
    path: _91slug_93SfQGVby4qeMeta?.path ?? "/de/support/:prefix()/:slug()",
    meta: _91slug_93SfQGVby4qeMeta || {},
    alias: _91slug_93SfQGVby4qeMeta?.alias || [],
    redirect: _91slug_93SfQGVby4qeMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SfQGVby4qeMeta?.name ?? "support-prefix-slug___mx",
    path: _91slug_93SfQGVby4qeMeta?.path ?? "/mx/support/:prefix()/:slug()",
    meta: _91slug_93SfQGVby4qeMeta || {},
    alias: _91slug_93SfQGVby4qeMeta?.alias || [],
    redirect: _91slug_93SfQGVby4qeMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SfQGVby4qeMeta?.name ?? "support-prefix-slug___fr",
    path: _91slug_93SfQGVby4qeMeta?.path ?? "/fr/support/:prefix()/:slug()",
    meta: _91slug_93SfQGVby4qeMeta || {},
    alias: _91slug_93SfQGVby4qeMeta?.alias || [],
    redirect: _91slug_93SfQGVby4qeMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SfQGVby4qeMeta?.name ?? "support-prefix-slug___fr-ca",
    path: _91slug_93SfQGVby4qeMeta?.path ?? "/fr-ca/support/:prefix()/:slug()",
    meta: _91slug_93SfQGVby4qeMeta || {},
    alias: _91slug_93SfQGVby4qeMeta?.alias || [],
    redirect: _91slug_93SfQGVby4qeMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[prefix]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933DgPUltuFEMeta?.name ?? "support-slug___en-US",
    path: _91slug_933DgPUltuFEMeta?.path ?? "/support/:slug()",
    meta: _91slug_933DgPUltuFEMeta || {},
    alias: _91slug_933DgPUltuFEMeta?.alias || [],
    redirect: _91slug_933DgPUltuFEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933DgPUltuFEMeta?.name ?? "support-slug___ca",
    path: _91slug_933DgPUltuFEMeta?.path ?? "/ca/support/:slug()",
    meta: _91slug_933DgPUltuFEMeta || {},
    alias: _91slug_933DgPUltuFEMeta?.alias || [],
    redirect: _91slug_933DgPUltuFEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933DgPUltuFEMeta?.name ?? "support-slug___nl",
    path: _91slug_933DgPUltuFEMeta?.path ?? "/nl/support/:slug()",
    meta: _91slug_933DgPUltuFEMeta || {},
    alias: _91slug_933DgPUltuFEMeta?.alias || [],
    redirect: _91slug_933DgPUltuFEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933DgPUltuFEMeta?.name ?? "support-slug___uk",
    path: _91slug_933DgPUltuFEMeta?.path ?? "/uk/support/:slug()",
    meta: _91slug_933DgPUltuFEMeta || {},
    alias: _91slug_933DgPUltuFEMeta?.alias || [],
    redirect: _91slug_933DgPUltuFEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933DgPUltuFEMeta?.name ?? "support-slug___de",
    path: _91slug_933DgPUltuFEMeta?.path ?? "/de/support/:slug()",
    meta: _91slug_933DgPUltuFEMeta || {},
    alias: _91slug_933DgPUltuFEMeta?.alias || [],
    redirect: _91slug_933DgPUltuFEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933DgPUltuFEMeta?.name ?? "support-slug___mx",
    path: _91slug_933DgPUltuFEMeta?.path ?? "/mx/support/:slug()",
    meta: _91slug_933DgPUltuFEMeta || {},
    alias: _91slug_933DgPUltuFEMeta?.alias || [],
    redirect: _91slug_933DgPUltuFEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933DgPUltuFEMeta?.name ?? "support-slug___fr",
    path: _91slug_933DgPUltuFEMeta?.path ?? "/fr/support/:slug()",
    meta: _91slug_933DgPUltuFEMeta || {},
    alias: _91slug_933DgPUltuFEMeta?.alias || [],
    redirect: _91slug_933DgPUltuFEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_933DgPUltuFEMeta?.name ?? "support-slug___fr-ca",
    path: _91slug_933DgPUltuFEMeta?.path ?? "/fr-ca/support/:slug()",
    meta: _91slug_933DgPUltuFEMeta || {},
    alias: _91slug_933DgPUltuFEMeta?.alias || [],
    redirect: _91slug_933DgPUltuFEMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/[slug].vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLTMoK14AUdMeta?.name ?? "support-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyLTMoK14AUdMeta?.path ?? "/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLTMoK14AUdMeta || {},
    alias: apps_45privacy_45policyLTMoK14AUdMeta?.alias || [],
    redirect: apps_45privacy_45policyLTMoK14AUdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLTMoK14AUdMeta?.name ?? "support-apps-privacy-policy___ca",
    path: apps_45privacy_45policyLTMoK14AUdMeta?.path ?? "/ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLTMoK14AUdMeta || {},
    alias: apps_45privacy_45policyLTMoK14AUdMeta?.alias || [],
    redirect: apps_45privacy_45policyLTMoK14AUdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLTMoK14AUdMeta?.name ?? "support-apps-privacy-policy___nl",
    path: apps_45privacy_45policyLTMoK14AUdMeta?.path ?? "/nl/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLTMoK14AUdMeta || {},
    alias: apps_45privacy_45policyLTMoK14AUdMeta?.alias || [],
    redirect: apps_45privacy_45policyLTMoK14AUdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLTMoK14AUdMeta?.name ?? "support-apps-privacy-policy___uk",
    path: apps_45privacy_45policyLTMoK14AUdMeta?.path ?? "/uk/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLTMoK14AUdMeta || {},
    alias: apps_45privacy_45policyLTMoK14AUdMeta?.alias || [],
    redirect: apps_45privacy_45policyLTMoK14AUdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLTMoK14AUdMeta?.name ?? "support-apps-privacy-policy___de",
    path: apps_45privacy_45policyLTMoK14AUdMeta?.path ?? "/de/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLTMoK14AUdMeta || {},
    alias: apps_45privacy_45policyLTMoK14AUdMeta?.alias || [],
    redirect: apps_45privacy_45policyLTMoK14AUdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLTMoK14AUdMeta?.name ?? "support-apps-privacy-policy___mx",
    path: apps_45privacy_45policyLTMoK14AUdMeta?.path ?? "/mx/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLTMoK14AUdMeta || {},
    alias: apps_45privacy_45policyLTMoK14AUdMeta?.alias || [],
    redirect: apps_45privacy_45policyLTMoK14AUdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLTMoK14AUdMeta?.name ?? "support-apps-privacy-policy___fr",
    path: apps_45privacy_45policyLTMoK14AUdMeta?.path ?? "/fr/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLTMoK14AUdMeta || {},
    alias: apps_45privacy_45policyLTMoK14AUdMeta?.alias || [],
    redirect: apps_45privacy_45policyLTMoK14AUdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyLTMoK14AUdMeta?.name ?? "support-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyLTMoK14AUdMeta?.path ?? "/fr-ca/support/apps-privacy-policy",
    meta: apps_45privacy_45policyLTMoK14AUdMeta || {},
    alias: apps_45privacy_45policyLTMoK14AUdMeta?.alias || [],
    redirect: apps_45privacy_45policyLTMoK14AUdMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqGAZfV2aSUaMeta?.name ?? "support-customer-referrals-faq___en-US",
    path: customer_45referrals_45faqGAZfV2aSUaMeta?.path ?? "/support/customer-referrals-faq",
    meta: customer_45referrals_45faqGAZfV2aSUaMeta || {},
    alias: customer_45referrals_45faqGAZfV2aSUaMeta?.alias || [],
    redirect: customer_45referrals_45faqGAZfV2aSUaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqGAZfV2aSUaMeta?.name ?? "support-customer-referrals-faq___ca",
    path: customer_45referrals_45faqGAZfV2aSUaMeta?.path ?? "/ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqGAZfV2aSUaMeta || {},
    alias: customer_45referrals_45faqGAZfV2aSUaMeta?.alias || [],
    redirect: customer_45referrals_45faqGAZfV2aSUaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqGAZfV2aSUaMeta?.name ?? "support-customer-referrals-faq___nl",
    path: customer_45referrals_45faqGAZfV2aSUaMeta?.path ?? "/nl/support/customer-referrals-faq",
    meta: customer_45referrals_45faqGAZfV2aSUaMeta || {},
    alias: customer_45referrals_45faqGAZfV2aSUaMeta?.alias || [],
    redirect: customer_45referrals_45faqGAZfV2aSUaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqGAZfV2aSUaMeta?.name ?? "support-customer-referrals-faq___uk",
    path: customer_45referrals_45faqGAZfV2aSUaMeta?.path ?? "/uk/support/customer-referrals-faq",
    meta: customer_45referrals_45faqGAZfV2aSUaMeta || {},
    alias: customer_45referrals_45faqGAZfV2aSUaMeta?.alias || [],
    redirect: customer_45referrals_45faqGAZfV2aSUaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqGAZfV2aSUaMeta?.name ?? "support-customer-referrals-faq___de",
    path: customer_45referrals_45faqGAZfV2aSUaMeta?.path ?? "/de/support/customer-referrals-faq",
    meta: customer_45referrals_45faqGAZfV2aSUaMeta || {},
    alias: customer_45referrals_45faqGAZfV2aSUaMeta?.alias || [],
    redirect: customer_45referrals_45faqGAZfV2aSUaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqGAZfV2aSUaMeta?.name ?? "support-customer-referrals-faq___mx",
    path: customer_45referrals_45faqGAZfV2aSUaMeta?.path ?? "/mx/support/customer-referrals-faq",
    meta: customer_45referrals_45faqGAZfV2aSUaMeta || {},
    alias: customer_45referrals_45faqGAZfV2aSUaMeta?.alias || [],
    redirect: customer_45referrals_45faqGAZfV2aSUaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqGAZfV2aSUaMeta?.name ?? "support-customer-referrals-faq___fr",
    path: customer_45referrals_45faqGAZfV2aSUaMeta?.path ?? "/fr/support/customer-referrals-faq",
    meta: customer_45referrals_45faqGAZfV2aSUaMeta || {},
    alias: customer_45referrals_45faqGAZfV2aSUaMeta?.alias || [],
    redirect: customer_45referrals_45faqGAZfV2aSUaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: customer_45referrals_45faqGAZfV2aSUaMeta?.name ?? "support-customer-referrals-faq___fr-ca",
    path: customer_45referrals_45faqGAZfV2aSUaMeta?.path ?? "/fr-ca/support/customer-referrals-faq",
    meta: customer_45referrals_45faqGAZfV2aSUaMeta || {},
    alias: customer_45referrals_45faqGAZfV2aSUaMeta?.alias || [],
    redirect: customer_45referrals_45faqGAZfV2aSUaMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/customer-referrals-faq.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishcUvcFhFD7mMeta?.name ?? "support-driver-training-spanish___en-US",
    path: driver_45training_45spanishcUvcFhFD7mMeta?.path ?? "/support/driver-training-spanish",
    meta: driver_45training_45spanishcUvcFhFD7mMeta || {},
    alias: driver_45training_45spanishcUvcFhFD7mMeta?.alias || [],
    redirect: driver_45training_45spanishcUvcFhFD7mMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishcUvcFhFD7mMeta?.name ?? "support-driver-training-spanish___ca",
    path: driver_45training_45spanishcUvcFhFD7mMeta?.path ?? "/ca/support/driver-training-spanish",
    meta: driver_45training_45spanishcUvcFhFD7mMeta || {},
    alias: driver_45training_45spanishcUvcFhFD7mMeta?.alias || [],
    redirect: driver_45training_45spanishcUvcFhFD7mMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishcUvcFhFD7mMeta?.name ?? "support-driver-training-spanish___nl",
    path: driver_45training_45spanishcUvcFhFD7mMeta?.path ?? "/nl/support/driver-training-spanish",
    meta: driver_45training_45spanishcUvcFhFD7mMeta || {},
    alias: driver_45training_45spanishcUvcFhFD7mMeta?.alias || [],
    redirect: driver_45training_45spanishcUvcFhFD7mMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishcUvcFhFD7mMeta?.name ?? "support-driver-training-spanish___uk",
    path: driver_45training_45spanishcUvcFhFD7mMeta?.path ?? "/uk/support/driver-training-spanish",
    meta: driver_45training_45spanishcUvcFhFD7mMeta || {},
    alias: driver_45training_45spanishcUvcFhFD7mMeta?.alias || [],
    redirect: driver_45training_45spanishcUvcFhFD7mMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishcUvcFhFD7mMeta?.name ?? "support-driver-training-spanish___de",
    path: driver_45training_45spanishcUvcFhFD7mMeta?.path ?? "/de/support/driver-training-spanish",
    meta: driver_45training_45spanishcUvcFhFD7mMeta || {},
    alias: driver_45training_45spanishcUvcFhFD7mMeta?.alias || [],
    redirect: driver_45training_45spanishcUvcFhFD7mMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishcUvcFhFD7mMeta?.name ?? "support-driver-training-spanish___mx",
    path: driver_45training_45spanishcUvcFhFD7mMeta?.path ?? "/mx/support/driver-training-spanish",
    meta: driver_45training_45spanishcUvcFhFD7mMeta || {},
    alias: driver_45training_45spanishcUvcFhFD7mMeta?.alias || [],
    redirect: driver_45training_45spanishcUvcFhFD7mMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishcUvcFhFD7mMeta?.name ?? "support-driver-training-spanish___fr",
    path: driver_45training_45spanishcUvcFhFD7mMeta?.path ?? "/fr/support/driver-training-spanish",
    meta: driver_45training_45spanishcUvcFhFD7mMeta || {},
    alias: driver_45training_45spanishcUvcFhFD7mMeta?.alias || [],
    redirect: driver_45training_45spanishcUvcFhFD7mMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45training_45spanishcUvcFhFD7mMeta?.name ?? "support-driver-training-spanish___fr-ca",
    path: driver_45training_45spanishcUvcFhFD7mMeta?.path ?? "/fr-ca/support/driver-training-spanish",
    meta: driver_45training_45spanishcUvcFhFD7mMeta || {},
    alias: driver_45training_45spanishcUvcFhFD7mMeta?.alias || [],
    redirect: driver_45training_45spanishcUvcFhFD7mMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training-spanish.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingpWLuawigwBMeta?.name ?? "support-driver-training___en-US",
    path: driver_45trainingpWLuawigwBMeta?.path ?? "/support/driver-training",
    meta: driver_45trainingpWLuawigwBMeta || {},
    alias: driver_45trainingpWLuawigwBMeta?.alias || [],
    redirect: driver_45trainingpWLuawigwBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingpWLuawigwBMeta?.name ?? "support-driver-training___ca",
    path: driver_45trainingpWLuawigwBMeta?.path ?? "/ca/support/driver-training",
    meta: driver_45trainingpWLuawigwBMeta || {},
    alias: driver_45trainingpWLuawigwBMeta?.alias || [],
    redirect: driver_45trainingpWLuawigwBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingpWLuawigwBMeta?.name ?? "support-driver-training___nl",
    path: driver_45trainingpWLuawigwBMeta?.path ?? "/nl/support/driver-training",
    meta: driver_45trainingpWLuawigwBMeta || {},
    alias: driver_45trainingpWLuawigwBMeta?.alias || [],
    redirect: driver_45trainingpWLuawigwBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingpWLuawigwBMeta?.name ?? "support-driver-training___uk",
    path: driver_45trainingpWLuawigwBMeta?.path ?? "/uk/support/driver-training",
    meta: driver_45trainingpWLuawigwBMeta || {},
    alias: driver_45trainingpWLuawigwBMeta?.alias || [],
    redirect: driver_45trainingpWLuawigwBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingpWLuawigwBMeta?.name ?? "support-driver-training___de",
    path: driver_45trainingpWLuawigwBMeta?.path ?? "/de/support/driver-training",
    meta: driver_45trainingpWLuawigwBMeta || {},
    alias: driver_45trainingpWLuawigwBMeta?.alias || [],
    redirect: driver_45trainingpWLuawigwBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingpWLuawigwBMeta?.name ?? "support-driver-training___mx",
    path: driver_45trainingpWLuawigwBMeta?.path ?? "/mx/support/driver-training",
    meta: driver_45trainingpWLuawigwBMeta || {},
    alias: driver_45trainingpWLuawigwBMeta?.alias || [],
    redirect: driver_45trainingpWLuawigwBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingpWLuawigwBMeta?.name ?? "support-driver-training___fr",
    path: driver_45trainingpWLuawigwBMeta?.path ?? "/fr/support/driver-training",
    meta: driver_45trainingpWLuawigwBMeta || {},
    alias: driver_45trainingpWLuawigwBMeta?.alias || [],
    redirect: driver_45trainingpWLuawigwBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: driver_45trainingpWLuawigwBMeta?.name ?? "support-driver-training___fr-ca",
    path: driver_45trainingpWLuawigwBMeta?.path ?? "/fr-ca/support/driver-training",
    meta: driver_45trainingpWLuawigwBMeta || {},
    alias: driver_45trainingpWLuawigwBMeta?.alias || [],
    redirect: driver_45trainingpWLuawigwBMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/driver-training.vue").then(m => m.default || m)
  },
  {
    name: indexrT9mqcUqGOMeta?.name ?? "support___en-US",
    path: indexrT9mqcUqGOMeta?.path ?? "/support",
    meta: indexrT9mqcUqGOMeta || {},
    alias: indexrT9mqcUqGOMeta?.alias || [],
    redirect: indexrT9mqcUqGOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexrT9mqcUqGOMeta?.name ?? "support___ca",
    path: indexrT9mqcUqGOMeta?.path ?? "/ca/support",
    meta: indexrT9mqcUqGOMeta || {},
    alias: indexrT9mqcUqGOMeta?.alias || [],
    redirect: indexrT9mqcUqGOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexrT9mqcUqGOMeta?.name ?? "support___nl",
    path: indexrT9mqcUqGOMeta?.path ?? "/nl/support",
    meta: indexrT9mqcUqGOMeta || {},
    alias: indexrT9mqcUqGOMeta?.alias || [],
    redirect: indexrT9mqcUqGOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexrT9mqcUqGOMeta?.name ?? "support___uk",
    path: indexrT9mqcUqGOMeta?.path ?? "/uk/support",
    meta: indexrT9mqcUqGOMeta || {},
    alias: indexrT9mqcUqGOMeta?.alias || [],
    redirect: indexrT9mqcUqGOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexrT9mqcUqGOMeta?.name ?? "support___de",
    path: indexrT9mqcUqGOMeta?.path ?? "/de/support",
    meta: indexrT9mqcUqGOMeta || {},
    alias: indexrT9mqcUqGOMeta?.alias || [],
    redirect: indexrT9mqcUqGOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexrT9mqcUqGOMeta?.name ?? "support___mx",
    path: indexrT9mqcUqGOMeta?.path ?? "/mx/support",
    meta: indexrT9mqcUqGOMeta || {},
    alias: indexrT9mqcUqGOMeta?.alias || [],
    redirect: indexrT9mqcUqGOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexrT9mqcUqGOMeta?.name ?? "support___fr",
    path: indexrT9mqcUqGOMeta?.path ?? "/fr/support",
    meta: indexrT9mqcUqGOMeta || {},
    alias: indexrT9mqcUqGOMeta?.alias || [],
    redirect: indexrT9mqcUqGOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: indexrT9mqcUqGOMeta?.name ?? "support___fr-ca",
    path: indexrT9mqcUqGOMeta?.path ?? "/fr-ca/support",
    meta: indexrT9mqcUqGOMeta || {},
    alias: indexrT9mqcUqGOMeta?.alias || [],
    redirect: indexrT9mqcUqGOMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: industrialUf11sOnH61Meta?.name ?? "support-industrial___en-US",
    path: industrialUf11sOnH61Meta?.path ?? "/support/industrial",
    meta: industrialUf11sOnH61Meta || {},
    alias: industrialUf11sOnH61Meta?.alias || [],
    redirect: industrialUf11sOnH61Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUf11sOnH61Meta?.name ?? "support-industrial___ca",
    path: industrialUf11sOnH61Meta?.path ?? "/ca/support/industrial",
    meta: industrialUf11sOnH61Meta || {},
    alias: industrialUf11sOnH61Meta?.alias || [],
    redirect: industrialUf11sOnH61Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUf11sOnH61Meta?.name ?? "support-industrial___nl",
    path: industrialUf11sOnH61Meta?.path ?? "/nl/support/industrial",
    meta: industrialUf11sOnH61Meta || {},
    alias: industrialUf11sOnH61Meta?.alias || [],
    redirect: industrialUf11sOnH61Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUf11sOnH61Meta?.name ?? "support-industrial___uk",
    path: industrialUf11sOnH61Meta?.path ?? "/uk/support/industrial",
    meta: industrialUf11sOnH61Meta || {},
    alias: industrialUf11sOnH61Meta?.alias || [],
    redirect: industrialUf11sOnH61Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUf11sOnH61Meta?.name ?? "support-industrial___de",
    path: industrialUf11sOnH61Meta?.path ?? "/de/support/industrial",
    meta: industrialUf11sOnH61Meta || {},
    alias: industrialUf11sOnH61Meta?.alias || [],
    redirect: industrialUf11sOnH61Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUf11sOnH61Meta?.name ?? "support-industrial___mx",
    path: industrialUf11sOnH61Meta?.path ?? "/mx/support/industrial",
    meta: industrialUf11sOnH61Meta || {},
    alias: industrialUf11sOnH61Meta?.alias || [],
    redirect: industrialUf11sOnH61Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUf11sOnH61Meta?.name ?? "support-industrial___fr",
    path: industrialUf11sOnH61Meta?.path ?? "/fr/support/industrial",
    meta: industrialUf11sOnH61Meta || {},
    alias: industrialUf11sOnH61Meta?.alias || [],
    redirect: industrialUf11sOnH61Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: industrialUf11sOnH61Meta?.name ?? "support-industrial___fr-ca",
    path: industrialUf11sOnH61Meta?.path ?? "/fr-ca/support/industrial",
    meta: industrialUf11sOnH61Meta || {},
    alias: industrialUf11sOnH61Meta?.alias || [],
    redirect: industrialUf11sOnH61Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/industrial.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement2dPOiYbyC4Meta?.name ?? "support-modern-slavery-statement___en-US",
    path: modern_45slavery_45statement2dPOiYbyC4Meta?.path ?? "/support/modern-slavery-statement",
    meta: modern_45slavery_45statement2dPOiYbyC4Meta || {},
    alias: modern_45slavery_45statement2dPOiYbyC4Meta?.alias || [],
    redirect: modern_45slavery_45statement2dPOiYbyC4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement2dPOiYbyC4Meta?.name ?? "support-modern-slavery-statement___ca",
    path: modern_45slavery_45statement2dPOiYbyC4Meta?.path ?? "/ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statement2dPOiYbyC4Meta || {},
    alias: modern_45slavery_45statement2dPOiYbyC4Meta?.alias || [],
    redirect: modern_45slavery_45statement2dPOiYbyC4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement2dPOiYbyC4Meta?.name ?? "support-modern-slavery-statement___nl",
    path: modern_45slavery_45statement2dPOiYbyC4Meta?.path ?? "/nl/support/modern-slavery-statement",
    meta: modern_45slavery_45statement2dPOiYbyC4Meta || {},
    alias: modern_45slavery_45statement2dPOiYbyC4Meta?.alias || [],
    redirect: modern_45slavery_45statement2dPOiYbyC4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement2dPOiYbyC4Meta?.name ?? "support-modern-slavery-statement___uk",
    path: modern_45slavery_45statement2dPOiYbyC4Meta?.path ?? "/uk/support/modern-slavery-statement",
    meta: modern_45slavery_45statement2dPOiYbyC4Meta || {},
    alias: modern_45slavery_45statement2dPOiYbyC4Meta?.alias || [],
    redirect: modern_45slavery_45statement2dPOiYbyC4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement2dPOiYbyC4Meta?.name ?? "support-modern-slavery-statement___de",
    path: modern_45slavery_45statement2dPOiYbyC4Meta?.path ?? "/de/support/modern-slavery-statement",
    meta: modern_45slavery_45statement2dPOiYbyC4Meta || {},
    alias: modern_45slavery_45statement2dPOiYbyC4Meta?.alias || [],
    redirect: modern_45slavery_45statement2dPOiYbyC4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement2dPOiYbyC4Meta?.name ?? "support-modern-slavery-statement___mx",
    path: modern_45slavery_45statement2dPOiYbyC4Meta?.path ?? "/mx/support/modern-slavery-statement",
    meta: modern_45slavery_45statement2dPOiYbyC4Meta || {},
    alias: modern_45slavery_45statement2dPOiYbyC4Meta?.alias || [],
    redirect: modern_45slavery_45statement2dPOiYbyC4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement2dPOiYbyC4Meta?.name ?? "support-modern-slavery-statement___fr",
    path: modern_45slavery_45statement2dPOiYbyC4Meta?.path ?? "/fr/support/modern-slavery-statement",
    meta: modern_45slavery_45statement2dPOiYbyC4Meta || {},
    alias: modern_45slavery_45statement2dPOiYbyC4Meta?.alias || [],
    redirect: modern_45slavery_45statement2dPOiYbyC4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: modern_45slavery_45statement2dPOiYbyC4Meta?.name ?? "support-modern-slavery-statement___fr-ca",
    path: modern_45slavery_45statement2dPOiYbyC4Meta?.path ?? "/fr-ca/support/modern-slavery-statement",
    meta: modern_45slavery_45statement2dPOiYbyC4Meta || {},
    alias: modern_45slavery_45statement2dPOiYbyC4Meta?.alias || [],
    redirect: modern_45slavery_45statement2dPOiYbyC4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/modern-slavery-statement.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyrokOnuwc5nMeta?.name ?? "support-privacy-apps-privacy-policy___en-US",
    path: apps_45privacy_45policyrokOnuwc5nMeta?.path ?? "/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyrokOnuwc5nMeta || {},
    alias: apps_45privacy_45policyrokOnuwc5nMeta?.alias || [],
    redirect: apps_45privacy_45policyrokOnuwc5nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyrokOnuwc5nMeta?.name ?? "support-privacy-apps-privacy-policy___ca",
    path: apps_45privacy_45policyrokOnuwc5nMeta?.path ?? "/ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyrokOnuwc5nMeta || {},
    alias: apps_45privacy_45policyrokOnuwc5nMeta?.alias || [],
    redirect: apps_45privacy_45policyrokOnuwc5nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyrokOnuwc5nMeta?.name ?? "support-privacy-apps-privacy-policy___nl",
    path: apps_45privacy_45policyrokOnuwc5nMeta?.path ?? "/nl/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyrokOnuwc5nMeta || {},
    alias: apps_45privacy_45policyrokOnuwc5nMeta?.alias || [],
    redirect: apps_45privacy_45policyrokOnuwc5nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyrokOnuwc5nMeta?.name ?? "support-privacy-apps-privacy-policy___uk",
    path: apps_45privacy_45policyrokOnuwc5nMeta?.path ?? "/uk/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyrokOnuwc5nMeta || {},
    alias: apps_45privacy_45policyrokOnuwc5nMeta?.alias || [],
    redirect: apps_45privacy_45policyrokOnuwc5nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyrokOnuwc5nMeta?.name ?? "support-privacy-apps-privacy-policy___de",
    path: apps_45privacy_45policyrokOnuwc5nMeta?.path ?? "/de/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyrokOnuwc5nMeta || {},
    alias: apps_45privacy_45policyrokOnuwc5nMeta?.alias || [],
    redirect: apps_45privacy_45policyrokOnuwc5nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyrokOnuwc5nMeta?.name ?? "support-privacy-apps-privacy-policy___mx",
    path: apps_45privacy_45policyrokOnuwc5nMeta?.path ?? "/mx/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyrokOnuwc5nMeta || {},
    alias: apps_45privacy_45policyrokOnuwc5nMeta?.alias || [],
    redirect: apps_45privacy_45policyrokOnuwc5nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyrokOnuwc5nMeta?.name ?? "support-privacy-apps-privacy-policy___fr",
    path: apps_45privacy_45policyrokOnuwc5nMeta?.path ?? "/fr/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyrokOnuwc5nMeta || {},
    alias: apps_45privacy_45policyrokOnuwc5nMeta?.alias || [],
    redirect: apps_45privacy_45policyrokOnuwc5nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: apps_45privacy_45policyrokOnuwc5nMeta?.name ?? "support-privacy-apps-privacy-policy___fr-ca",
    path: apps_45privacy_45policyrokOnuwc5nMeta?.path ?? "/fr-ca/support/privacy/apps-privacy-policy",
    meta: apps_45privacy_45policyrokOnuwc5nMeta || {},
    alias: apps_45privacy_45policyrokOnuwc5nMeta?.alias || [],
    redirect: apps_45privacy_45policyrokOnuwc5nMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/apps-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: indexm3pUALtPjVMeta?.name ?? "support-privacy___en-US",
    path: indexm3pUALtPjVMeta?.path ?? "/support/privacy",
    meta: indexm3pUALtPjVMeta || {},
    alias: indexm3pUALtPjVMeta?.alias || [],
    redirect: indexm3pUALtPjVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexm3pUALtPjVMeta?.name ?? "support-privacy___ca",
    path: indexm3pUALtPjVMeta?.path ?? "/ca/support/privacy",
    meta: indexm3pUALtPjVMeta || {},
    alias: indexm3pUALtPjVMeta?.alias || [],
    redirect: indexm3pUALtPjVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexm3pUALtPjVMeta?.name ?? "support-privacy___nl",
    path: indexm3pUALtPjVMeta?.path ?? "/nl/support/privacy",
    meta: indexm3pUALtPjVMeta || {},
    alias: indexm3pUALtPjVMeta?.alias || [],
    redirect: indexm3pUALtPjVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexm3pUALtPjVMeta?.name ?? "support-privacy___uk",
    path: indexm3pUALtPjVMeta?.path ?? "/uk/support/privacy",
    meta: indexm3pUALtPjVMeta || {},
    alias: indexm3pUALtPjVMeta?.alias || [],
    redirect: indexm3pUALtPjVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexm3pUALtPjVMeta?.name ?? "support-privacy___de",
    path: indexm3pUALtPjVMeta?.path ?? "/de/support/privacy",
    meta: indexm3pUALtPjVMeta || {},
    alias: indexm3pUALtPjVMeta?.alias || [],
    redirect: indexm3pUALtPjVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexm3pUALtPjVMeta?.name ?? "support-privacy___mx",
    path: indexm3pUALtPjVMeta?.path ?? "/mx/support/privacy",
    meta: indexm3pUALtPjVMeta || {},
    alias: indexm3pUALtPjVMeta?.alias || [],
    redirect: indexm3pUALtPjVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexm3pUALtPjVMeta?.name ?? "support-privacy___fr",
    path: indexm3pUALtPjVMeta?.path ?? "/fr/support/privacy",
    meta: indexm3pUALtPjVMeta || {},
    alias: indexm3pUALtPjVMeta?.alias || [],
    redirect: indexm3pUALtPjVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexm3pUALtPjVMeta?.name ?? "support-privacy___fr-ca",
    path: indexm3pUALtPjVMeta?.path ?? "/fr-ca/support/privacy",
    meta: indexm3pUALtPjVMeta || {},
    alias: indexm3pUALtPjVMeta?.alias || [],
    redirect: indexm3pUALtPjVMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/index.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresMVrQL1YyP1Meta?.name ?? "support-privacy-special-features___en-US",
    path: special_45featuresMVrQL1YyP1Meta?.path ?? "/support/privacy/special-features",
    meta: special_45featuresMVrQL1YyP1Meta || {},
    alias: special_45featuresMVrQL1YyP1Meta?.alias || [],
    redirect: special_45featuresMVrQL1YyP1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresMVrQL1YyP1Meta?.name ?? "support-privacy-special-features___ca",
    path: special_45featuresMVrQL1YyP1Meta?.path ?? "/ca/support/privacy/special-features",
    meta: special_45featuresMVrQL1YyP1Meta || {},
    alias: special_45featuresMVrQL1YyP1Meta?.alias || [],
    redirect: special_45featuresMVrQL1YyP1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresMVrQL1YyP1Meta?.name ?? "support-privacy-special-features___nl",
    path: special_45featuresMVrQL1YyP1Meta?.path ?? "/nl/support/privacy/special-features",
    meta: special_45featuresMVrQL1YyP1Meta || {},
    alias: special_45featuresMVrQL1YyP1Meta?.alias || [],
    redirect: special_45featuresMVrQL1YyP1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresMVrQL1YyP1Meta?.name ?? "support-privacy-special-features___uk",
    path: special_45featuresMVrQL1YyP1Meta?.path ?? "/uk/support/privacy/special-features",
    meta: special_45featuresMVrQL1YyP1Meta || {},
    alias: special_45featuresMVrQL1YyP1Meta?.alias || [],
    redirect: special_45featuresMVrQL1YyP1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresMVrQL1YyP1Meta?.name ?? "support-privacy-special-features___de",
    path: special_45featuresMVrQL1YyP1Meta?.path ?? "/de/support/privacy/special-features",
    meta: special_45featuresMVrQL1YyP1Meta || {},
    alias: special_45featuresMVrQL1YyP1Meta?.alias || [],
    redirect: special_45featuresMVrQL1YyP1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresMVrQL1YyP1Meta?.name ?? "support-privacy-special-features___mx",
    path: special_45featuresMVrQL1YyP1Meta?.path ?? "/mx/support/privacy/special-features",
    meta: special_45featuresMVrQL1YyP1Meta || {},
    alias: special_45featuresMVrQL1YyP1Meta?.alias || [],
    redirect: special_45featuresMVrQL1YyP1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresMVrQL1YyP1Meta?.name ?? "support-privacy-special-features___fr",
    path: special_45featuresMVrQL1YyP1Meta?.path ?? "/fr/support/privacy/special-features",
    meta: special_45featuresMVrQL1YyP1Meta || {},
    alias: special_45featuresMVrQL1YyP1Meta?.alias || [],
    redirect: special_45featuresMVrQL1YyP1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: special_45featuresMVrQL1YyP1Meta?.name ?? "support-privacy-special-features___fr-ca",
    path: special_45featuresMVrQL1YyP1Meta?.path ?? "/fr-ca/support/privacy/special-features",
    meta: special_45featuresMVrQL1YyP1Meta || {},
    alias: special_45featuresMVrQL1YyP1Meta?.alias || [],
    redirect: special_45featuresMVrQL1YyP1Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/special-features.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listjLVPZnshzKMeta?.name ?? "support-privacy-subprocessor-list___en-US",
    path: subprocessor_45listjLVPZnshzKMeta?.path ?? "/support/privacy/subprocessor-list",
    meta: subprocessor_45listjLVPZnshzKMeta || {},
    alias: subprocessor_45listjLVPZnshzKMeta?.alias || [],
    redirect: subprocessor_45listjLVPZnshzKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listjLVPZnshzKMeta?.name ?? "support-privacy-subprocessor-list___ca",
    path: subprocessor_45listjLVPZnshzKMeta?.path ?? "/ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listjLVPZnshzKMeta || {},
    alias: subprocessor_45listjLVPZnshzKMeta?.alias || [],
    redirect: subprocessor_45listjLVPZnshzKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listjLVPZnshzKMeta?.name ?? "support-privacy-subprocessor-list___nl",
    path: subprocessor_45listjLVPZnshzKMeta?.path ?? "/nl/support/privacy/subprocessor-list",
    meta: subprocessor_45listjLVPZnshzKMeta || {},
    alias: subprocessor_45listjLVPZnshzKMeta?.alias || [],
    redirect: subprocessor_45listjLVPZnshzKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listjLVPZnshzKMeta?.name ?? "support-privacy-subprocessor-list___uk",
    path: subprocessor_45listjLVPZnshzKMeta?.path ?? "/uk/support/privacy/subprocessor-list",
    meta: subprocessor_45listjLVPZnshzKMeta || {},
    alias: subprocessor_45listjLVPZnshzKMeta?.alias || [],
    redirect: subprocessor_45listjLVPZnshzKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listjLVPZnshzKMeta?.name ?? "support-privacy-subprocessor-list___de",
    path: subprocessor_45listjLVPZnshzKMeta?.path ?? "/de/support/privacy/subprocessor-list",
    meta: subprocessor_45listjLVPZnshzKMeta || {},
    alias: subprocessor_45listjLVPZnshzKMeta?.alias || [],
    redirect: subprocessor_45listjLVPZnshzKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listjLVPZnshzKMeta?.name ?? "support-privacy-subprocessor-list___mx",
    path: subprocessor_45listjLVPZnshzKMeta?.path ?? "/mx/support/privacy/subprocessor-list",
    meta: subprocessor_45listjLVPZnshzKMeta || {},
    alias: subprocessor_45listjLVPZnshzKMeta?.alias || [],
    redirect: subprocessor_45listjLVPZnshzKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listjLVPZnshzKMeta?.name ?? "support-privacy-subprocessor-list___fr",
    path: subprocessor_45listjLVPZnshzKMeta?.path ?? "/fr/support/privacy/subprocessor-list",
    meta: subprocessor_45listjLVPZnshzKMeta || {},
    alias: subprocessor_45listjLVPZnshzKMeta?.alias || [],
    redirect: subprocessor_45listjLVPZnshzKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: subprocessor_45listjLVPZnshzKMeta?.name ?? "support-privacy-subprocessor-list___fr-ca",
    path: subprocessor_45listjLVPZnshzKMeta?.path ?? "/fr-ca/support/privacy/subprocessor-list",
    meta: subprocessor_45listjLVPZnshzKMeta || {},
    alias: subprocessor_45listjLVPZnshzKMeta?.alias || [],
    redirect: subprocessor_45listjLVPZnshzKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/privacy/subprocessor-list.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesJrBNXKsRk0Meta?.name ?? "support-support-kb-articles___en-US",
    path: support_45kb_45articlesJrBNXKsRk0Meta?.path ?? "/support/support-kb-articles",
    meta: support_45kb_45articlesJrBNXKsRk0Meta || {},
    alias: support_45kb_45articlesJrBNXKsRk0Meta?.alias || [],
    redirect: support_45kb_45articlesJrBNXKsRk0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesJrBNXKsRk0Meta?.name ?? "support-support-kb-articles___ca",
    path: support_45kb_45articlesJrBNXKsRk0Meta?.path ?? "/ca/support/support-kb-articles",
    meta: support_45kb_45articlesJrBNXKsRk0Meta || {},
    alias: support_45kb_45articlesJrBNXKsRk0Meta?.alias || [],
    redirect: support_45kb_45articlesJrBNXKsRk0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesJrBNXKsRk0Meta?.name ?? "support-support-kb-articles___nl",
    path: support_45kb_45articlesJrBNXKsRk0Meta?.path ?? "/nl/support/support-kb-articles",
    meta: support_45kb_45articlesJrBNXKsRk0Meta || {},
    alias: support_45kb_45articlesJrBNXKsRk0Meta?.alias || [],
    redirect: support_45kb_45articlesJrBNXKsRk0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesJrBNXKsRk0Meta?.name ?? "support-support-kb-articles___uk",
    path: support_45kb_45articlesJrBNXKsRk0Meta?.path ?? "/uk/support/support-kb-articles",
    meta: support_45kb_45articlesJrBNXKsRk0Meta || {},
    alias: support_45kb_45articlesJrBNXKsRk0Meta?.alias || [],
    redirect: support_45kb_45articlesJrBNXKsRk0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesJrBNXKsRk0Meta?.name ?? "support-support-kb-articles___de",
    path: support_45kb_45articlesJrBNXKsRk0Meta?.path ?? "/de/support/support-kb-articles",
    meta: support_45kb_45articlesJrBNXKsRk0Meta || {},
    alias: support_45kb_45articlesJrBNXKsRk0Meta?.alias || [],
    redirect: support_45kb_45articlesJrBNXKsRk0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesJrBNXKsRk0Meta?.name ?? "support-support-kb-articles___mx",
    path: support_45kb_45articlesJrBNXKsRk0Meta?.path ?? "/mx/support/support-kb-articles",
    meta: support_45kb_45articlesJrBNXKsRk0Meta || {},
    alias: support_45kb_45articlesJrBNXKsRk0Meta?.alias || [],
    redirect: support_45kb_45articlesJrBNXKsRk0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesJrBNXKsRk0Meta?.name ?? "support-support-kb-articles___fr",
    path: support_45kb_45articlesJrBNXKsRk0Meta?.path ?? "/fr/support/support-kb-articles",
    meta: support_45kb_45articlesJrBNXKsRk0Meta || {},
    alias: support_45kb_45articlesJrBNXKsRk0Meta?.alias || [],
    redirect: support_45kb_45articlesJrBNXKsRk0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: support_45kb_45articlesJrBNXKsRk0Meta?.name ?? "support-support-kb-articles___fr-ca",
    path: support_45kb_45articlesJrBNXKsRk0Meta?.path ?? "/fr-ca/support/support-kb-articles",
    meta: support_45kb_45articlesJrBNXKsRk0Meta || {},
    alias: support_45kb_45articlesJrBNXKsRk0Meta?.alias || [],
    redirect: support_45kb_45articlesJrBNXKsRk0Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/support/support-kb-articles.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageupGhtubXEKMeta?.name ?? "thank-you-page___en-US",
    path: thank_45you_45pageupGhtubXEKMeta?.path ?? "/thank-you-page",
    meta: thank_45you_45pageupGhtubXEKMeta || {},
    alias: thank_45you_45pageupGhtubXEKMeta?.alias || [],
    redirect: thank_45you_45pageupGhtubXEKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageupGhtubXEKMeta?.name ?? "thank-you-page___ca",
    path: thank_45you_45pageupGhtubXEKMeta?.path ?? "/ca/thank-you-page",
    meta: thank_45you_45pageupGhtubXEKMeta || {},
    alias: thank_45you_45pageupGhtubXEKMeta?.alias || [],
    redirect: thank_45you_45pageupGhtubXEKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageupGhtubXEKMeta?.name ?? "thank-you-page___nl",
    path: thank_45you_45pageupGhtubXEKMeta?.path ?? "/nl/thank-you-page",
    meta: thank_45you_45pageupGhtubXEKMeta || {},
    alias: thank_45you_45pageupGhtubXEKMeta?.alias || [],
    redirect: thank_45you_45pageupGhtubXEKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageupGhtubXEKMeta?.name ?? "thank-you-page___uk",
    path: thank_45you_45pageupGhtubXEKMeta?.path ?? "/uk/thank-you-page",
    meta: thank_45you_45pageupGhtubXEKMeta || {},
    alias: thank_45you_45pageupGhtubXEKMeta?.alias || [],
    redirect: thank_45you_45pageupGhtubXEKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageupGhtubXEKMeta?.name ?? "thank-you-page___de",
    path: thank_45you_45pageupGhtubXEKMeta?.path ?? "/de/thank-you-page",
    meta: thank_45you_45pageupGhtubXEKMeta || {},
    alias: thank_45you_45pageupGhtubXEKMeta?.alias || [],
    redirect: thank_45you_45pageupGhtubXEKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageupGhtubXEKMeta?.name ?? "thank-you-page___mx",
    path: thank_45you_45pageupGhtubXEKMeta?.path ?? "/mx/thank-you-page",
    meta: thank_45you_45pageupGhtubXEKMeta || {},
    alias: thank_45you_45pageupGhtubXEKMeta?.alias || [],
    redirect: thank_45you_45pageupGhtubXEKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageupGhtubXEKMeta?.name ?? "thank-you-page___fr",
    path: thank_45you_45pageupGhtubXEKMeta?.path ?? "/fr/thank-you-page",
    meta: thank_45you_45pageupGhtubXEKMeta || {},
    alias: thank_45you_45pageupGhtubXEKMeta?.alias || [],
    redirect: thank_45you_45pageupGhtubXEKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thank_45you_45pageupGhtubXEKMeta?.name ?? "thank-you-page___fr-ca",
    path: thank_45you_45pageupGhtubXEKMeta?.path ?? "/fr-ca/thank-you-page",
    meta: thank_45you_45pageupGhtubXEKMeta || {},
    alias: thank_45you_45pageupGhtubXEKMeta?.alias || [],
    redirect: thank_45you_45pageupGhtubXEKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thank-you-page.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingHpnR5GDq7YMeta?.name ?? "thankyou-recruiting___en-US",
    path: thankyou_45recruitingHpnR5GDq7YMeta?.path ?? "/thankyou-recruiting",
    meta: thankyou_45recruitingHpnR5GDq7YMeta || {},
    alias: thankyou_45recruitingHpnR5GDq7YMeta?.alias || [],
    redirect: thankyou_45recruitingHpnR5GDq7YMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingHpnR5GDq7YMeta?.name ?? "thankyou-recruiting___ca",
    path: thankyou_45recruitingHpnR5GDq7YMeta?.path ?? "/ca/thankyou-recruiting",
    meta: thankyou_45recruitingHpnR5GDq7YMeta || {},
    alias: thankyou_45recruitingHpnR5GDq7YMeta?.alias || [],
    redirect: thankyou_45recruitingHpnR5GDq7YMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingHpnR5GDq7YMeta?.name ?? "thankyou-recruiting___nl",
    path: thankyou_45recruitingHpnR5GDq7YMeta?.path ?? "/nl/thankyou-recruiting",
    meta: thankyou_45recruitingHpnR5GDq7YMeta || {},
    alias: thankyou_45recruitingHpnR5GDq7YMeta?.alias || [],
    redirect: thankyou_45recruitingHpnR5GDq7YMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingHpnR5GDq7YMeta?.name ?? "thankyou-recruiting___uk",
    path: thankyou_45recruitingHpnR5GDq7YMeta?.path ?? "/uk/thankyou-recruiting",
    meta: thankyou_45recruitingHpnR5GDq7YMeta || {},
    alias: thankyou_45recruitingHpnR5GDq7YMeta?.alias || [],
    redirect: thankyou_45recruitingHpnR5GDq7YMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingHpnR5GDq7YMeta?.name ?? "thankyou-recruiting___de",
    path: thankyou_45recruitingHpnR5GDq7YMeta?.path ?? "/de/thankyou-recruiting",
    meta: thankyou_45recruitingHpnR5GDq7YMeta || {},
    alias: thankyou_45recruitingHpnR5GDq7YMeta?.alias || [],
    redirect: thankyou_45recruitingHpnR5GDq7YMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingHpnR5GDq7YMeta?.name ?? "thankyou-recruiting___mx",
    path: thankyou_45recruitingHpnR5GDq7YMeta?.path ?? "/mx/thankyou-recruiting",
    meta: thankyou_45recruitingHpnR5GDq7YMeta || {},
    alias: thankyou_45recruitingHpnR5GDq7YMeta?.alias || [],
    redirect: thankyou_45recruitingHpnR5GDq7YMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingHpnR5GDq7YMeta?.name ?? "thankyou-recruiting___fr",
    path: thankyou_45recruitingHpnR5GDq7YMeta?.path ?? "/fr/thankyou-recruiting",
    meta: thankyou_45recruitingHpnR5GDq7YMeta || {},
    alias: thankyou_45recruitingHpnR5GDq7YMeta?.alias || [],
    redirect: thankyou_45recruitingHpnR5GDq7YMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45recruitingHpnR5GDq7YMeta?.name ?? "thankyou-recruiting___fr-ca",
    path: thankyou_45recruitingHpnR5GDq7YMeta?.path ?? "/fr-ca/thankyou-recruiting",
    meta: thankyou_45recruitingHpnR5GDq7YMeta || {},
    alias: thankyou_45recruitingHpnR5GDq7YMeta?.alias || [],
    redirect: thankyou_45recruitingHpnR5GDq7YMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-recruiting.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralMTclriTzMGMeta?.name ?? "thankyou-referral___en-US",
    path: thankyou_45referralMTclriTzMGMeta?.path ?? "/thankyou-referral",
    meta: thankyou_45referralMTclriTzMGMeta || {},
    alias: thankyou_45referralMTclriTzMGMeta?.alias || [],
    redirect: thankyou_45referralMTclriTzMGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralMTclriTzMGMeta?.name ?? "thankyou-referral___ca",
    path: thankyou_45referralMTclriTzMGMeta?.path ?? "/ca/thankyou-referral",
    meta: thankyou_45referralMTclriTzMGMeta || {},
    alias: thankyou_45referralMTclriTzMGMeta?.alias || [],
    redirect: thankyou_45referralMTclriTzMGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralMTclriTzMGMeta?.name ?? "thankyou-referral___nl",
    path: thankyou_45referralMTclriTzMGMeta?.path ?? "/nl/thankyou-referral",
    meta: thankyou_45referralMTclriTzMGMeta || {},
    alias: thankyou_45referralMTclriTzMGMeta?.alias || [],
    redirect: thankyou_45referralMTclriTzMGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralMTclriTzMGMeta?.name ?? "thankyou-referral___uk",
    path: thankyou_45referralMTclriTzMGMeta?.path ?? "/uk/thankyou-referral",
    meta: thankyou_45referralMTclriTzMGMeta || {},
    alias: thankyou_45referralMTclriTzMGMeta?.alias || [],
    redirect: thankyou_45referralMTclriTzMGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralMTclriTzMGMeta?.name ?? "thankyou-referral___de",
    path: thankyou_45referralMTclriTzMGMeta?.path ?? "/de/thankyou-referral",
    meta: thankyou_45referralMTclriTzMGMeta || {},
    alias: thankyou_45referralMTclriTzMGMeta?.alias || [],
    redirect: thankyou_45referralMTclriTzMGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralMTclriTzMGMeta?.name ?? "thankyou-referral___mx",
    path: thankyou_45referralMTclriTzMGMeta?.path ?? "/mx/thankyou-referral",
    meta: thankyou_45referralMTclriTzMGMeta || {},
    alias: thankyou_45referralMTclriTzMGMeta?.alias || [],
    redirect: thankyou_45referralMTclriTzMGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralMTclriTzMGMeta?.name ?? "thankyou-referral___fr",
    path: thankyou_45referralMTclriTzMGMeta?.path ?? "/fr/thankyou-referral",
    meta: thankyou_45referralMTclriTzMGMeta || {},
    alias: thankyou_45referralMTclriTzMGMeta?.alias || [],
    redirect: thankyou_45referralMTclriTzMGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyou_45referralMTclriTzMGMeta?.name ?? "thankyou-referral___fr-ca",
    path: thankyou_45referralMTclriTzMGMeta?.path ?? "/fr-ca/thankyou-referral",
    meta: thankyou_45referralMTclriTzMGMeta || {},
    alias: thankyou_45referralMTclriTzMGMeta?.alias || [],
    redirect: thankyou_45referralMTclriTzMGMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou-referral.vue").then(m => m.default || m)
  },
  {
    name: thankyouM1tKYuy0WSMeta?.name ?? "thankyou___en-US",
    path: thankyouM1tKYuy0WSMeta?.path ?? "/thankyou",
    meta: thankyouM1tKYuy0WSMeta || {},
    alias: thankyouM1tKYuy0WSMeta?.alias || [],
    redirect: thankyouM1tKYuy0WSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouM1tKYuy0WSMeta?.name ?? "thankyou___ca",
    path: thankyouM1tKYuy0WSMeta?.path ?? "/ca/thankyou",
    meta: thankyouM1tKYuy0WSMeta || {},
    alias: thankyouM1tKYuy0WSMeta?.alias || [],
    redirect: thankyouM1tKYuy0WSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouM1tKYuy0WSMeta?.name ?? "thankyou___nl",
    path: thankyouM1tKYuy0WSMeta?.path ?? "/nl/thankyou",
    meta: thankyouM1tKYuy0WSMeta || {},
    alias: thankyouM1tKYuy0WSMeta?.alias || [],
    redirect: thankyouM1tKYuy0WSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouM1tKYuy0WSMeta?.name ?? "thankyou___uk",
    path: thankyouM1tKYuy0WSMeta?.path ?? "/uk/thankyou",
    meta: thankyouM1tKYuy0WSMeta || {},
    alias: thankyouM1tKYuy0WSMeta?.alias || [],
    redirect: thankyouM1tKYuy0WSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouM1tKYuy0WSMeta?.name ?? "thankyou___de",
    path: thankyouM1tKYuy0WSMeta?.path ?? "/de/thankyou",
    meta: thankyouM1tKYuy0WSMeta || {},
    alias: thankyouM1tKYuy0WSMeta?.alias || [],
    redirect: thankyouM1tKYuy0WSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouM1tKYuy0WSMeta?.name ?? "thankyou___mx",
    path: thankyouM1tKYuy0WSMeta?.path ?? "/mx/thankyou",
    meta: thankyouM1tKYuy0WSMeta || {},
    alias: thankyouM1tKYuy0WSMeta?.alias || [],
    redirect: thankyouM1tKYuy0WSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouM1tKYuy0WSMeta?.name ?? "thankyou___fr",
    path: thankyouM1tKYuy0WSMeta?.path ?? "/fr/thankyou",
    meta: thankyouM1tKYuy0WSMeta || {},
    alias: thankyouM1tKYuy0WSMeta?.alias || [],
    redirect: thankyouM1tKYuy0WSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: thankyouM1tKYuy0WSMeta?.name ?? "thankyou___fr-ca",
    path: thankyouM1tKYuy0WSMeta?.path ?? "/fr-ca/thankyou",
    meta: thankyouM1tKYuy0WSMeta || {},
    alias: thankyouM1tKYuy0WSMeta?.alias || [],
    redirect: thankyouM1tKYuy0WSMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/thankyou.vue").then(m => m.default || m)
  },
  {
    name: eliteZyXPc9QlFFMeta?.name ?? "tiers-elite___uk",
    path: eliteZyXPc9QlFFMeta?.path ?? "/uk/tiers/elite",
    meta: eliteZyXPc9QlFFMeta || {},
    alias: eliteZyXPc9QlFFMeta?.alias || [],
    redirect: eliteZyXPc9QlFFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteZyXPc9QlFFMeta?.name ?? "tiers-elite___en-US",
    path: eliteZyXPc9QlFFMeta?.path ?? "/tiers/elite",
    meta: eliteZyXPc9QlFFMeta || {},
    alias: eliteZyXPc9QlFFMeta?.alias || [],
    redirect: eliteZyXPc9QlFFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: eliteZyXPc9QlFFMeta?.name ?? "tiers-elite___ca",
    path: eliteZyXPc9QlFFMeta?.path ?? "/ca/tiers/elite",
    meta: eliteZyXPc9QlFFMeta || {},
    alias: eliteZyXPc9QlFFMeta?.alias || [],
    redirect: eliteZyXPc9QlFFMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/elite.vue").then(m => m.default || m)
  },
  {
    name: plusQfbwSkeAVZMeta?.name ?? "tiers-plus___uk",
    path: plusQfbwSkeAVZMeta?.path ?? "/uk/tiers/plus",
    meta: plusQfbwSkeAVZMeta || {},
    alias: plusQfbwSkeAVZMeta?.alias || [],
    redirect: plusQfbwSkeAVZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusQfbwSkeAVZMeta?.name ?? "tiers-plus___en-US",
    path: plusQfbwSkeAVZMeta?.path ?? "/tiers/plus",
    meta: plusQfbwSkeAVZMeta || {},
    alias: plusQfbwSkeAVZMeta?.alias || [],
    redirect: plusQfbwSkeAVZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: plusQfbwSkeAVZMeta?.name ?? "tiers-plus___ca",
    path: plusQfbwSkeAVZMeta?.path ?? "/ca/tiers/plus",
    meta: plusQfbwSkeAVZMeta || {},
    alias: plusQfbwSkeAVZMeta?.alias || [],
    redirect: plusQfbwSkeAVZMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/plus.vue").then(m => m.default || m)
  },
  {
    name: premierfqbp6koKr4Meta?.name ?? "tiers-premier___uk",
    path: premierfqbp6koKr4Meta?.path ?? "/uk/tiers/premier",
    meta: premierfqbp6koKr4Meta || {},
    alias: premierfqbp6koKr4Meta?.alias || [],
    redirect: premierfqbp6koKr4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierfqbp6koKr4Meta?.name ?? "tiers-premier___en-US",
    path: premierfqbp6koKr4Meta?.path ?? "/tiers/premier",
    meta: premierfqbp6koKr4Meta || {},
    alias: premierfqbp6koKr4Meta?.alias || [],
    redirect: premierfqbp6koKr4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: premierfqbp6koKr4Meta?.name ?? "tiers-premier___ca",
    path: premierfqbp6koKr4Meta?.path ?? "/ca/tiers/premier",
    meta: premierfqbp6koKr4Meta || {},
    alias: premierfqbp6koKr4Meta?.alias || [],
    redirect: premierfqbp6koKr4Meta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/premier.vue").then(m => m.default || m)
  },
  {
    name: starterAAT2AD9ifKMeta?.name ?? "tiers-starter___uk",
    path: starterAAT2AD9ifKMeta?.path ?? "/uk/tiers/starter",
    meta: starterAAT2AD9ifKMeta || {},
    alias: starterAAT2AD9ifKMeta?.alias || [],
    redirect: starterAAT2AD9ifKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterAAT2AD9ifKMeta?.name ?? "tiers-starter___en-US",
    path: starterAAT2AD9ifKMeta?.path ?? "/tiers/starter",
    meta: starterAAT2AD9ifKMeta || {},
    alias: starterAAT2AD9ifKMeta?.alias || [],
    redirect: starterAAT2AD9ifKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: starterAAT2AD9ifKMeta?.name ?? "tiers-starter___ca",
    path: starterAAT2AD9ifKMeta?.path ?? "/ca/tiers/starter",
    meta: starterAAT2AD9ifKMeta || {},
    alias: starterAAT2AD9ifKMeta?.alias || [],
    redirect: starterAAT2AD9ifKMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/tiers/starter.vue").then(m => m.default || m)
  },
  {
    name: processd2GoM6jeXcMeta?.name ?? "trial-process___en-US",
    path: processd2GoM6jeXcMeta?.path ?? "/trial/process",
    meta: processd2GoM6jeXcMeta || {},
    alias: processd2GoM6jeXcMeta?.alias || [],
    redirect: processd2GoM6jeXcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processd2GoM6jeXcMeta?.name ?? "trial-process___ca",
    path: processd2GoM6jeXcMeta?.path ?? "/ca/trial/process",
    meta: processd2GoM6jeXcMeta || {},
    alias: processd2GoM6jeXcMeta?.alias || [],
    redirect: processd2GoM6jeXcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processd2GoM6jeXcMeta?.name ?? "trial-process___nl",
    path: processd2GoM6jeXcMeta?.path ?? "/nl/trial/process",
    meta: processd2GoM6jeXcMeta || {},
    alias: processd2GoM6jeXcMeta?.alias || [],
    redirect: processd2GoM6jeXcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processd2GoM6jeXcMeta?.name ?? "trial-process___uk",
    path: processd2GoM6jeXcMeta?.path ?? "/uk/trial/process",
    meta: processd2GoM6jeXcMeta || {},
    alias: processd2GoM6jeXcMeta?.alias || [],
    redirect: processd2GoM6jeXcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processd2GoM6jeXcMeta?.name ?? "trial-process___de",
    path: processd2GoM6jeXcMeta?.path ?? "/de/trial/process",
    meta: processd2GoM6jeXcMeta || {},
    alias: processd2GoM6jeXcMeta?.alias || [],
    redirect: processd2GoM6jeXcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processd2GoM6jeXcMeta?.name ?? "trial-process___mx",
    path: processd2GoM6jeXcMeta?.path ?? "/mx/trial/process",
    meta: processd2GoM6jeXcMeta || {},
    alias: processd2GoM6jeXcMeta?.alias || [],
    redirect: processd2GoM6jeXcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processd2GoM6jeXcMeta?.name ?? "trial-process___fr",
    path: processd2GoM6jeXcMeta?.path ?? "/fr/trial/process",
    meta: processd2GoM6jeXcMeta || {},
    alias: processd2GoM6jeXcMeta?.alias || [],
    redirect: processd2GoM6jeXcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: processd2GoM6jeXcMeta?.name ?? "trial-process___fr-ca",
    path: processd2GoM6jeXcMeta?.path ?? "/fr-ca/trial/process",
    meta: processd2GoM6jeXcMeta || {},
    alias: processd2GoM6jeXcMeta?.alias || [],
    redirect: processd2GoM6jeXcMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/trial/process.vue").then(m => m.default || m)
  },
  {
    name: supportGYqEHNb5hNMeta?.name ?? "warranty-support___uk",
    path: supportGYqEHNb5hNMeta?.path ?? "/uk/warranty/support",
    meta: supportGYqEHNb5hNMeta || {},
    alias: supportGYqEHNb5hNMeta?.alias || [],
    redirect: supportGYqEHNb5hNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportGYqEHNb5hNMeta?.name ?? "warranty-support___en-US",
    path: supportGYqEHNb5hNMeta?.path ?? "/warranty/support",
    meta: supportGYqEHNb5hNMeta || {},
    alias: supportGYqEHNb5hNMeta?.alias || [],
    redirect: supportGYqEHNb5hNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportGYqEHNb5hNMeta?.name ?? "warranty-support___ca",
    path: supportGYqEHNb5hNMeta?.path ?? "/ca/warranty/support",
    meta: supportGYqEHNb5hNMeta || {},
    alias: supportGYqEHNb5hNMeta?.alias || [],
    redirect: supportGYqEHNb5hNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: supportGYqEHNb5hNMeta?.name ?? "warranty-support___ie",
    path: supportGYqEHNb5hNMeta?.path ?? "/ie/warranty/support",
    meta: supportGYqEHNb5hNMeta || {},
    alias: supportGYqEHNb5hNMeta?.alias || [],
    redirect: supportGYqEHNb5hNMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/warranty/support.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MjvyIMUlenMeta?.name ?? "webinars-slug___en-US",
    path: _91slug_93MjvyIMUlenMeta?.path ?? "/webinars/:slug()",
    meta: _91slug_93MjvyIMUlenMeta || {},
    alias: _91slug_93MjvyIMUlenMeta?.alias || [],
    redirect: _91slug_93MjvyIMUlenMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MjvyIMUlenMeta?.name ?? "webinars-slug___ca",
    path: _91slug_93MjvyIMUlenMeta?.path ?? "/ca/webinars/:slug()",
    meta: _91slug_93MjvyIMUlenMeta || {},
    alias: _91slug_93MjvyIMUlenMeta?.alias || [],
    redirect: _91slug_93MjvyIMUlenMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MjvyIMUlenMeta?.name ?? "webinars-slug___nl",
    path: _91slug_93MjvyIMUlenMeta?.path ?? "/nl/webinars/:slug()",
    meta: _91slug_93MjvyIMUlenMeta || {},
    alias: _91slug_93MjvyIMUlenMeta?.alias || [],
    redirect: _91slug_93MjvyIMUlenMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MjvyIMUlenMeta?.name ?? "webinars-slug___uk",
    path: _91slug_93MjvyIMUlenMeta?.path ?? "/uk/webinars/:slug()",
    meta: _91slug_93MjvyIMUlenMeta || {},
    alias: _91slug_93MjvyIMUlenMeta?.alias || [],
    redirect: _91slug_93MjvyIMUlenMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MjvyIMUlenMeta?.name ?? "webinars-slug___de",
    path: _91slug_93MjvyIMUlenMeta?.path ?? "/de/webinars/:slug()",
    meta: _91slug_93MjvyIMUlenMeta || {},
    alias: _91slug_93MjvyIMUlenMeta?.alias || [],
    redirect: _91slug_93MjvyIMUlenMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MjvyIMUlenMeta?.name ?? "webinars-slug___mx",
    path: _91slug_93MjvyIMUlenMeta?.path ?? "/mx/webinars/:slug()",
    meta: _91slug_93MjvyIMUlenMeta || {},
    alias: _91slug_93MjvyIMUlenMeta?.alias || [],
    redirect: _91slug_93MjvyIMUlenMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MjvyIMUlenMeta?.name ?? "webinars-slug___fr",
    path: _91slug_93MjvyIMUlenMeta?.path ?? "/fr/webinars/:slug()",
    meta: _91slug_93MjvyIMUlenMeta || {},
    alias: _91slug_93MjvyIMUlenMeta?.alias || [],
    redirect: _91slug_93MjvyIMUlenMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MjvyIMUlenMeta?.name ?? "webinars-slug___fr-ca",
    path: _91slug_93MjvyIMUlenMeta?.path ?? "/fr-ca/webinars/:slug()",
    meta: _91slug_93MjvyIMUlenMeta || {},
    alias: _91slug_93MjvyIMUlenMeta?.alias || [],
    redirect: _91slug_93MjvyIMUlenMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexfR7k4n860eMeta?.name ?? "webinars___en-US",
    path: indexfR7k4n860eMeta?.path ?? "/webinars",
    meta: indexfR7k4n860eMeta || {},
    alias: indexfR7k4n860eMeta?.alias || [],
    redirect: indexfR7k4n860eMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexfR7k4n860eMeta?.name ?? "webinars___ca",
    path: indexfR7k4n860eMeta?.path ?? "/ca/webinars",
    meta: indexfR7k4n860eMeta || {},
    alias: indexfR7k4n860eMeta?.alias || [],
    redirect: indexfR7k4n860eMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexfR7k4n860eMeta?.name ?? "webinars___nl",
    path: indexfR7k4n860eMeta?.path ?? "/nl/webinars",
    meta: indexfR7k4n860eMeta || {},
    alias: indexfR7k4n860eMeta?.alias || [],
    redirect: indexfR7k4n860eMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexfR7k4n860eMeta?.name ?? "webinars___uk",
    path: indexfR7k4n860eMeta?.path ?? "/uk/webinars",
    meta: indexfR7k4n860eMeta || {},
    alias: indexfR7k4n860eMeta?.alias || [],
    redirect: indexfR7k4n860eMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexfR7k4n860eMeta?.name ?? "webinars___de",
    path: indexfR7k4n860eMeta?.path ?? "/de/webinars",
    meta: indexfR7k4n860eMeta || {},
    alias: indexfR7k4n860eMeta?.alias || [],
    redirect: indexfR7k4n860eMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexfR7k4n860eMeta?.name ?? "webinars___mx",
    path: indexfR7k4n860eMeta?.path ?? "/mx/webinars",
    meta: indexfR7k4n860eMeta || {},
    alias: indexfR7k4n860eMeta?.alias || [],
    redirect: indexfR7k4n860eMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexfR7k4n860eMeta?.name ?? "webinars___fr",
    path: indexfR7k4n860eMeta?.path ?? "/fr/webinars",
    meta: indexfR7k4n860eMeta || {},
    alias: indexfR7k4n860eMeta?.alias || [],
    redirect: indexfR7k4n860eMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: indexfR7k4n860eMeta?.name ?? "webinars___fr-ca",
    path: indexfR7k4n860eMeta?.path ?? "/fr-ca/webinars",
    meta: indexfR7k4n860eMeta || {},
    alias: indexfR7k4n860eMeta?.alias || [],
    redirect: indexfR7k4n860eMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfcTuPvAQLMeta?.name ?? "webinars-thank-you-slug___en-US",
    path: _91slug_93OfcTuPvAQLMeta?.path ?? "/webinars/thank-you/:slug()",
    meta: _91slug_93OfcTuPvAQLMeta || {},
    alias: _91slug_93OfcTuPvAQLMeta?.alias || [],
    redirect: _91slug_93OfcTuPvAQLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfcTuPvAQLMeta?.name ?? "webinars-thank-you-slug___ca",
    path: _91slug_93OfcTuPvAQLMeta?.path ?? "/ca/webinars/thank-you/:slug()",
    meta: _91slug_93OfcTuPvAQLMeta || {},
    alias: _91slug_93OfcTuPvAQLMeta?.alias || [],
    redirect: _91slug_93OfcTuPvAQLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfcTuPvAQLMeta?.name ?? "webinars-thank-you-slug___nl",
    path: _91slug_93OfcTuPvAQLMeta?.path ?? "/nl/webinars/thank-you/:slug()",
    meta: _91slug_93OfcTuPvAQLMeta || {},
    alias: _91slug_93OfcTuPvAQLMeta?.alias || [],
    redirect: _91slug_93OfcTuPvAQLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfcTuPvAQLMeta?.name ?? "webinars-thank-you-slug___uk",
    path: _91slug_93OfcTuPvAQLMeta?.path ?? "/uk/webinars/thank-you/:slug()",
    meta: _91slug_93OfcTuPvAQLMeta || {},
    alias: _91slug_93OfcTuPvAQLMeta?.alias || [],
    redirect: _91slug_93OfcTuPvAQLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfcTuPvAQLMeta?.name ?? "webinars-thank-you-slug___de",
    path: _91slug_93OfcTuPvAQLMeta?.path ?? "/de/webinars/thank-you/:slug()",
    meta: _91slug_93OfcTuPvAQLMeta || {},
    alias: _91slug_93OfcTuPvAQLMeta?.alias || [],
    redirect: _91slug_93OfcTuPvAQLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfcTuPvAQLMeta?.name ?? "webinars-thank-you-slug___mx",
    path: _91slug_93OfcTuPvAQLMeta?.path ?? "/mx/webinars/thank-you/:slug()",
    meta: _91slug_93OfcTuPvAQLMeta || {},
    alias: _91slug_93OfcTuPvAQLMeta?.alias || [],
    redirect: _91slug_93OfcTuPvAQLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfcTuPvAQLMeta?.name ?? "webinars-thank-you-slug___fr",
    path: _91slug_93OfcTuPvAQLMeta?.path ?? "/fr/webinars/thank-you/:slug()",
    meta: _91slug_93OfcTuPvAQLMeta || {},
    alias: _91slug_93OfcTuPvAQLMeta?.alias || [],
    redirect: _91slug_93OfcTuPvAQLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OfcTuPvAQLMeta?.name ?? "webinars-thank-you-slug___fr-ca",
    path: _91slug_93OfcTuPvAQLMeta?.path ?? "/fr-ca/webinars/thank-you/:slug()",
    meta: _91slug_93OfcTuPvAQLMeta || {},
    alias: _91slug_93OfcTuPvAQLMeta?.alias || [],
    redirect: _91slug_93OfcTuPvAQLMeta?.redirect,
    component: () => import("/codebuild/output/src1146024831/src/horizon/pages/webinars/thank-you/[slug].vue").then(m => m.default || m)
  }
]